import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TextP } from '../../components/Text';
import WebsiteAppWrapper from '../../components/WebsiteAppWrapper';
import { useMatchingEmailPreferences } from '../../hooks';
import * as Styled from '../../styles/ProjectInterestEmailStyles';
import { MATCHING_EMAIL_PREFERENCES } from './const';

const MatchingEmailPreferences = () => {
  const location: any = useLocation();
  const {
    error,
    isLoading,
    hookHandleUpdateMatchingEmailPreferences,
  } = useMatchingEmailPreferences();

  useEffect(() => {
    hookHandleUpdateMatchingEmailPreferences(new URLSearchParams(location.search));
  }, []);

  return (
    <WebsiteAppWrapper>
      <Styled.BodyStyled>
        {!isLoading && (
          <div>
            <Styled.MessageStyled>
              <TextP>
                {!error ? MATCHING_EMAIL_PREFERENCES.SUCCESS : MATCHING_EMAIL_PREFERENCES.FAILED}
              </TextP>
            </Styled.MessageStyled>
          </div>
        )}
      </Styled.BodyStyled>
    </WebsiteAppWrapper>
  );
};

export default MatchingEmailPreferences;
