import React from 'react';
import * as Styled from './styles';

type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  type?: string
}

export const Modal = ({
  children, isOpen, className, type,
}: ModalProps) => (
  <Styled.WrapperStyled isOpen={isOpen} className={className} type={type}>
    <Styled.ContentStyled type={type}>
      {children}
    </Styled.ContentStyled>
  </Styled.WrapperStyled>
);
