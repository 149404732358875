import styled from 'styled-components';
import { fonts } from '../../styles/theme';

export const BodyWrapper = styled.div`
  padding: 0.625rem 0 1.5rem;
`;

export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const ButtonWrapper = styled.div`
  margin-right: 0.625rem;
`;

export const ModalStyled = styled.div`
  ${fonts.font1.H14};
  line-height: 1.5rem;
  max-width: 600px;
`;
