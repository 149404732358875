import { useState } from 'react';
import { useAPI } from './useAPI';

export const useMatchingEmailPreferences = () => {
  const { publicUpdate } = useAPI();

  const [error, setError] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const getParamsValue = (params: URLSearchParams) => {
    const userEmail = decodeURIComponent(params.get('email') as string);
    return userEmail;
  };

  const handleUpdateMatchingEmailPreferences = async (params: URLSearchParams) => {
    try {
      const userEmail = getParamsValue(params);
      if (userEmail === 'null') throw new Error('no email');

      const results = await publicUpdate('public/matchEmail', { email: userEmail });
      if (results.error) throw new Error(results.error);

      setIsloading(false);
    } catch (err) {
      setError(true);
      setIsloading(false);
      return err;
    }
  };

  return {
    error,
    isLoading,

    hookHandleUpdateMatchingEmailPreferences: handleUpdateMatchingEmailPreferences,
  };
};
