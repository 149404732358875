import React from 'react';
import { colours, fonts } from '../../styles/theme';
import { projectStatus, ProjectStatusOptions } from '../../utils/researcherConst';
import { RadioInput } from '../RadioInput';
import { TextP } from '../Text';
import {
  FlexBoxStyled, FormStatusPickerStyled, LabelStyled, LabelTitleStyled, RadioWrapper,
} from './styles';
import { FORM_STATUS_PICKER } from './const';
import FormSubtitle from '../FormSubtitle';

type FormStatusPickerProps = {
  handleChange: (value: string, field: string) => void
  selectedStatus?: string
  error?: string
  hideHeader?: boolean
}

const FormStatusPicker = ({
  selectedStatus, handleChange, hideHeader, error,
}: FormStatusPickerProps) => (
  <FormStatusPickerStyled>
    {!hideHeader && (
      <>
        <FormSubtitle
          subtitle={FORM_STATUS_PICKER.TITLE}
          margin="small"
        />
        <TextP color={error ? colours.colour7 : ''}>{FORM_STATUS_PICKER.DESCRIPTION}</TextP>
      </>
    )}
    <FlexBoxStyled>
      {ProjectStatusOptions.map((option) => (
        <RadioWrapper key={option.value}>
          <RadioInput
            onChange={() => handleChange(option.value, 'desired_status')}
            name="nextProjectStatus"
            id={option.value}
            checked={selectedStatus === option.value}
            value={option.value}
          />
          <LabelStyled htmlFor={option.value}>
            <LabelTitleStyled>
              <TextP font={fonts.font1.H14B}>
                {projectStatus[option.value]}
              </TextP>
            </LabelTitleStyled>
            <TextP>
              {option.discription}
            </TextP>
          </LabelStyled>
        </RadioWrapper>
      ))}
    </FlexBoxStyled>
  </FormStatusPickerStyled>
);

export default FormStatusPicker;
