export const toastConst: {
  [key: string]: {
    title: string,
    discription?: string,
    action?: string,
    actionMessage?: string,
  }
} = {
  draftSaved: {
    title: 'Draft saved!',
  },
  updatedCritical: {
    title: 'Your research was resubmitted for review.',
  },
  updateNonCritical: {
    title: 'Your research changes were saved and updated',
  },
  changeInterest: {
    title: 'Interest Updated!',
  },
  interestedCreate: {
    title: 'Thank you for your interest',
    discription: 'Your contact information will be sent to the research team to follow up with you about this opportunity.',
    action: 'checkbox',
    actionMessage: 'Don’t show this message again',
  },
  interestedDelete: {
    title: 'You have deleted #Opportunity Name',
    action: 'undo',
  },
  interestedArchived: {
    title: 'You have archived #Opportunity Name',
    action: 'undo',
  },
  interestedUnarchived: {
    title: 'You have unarchived #Opportunity Name',
    action: 'undo',
  },
  withdrawInterest: {
    title: 'You have withdrawn interest from #Opportunity Name',
    action: 'undo',
  },
  interestedDeleteUndo: {
    title: 'You have undone delete #Opportunity Name',
  },
  interestedArchivedUndo: {
    title: 'You have undone archive #Opportunity Name',
  },
  interestedUnarchivedUndo: {
    title: 'You have undone unarchive #Opportunity Name',
  },
  withdrawInterestUndo: {
    title: 'You have undone withdraw interest from #Opportunity Name',
  },
  restored: {
    title: 'Successfully restored',
  },
};
