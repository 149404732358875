import { useState } from 'react';
import { useResearcherAPI } from './useResearcherAPI';
import { useResultForm } from './useResultForm';

export const useEditResultForm = () => {
  const { updateResult, getResult } = useResearcherAPI();

  const {
    projectType,
    formInfo,
    resourceFile,
    linkStudies,
    handleChange,
    handleFile,
    handleToggle,
    getLinkStudies,
    setProjectType,
    setFormInfo,
    changes,
    resetChanges,
    handleSingleValidation,
    formErrors,
    setFormErrors,
    handleRemoveFile,
  } = useResultForm();

  const [isSubmiting, setIsSubmiting] = useState(false);

  const getProjectResult = async (projectId: number) => {
    try {
      const projectResult = await getResult(projectId, projectType);
      if (projectResult.patient_partners_engagement_strategy === '') {
        projectResult.patient_oriented_project = false;
      } else {
        projectResult.patient_oriented_project = true;
      }
      if (projectResult.linked_study_id) {
        projectResult.link_study = true;
      } else {
        projectResult.link_study = false;
      }

      if (projectResult) {
        setFormInfo(projectResult);
      }
    } catch (error: any) {
      setFormErrors({ error: error.message });
    }
  };

  const handleSubmit = async (formValues: IResult | any) => {
    const data = new FormData();

    Object.keys(formValues).forEach((key) => {
      data.append(key, formValues[key]);
    });

    if (resourceFile) {
      data.append('resourceFile', resourceFile);
    }
    const res = await updateResult(data);
    return res;
  };

  const handleValidation = async (formValues: IResult | any, validation: any) => {
    setIsSubmiting(true);
    setFormErrors({});
    try {
      await validation.validate({
        ...formValues,
      }, { abortEarly: false });
      setIsSubmiting(false);
    } catch (error: any) {
      const errors: { [key: string]: string } = {};
      error.inner.forEach((element: any) => {
        errors[element.path] = element.message;
      });
      setIsSubmiting(false);
      throw errors;
    }
  };

  const handleGetSignedURl = async () => {
    if (formInfo.fileLink) {
      window.open(formInfo.fileLink, '_blank');
    }
  };

  return {
    formInfo,
    resourceFile,
    formErrors,
    isSubmiting,
    projectType,
    linkStudies,
    setProjectType,
    getProjectResult,
    handleToggle,
    handleChange,
    handleFile,
    handleSubmit,
    handleValidation,
    getLinkStudies,
    changes,
    resetChanges,
    setFormErrors,
    handleSingleValidation,
    handleRemoveFile,
    handleGetSignedURl,
  };
};
