import React from 'react';
import { useResetPassword } from '../../hooks';
import { TextInput } from '../../components';
import Button from '../../components/Button';
import { Figure } from '../../components/Figure';
import { TextH1 } from '../../components/Text';
import {
  ButtonWrapper, HeaderStyled, MainStyled, PageWrapper,
} from '../../styles/loginStyle';
import { fonts } from '../../styles/theme';
import { imageList } from '../../utils/imageList';
import { LinkComponent } from '../../components/Link';

const ForcePasswordChange = () => {
  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    errors,
    handleForceChangePassword,
  } = useResetPassword();

  return (
    <PageWrapper>
      <HeaderStyled>
        <LinkComponent url="login">
          <Figure imageSrc={imageList.logoFull} alt="logo" maxWidth="150px" marginBottom="1.375rem" />
        </LinkComponent>
        <TextH1 font={fonts.font1.H19}>Reset your password</TextH1>
      </HeaderStyled>
      <MainStyled>
        <form onSubmit={handleForceChangePassword} autoComplete="off">
          <TextInput
            label="New Password"
            type="password"
            description="Your password must be at least 8 characters, and include lowercase and uppercase alphabetical characters, a number and a special character."
            textValue={password}
            setTextValue={setPassword}
            theme="column"
            error={errors.password}
          />
          <TextInput
            label="Confirm Password"
            type="password"
            textValue={confirmPassword}
            setTextValue={setConfirmPassword}
            theme="column"
            error={errors.confirmPassword}
          />
          <ButtonWrapper theme="center">
            <Button onClick={() => ''} id="loginButton">
              <p>Set New Password</p>
            </Button>
          </ButtonWrapper>
        </form>
      </MainStyled>
    </PageWrapper>
  );
};

export default ForcePasswordChange;
