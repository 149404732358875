import React from 'react';
import { VOLUNTEER_DASHBOAOD_HERO } from './const';
import * as Styled from './styles';
import { TextInput } from '../../TextInput';

type VolunteerDashboardHeroPropTypes = {
  userName: string
  searchValue: string,
  searchValueHandler: (value: string) => void
}
export const VolunteerDashboardHero = ({
  userName, searchValue, searchValueHandler,
}: VolunteerDashboardHeroPropTypes) => (
  <Styled.HeroWrapper>
    <p>{VOLUNTEER_DASHBOAOD_HERO.USER_MESSAGE} {userName}</p>

    <Styled.SearchBarWrapper>
      <TextInput
        setTextValue={searchValueHandler}
        textValue={searchValue}
        placeholder={VOLUNTEER_DASHBOAOD_HERO.SEARCH.PLACEHOLDER}
        theme="searchSecondary"
        isSearchBar
      />
    </Styled.SearchBarWrapper>
  </Styled.HeroWrapper>
);
