export const FUNDING_INFORMATION = {
  TITLE: 'Funding Information',
  TITLE_MODILE: 'Opportunity Date & Funding Information',

  FUNDING_AGENCY: {
    LABEL: 'Primary Funding Agency',
    LABEL_DESCRIPTION: 'Who is funding this project?',
  },
  RECUITMENT_DATES: {
    TITLE: 'Estimated Recruitment End Date',
    LABEL_DESCRIPTION: 'What is the estimated date for when recruitment is expected to be closed?',
    PLACEHOLDER: 'YYYY-MM-DD',
  },
};
