export const PPO_DETAIL = {
  PROJECT_INTEREST_MESSAGE: {
    TITLE: "You're Interested!",
    DEAULT_INTEREST: ['if you have not been contacted by the research team in 5 business days, please feel free to contact the primary contacts listed on this page.'],
    DIRECT_LINK_INTEREST: [
      'Thank you for expressing your interest in participating in our online study.',
      'To participate in the online study, click the button below to proceed.',
    ],
    DIRECT_LINK_BUTTON: 'Proceed to Online Study',
  },
};
