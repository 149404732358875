import styled from 'styled-components';
import { colours } from '../../styles/theme';

type BodyStyledProps = {
  readonly checked: boolean
}

export const InsideStyled = styled.div<BodyStyledProps>`
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background: ${({ checked }) => (checked ? colours.colour2 : colours.colour4)};
`;

export const BodyStyled = styled.div<BodyStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: ${colours.colour4};
  border: 1px solid ${({ checked }) => (checked ? colours.colour2 : colours.colour29)};
`;

export const WrapperStyled = styled.label`
  cursor: pointer;
  display: inline-block;
  input {
    display: none;
  }
`;

export const InputStyled = styled.input`
  &:disabled {
    cursor: not-allowed;
  }
`;
