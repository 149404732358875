import React, { ReactNode, useEffect } from 'react';
import {
  useNavigate, useParams, useLocation,
} from 'react-router-dom';
import { useCookies } from '../../hooks/useCookies';

type ProtectedRouteProps = {
  children: ReactNode
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const locaction = useLocation();
  const { checkAuthCookie } = useCookies();

  useEffect(() => {
    const authCookie = checkAuthCookie();
    if (!authCookie) {
      if ((locaction.pathname.includes('study') || locaction.pathname.includes('ppo')) && params.id) {
        const projectType = locaction.pathname.includes('study') ? 'study' : 'ppo';
        navigate(`/login?project=${params.id}&type=${projectType}`);
      } else if (locaction.pathname.includes('myProfile')) {
        const profileType = locaction.pathname.includes('email-notifications') ? 'email' : 'myProfile';
        navigate(`/login?myProfile=${profileType}`);
      } else {
        navigate('/login');
      }
    }
  }, []);

  return (<div>{children}</div>);
};

export default ProtectedRoute;
