import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';

type LabelStyledProps = {
  readonly theme?: string
};

type InputStyledProps = {
  readonly theme?: string
}

type InputWrapperProps = {
  readonly width?: string
  readonly theme?: string
}

type SelectedStyledProps = {
  readonly theme?: string
}

export const SelectedStyled = styled.select<SelectedStyledProps>`
  width: 100%;
  padding: 0.438rem;
  color: ${colours.colour6};
  background: ${colours.colour4};
  border-radius: 0.25rem;
  
  ${({ theme }) => (theme === 'third' ? fonts.font1.H16 : fonts.font1.H12)};
  margin-top: ${({ theme }) => (theme === 'secondary' ? '0' : '0.4rem')};
  height: ${({ theme }) => (theme === 'third' ? toRem(50) : toRem(38))};

  border: ${({ theme }) => {
    switch (theme) {
      case 'minimal':
        return `1px solid ${colours.colour29}`;
      case 'secondary':
        return `1px solid ${colours.colour29}`;
      default:
        return `1px solid ${colours.colour5}`;
    }
  }};

  &:disabled {
    cursor: not-allowed;
    background: ${colours.colour39};
    color: ${colours.colour6};
    opacity: 0.9;
  }
`;

export const LabelStyled = styled.label<LabelStyledProps>`
  color: ${colours.colour6};
  font-weight: 500;
  line-height: 1.33rem;

  display: ${({ theme }) => (theme === 'column' ? 'block' : '')};
  text-align: ${({ theme }) => (theme === 'column' ? 'center' : 'left')};

  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return fonts.font1.H14B;
      case 'third':
        return fonts.font1.H18;
      default:
        return fonts.font1.H17B;
    }
  }};

  margin: ${({ theme }) => {
    switch (theme) {
      case 'column':
        return ' 1.375rem auto 0.28571429rem;';
      case 'secondary':
        return '0 1rem 0 0';
      default:
        return '1.375rem 0 0.28571429rem';
    }
  }};
`;

export const InputStyled = styled.input<InputStyledProps>`
  ${fonts.font1.H12};
  line-height: 0.9rem;

  padding: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '0.3rem';
      default:
        return '0.67857143rem 1rem';
    }
  }};

  border:  ${({ theme }) => {
    switch (theme) {
      case 'round':
        return `3px solid ${colours.colour19}`;
      default:
        return `1px solid ${colours.colour5}`;
    }
  }};

  border-radius: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '30px';
      default:
        return '4px';
    }
  }};

  margin-top: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '0';
      case 'column':
        return '0';
      case 'secondary':
        return 0;
      default:
        return '0.875rem';
    }
  }};

  width: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '100%';
      default:
        return '100%';
    }
  }};
`;

export const ParagrahStyled = styled.p`
  ${({ theme }) => (theme === 'third' ? fonts.font1.H16B : fonts.font1.H14)};
  overflow-wrap: break-word;
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  width: ${({ width }) => width || '100%'};
  display: ${({ theme }) => (theme === 'secondary' ? 'flex' : 'block')};
  align-items: ${({ theme }) => (theme === 'secondary' ? 'center' : '')};

  p {
    margin-top: ${({ theme }) => (theme === 'secondary' ? 0 : '0.35rem')};
  }
`;

export const DescriptionWrapper = styled.div`
  margin-top: 0.25rem;
`;
