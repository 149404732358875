import React from 'react';
import { fonts } from '../../styles/theme';
import { LinkComponent } from '../Link';
import { TextH3 } from '../Text';
import { ArrowStyled, ErrorDivStyled, ErrorWrapper } from './styles';

type ExampleProps = {
  message: string
  linkError?: { text: string, type: string, url?: string }[]
};

export const ErrorMessage = ({ message, linkError }: ExampleProps) => (
  <ErrorWrapper>
    <ArrowStyled />
    <ErrorDivStyled>
      {linkError ? (
        <TextH3 font={fonts.font1.H12B} lineHeight="1rem">
          {linkError.map((link: { text: string, type: string, url?: string }) => (
            <React.Fragment key={link.text}>
              {link.type === 'link' ? (
                <LinkComponent url={link.url || ''} theme="error">
                  {link.text}
                </LinkComponent>
              ) : (
                <span>
                  {' '} {link.text} {' '}
                </span>
              )}
            </React.Fragment>
          ))}
          link error
        </TextH3>
      ) : (
        <TextH3 font={fonts.font1.H12B} lineHeight="1rem">
          {message}
        </TextH3>
      )}
    </ErrorDivStyled>
  </ErrorWrapper>
);
