import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useCookies } from '../../../../hooks/useCookies';
import { useVolunteerProfile } from '../../../../hooks/useVolunteerProfile';
import { ProfileWrapper } from '../../../../styles/MyProfileStyles';
import EmailNotification from '../VolunteerEmailNotifications';
import PasswordReset from '../PasswordReset';
import ResearchPreferences from '../VolunteerResearchPreference';
import SideMenu from '../VolunteerSideMenu';
import MyProfileBasic from '../VolunteerMyProfile';
import MyProfileUpdateSuccess from '../MyProfileUpdateSuccess';
import { useResetPassword } from '../../../../hooks';
import * as Styled from './styles';
import { MyProfileSideMenu, urlToMenu } from '../../../../utils/profileConst';
import { DropdownSelect } from '../../../../components/DropdownSelect';
import environment from '../../../../config/environment';

type IProps = {
  page: string
  subMenu: string
}
const VolunteerProfile = ({ page, subMenu }: IProps) => {
  const { getCookie, deleteCookie } = useCookies();
  const {
    categories,
    formErrors,
    menu,
    regions,
    user,
    updateStatus,
    getCategories,
    getRegions,
    getUserInfo,
    handleChange,
    handleCheck,
    handleHealthCategories,
    handleLocation,
    handleMenu,
    handleValidation,
    handleUpdate,
    handleSingleValidation,
    setMenu,
    path,
    setUpdateStatus,
  } = useVolunteerProfile();

  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    oldPassword,
    setOldPassword,
    handleChangePassword,
    errors,
    handlePasswordSingleValidation,
  } = useResetPassword();

  const updatePassword = async () => {
    const updated = await handleChangePassword();
    if (updated) {
      setUpdateStatus('success');
    }
  };

  useEffect(() => {
    try {
      const userInfo = getCookie(environment.app.cookieName);
      const decodedUser: any = jwtDecode(userInfo);
      getUserInfo(parseInt(decodedUser.id, 10), 'volunteer');
      getRegions();
      getCategories();
    } catch (err) {
      deleteCookie(environment.app.cookieName);
    }
  }, []);

  useEffect(() => {
    setMenu(urlToMenu[page]);
  }, [page]);

  return (
    <ProfileWrapper>
      {updateStatus === 'success' ? (
        <MyProfileUpdateSuccess userType="volunteer" setStatus={handleUpdate} path={path} />
      ) : (
        <>
          <Styled.DesktopWrapper>
            <SideMenu
              menu={menu}
              onClick={handleMenu}
              subMenu={subMenu}
            />
          </Styled.DesktopWrapper>

          <Styled.MobileWrapper>
            <DropdownSelect
              id="menuOptions"
              placeholder="Menu Options"
              label={menu}
              selected={{ label: menu, value: menu }}
              onChange={(value) => handleMenu(value.value)}
              options={MyProfileSideMenu}
            />
          </Styled.MobileWrapper>

          <Styled.FormWrapper>
            {page === 'myProfile' && (
              <MyProfileBasic
                user={user}
                handleCheck={handleCheck}
                handleChange={handleChange}
                handleValidation={handleValidation}
                errors={formErrors}
                handleSingleValidation={handleSingleValidation}
              />
            )}
            {page === 'researcher-preferences' && (
              <ResearchPreferences
                healthCategories={categories}
                user={user}
                errors={formErrors}
                handleChange={handleChange}
                regions={regions}
                selectedAreas={user.health_categories}
                handleCheck={handleCheck}
                handleLocation={handleLocation}
                handleResearchArea={handleHealthCategories}
                handleValidation={handleValidation}
                handleSingleValidation={handleSingleValidation}
              />
            )}
            {page === 'email-notifications' && (
              <EmailNotification
                handleChange={handleChange}
                handleValidation={handleValidation}
                user={user}
              />
            )}
            {page === 'password-reset' && (
              <PasswordReset
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                oldPassword={oldPassword}
                setOldPassword={setOldPassword}
                userType="volunteer"
                handleChangePassword={updatePassword}
                errors={errors}
                handleSingleValidation={handlePasswordSingleValidation}
              />
            )}
          </Styled.FormWrapper>

        </>
      )}
    </ProfileWrapper>
  );
};

export default VolunteerProfile;
