import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
import { TextP } from '../Text';
import * as Styled from './styles';
import { toastConst } from '../../utils/ToastConst';
import { fonts } from '../../styles/theme';
import { Checkbox } from '../CheckBox';

type ToastProps = {
  message?: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  type?: string
  name?: string
  handleButton?: () => void
  handleCheck?: (check: boolean) => void
  checkmark?: boolean
  bottomOffset?: boolean
}
const Toast = ({
  message = '',
  isOpen,
  setIsOpen,
  type = 'basic',
  name,
  handleButton,
  handleCheck,
  checkmark,
  bottomOffset = true,
}: ToastProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const closeTimer = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 10000);
  };

  const handleCreateToastMessage = (part: string) => {
    if (type === 'basic' || !name) {
      switch (part) {
        case 'title':
          return toastConst[message]?.title;
        default:
          return toastConst[message]?.discription;
      }
    }

    switch (part) {
      case 'title':
        return toastConst[message]?.title.replace('#Opportunity Name', name);
      default:
        return toastConst[message]?.discription;
    }
  };

  useEffect(() => {
    if (isOpen) {
      closeTimer();
    }
  }, [isOpen]);

  return (
    <Styled.ToastStyled isOpen={isOpen} bottomOffset={bottomOffset}>
      <FontAwesomeIcon icon={checkmark ? 'check-circle' : 'info-circle'} />
      <Styled.BodyStyled>
        <Styled.ToastTitleStyled>
          <TextP font={fonts.font1.H14B}>{handleCreateToastMessage('title')}</TextP>
        </Styled.ToastTitleStyled>
        <TextP>{handleCreateToastMessage('discription')}</TextP>
        {handleButton && toastConst[message]?.action === 'undo' && (
          <Styled.ToastButtonWrapper>
            <Button onClick={handleButton} id="undo" theme="plain">
              Undo
            </Button>
          </Styled.ToastButtonWrapper>
        )}

        {handleCheck && toastConst[message]?.action === 'checkbox' && (
          <Styled.ToastButtonWrapper>
            <Checkbox
              label={toastConst[message]?.actionMessage || ''}
              isChecked={isChecked}
              onChange={(value) => {
                handleCheck(value);
                setIsChecked(true);
              }}
            />
          </Styled.ToastButtonWrapper>
        )}
      </Styled.BodyStyled>
      <Styled.ButtonWrapper>
        <Button onClick={() => setIsOpen(false)} theme="plain" id="toastClose" buttonWidth="100%">
          <FontAwesomeIcon icon="xmark" size="lg" />
        </Button>
      </Styled.ButtonWrapper>
    </Styled.ToastStyled>
  );
};

export default Toast;
