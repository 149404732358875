import { useState } from 'react';
import * as Yup from 'yup';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useAPI } from './useAPI';
import { useCookies } from './useCookies';
import { UpdateMyProfileSchema, UpdateResearchPreferenceSchema, UpdateMyProfileOnBlurOtherSchema } from '../utils/validation';
import { InitialVolunteerUser, menuToUrl } from '../utils/profileConst';
import environment from '../config/environment';

export const useVolunteerProfile = () => {
  const navigate = useNavigate();

  const {
    getUser, getHealthCategory, getHealthRegions, updateUser,
  } = useAPI();
  const { createCookie } = useCookies();

  const [categories, setCategories] = useState<{ category: string, items: { id: number, item: string, category: string }[] }[]>([]);
  const [user, setUser] = useState<IVolunteerUser>(InitialVolunteerUser);
  const [regions, setRegions] = useState([]);
  const [menu, setMenu] = useState('My Profile');
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [updateStatus, setUpdateStatus] = useState('');
  const [path, setPath] = useState('/myProfile');
  const [researchPreferencesMessage, setResearchPreferencesMessage] = useState(false);

  const handleSingleValidation = async (value: any, field: string, type?: string) => {
    let errors: { [key: string]: string } = {};
    try {
      if (field === 'ethnicity_other' || field === 'travel_locations') {
        await Yup.reach(UpdateMyProfileOnBlurOtherSchema, field).validate(value);
      } else if (type === 'profile') {
        await Yup.reach(UpdateMyProfileSchema, field).validate(value);
      } else {
        await Yup.reach(UpdateResearchPreferenceSchema, field).validate(value);
      }
      errors = formErrors;
      delete errors[field];
      if (field === 'ethnicityOther') delete errors.ethnicity_other;
      setFormErrors({ ...errors });
    } catch (error: any) {
      errors[field] = error.message;
      setFormErrors({ ...formErrors, ...errors });
    }
  };

  const getRegions = async () => {
    const healthRegions = await getHealthRegions();
    setRegions(healthRegions);
  };

  const getCategories = async () => {
    const healthCategories = await getHealthCategory();
    setCategories(healthCategories);
  };

  const getUserInfo = async (id: number, type: string) => {
    const userInfo = await getUser(id, type);
    setUser({
      ...userInfo,
      phone_number: userInfo.phone_number.substring(userInfo.phone_number.length - 10, userInfo.phone_number.length),
      birth_date: userInfo.birth_date.substring(0, 10),
      travel_locations: userInfo.travel_locations ? userInfo.travel_locations : [],
      health_categories: userInfo.health_categories ? userInfo.health_categories : {},
      ethnicity_is_other: userInfo.ethnicity ? userInfo.ethnicity.includes('Other') : false,
    });
    if (!userInfo.profile_complete) {
      const researchPreferencesComplete = !!userInfo.health_categories && !!userInfo.travel_locations && !!userInfo.project_type_preference && !!userInfo.travel_distance;
      setResearchPreferencesMessage(!researchPreferencesComplete);
    }
  };

  const handleUpdate = () => {
    setUpdateStatus('');
  };

  const handleUpdateSuccess = () => {
    setUpdateStatus('success');
  };

  const handleChange = (value: string | number | boolean, field: string) => {
    if (!value && (field === 'child1_birthyear' || field === 'child2_birthyear' || field === 'child3_birthyear')) {
      setUser((prev) => ({
        ...prev,
        [field]: null,
      }));
    } else {
      setUser((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
    if (field === 'sex' || field === 'affiliated_health_authority_region_id' || field === 'travel_distance') handleSingleValidation(value, field, field === 'sex' ? 'profile' : '');
  };

  const handleCheck = (checked: boolean, keyName: string, keyType: string) => {
    switch (keyType) {
      case 'gender': {
        let updateGender;
        if (!checked) {
          updateGender = user.gender ? user.gender.filter((gend: string) => gend !== keyName) : [];
        } else {
          updateGender = user.gender ? [...user.gender, keyName] : [keyName];
        }
        setUser({
          ...user,
          gender: updateGender,
        });
        handleSingleValidation(updateGender, 'gender', 'profile');
        break;
      }
      case 'ethnicity': {
        let updateEthnicity;
        if (!checked) {
          if (keyName === 'Other') user.ethnicity_is_other = false;
          updateEthnicity = user.ethnicity ? user.ethnicity.filter((eth: string) => eth !== keyName) : [];
        } else {
          if (keyName === 'Other') user.ethnicity_is_other = true;
          updateEthnicity = user.ethnicity ? [...user.ethnicity, keyName] : [keyName];
        }
        setUser({
          ...user,
          ethnicity: updateEthnicity,
        });
        handleSingleValidation(updateEthnicity, 'ethnicity', 'profile');
        break;
      }
      case 'project': {
        let updateProjectPreference;
        if (!checked) {
          updateProjectPreference = user.project_type_preference ? user.project_type_preference.filter((loc: string) => loc !== keyName) : [];
        } else {
          updateProjectPreference = user.project_type_preference ? [...user.project_type_preference, keyName] : [keyName];
        }
        setUser({
          ...user,
          project_type_preference: updateProjectPreference,
        });
        handleSingleValidation(updateProjectPreference, 'project_type_preference');
        break;
      }
      default:
        break;
    }
  };

  const handleHealthCategories = (name: string, id: number, category: string) => {
    const areas = user.health_categories;
    const tempIndex = areas.findIndex((item) => item.id === id);
    if (tempIndex !== -1) {
      areas.splice(tempIndex, 1);
    } else {
      areas.push({ name, id, category });
    }
    setUser({ ...user, health_categories: areas });
    handleSingleValidation(areas, 'health_categories');
  };

  const handleLocation = (checked: boolean, region: { name: string, id: number }) => {
    let updateLocation;
    if (!checked) {
      updateLocation = user.travel_locations ? user.travel_locations.filter((loc: number) => loc !== region.id) : [];
    } else {
      updateLocation = user.travel_locations ? [...user.travel_locations, region.id] : [region.id];
    }
    setUser({
      ...user,
      travel_locations: updateLocation,
    });
    handleSingleValidation(updateLocation, 'travel_locations');
  };

  const handleMenu = (menuOption: string) => {
    if (menuOption === 'My Profile') {
      navigate('/myProfile');
    } else {
      navigate(`/myProfile/${menuToUrl[menuOption]}`);
    }
  };

  const handleValidation = async (data: IVolunteerUser, page: string) => {
    const errors: { [key: string]: string } = {};
    const userInfo = data;
    try {
      if (page === 'MyProfile') {
        await UpdateMyProfileSchema.validate({
          ...userInfo,
        }, { abortEarly: false });
        setFormErrors({});
        const status = await updateUser(userInfo);

        /** renewing cookie  */
        if (status.data.jwtToken) {
          const renewCookie = status.data.jwtToken;
          if (renewCookie === '') return;
          const decodedUser: any = jwtDecode(renewCookie);
          setResearchPreferencesMessage(!decodedUser.profile_complete);
          createCookie(environment.app.cookieName, JSON.stringify(renewCookie), 'volunteer');
        }
        setPath('researcher-preferences');
        if (status.status === 'success') setUpdateStatus('success');
        return;
      }
      if (page === 'ResearchPreferences') {
        await UpdateResearchPreferenceSchema.validate({
          ...userInfo,
        }, { abortEarly: false });
        setFormErrors({});
        const status = await updateUser(userInfo);
        setResearchPreferencesMessage(false);

        /** renewing cookie  */
        if (status.data.jwtToken) {
          const renewCookie = status.data.jwtToken;
          if (renewCookie === '') return;
          createCookie(environment.app.cookieName, JSON.stringify(renewCookie), 'volunteer');
        }

        if (status.status === 'success') setUpdateStatus('success');
        setPath('/myProfile/email-notifications');
        return;
      }
      updateUser(userInfo);
      setPath('/dashboard');
      setUpdateStatus('success');
    } catch (error: any) {
      if (error.inner) {
        error.inner.forEach((element: any) => {
          errors[element.path] = element.message;
        });
      }
      setFormErrors(errors);
    }
  };

  return {
    categories,
    formErrors,
    menu,
    regions,
    user,
    updateStatus,
    getCategories,
    getRegions,
    getUserInfo,
    handleChange,
    handleCheck,
    handleHealthCategories,
    handleLocation,
    handleMenu,
    handleValidation,
    handleUpdate,
    handleUpdateSuccess,
    handleSingleValidation,
    researchPreferencesMessage,
    setMenu,
    path,
    setUpdateStatus,
  };
};
