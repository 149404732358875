import styled, { keyframes } from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type ToastStyledProps = {
  readonly isOpen: boolean
  readonly bottomOffset?: boolean
}

const fadeIn = keyframes`
  0% { bottom: 0; opacity: 0 }
  100% { bottom: 16px; opacity: 1; }
`;

export const ToastStyled = styled.div<ToastStyledProps>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  background: ${colours.colour32};
  border: 1px solid ${colours.colour38};
  box-shadow: 0px 2px 16px rgba(26, 26, 26, 0.04), 0px 16px 24px -2px rgba(26, 26, 26, 0.08);
  border-radius: 6px;
  max-width: 680px;
  position: fixed;
  bottom: 16px;
  left: 0;
  right: 0;
  margin: 0 1rem ${({ bottomOffset }) => (bottomOffset ? '5rem' : 0)};
  animation-name: ${fadeIn};
  animation-duration: 0.5s;

  @media (min-width: 770px) {
    margin: 0 auto;
  }
`;

export const BodyStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 1.125rem;
  ${fonts.font1.H14};
`;

export const ButtonWrapper = styled.div`
  width: 1rem;
  display: flex;
  justify-content: center;
`;

export const ToastTitleStyled = styled.div`
  ${fonts.font1.H14B};
  margin-bottom: 0.5rem;
`;

export const ToastButtonWrapper = styled.div`
  margin-top: 0.5rem;
`;
