import React from 'react';
import { TextInput } from '../../../../components';
import { ButtonStyled } from '../../../../components/Button/styles';
import { Checkbox } from '../../../../components/CheckBox';
import { ErrorMessage } from '../../../../components/ErrorMessage';
import FormSubtitle from '../../../../components/FormSubtitle';
import { SelectInput } from '../../../../components/SelectInput';
import { TextH1, TextH2, TextP } from '../../../../components/Text';
import { Toggle } from '../../../../components/Toggle';
import { fonts } from '../../../../styles/theme';
import { profileWillingnessToTravelOptions } from '../../../../utils/profileConst';
import {
  ButtonWrapper,
  CheckBoxErrorStyled,
  DefaultWrapper, FlexboxStyledCenter, SectionStyled,
} from '../../../../styles/MyProfileStyles';
import VolunteerHealthResearchAreas from '../VolunteerHealthResearchAreas';
import { NumberInput } from '../../../../components/NumberInput';
import { CheckBoxStyled } from '../VolunteerMyProfile/styles';

type ResearchPreferenceProps = {
  healthCategories: { category: string, items: { id: number, item: string, category: string }[] }[]
  user: IVolunteerUser
  errors: any
  regions: { name: string, id: number }[]
  selectedAreas: { name: string, id: number, category: string, }[]
  handleChange: (value: string | number | boolean, field: string) => void
  handleLocation: (check: boolean, region: { id: number, name: string }) => void
  handleCheck: (check: boolean, keyName: string, keyType: string) => void
  handleResearchArea: (name: string, id: number, category: string) => void
  handleValidation: (formValues: IVolunteerUser, page: string) => void
  handleSingleValidation: (value: any, field: string) => void
}

const ResearchPreferences = ({
  healthCategories, user, regions, selectedAreas, errors, handleCheck, handleResearchArea, handleValidation, handleChange, handleLocation, handleSingleValidation,
}: ResearchPreferenceProps) => (
  <DefaultWrapper>
    <SectionStyled>
      <TextH1 font={fonts.font1.H26} textAlgin="left">Research Preferences</TextH1>
    </SectionStyled>
    <SectionStyled>
      <TextH2 font={fonts.font1.H18} textAlgin="left" id="PARENTS">PARENTS & GUARDIANS</TextH2>
    </SectionStyled>
    <SectionStyled theme="L">
      <FlexboxStyledCenter>
        <Toggle checked={user.for_child || false} onChange={() => handleChange(!user.for_child, 'for_child')} />
        <TextP font={fonts.font1.H14} lineHeight="1.33rem">
          I am a parent/guardian who will be searching for research opportunities for my child/children.
        </TextP>
      </FlexboxStyledCenter>
      {user.for_child && (
        <>
          <SectionStyled>
            <TextP font={fonts.font1.H14} lineHeight="1.33rem">
              If YES, please enter each child’s birth year. This information will assist us in learning more about our volunteers. Remember to select the health categories under &quot;Child &amp; Adolescent Health&quot; under Health Research Interests to receive relevant research opportunities for your child(ren).
            </TextP>
          </SectionStyled>
          <SectionStyled theme="M">
            <NumberInput
              label="Child 1 Birth Year"
              placeholder="XXXX"
              numberValue={user.child1_birthyear}
              description="eg. 2019"
              setNumberValue={(value) => handleChange(value, 'child1_birthyear')}
              isRequired={false}
              theme="secondary"
              padding="normal"
              error={errors.child1_birthyear}
              unbold
              onBlurAction={() => handleSingleValidation(user.child1_birthyear, 'child1_birthyear')}
            />
          </SectionStyled>
          <SectionStyled theme="M">
            <NumberInput
              label="Child 2 Birth Year"
              placeholder="XXXX"
              numberValue={user.child2_birthyear}
              setNumberValue={(value) => handleChange(value, 'child2_birthyear')}
              isRequired={false}
              theme="secondary"
              padding="normal"
              error={errors.child2_birthyear}
              unbold
              onBlurAction={() => handleSingleValidation(user.child2_birthyear, 'child2_birthyear')}
            />
          </SectionStyled>
          <SectionStyled theme="M">
            <NumberInput
              label="Child 3 Birth Year"
              placeholder="XXXX"
              numberValue={user.child3_birthyear}
              setNumberValue={(value) => handleChange(value, 'child3_birthyear')}
              isRequired={false}
              theme="secondary"
              padding="normal"
              error={errors.child3_birthyear}
              unbold
              onBlurAction={() => handleSingleValidation(user.child3_birthyear, 'child3_birthyear')}
            />
          </SectionStyled>
        </>
      )}
    </SectionStyled>
    <SectionStyled>
      <TextH2 font={fonts.font1.H18} textAlgin="left" id="TRAVEL">TRAVEL PREFERENCES</TextH2>
    </SectionStyled>
    <SectionStyled theme="L">
      <SectionStyled theme="M">
        <SelectInput
          label="Location"
          placeholder="Select your location"
          value={user.affiliated_health_authority_region_id}
          onChange={(value) => handleChange(Number(value), 'affiliated_health_authority_region_id')}
          id="affiliated_health_authority_region_id"
          description="Select the region where you are located. For more information, see map of regions "
          options={regions}
          error={errors.affiliated_health_authority_region_id}
          linkLabel="here"
          link="https://assets.ctfassets.net/rbx11ml43wqx/5BYPxokCeDsg9mFH5fONER/a8d6e2a1de79d3af251ccaeb49ac35df/REACH_BC_-_BC_Regions.pdf"
          newWindow
        />
      </SectionStyled>
      <SectionStyled theme="M">
        <SelectInput
          placeholder="Select your willingness to travel"
          label="Willingness to Travel"
          value={user.travel_distance}
          onChange={(value) => handleChange(value, 'travel_distance')}
          id="travel_distance"
          description="Select your preference for traveling to a research opportunity. You may wish to choose &quot;Travel to any region&quot; for a greater chance of being matched to a research opportunity."
          options={profileWillingnessToTravelOptions}
          error={errors.travel_distance}
        />
      </SectionStyled>

      {user.travel_distance === 'health_authority'
        && (
          <>
            <SectionStyled>
              <FormSubtitle subtitle="Locations of Interest" isRequired />
              <SectionStyled>
                <TextP font={fonts.font1.H14}>
                  Select all that apply.
                </TextP>
              </SectionStyled>
            </SectionStyled>
            <SectionStyled>
              {
                regions.map((item: { name: string, id: number }) => (
                  <CheckBoxStyled key={item.id}>
                    <Checkbox label={item.name} isChecked={user.travel_locations ? user.travel_locations.includes(item.id) : false} onChange={(checked) => handleLocation(checked, item)} />
                  </CheckBoxStyled>
                ))
              }
            </SectionStyled>
            <CheckBoxErrorStyled>
              {errors.travel_locations && user.travel_distance === 'health_authority' && <ErrorMessage message={errors.travel_locations} />}
            </CheckBoxErrorStyled>
          </>
        )}
    </SectionStyled>
    <SectionStyled>
      <TextH2 font={fonts.font1.H18} textAlgin="left" id="PARTICIPATION">PARTICIPATION PREFERENCES</TextH2>
    </SectionStyled>
    <SectionStyled theme="L">
      <SectionStyled>
        <FormSubtitle subtitle="Types of Research Opportunities" isRequired unbold />
        <TextP font={fonts.font1.H14}>
          How do you want to participate in health research? Select all that apply.
        </TextP>
      </SectionStyled>
      <SectionStyled>
        <Checkbox
          label="Be a study participant. E.g. participate in a clinical trial, answer a survey, or test a medical device."
          isChecked={user.project_type_preference ? user.project_type_preference.includes('study') : false}
          onChange={(checked) => handleCheck(checked, 'study', 'project')}
          textTransform=""
        />
      </SectionStyled>
      <SectionStyled theme="M">
        <SectionStyled>
          <Checkbox
            label="Be a patient partner. E.g. help advise a research team in designing or carrying out a research study."
            isChecked={user.project_type_preference ? user.project_type_preference.includes('ppo') : false}
            onChange={(checked) => handleCheck(checked, 'ppo', 'project')}
            textTransform=""
          />
        </SectionStyled>
        <CheckBoxErrorStyled>
          {errors.project_type_preference && <ErrorMessage message={errors.project_type_preference} />}
        </CheckBoxErrorStyled>
      </SectionStyled>
      <SectionStyled>
        <TextInput
          textValue={user.site_code ? user.site_code : ''}
          setTextValue={(value) => handleChange(value, 'site_code')}
          label="Registration Site Code"
          description="If you were given a REACH BC registration code, enter it here."
          isRequired={false}
        />
      </SectionStyled>
    </SectionStyled>
    <div id="AREAS">
      <SectionStyled theme="M">
        <TextH2 font={fonts.font1.H18} textAlgin="left" id="PARTICIPATION">
          HEALTH RESEARCH INTERESTS
        </TextH2>
      </SectionStyled>
      <TextP font={fonts.font1.H14} lineHeight="1.33rem">
        Select all the health research categories you&apos;re interested in receiving research opportunities about. The more you select, the more research opportunities you may be matched to.
      </TextP>
      <VolunteerHealthResearchAreas
        categoriesMap={healthCategories}
        handleChange={handleResearchArea}
        selectedResearchAreas={selectedAreas}
      />
      <CheckBoxErrorStyled>
        {errors.health_categories && <ErrorMessage message={errors.health_categories} />}
      </CheckBoxErrorStyled>
    </div>
    <ButtonWrapper>
      <ButtonStyled
        onClick={() => handleValidation(user, 'ResearchPreferences')}
        theme="myProfileButton"
      >
        Save Preferences
      </ButtonStyled>
    </ButtonWrapper>
  </DefaultWrapper>
);
export default ResearchPreferences;
