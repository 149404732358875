import toRem from '../utils/toRem';

export const colours = {
  colour1: '#ff7900',
  colour2: '#FFB700',
  colour3: '#b0d9d5',
  colour4: '#fff',
  colour5: '#000',
  colour6: '#212121',
  colour7: '#e91c0d',
  colour8: '#1b1c1d',
  colour9: '#e0e1e2',
  colour10: '#00000499',
  colour11: '#27292a',
  colour12: '#cacbcd',
  colour13: '#0000001A',
  colour14: '#00587a',
  colour15: '#006a94',
  colour16: '#7A7574',
  colour17: '#d4d4d5',
  colour18: '#f2f2f2',
  colour19: '#efefef',
  colour20: '#2DAC87',
  colour21: '#B0D9D5',
  colour22: '#3a863d',
  colour23: '#EEF3F4',
  colour24: '#767676',
  colour25: '#6d7278',
  colour26: '#a5ccc8',
  colour27: '#FFE1BE',
  colour28: '#F2F2F3',
  colour29: '#BEC0C5',
  colour30: '#0E0E0E',
  colour31: '#E86825',
  colour32: '#EEF3F8',
  colour33: '#D4E7FA',
  colour34: '#1B1C1D1A',
  colour35: '#838791',
  colour36: '#50545E',
  colour37: '#FFD6D7',
  colour38: '#B0D9DE',
  colour39: '#D9DADD',
  colour40: '#202532',
  colour41: '#EAEAEB',
  colour42: '#fffaf3',
  colour43: '#DBD0B9',
  colour44: '#FFE194',
  colour45: '#d1d2d4',
  colour46: '#999999',
  colour47: '#FF0000',
  colour48: '#FFEABE',
  colour49: '#F9F9F9',
  colour50: '#F4F4F4',
};

export const fonts = {
  font1: {
    H10: `font-size: ${toRem(10)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H11: `font-size: ${toRem(11)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H12: `font-size: ${toRem(12)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H12N: `font-size: ${toRem(12)}; font-weight: 600; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H12B: `font-size: ${toRem(12)}; font-weight: 700; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H13: `font-size: ${toRem(13)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H13B: `font-size: ${toRem(13)}; font-weight: 700; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H14: `font-size: ${toRem(14)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H14N: `font-size: ${toRem(14)}; font-weight: 600; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H14B: `font-size: ${toRem(14)}; font-weight: 700; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H15: `font-size: ${toRem(15)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H15B: `font-size: ${toRem(15)}; font-weight: 700; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H16: `font-size: ${toRem(16)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H16B: `font-size: ${toRem(16)}; font-weight: 600; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H17: `font-size: ${toRem(17.5)}; font-weight: 500; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H17B: `font-size: ${toRem(17.5)}; font-weight: 600; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H18: `font-size: ${toRem(18)}; font-weight: 500; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H18B: `font-size: ${toRem(18)}; font-weight: 700; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H19: `font-size: ${toRem(19.5)}; font-weight: 500; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H20: `font-size: ${toRem(20)}; font-weight: 500; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H20B: `font-size: ${toRem(20)}; font-weight: 700; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H21: `font-size: ${toRem(21)}; font-weight: 500; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H21B: `font-size: ${toRem(21)}; font-weight: 700; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H24: `font-size: ${toRem(24)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H24B: `font-size: ${toRem(24)}; font-weight: 600; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H26: `font-size: ${toRem(26)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H26B: `font-size: ${toRem(26)}; font-weight: 600; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H28: `font-size: ${toRem(28)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H28B: `font-size: ${toRem(28)}; font-weight: 600; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H31: `font-size: ${toRem(31)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H31B: `font-size: ${toRem(31)}; font-weight: 600; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H32B: `font-size: ${toRem(32)}; font-weight: 700; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H36: `font-size: ${toRem(36)}; font-weight: 400; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H36B: `font-size: ${toRem(36)}; font-weight: 600; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
    H40B: `font-size: ${toRem(40)}; font-weight: 600; font-family: Mulish, Helvetica Neue, Arial, Helvetica, sans-serif;`,
  },
};

export const breakpoints = {
  desktop: '820px', // desktop starts at 500px
};
