import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colours, fonts } from '../../styles/theme';
import { TextH1, TextP } from '../Text';
import * as Styled from './styles';
import { projectStatus } from '../../utils/researcherConst';
import StatusTag from '../StatusTag';
import { LinkComponent } from '../Link';
import Button from '../Button';

type HeroProps = {
  type: string
  title?: string
  topSubtitle?: string
  bottomSubtitle?: string
  patientOriented?: string
  status?: string
  setCurrentTab: (tab: string) => void
  currentTab: string
}

const Hero = ({
  type, status, title, bottomSubtitle, topSubtitle, patientOriented,
  currentTab, setCurrentTab,
}: HeroProps) => (
  <>
    <Styled.LinkWrapper>
      {currentTab === 'Results' ? (
        <Button
          id="backToDetails"
          onClick={() => setCurrentTab('Detail')}
          theme="plainTertiary"
        >
          <Styled.LinkContent>
            <FontAwesomeIcon icon="chevron-left" />
            <TextP>Back to study details</TextP>
          </Styled.LinkContent>
        </Button>
      ) : (
        <LinkComponent url="/dashboard" theme="returnLink">
          <Styled.LinkContent>
            <FontAwesomeIcon icon="chevron-left" />
            <TextP>Back to Dashboard</TextP>
          </Styled.LinkContent>
        </LinkComponent>
      )}
    </Styled.LinkWrapper>
    <Styled.HeroWrapper>
      <Styled.TextWrapper marginTheme="studyType">
        <TextP color={colours.colour25} textAlgin="center">
          {type === 'study' && 'Research Study'}
          {type === 'ppo' && 'Patient Partner Research Opportunity'}
          {patientOriented && ' ｜ Patient Oriented Research'}
        </TextP>
      </Styled.TextWrapper>
      <Styled.TextWrapper marginTheme="hero">
        <TextP font={fonts.font1.H24} color={colours.colour25} textAlgin="center">
          {topSubtitle}
        </TextP>
      </Styled.TextWrapper>
      <Styled.TextWrapper marginTheme="hero">
        <TextH1 font={fonts.font1.H36}>
          {title}
        </TextH1>
      </Styled.TextWrapper>
      {bottomSubtitle
        && (
          <Styled.TextWrapper marginTheme="hero">
            <TextP font={fonts.font1.H24} color={colours.colour25} textAlgin="center">
              {bottomSubtitle}
            </TextP>
          </Styled.TextWrapper>
        )}
      {status && (
        <StatusTag
          theme={status}
          label={projectStatus[status]}
          fontSize={fonts.font1.H14}
          padding="0.375rem 0.625rem"
        />
      )}
    </Styled.HeroWrapper>
  </>
);

export default Hero;
