import styled from 'styled-components';
import { fonts } from '../../styles/theme';

export const ModalStyled = styled.div`
  padding: 0.5rem 2.5rem 1.5rem;
  max-width: 828px;
  letter-spacing: 0.2px;
`;

export const HeaderWapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const BodyWrapper = styled.div`
  margin-bottom: 3.25rem;

  h4 {
    ${fonts.font1.H20B};
    line-height: 2rem;
    margin-bottom: 1rem;
  }

  p {
    ${fonts.font1.H16};
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ButtonWrapper = styled.div`
  margin-right: 1rem;
`;
