export const VOLUNTEER_PROFILE_BASIC = {
  TITLE: 'My Profile',
  FIRST_NAME: {
    LABEL: 'First Name',
  },
  LAST_NAME: {
    LABEL: 'Last Name',
  },

  PHONE: {
    LABEL: 'Phone Number',
    DESCRIPTION: 'Please enter numbers only. Example: 6045555555',
  },

  BIRTH: {
    LABEL: 'Date of Birth (YYYY-MM-DD)',
  },

  EMAIL: {
    LABEL: 'Email',
    EDIT_EMAIL: {
      LABEL: 'To update your email address, please contact ',
      LINK: {
        URL: 'admin@reachbc.ca',
        LABEL: 'admin@reachbc.ca',
      },
    },
  },
  SEX: {
    LABEL: 'Sex',
    DISCRIPTION: 'What is your sex assigned at birth?',
  },
  GENDER: {
    LABEL: 'Gender',
    DISCRIPTION: 'How do you identify?',
  },
  ETHNICITY: {
    LABEL: 'Ethnicity/Population Group',
    DISCRIPTION: 'Which of the following categories best describes you?',
    DISCRIPTION_TWO: 'If you self-identify as multiracial, please select all that apply. Please note, this information is captured to understand how well our volunteers are representative of the population and will not be used for matching to health research opportunity at this time. Note: we recognize the categories for racial self-identification are imperfect, but are being used to be consistent with the Canadian census.',
    OTHER_LABEL: 'Please specify:',
  },
  BUTTONS: {
    SAVE: 'Save Preferences',
  },
};
