import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colours, fonts } from '../../styles/theme';
import * as Style from './styles';
import { TextP } from '../Text';

const PublicToolTip = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Style.PublicToolTipStyled
      onMouseLeave={() => setIsOpen(false)}
      onMouseEnter={() => setIsOpen(true)}
    >
      <Style.ToolTipBodyStyled>
        {isOpen && (
          <>
            <Style.ToolTipTextWrapper>
              <TextP font={fonts.font1.H14} lineHeight="1.33rem">
                This information is visible to the public.
              </TextP>
            </Style.ToolTipTextWrapper>
            <Style.ArrowStyled />
          </>
        )}
      </Style.ToolTipBodyStyled>
      <Style.IconWrapper>
        <FontAwesomeIcon icon="eye" color={colours.colour20} size="xs" width="13px" />
      </Style.IconWrapper>
    </Style.PublicToolTipStyled>
  );
};

export default PublicToolTip;
