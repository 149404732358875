import React from 'react';
import AppWrapper from '../../../components/AppWrapper';
import ProjectForm from '../../../components/ProjectForm';
import { ppoForm } from '../../../utils/researcherConst';
import PPOForm from '../../../components/PPOForm';
import { usePPOEdit } from './usePPOEdit';

const PPOEdit = () => {
  const hook = usePPOEdit();

  return (
    <AppWrapper>
      <ProjectForm
        generalInfo={ppoForm}
        heroInfo={hook.formHook.heroInfo}
        handleSubmit={hook.handleSubmitClick}
        handleDraft={hook.handleUpdateDraft}
        handleChange={hook.formHook.handleEditChange}
        desiredStatus
        isEdit
        recruitmentEndDate={hook.formHook.formInfo.recruitment_end}
        canEdit={hook.formHook.canEdit}
        selectedStatus={hook.formHook.formInfo.desired_status}
        projectPath={`/project/ppo/${hook.formHook.formInfo.id}`}
        isSubmiting={hook.formHook.isSubmiting}
        isDraft={hook.formHook.isDraft}
        status={hook.formHook.formInfo.status}
        setToastIsOpen={hook.formHook.setToastIsOpen}
        setModalType={hook.formHook.setModalType}
        rightButtonAction={hook.handleRightButtonAction}
        leftButtonAction={hook.handleLeftButtonAction}
        modalOpen={hook.formHook.modalIsOpen}
        setModalOpen={hook.formHook.setModalIsOpen}
        modalInfo={hook.formHook.modalType}
        changesMade={hook.formHook.changesMade}
        handleNavigate={hook.handleNavigate}
        toastIsOpen={hook.formHook.toastIsOpen}
        toastText={hook.formHook.toastMessage}
        errors={hook.formHook.formErrors}
        stepBackStatus={hook.formHook.stepBackStatus}
        setStatusModal={hook.formHook.setStatusModal}
        statusModal={hook.formHook.statusModal}
      >
        <PPOForm
          formInfo={hook.formHook.formInfo}
          formErrors={hook.formHook.formErrors}
          teamMembers={hook.formHook.teamMembers}
          collaboratingOrganizations={hook.formHook.collaboratingOrganizations}
          collaborators={hook.formHook.collaborators}
          healthAuthorityRegionOptions={hook.formHook.healthRegions}
          healthCategories={hook.formHook.healthCategory}
          hospitalList={hook.formHook.hospitals}
          selectedResearchAreas={hook.formHook.selectedResearchAreas}
          adminComments={hook.formHook.formInfo.admin_comments}
          handleChange={hook.formHook.handleEditChange}
          handleAddInput={hook.formHook.handleAddInput}
          handleChangeAddedInput={hook.formHook.handleEditChangeAddedInput}
          handleChangeResearchAreas={hook.formHook.toggleResearchArea}
          handleCheck={hook.formHook.handleEditCheck}
          handleRemoveInput={hook.formHook.handleRemoveInput}
          handleToggle={hook.formHook.handleEditToggle}
          checkPI={hook.formHook.checkPI}
          checkTeamMember={hook.formHook.checkTeamMember}
          isDraft={hook.formHook.isDraft}
          handleDelete={() => {
            hook.formHook.setModalType('delete');
            hook.formHook.setModalIsOpen(true);
          }}
          canEdit={hook.formHook.canEdit}
          forceChange={hook.formHook.forceChange}
          handleSingleValidation={hook.formHook.handleSingleValidation}
        />
      </ProjectForm>
    </AppWrapper>
  );
};

export default PPOEdit;
