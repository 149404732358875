// gmail added for testing purposes
export const domainWhitelist = [
  'alzheimerbc.org',
  'arthritisresearch.ca',
  'bccancer.bc.ca',
  'bcchr.ca',
  'spor-bcphcrn.ca',
  'bcstrokecentre.ca',
  'bcsupportunit.ca',
  'bcit.ca',
  'cansolveckd.ca',
  'csfs.org',
  'hiphealth.ca',
  'cfri.ca',
  'child-bright.ca',
  'cw.bc.ca',
  'diabetesaction.ca',
  'fnha.ca',
  'fraserclinicaltrials.org',
  'fraserhealth.ca',
  'icord.org',
  'interiorhealth.ca',
  'viha.ca',
  'jointhealth.org',
  'kpu.ca',
  'msfhr.org',
  'gov.bc.ca',
  'northernhealth.ca',
  'bcpsqc.ca',
  'popdata.bc.ca',
  'providencehealth.ca',
  'providencehealth.bc.ca',
  'phsa.ca',
  'rickhanseninstitute.org',
  'royalroads.ca',
  'sfu.ca',
  'coquitlam.ca',
  'tru.ca',
  'twu.ca',
  'ubc.ca',
  'unbc.ca',
  'uvic.ca',
  'domain7.com',
  'vch.ca',
  'ufv.ca',
  'bccdc.ca',
  'genomebc.ca',
  'prostatecentre.com',
  'blood.ca',
  'ovcare.ca',
  'phri.ca',
  'bccsu.ca',
  'bcgsc.ca',
  'bcehs.ca',
  'bccrc.ca',
  'bcahsn.ca',
  'bccfe.ca',
  'mytwu.ca',
  'reachbc.ca',
  'mytru.ca',
  'viu.ca',
  'islandhealth.ca',
  'healthresearchbc.ca',
  'ihe.ca',
  'nautilusdigital.com',
  'qubiologics.com',
];
