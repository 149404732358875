export const PPO_ELIGILITY_CRITERIA = {
  TITLE: 'Eligibility Criteria',
  DESCRIPTION: 'Who is eligible to participate?',
  AGE: {
    LABEL: 'Age Requirement',
    MIN_LABEL: 'Must be between',
    MAX_LABEL: 'and',
    END_LABEL: 'old',
  },
  SEXES: {
    LABEL: 'Sexes Eligible',
    DESCRIPTION: 'Select all that apply.',
  },
  GENDERS: {
    LABEL: 'Genders Eligible',
    DESCRIPTION: 'Leave as "All" unless you are specifically studying gender differences. Remember, gender is complex. It\'s possible that despite envisioning your study as being applicable only to women, for example, that some trans men and non-binary people might also have that particular health need or issue.',
    DESCRIPTION_TWO: 'Select all that apply.',
  },
  ADDITIONAL_ELIGIBILITY: {
    LABEL: 'Additional Eligibility Criteria',
    DESCRIPTION: 'Any additional information to describe who would be your ideal patient partner. E.g. Ability to read and understand English. Comfortable with computers and technology. (Max character limit is 700).',
  },
  HEALTH_CATEGORIES: {
    LABEL: 'Health Research Categories',
    DESCRIPTION: 'Which of the following categories does your research study fall under? Choose at least one subcategory below. If your study does not fit in one of the subcategories listed, select the subcategory “Other” in the most appropriate category for your research.',
  },
  TARGETS: {
    TITLE: 'What is your target sample size?',
    LABEL_PATIENT: 'Patient Partners/Non-Control/Experimental Group',
    LABEL_CONTROL: 'Controls',
  },
};
