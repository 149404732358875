import styled from 'styled-components';

type SectiontWrapperProps = {
  readonly bottomBorder?: boolean
  readonly isLast?: boolean
}

type TextWrapperProps = {
  readonly theme?: string
}

export const SectiontWrapper = styled.div<SectiontWrapperProps>`
  margin: ${({ theme }) => (theme === 'secondary' ? '0 0 2.5rem' : '0')} ;
  padding-bottom: ${({ isLast }) => (isLast ? '0' : '2.5rem')};
`;

export const FlexBoxStyled = styled.div`
  margin-top: 1rem;
`;

export const TextWrapper = styled.div<TextWrapperProps>`
  margin-top: 0.5rem;
  display: ${({ theme }) => (theme === 'link' ? 'flex' : '')};
  align-items: ${({ theme }) => (theme === 'link' ? 'center' : '')};

  p {
    margin-left: ${({ theme }) => (theme === 'link' ? '8px' : '')};
  }
`;
