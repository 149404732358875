import styled from 'styled-components';

export const SuccessWrapper = styled.section`
  margin: auto;
  margin-top: 15%;
  
  @media (min-width: 820px) {
    max-width: 25%;
  };
`;

export const HeaderWrapper = styled.div`
  padding: 0.875rem;
  margin-bottom: 0.875rem;
`;
