export const PROJECT_DETAILS = {
  SELECTION_TITLES: {
    PURPOSE: 'Purpose',
    DETAILS: 'Details',
    ELIGIBILITY: 'Eligibility Requirement',
    HEALTH: 'Health Categories',
    STUDY_INFORMATION: 'Study Information',
    LOCATION: 'Study Location',
    PRINCIPAL_INVESTIGATOR: 'Principal Investigator',
    CONTACTS: 'Primary Contacts',
    INFORMATION: 'Study Information',
  },
  SUBTITLES: {
    COMMITMENT: 'Estimated Commitment',
    INVOLVED: "What's Involved",
    PROCEDURES: 'Procedures Required',
    AGE: 'Age Range',
    SEXES: 'Sexes',
    GENDER: 'Gender',
    HEALTHY_VOLUNTEER: 'Accepting Healthy Volunteer',
    INCLUSION: 'Inclusion Criteria',
    EXCLUSION: 'Exclusion Criteria',
    COMPENSATION: 'Compensation',
    EXPENSES: 'Reimbursement of Expenses',
    RECRUITMENT: 'Estimated Recruitment End Date',
    FUNDING: 'Funding Agency',
    COLLABORATING: 'Collaborating Organizations',
    PARTICIPATION_TYPE: 'Participation Type',
    ENGAGEMENT: 'Level of Engagement',
    ADDITIONAL_ELIGIBILITY: 'Additional Eligibility',
    ORIENTATION: 'Orientation Provided',
    BACKGROUND: 'Background',
  },
  LINK_POINTS: {
    DETAILS: 'projectDetail',
    PURPOSE: 'projectPurpose',
    ELIGIBILITY: 'projectEligibility',
  },
  TABS: [
    { LABEL: 'Purpose', URL: 'projectPurpose' },
    { LABEL: 'Details', URL: 'projectDetail' },
    { LABEL: 'Eligibility', URL: 'projectEligibility' },
  ],
};
