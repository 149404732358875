import React from 'react';
import { fonts } from '../../../../styles/theme';
import { Checkbox } from '../../../CheckBox';
import { ErrorMessage } from '../../../ErrorMessage';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { TextP } from '../../../Text';
import { TextArea } from '../../../TextArea.tsx';
import { CheckboxWrapper, SectiontWrapper } from '../styles';
import { OPPORTUNITY_PURPOSE } from './const';

type OpportunityPurposeProps = {
  levelsOfEngagements: { name: string, label: string }[]
  selectedEngagements: string[]
  purpose: string
  involved: string
  opportunityBackground: string
  opportunityOrientation: string
  canEdit: boolean
  handleCheck: (value: boolean, field: string, key: string) => void
  handleChange: (value: string, field: string) => void
  errors: { [key: string]: string }
  handleSingleValidation: (value: any, field: string) => void
}
const OpportunityPurpose = ({
  levelsOfEngagements, selectedEngagements, canEdit,
  opportunityOrientation, opportunityBackground, involved, purpose,
  handleCheck, handleChange, errors, handleSingleValidation,
}: OpportunityPurposeProps) => (
  <FormSection title={OPPORTUNITY_PURPOSE.TITLE}>
    <SectiontWrapper id="purpose">
      <TextArea
        label={OPPORTUNITY_PURPOSE.PURPOSE.LABEL}
        descriptionList={OPPORTUNITY_PURPOSE.PURPOSE.DESCRIPTION_LIST}
        description={OPPORTUNITY_PURPOSE.PURPOSE.DESCRIPTION}
        isRequired
        textValue={purpose}
        theme="form"
        setTextValue={(value) => handleChange(value, 'purpose')}
        isDisabled={!canEdit}
        error={errors.purpose}
        onBlurAction={() => handleSingleValidation(purpose, 'purpose')}
        maxLength={450}
      />
    </SectiontWrapper>

    <SectiontWrapper id="description">
      <TextArea
        label={OPPORTUNITY_PURPOSE.INVOLVED.LABEL}
        description={OPPORTUNITY_PURPOSE.INVOLVED.DESCRIPTION}
        isRequired
        textValue={involved}
        setTextValue={(value) => handleChange(value, 'description')}
        isDisabled={!canEdit}
        error={errors.description}
        onBlurAction={() => handleSingleValidation(involved, 'description')}
        theme="form"
        maxLength={700}
      />
    </SectiontWrapper>

    <SectiontWrapper id="level_of_engagement">
      <FormSubtitle subtitle={OPPORTUNITY_PURPOSE.ENGAGEMENT.LABEL} isRequired />
      <TextP font={fonts.font1.H14} lineHeight="1.33rem">
        {OPPORTUNITY_PURPOSE.ENGAGEMENT.DESCRIPTION}
      </TextP>

      {levelsOfEngagements.map((level) => (
        <CheckboxWrapper key={level.name}>
          <Checkbox
            label={level.name}
            subLabel={level.label}
            isChecked={selectedEngagements?.includes(level.name)}
            onChange={(check) => handleCheck(check, level.name, 'level_of_engagement')}
            disabled={!canEdit}
            textTransform="none"
          />
        </CheckboxWrapper>
      ))}
      {errors.level_of_engagement && <ErrorMessage message={errors.level_of_engagement} />}
    </SectiontWrapper>

    <SectiontWrapper id="orientation_provided">
      <TextArea
        label={OPPORTUNITY_PURPOSE.ORIENTATION.LABEL}
        description={OPPORTUNITY_PURPOSE.ORIENTATION.DESCRIPTION}
        isRequired
        textValue={opportunityOrientation}
        setTextValue={(value) => handleChange(value, 'orientation_provided')}
        error={errors.orientation_provided}
        isDisabled={!canEdit}
        onBlurAction={() => handleSingleValidation(opportunityOrientation, 'orientation_provided')}
        theme="form"
        maxLength={300}
      />
    </SectiontWrapper>

    <SectiontWrapper id="background" isLast>
      <TextArea
        label={OPPORTUNITY_PURPOSE.BACKGROUND.LABEL}
        description={OPPORTUNITY_PURPOSE.BACKGROUND.DESCRIPTION}
        isRequired
        textValue={opportunityBackground}
        setTextValue={(value) => handleChange(value, 'background')}
        error={errors.background}
        isDisabled={!canEdit}
        onBlurAction={() => handleSingleValidation(opportunityBackground, 'background')}
        theme="form"
        maxLength={900}
      />
    </SectiontWrapper>
  </FormSection>
);

export default OpportunityPurpose;
