import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type ExamplesProps = {
  readonly example?: string;
};

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
  width: fit-content;
`;

export const ErrorDivStyled = styled.div<ExamplesProps>`
  ${fonts.font1.H12}
  color: ${colours.colour7};
  border: 1px solid ${colours.colour7};
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background: ${colours.colour4};
`;

export const ArrowStyled = styled.div`
  width: 10px;
  height: 10px;
  border-right: 1px solid ${colours.colour7};
  border-top: 1px solid ${colours.colour7};
  transform: rotate(-45deg);
  margin-bottom: -5px;
  background: ${colours.colour4};
`;
