import environment from '../config/environment';

export const useCookies = () => {
  const createCookie = (name: string, value: string, userType: string) => {
    const now = new Date();
    const expireTime = new Date();
    if (userType === 'volunteer') {
      expireTime.setTime(now.getTime() + 1000 * 60 * 60);
    }
    if (userType === 'researcher') {
      expireTime.setTime(now.getTime() + 1000 * 60 * 15);
    }
    document.cookie = `${name}=${value}; expires=${new Date(expireTime).toUTCString()}; path=/;`;
  };

  const getCookie = (name: string) => {
    try {
      const value = document.cookie;
      const cookie = value.split('; ').filter((cookieValue) => {
        const cookieName = cookieValue.split('=')[0];
        return cookieName === name;
      });
      return cookie[0].split('=')[1];
    } catch (err) {
      return '';
    }
  };

  const renewCookie = (name: string, userType: string) => {
    const now = new Date();
    const expireTime = new Date();
    if (userType === 'volunteer') {
      expireTime.setTime(now.getTime() + 1000 * 60 * 60);
    }
    if (userType === 'researcher') {
      expireTime.setTime(now.getTime() + 1000 * 60 * 15);
    }
    document.cookie = `${name}=${getCookie(name)}; expires=${new Date(expireTime).toUTCString()}; path=/;`;
  };

  const deleteCookie = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 UTC; path=/;`;
  };

  const checkCookie = () => {
    const value = document.cookie;
    return value;
  };

  const checkAuthCookie = () => {
    const value = document.cookie;
    if (!value) {
      return false;
    }
    const cookie = value.split('; ').filter((cookieValue) => {
      const cookieName = cookieValue.split('=')[0];
      return cookieName === environment.app.cookieName;
    });
    return cookie.length !== 0;
  };

  return {
    createCookie, getCookie, deleteCookie, renewCookie, checkCookie, checkAuthCookie,
  };
};
