import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';

type NoticeDescriptionStyledPropTypes = {
  readonly theme?: 'secondary'
}

type LinkWrapperStyledPropTypes = {
  readonly theme?: 'secondary'
}

export const NoticeStyled = styled.div`
  background: ${colours.colour48};
  border-radius: 8px;
  ${fonts.font1.H15};
  margin: 1.5rem auto;
  padding: 16px 24px;
  border-left: 4px solid ${colours.colour2};

  @media (min-width: 600px) {
    padding: 24px 24px 32px;
  }
`;

export const NoticeHeaderStyled = styled.div`
  ${fonts.font1.H16B};
  line-height: 1.5rem;
  width: 100%;
  margin-bottom: 1rem;
`;

export const NoticeDescriptionStyled = styled.p<NoticeDescriptionStyledPropTypes>`
  ${({ theme }) => (theme === 'secondary' ? fonts.font1.H14 : fonts.font1.H16)};
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
`;

export const LinkWrapperStyled = styled.div<LinkWrapperStyledPropTypes>`
  margin-top: ${({ theme }) => (theme === 'secondary' ? '1rem' : '2rem')};
  margin-right: 1rem;
  width: fit-content;
`;

export const LinkWrapperContainer = styled.div`
  display: flex;
`;

export const IconWraper = styled.span`
  margin-right: ${toRem(8)};
`;
