export const PROJECT_CARD = {
  TYPE: {
    STUDY: 'Research Study',
    PPO: 'Patient Partner Research Opportunity',
  },
  IS_ONLINE: 'Online',
  IN_PERSON: 'In Person',
  HEALTHY_VOLUNTEERS: 'Accept Healthy Volunteers',
  COMPENSATION: 'Compensation Provided',
  MONTH: 'Months Old',
  YEAR: 'Years Old',
  PATINET_ORIENTED: 'Patient-Oriented',
  RESULT: 'Results available',
  COMMITMENT: 'Estimated Commitment: ',
  LINKS: {
    DETAILS: {
      URL: '/project/',
      LABEL: 'View Full Details',
    },
  },

  BUTTONS: {
    ARCHIVE: 'Archive',
    UNARCHIVE: 'Unarchive',
  },

  DELETE_DROPDOWN: {
    LABEL: 'Delete',
    OPTIONS: [
      { VALUE: 'not_interested', LABEL: "I'm not interested" },
      { VALUE: 'not_eligible', LABEL: "I'm not eligible" },
    ],
  },
};

export const PROJECT_TYPES: Record<string, any> = {
  STUDY: 'Research Study',
  PPO: 'Patient Partner Research Opportunity',
};
