import { useState } from 'react';
import { useResearcherAPI } from './useResearcherAPI';
import { checkForURL, stringURlReplacement } from '../utils';

export const useResults = () => {
  const { getResult } = useResearcherAPI();
  const [result, setResult] = useState<IResult>();

  const getProjectResult = async (projectId: number, type: string) => {
    try {
      const projectResult = await getResult(projectId, type);

      const resultFieldArray = projectResult?.summary ? projectResult.summary.split('\r\n') : [];

      projectResult.summaryWithLink = resultFieldArray.map((para: string) => {
        if (projectResult && checkForURL(para)) {
          return stringURlReplacement(para);
        }
        return para;
      });

      setResult(projectResult);
    } catch (error: any) {
      throw new Error(error);
    }
  };
  return { result, getProjectResult };
};
