import React from 'react';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { TextP } from '../../../Text';
import { TextArea } from '../../../TextArea.tsx';
import { FlexWrapper, SectiontWrapper, TextWrapper } from '../styles';
import { PPO_ESTIMATED_COMMITMENT } from './const';
import { Checkbox } from '../../../CheckBox';

type EstimatedCommitmentProps = {
  commitment: string
  compensation: boolean
  expenses: boolean
  canEdit: boolean
  errors: { [key: string]: string }
  handleChange: (value: string, field: string) => void
  handleToggle: (value: boolean, field: string) => void
  handleSingleValidation: (value: string, field: string) => void
}

const OpportunityEstimatedCommitment = ({
  commitment, compensation, expenses, errors, canEdit,
  handleChange, handleToggle, handleSingleValidation,
}: EstimatedCommitmentProps) => (
  <FormSection title={PPO_ESTIMATED_COMMITMENT.TITLE}>
    <SectiontWrapper id="commitment">
      <TextArea
        label={PPO_ESTIMATED_COMMITMENT.COMMITMENT.LABEL}
        description2={PPO_ESTIMATED_COMMITMENT.COMMITMENT.DESCRIPTION}
        isRequired
        textValue={commitment}
        setTextValue={(value) => handleChange(value, 'commitment')}
        error={errors.commitment}
        isDisabled={!canEdit}
        onBlurAction={() => handleSingleValidation(commitment, 'commitment')}
        maxLength={300}
      />
    </SectiontWrapper>

    <SectiontWrapper id="compensation">
      <FormSubtitle subtitle={PPO_ESTIMATED_COMMITMENT.COMPENSATION.LABEL} />
      <TextWrapper>
        <TextP lineHeight="1.33rem">
          {PPO_ESTIMATED_COMMITMENT.COMPENSATION.DESCRIPTION}
        </TextP>
      </TextWrapper>
      <FlexWrapper>
        <Checkbox
          label={PPO_ESTIMATED_COMMITMENT.COMPENSATION.CHECKBOX_LABEL}
          labelTheme="secondary"
          isChecked={compensation}
          onChange={() => handleToggle(!compensation, 'compensation')}
          disabled={!canEdit}
          textTransform="none"
        />
      </FlexWrapper>
    </SectiontWrapper>

    <SectiontWrapper id="expenses" isLast>
      <FormSubtitle subtitle={PPO_ESTIMATED_COMMITMENT.REIMBURSED.LABEL} />
      <TextWrapper>
        <TextP lineHeight="1.33rem">
          {PPO_ESTIMATED_COMMITMENT.REIMBURSED.DESCRIPTION}
        </TextP>
        <TextP lineHeight="1.33rem">
          {PPO_ESTIMATED_COMMITMENT.REIMBURSED.DESCRIPTION_TWO}
        </TextP>
      </TextWrapper>
      <FlexWrapper>
        <Checkbox
          label={PPO_ESTIMATED_COMMITMENT.REIMBURSED.CHECKBOX_LABEL}
          isChecked={expenses}
          onChange={() => handleToggle(!expenses, 'expenses')}
          labelTheme="secondary"
          disabled={!canEdit}
          textTransform="none"
        />
      </FlexWrapper>
    </SectiontWrapper>
  </FormSection>
);

export default OpportunityEstimatedCommitment;
