import environment from '../../config/environment';

export const CREATE_VOLUNTEER = {
  TITLE: 'Sign Up as a Volunteer',
  DESCRIPTION: 'Create your volunteer account today. Your information will be used to match you to studies and to contact you.',
  HAVE_ACCOUNT: {
    DESCRIPTION: 'Already have an account?',
    LABEL: 'Log In',
    URL: '/login',
  },
  BUTTONS: {
    CREATE: 'Create Account',
  },
  LINKS: {
    PRIVACY: {
      DESCRIPTIONS: 'By clicking Create Account, you are acknowledging your agreement with the ',
      URL: `${environment.app.websiteURL}/privacystatement`,
      LABEL: 'Privacy Statement and Terms of Use',
    },
  },

  RECEIVE_NEWS_LABEL: 'I would like to receive news and events related to REACH BC.',
  LEARNED_ABOUT: {
    LABEL: 'How did you hear about REACH BC?',
    PLACEHOLDER: 'Please select an option',
    OPTIONS: [
      { value: 'Online Search (Google, Bing, etc.)', label: 'Online Search' },
      { value: 'Social Media (Facebook, Twitter, Instagram, etc.)', label: 'Social Media' },
      { value: 'Email', label: 'Email' },
      { value: 'News Article/Blog Post', label: 'News Article/Blog Post' },
      { value: 'Event/Conference', label: 'Event/Conference' },
      { value: 'Webinar/Podcast', label: 'Webinar/Podcast' },
      { value: 'College/University/Tech School', label: 'College/University/Tech School' },
      { value: 'Hospital/Clinic Registration Site', label: 'Hospital/Clinic Registration Site' },
      { value: 'BC Support Unit', label: 'BC SUPPORT Unit' },
      { value: 'Printed Material (Flyer, Brochure, Poster)', label: 'Printed Material' },
      { value: 'Referral from a Friend or Family Member', label: 'Referral from Friend/Family' },
      { value: 'Other Website', label: 'Other Website (Specify if possible)' },
      { value: 'Workplace or Colleague Recommendation', label: 'Workplace or Colleague Recommendation' },
      { value: 'Other', label: 'Other (Please Specify)' },
    ],
  },
  LEARNED_ABOUT_OTHER: {
    LABEL: "If 'Other', please specify",
  },
};
