import React from 'react';
import { ToggleBallStyled, ToggleBodyStyled, ToggleWrapperStyled } from './styles';

type ToggleProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  name?: string
}

export const Toggle = ({
  checked, onChange, disabled = false, name,
}: ToggleProps) => (
  <ToggleWrapperStyled>
    <ToggleBodyStyled checked={checked}>
      <ToggleBallStyled checked={checked} />
    </ToggleBodyStyled>
    <input
      type="checkbox"
      id={name}
      name={name}
      checked={checked}
      onChange={() => onChange(!checked)}
      disabled={disabled}
    />
  </ToggleWrapperStyled>
);
