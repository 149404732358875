import React from 'react';
import * as Styled from './styles';

type RadioProps = {
  name: string;
  value: string | number;
  onChange: (value: number | string) => void;
  checked?: boolean;
  className?: string;
  id: string
  disabled?: boolean
}

export const RadioInput = ({
  onChange, value, name, checked = false, className, id, disabled,
}: RadioProps) => (
  <Styled.WrapperStyled className={className}>
    <Styled.InputStyled
      id={id}
      name={name}
      value={value}
      type="radio"
      onChange={(e) => onChange(e.target.value)}
      checked={checked}
      disabled={disabled}
    />
    <Styled.BodyStyled checked={checked}>
      <Styled.InsideStyled checked={checked} />
    </Styled.BodyStyled>
  </Styled.WrapperStyled>
);
