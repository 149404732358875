import styled from 'styled-components';
import { colours, fonts } from './theme';

type ProgressBarStyledProps = {
  readonly futureStep: boolean
}

type InputWrapperProps = {
  readonly justifyContent?: string
  readonly alignment?: string
}

export const PageWrapper = styled.div`
  margin: 60px auto 40px;
  max-width: 1200px;
`;

export const CreateResearcherWrapper = styled.div`
  margin: 0px auto 40px;
`;

export const HeaderStyled = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.875rem auto;
  max-width: 87.5%;
  
  @media (min-width: 992px) {
    max-width: 60.5%;
  }
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  width: 62.5%;
  margin: 2.5rem 0;
`;

export const ProgressBarStyled = styled.div<ProgressBarStyledProps>`
  border-right: 2px solid ${colours.colour4};
  height: 8px;
  width: 50%;
  background: ${({ futureStep }) => (futureStep ? colours.colour17 : colours.colour1)};
`;

export const HeaderTextWrapper = styled.div`
  width: 62.5%;
`;

export const MainStyled = styled.main`
  max-width: 87.5%;
  margin: 0 auto;
  
  @media (min-width: 992px) {
    max-width: 62.5%;
  }
  `;
export const SelectWrapper = styled.div`
  width: 100%;
  margin-top: 1.375rem;
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  max-width: 100%;
  margin: 0 auto 1.75rem;
  align-items: ${({ alignment }) => (alignment || '')};

  @media (min-width: 600px){
    max-width: 80%;
  }
`;

export const LinkWrapper = styled.div`
  margin: 0 auto;
  max-width: 450px;
  ${fonts.font1.H13B}
`;

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: fit-content;
`;

export const ExtensionWrapper = styled.div`
  margin-top: -0.01em;
  margin-left: 1em;
`;

export const SelectedStyled = styled.select`
  width: 100%;
  height: 38px;
  padding: 0.438rem;
  border-radius: 4px;
  background: white;
`;

export const SectionStyled = styled.div`
  margin-bottom: 1.5rem;
`;

export const PopUpErrorStyled = styled.div`
  display: block;
  align-items: center;
  width: 100%;
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
`;

export const ReCAPTCHAStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1.75rem;
  max-width: 80%;
  margin: auto;
  padding: 1.75rem 0;
  
  @media (min-width: 992px) {
    max-width: 80%;
  }
`;
