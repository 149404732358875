export const LOGIN = {
  TITLE: 'Log In',
  DESCRIPTION: "Don't have an account? ",
  DESCRIPTION_LINK: {
    URL: '/volunteer/create',
    LABEL: 'Sign up to create an account',
  },
  EMAIL: {
    LABEL: 'Email',
    PLACEHOLDER: 'Enter your email',
  },
  PASSWORD: {
    LABEL: 'Password',
    PLACEHOLDER: 'Enter your password',
    LINK: {
      LABEL: 'Forgot Password?',
      URL: '/forgot-password',
    },
  },
  BUTTONS: {
    LOGIN: 'Log In',
  },
  SESSION_EXPIRED: 'Sorry, your session has expired.',
  VERIFY_SEND: [
    'Please check your email to verify your account.',
    'If you do not receive the verification email within a few minutes, please check your spam/junk folder.',
    'Add "info@reachbc.ca" to your safe sender list to ensure our emails get to you.',
  ],
  VERIFY_ERROR: {
    MESSAGE_START: 'This account has not been activated.',
    MESSAGE_LINK: 'Click here',
    MESSAGE_END: 'to send a new verification link to your e-mail.',
  },
  ACCOUNT: "Don't have an account? ",
  ACCOUNT_CREATE: 'Create an account',
  TROUBLE: {
    LABEL: 'Having trouble signing in?',
    URL: '/forgot-password',
  },
};
