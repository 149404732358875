import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { colours, fonts } from '../../../../styles/theme';
import Button from '../../../Button';
import { DropdownSelect } from '../../../DropdownSelect';
import { TextP } from '../../../Text';
import {
  TableFooterStyled, NumberInputWrapper, RowCountWrapper, PageSetterWrapper, NumberInputTextWrapper,
} from './styles';

type FooterProps = {
  pageNumber: { value: string, label: string },
  setPageNumber: (pageNumber: any) => void,
  totalPages: number,
  rowCount: number,
  setRowCount: (rowCount: any) => void,
  rowOption: number[],
}

export const Footer = ({
  pageNumber, setPageNumber, totalPages, rowCount, setRowCount, rowOption,
}: FooterProps) => {
  const renderPageSetterOptions = (total: number) => {
    const rows = [];
    for (let i = 1; i <= total; i += 1) {
      rows.push({ label: i.toString(), value: i.toString() });
    }
    return rows;
  };
  return (
    <TableFooterStyled>
      <RowCountWrapper>
        <TextP font={fonts.font1.H14} color={colours.colour8}>Items per page</TextP>
        <DropdownSelect
          onChange={setRowCount}
          options={rowOption.map((row) => ({
            value: row.toString(),
            label: row.toString(),
          }))}
          selected={{ label: rowCount.toString(), value: rowCount.toString() }}
          placeholder="1"
          width="80px"
        />
      </RowCountWrapper>
      <PageSetterWrapper>
        <Button
          id="firstButton"
          theme="footerButton"
          buttonWidth="20px"
          disabled={Number(pageNumber.value) === 1}
          onClick={
            () => {
              setPageNumber({ value: 1, label: 1 });
            }
          }
        >
          <FontAwesomeIcon icon="angles-left" />
        </Button>
        <Button
          id="previousButton"
          theme="footerButton"
          buttonWidth="20px"
          disabled={Number(pageNumber.value) === 1}
          onClick={
            () => {
              if (Number(pageNumber.value) > 1) setPageNumber({ value: Number(pageNumber.value) - 1, label: Number(pageNumber.value) - 1 });
            }
          }
        >
          <FontAwesomeIcon icon="angle-left" />
        </Button>
        <NumberInputWrapper>
          <DropdownSelect
            onChange={(e) => {
              setPageNumber(e);
            }}
            options={renderPageSetterOptions(totalPages)}
            selected={{ label: pageNumber.value.toString(), value: pageNumber.toString() }}
            placeholder={pageNumber.toString()}

          />
          <NumberInputTextWrapper>
            <TextP font={fonts.font1.H14} color={colours.colour8}>of</TextP>
            <TextP font={fonts.font1.H14} color={colours.colour8}>{totalPages}</TextP>
          </NumberInputTextWrapper>
        </NumberInputWrapper>
        <Button
          id="nextButton"
          theme="footerButton"
          buttonWidth="20px"
          disabled={Number(pageNumber.value) === totalPages}
          onClick={
            () => {
              if (Number(pageNumber.value) < totalPages) setPageNumber({ value: Number(pageNumber.value) + 1, label: Number(pageNumber.value) + 1 });
            }
          }
        >
          <FontAwesomeIcon icon="angle-right" />
        </Button>
        <Button
          id="lastButton"
          theme="footerButton"
          buttonWidth="20px"
          disabled={Number(pageNumber.value) === totalPages}
          onClick={
            () => {
              setPageNumber({ value: totalPages, label: totalPages });
            }
          }
        >
          <FontAwesomeIcon icon="angles-right" />
        </Button>
      </PageSetterWrapper>
    </TableFooterStyled>
  );
};
