import React from 'react';
import { ButtonStyled } from '../../../../components/Button/styles';
import { LinkComponent } from '../../../../components/Link';
import { TextH1, TextH2, TextP } from '../../../../components/Text';
import { Toggle } from '../../../../components/Toggle';
import { fonts } from '../../../../styles/theme';
import {
  ButtonWrapper,
  DefaultWrapper, FlexboxStyled, FlexboxStyledCenter, SectionStyled,
} from '../../../../styles/MyProfileStyles';
import environment from '../../../../config/environment';

type EmailNotificationProps = {
  handleChange: (toggle: boolean, keyType: string) => void
  handleValidation: (formValues: IVolunteerUser, page: string) => void
  user: IVolunteerUser
}

const EmailNotification = ({
  user, handleChange, handleValidation,
}: EmailNotificationProps) => (
  <DefaultWrapper>
    <SectionStyled>
      <TextH1 font={fonts.font1.H26} textAlgin="left">Email Notifications</TextH1>
    </SectionStyled>
    <FlexboxStyled>
      <Toggle checked={user.receive_news} onChange={() => handleChange(!user.receive_news, 'receive_news')} />
      <TextP font={fonts.font1.H14} lineHeight="1.33rem">
        I would like to receive news and events related to REACH BC.
      </TextP>
    </FlexboxStyled>
    <FlexboxStyledCenter>
      <Toggle checked={user.receive_matching_email} onChange={() => handleChange(!user.receive_matching_email, 'receive_matching_email')} />
      <TextP font={fonts.font1.H14} lineHeight="1.33rem">
        I would like to receive weekly emails of the research opportunities that I match to based on my profile settings.
      </TextP>
    </FlexboxStyledCenter>
    <SectionStyled>
      <TextH2 font={fonts.font1.H14} lineHeight="1.33rem">
        By clicking Submit, you are acknowledging your agreement with the Privacy Statement and <LinkComponent url={`${environment.app.websiteURL}/privacystatement`} newWindow theme="termsOfUse"> Terms of Use</LinkComponent>.
      </TextH2>
    </SectionStyled>
    <ButtonWrapper>
      <ButtonStyled
        onClick={() => handleValidation(user, 'EmailNotifications')}
        theme="myProfileButton"
      >Save Preferences
      </ButtonStyled>
    </ButtonWrapper>
  </DefaultWrapper>
);

export default EmailNotification;
