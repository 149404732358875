import styled from 'styled-components';
import { fonts, colours } from '../../styles/theme';
import toRem from '../../utils/toRem';

type ContainWrapperStyledPropTypes = {
  readonly theme?: string
}

export const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin: ${toRem(64)} 0;
  gap: ${toRem(24)};
`;

export const TitleStyled = styled.h3`
  ${fonts.font1.H20B};
  line-height: ${toRem(32)};
  margin-bottom: ${toRem(8)};
`;

export const DescriptionStyled = styled.p`
  ${fonts.font1.H16};
  line-height: ${toRem(24)};
  letter-spacing: 0.2px;
`;

export const ContainWrapperStyled = styled.div<ContainWrapperStyledPropTypes>`
  max-width: ${toRem(250)};
  padding-top: ${({ theme }) => (theme === 'secondary' ? toRem(16) : 0)};
  border-top: ${({ theme }) => (theme === 'secondary' ? `1px solid ${colours.colour41}` : 'none')};
`;

export const ListStyled = styled.ul`
  list-style: disc;
  list-style-position: outside;
  line-height: 1.25rem;
  margin: 0.25rem 0 1rem 0.5rem;
  text-align: left;
  ${fonts.font1.H14};
  line-height: ${toRem(20)};
`;

export const ListItemStyled = styled.li`
  margin-left: ${toRem(10)};
  margin-bottom: ${toRem(2)};
`;

export const LinkWrapper = styled.div`
  margin: ${toRem(16)} auto 0;
  width: fit-content;
`;

export const CardWrapper = styled.div`
  margin-bottom: ${toRem(24)};
`;
