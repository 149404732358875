import React from 'react';
import { ButtonStyled } from '../../../../components/Button/styles';
import { TextH1, TextP } from '../../../../components/Text';
import { ButtonWrapper } from '../../../../styles/loginStyle';
import { ErrorMessageStyled, ResearcherWrapper, SectionStyled } from '../../../../styles/MyProfileStyles';
import { fonts } from '../../../../styles/theme';
import PasswordReset from '../PasswordReset';

type EditProfileProps = {
  handleStep: () => void
  password: string
  setPassword: (password: string) => void
  oldPassword: string
  setOldPassword: (password: string) => void
  confirmPassword: string
  setConfirmPassword: (password: string) => void
  handleChangePassword: () => void
  errors: { [key: string]: string }
  updateStatus: boolean
}
const EditProfile = ({
  password, setPassword, oldPassword, setOldPassword, confirmPassword, setConfirmPassword, errors,
  handleChangePassword, handleStep, updateStatus,
}: EditProfileProps) => ((
  <ResearcherWrapper>
    <SectionStyled theme="M">
      <TextH1 font={fonts.font1.H26}>
        Edit Profile
      </TextH1>
    </SectionStyled>
    {errors.fetch && (
      <ErrorMessageStyled>
        <TextP>{errors.fetch}</TextP>
      </ErrorMessageStyled>
    )}

    {updateStatus && (
      <ErrorMessageStyled sucess>
        <TextP>Your password has been updated</TextP>
      </ErrorMessageStyled>
    )}
    <PasswordReset
      userType="researcher"
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      oldPassword={oldPassword}
      setOldPassword={setOldPassword}
      handleChangePassword={handleChangePassword}
      errors={errors}
    />
    <ButtonWrapper>
      <ButtonStyled
        onClick={() => handleStep()}
        theme="outlineSecondary"
      >
        Continue to Edit Details
      </ButtonStyled>
    </ButtonWrapper>
  </ResearcherWrapper>
));

export default EditProfile;
