import React from 'react';
import FormSection from '../../../FormSelection';
import { TextArea } from '../../../TextArea.tsx';
import { SectiontWrapper } from '../styles';
import { STUDY_PURPOSE } from './const';

type StudyPurposeProps = {
  purpose: string
  description: string
  errors: { [key: string]: string }
  canEdit: boolean
  handleChange: (value: string, field: string) => void
  handleSingleValidation: (value: any, field: string) => void
}

const StudyPurpose = ({
  purpose, description, errors, handleChange, canEdit, handleSingleValidation,
}: StudyPurposeProps) => (
  <FormSection title={STUDY_PURPOSE.TITLE}>
    <SectiontWrapper id="purpose">
      <TextArea
        label={STUDY_PURPOSE.PURPOSE.LABEL}
        description={STUDY_PURPOSE.PURPOSE.DESCRIPTION}
        descriptionList={STUDY_PURPOSE.PURPOSE.DESCRIPTION_LIST}
        isRequired
        textValue={purpose}
        setTextValue={(value) => handleChange(value, 'purpose')}
        error={errors.purpose}
        isDisabled={!canEdit}
        theme="form"
        onBlurAction={() => handleSingleValidation(purpose, 'purpose')}
        maxLength={450}
      />
    </SectiontWrapper>
    <SectiontWrapper id="description" isLast>
      <TextArea
        label={STUDY_PURPOSE.INVOLVED.LABEL}
        description={STUDY_PURPOSE.INVOLVED.DESCRIPTION}
        isRequired
        textValue={description}
        setTextValue={(value) => handleChange(value, 'description')}
        theme="form"
        isDisabled={!canEdit}
        error={errors.description}
        onBlurAction={() => handleSingleValidation(description, 'description')}
        maxLength={700}
      />
    </SectiontWrapper>
  </FormSection>
);

export default StudyPurpose;
