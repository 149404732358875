export const sexOrder = Object.freeze([
  'Female',
  'Male',
  'Inter-sex',
  'Decline to State',
]);

export const genderOrder = Object.freeze([
  'Woman',
  'Man',
  'Non-binary',
  'Trans woman',
  'Trans man',
  'Two-spirit',
  'Other',
  'Decline to State',
]);
