import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { fonts } from '../../styles/theme';
import { TextP } from '../Text';
import {
  AccrodionChildernStyled, CountBubbleStyled, ButtonStyled, WrapperStyled,
} from './styles';

type AccrodionProps = {
  children: React.ReactNode
  count?: number
  label: string
  isOpen: boolean
  disabled?: boolean
  setIsOpen: () => void
}
const Accrodion = ({
  isOpen, label, count = 0, disabled, setIsOpen, children,
}: AccrodionProps) => (
  <>
    <ButtonStyled
      id={`${label}-Accrodion`}
      onClick={() => setIsOpen()}
      disabled={disabled}
    >
      <TextP>
        {label}
      </TextP>
      <WrapperStyled>
        {count > 0 && (
          <CountBubbleStyled>
            <TextP font={fonts.font1.H10} textAlgin="left">
              {count}
            </TextP>
          </CountBubbleStyled>
        )}
        <FontAwesomeIcon icon="chevron-down" />
      </WrapperStyled>
    </ButtonStyled>
    <AccrodionChildernStyled isOpen={isOpen}>
      {children}
    </AccrodionChildernStyled>
  </>
);

export default Accrodion;
