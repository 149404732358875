import React, { useEffect } from 'react';
import AppWrapper from '../../../components/AppWrapper';
import ProjectForm from '../../../components/ProjectForm';
import StudyForm from '../../../components/StudyForm';
import { studyForm } from '../../../utils/researcherConst';
import { useCookies } from '../../../hooks/useCookies';
import environment from '../../../config/environment';
import { useNewStudy } from './hooks/useNewStudy';

const StudyNew = () => {
  const { getCookie } = useCookies();

  const hook = useNewStudy();

  useEffect(() => {
    hook.formHook.fetchValues(true);

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (hook.formHook.changesMade && window) {
      window.onbeforeunload = function () {
        if (!getCookie(environment.app.cookieName)) {
          window.onbeforeunload = null;
          return;
        }

        return 'Are you sure you want to leave?';
      };
    }
  }, [hook.formHook.changesMade]);

  return (
    <AppWrapper>
      <ProjectForm
        generalInfo={studyForm}
        selectedStatus={hook.formHook.formInfo.desired_status}
        recruitmentEndDate={hook.formHook.formInfo.recruitment_end}
        desiredStatus
        handleChange={hook.formHook.handleChange}
        handleSubmit={hook.handleFormSubmit}
        handleDraft={hook.handleCreateDraft}
        isSubmiting={hook.formHook.isSubmiting}
        toastIsOpen={hook.formHook.toastIsOpen}
        toastText={hook.formHook.toastMessage}
        modalInfo={hook.formHook.modalType}
        modalOpen={hook.formHook.modalIsOpen}
        changesMade={hook.formHook.changesMade}
        handleNavigate={hook.handleNavigate}
        setModalType={hook.formHook.setModalType}
        rightButtonAction={hook.handleRightButtonAction}
        leftButtonAction={hook.handleLeftButtonAction}
        setModalOpen={hook.formHook.setModalIsOpen}
        setToastIsOpen={hook.formHook.setToastIsOpen}
        setStatusModal={hook.formHook.setStatusModal}
        statusModal={hook.formHook.statusModal}
        canEdit
        errors={hook.formHook.formErrors}
      >
        <StudyForm
          formInfo={hook.formHook.formInfo}
          formErrors={hook.formHook.formErrors}
          collaborators={hook.formHook.collaborators}
          teamMembers={hook.formHook.teamMembers}
          collaboratingOrganizations={hook.formHook.collaboratingOrganizations}
          healthAuthorityRegionOptions={hook.formHook.healthRegions}
          hospitalList={hook.formHook.hospitals}
          healthCategories={hook.formHook.healthCategories}
          selectedResearchAreas={hook.formHook.selectedResearchAreas}
          adminComments={hook.formHook.formInfo.admin_comments}
          RebFileCurrent={hook.formHook.rebFile}
          additionalDocCurrent={hook.formHook.additionalDoc}
          isDraft={hook.formHook.isDraft}
          handleChange={hook.formHook.handleChange}
          handleAddInput={hook.formHook.handleAddInput}
          handleChangeAddedInput={hook.formHook.handleChangeAddedInput}
          handleRemoveInput={hook.formHook.handleRemoveInput}
          handleChangeResearchAreas={hook.formHook.toggleResearchArea}
          handleCheck={hook.formHook.handleCheck}
          handleToggle={hook.formHook.handleToggle}
          handleFile={hook.formHook.handleFile}
          checkPI={hook.formHook.checkPI}
          checkTeamMember={hook.formHook.checkTeamMember}
          handleDelete={() => {
            hook.formHook.setModalType('delete');
            hook.formHook.setModalIsOpen(true);
          }}
          canEdit
          forceChange={hook.formHook.forceChange}
          handleSingleValidation={hook.formHook.handleSingleValidation}
          handleRemoveFile={hook.formHook.handleRemoveFile}
        />
      </ProjectForm>
    </AppWrapper>
  );
};

export default StudyNew;
