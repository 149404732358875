import React, { useState } from 'react';
import { Checkbox } from '../../../CheckBox';
import Button from '../../../Button';
import { Calendar } from '../../../Calendar';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { TextP } from '../../../Text';
import { TextInput } from '../../../TextInput';
import {
  SectiontWrapper, FlexWrapper, TextWrapper, CheckboxWrapper, MobileWrapper,
} from '../styles';
import { formateDate } from '../../../../utils/formateDate';
import { ErrorMessage } from '../../../ErrorMessage';
import { FUNDING_INFORMATION } from './const';

type StudyDatesFundingInformationProps = {
  handleCheck: (value: boolean, field: number | string, key: string) => void
  handleChange: (value: string | Date, field: string) => void
  registrationNum: string
  selectedFundingAgency: string[]
  fundingAgencyOptions: { value: string, label: string }[]
  recruitmentEndDate: Date
  errors: { [key: string]: string }
  canEdit: boolean
  handleToggle: (value: boolean, field: string) => void
  isClinicalTrial: boolean
  handleSingleValidation: (value: any, field: string) => void
}

const StudyDatesFundingInformation = ({
  registrationNum, fundingAgencyOptions, handleChange, handleToggle, recruitmentEndDate, errors, canEdit, isClinicalTrial, handleSingleValidation,
  handleCheck, selectedFundingAgency,
}: StudyDatesFundingInformationProps) => {
  const [calenderToggle, setCalenderToggle] = useState(false);
  return (
    <FormSection title={FUNDING_INFORMATION.TITLE} titleMobile={FUNDING_INFORMATION.TITLE_MODILE}>
      <SectiontWrapper>
        <FormSubtitle subtitle={FUNDING_INFORMATION.TITLE} />
        <FlexWrapper>
          <Checkbox
            label={FUNDING_INFORMATION.CLINICAL_TRIAL.LABEL}
            isChecked={isClinicalTrial}
            labelTheme="secondary"
            onChange={() => handleToggle(!isClinicalTrial, 'is_clinical_trial')}
            disabled={!canEdit}
            textTransform="none"
          />

        </FlexWrapper>
      </SectiontWrapper>
      <SectiontWrapper id="registration_num">
        <TextInput
          label={FUNDING_INFORMATION.REGISTRATION_NUMBER.LABEL}
          description={FUNDING_INFORMATION.REGISTRATION_NUMBER.LABEL_DESCRIPTION}
          textValue={registrationNum}
          setTextValue={(value) => handleChange(value, 'registration_num')}
          isRequired={isClinicalTrial}
          onBlurAction={isClinicalTrial ? () => handleSingleValidation(registrationNum, 'registration_num') : undefined}
          error={errors.registration_num}
          disabled={!canEdit}
          theme="form"
        />
      </SectiontWrapper>
      <SectiontWrapper id="funding_agency" isLast>
        <FormSubtitle
          subtitle={FUNDING_INFORMATION.FUNDING_AGENCY.LABEL}
          margin="small"
          isRequired
        />
        <TextP lineHeight="1.33rem">
          {FUNDING_INFORMATION.FUNDING_AGENCY.LABEL_DESCRIPTION}
        </TextP>
        {fundingAgencyOptions.map((option) => (
          <CheckboxWrapper key={option.label || option.value}>
            <Checkbox
              label={option.label}
              isChecked={selectedFundingAgency.includes(option.value)}
              onChange={(check) => handleCheck(check, option.value, 'funding_agency')}
              disabled={!canEdit}
              textTransform="none"
            />
          </CheckboxWrapper>
        ))}
      </SectiontWrapper>

      <MobileWrapper>
        <SectiontWrapper id="recruitment_end">
          <FormSubtitle
            subtitle={FUNDING_INFORMATION.RECUITMENT_DATES.TITLE}
            isRequired
            margin="small"
          />
          <TextWrapper>
            <TextP>{FUNDING_INFORMATION.RECUITMENT_DATES.LABEL_DESCRIPTION}</TextP>
          </TextWrapper>
          <Button
            id="calenderToggle"
            theme="outlineSecondary"
            onClick={() => setCalenderToggle(!calenderToggle)}
            buttonWidth="100%"
            disabled={!canEdit}
          >
            {recruitmentEndDate ? (
              <TextP>{formateDate(recruitmentEndDate)}</TextP>
            ) : (
              <TextP>
                {FUNDING_INFORMATION.RECUITMENT_DATES.PLACEHOLDER}
              </TextP>
            )}
          </Button>
          <Calendar
            onApply={(value) => {
              handleChange(value, 'recruitment_end');
              setCalenderToggle(false);
            }}
            date={recruitmentEndDate ? new Date(`${recruitmentEndDate}`?.slice(0, 15) || new Date()) : new Date()}
            isOpen={calenderToggle}
            setIsOpen={setCalenderToggle}
          />
          {errors.recruitment_end && <ErrorMessage message={errors.recruitment_end} />}
        </SectiontWrapper>
      </MobileWrapper>
    </FormSection>
  );
};

export default StudyDatesFundingInformation;
