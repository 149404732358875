import React from 'react';
import { RequiredForm, TextInput } from '../../../../components';
import {
  InputWrapper, DateDivStyled, DateWrapper, FormStyled, WarningMessage, EmailWrapper,
} from '../../../../styles/VolunteerCreateStyles';
import { TextP } from '../../../../components/Text';
import { fonts } from '../../../../styles/theme';
import { PasswordRequirementChecker } from '../../../../components/PasswordRequirementChecker';

type volunteerInfoProps = {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  password: string
  passwordConfirm: string
  birthDateMonth?: string
  birthDateDay?: string
  birthDateYear?: string
  siteCode?: string
  healthCategories?: {
    name: string
    id: number
    category: string
  }[]
}

type VolunteerInfoFormProps = {
  volunteerInfo: volunteerInfoProps
  volunteerInfoError: { [key: string]: string }
  handleSubmit: (event: React.FormEvent) => void
  handleChange: (value: string, field: string) => void
  checkEmail: () => void
  handleSingleValidation: (value: any, field: string, type?: string) => void
  researcherEmailType: boolean
  hookPasswordRequirements: Record<string, boolean>
  hookHandlePasswordChecking: (value: string) => void
  hookDisplayValidationText: boolean
}

const VolunteerInfoForm = ({
  volunteerInfo,
  volunteerInfoError,
  handleSubmit,
  handleChange,
  checkEmail,
  handleSingleValidation,
  researcherEmailType,
  hookPasswordRequirements,
  hookHandlePasswordChecking,
  hookDisplayValidationText,
}: VolunteerInfoFormProps) => (
  <FormStyled onSubmit={handleSubmit}>
    <InputWrapper>
      <TextInput
        label="First Name"
        textValue={volunteerInfo.firstName}
        setTextValue={(value) => { handleChange(value, 'firstName'); }}
        error={volunteerInfoError.firstName}
        type="text"
        inputWidth="49%"
        onBlurAction={() => handleSingleValidation(volunteerInfo.firstName, 'firstName')}
      />
      <TextInput
        label="Last Name"
        textValue={volunteerInfo.lastName}
        setTextValue={(value) => { handleChange(value, 'lastName'); }}
        error={volunteerInfoError.lastName}
        type="text"
        inputWidth="49%"
        onBlurAction={() => handleSingleValidation(volunteerInfo.lastName, 'lastName')}
      />
    </InputWrapper>
    <InputWrapper>
      <EmailWrapper>
        <TextInput
          label="Email"
          description="Your email address will be your login user name."
          textValue={volunteerInfo.email}
          setTextValue={(value) => { handleChange(value, 'email'); }}
          error={volunteerInfoError.email}
          onBlurAction={checkEmail}
          type="email"
        />
        {researcherEmailType && (
          <WarningMessage>
            <TextP>Are you sure you want to create a volunteer account?  If you are a researcher please create a researcher account.</TextP>
          </WarningMessage>
        )}
      </EmailWrapper>
    </InputWrapper>
    <InputWrapper>
      <TextInput
        label="Password"
        textValue={volunteerInfo.password}
        setTextValue={(value) => {
          handleChange(value, 'password');
          hookHandlePasswordChecking(value);
        }}
        error={volunteerInfoError.password}
        type="password"
      />
    </InputWrapper>

    {hookDisplayValidationText && (
      <InputWrapper>
        <PasswordRequirementChecker
          passwordRequirements={hookPasswordRequirements}
        />
      </InputWrapper>
    )}

    <InputWrapper>
      <TextInput
        label="Confirm Password"
        textValue={volunteerInfo.passwordConfirm}
        setTextValue={(value) => { handleChange(value, 'passwordConfirm'); }}
        error={volunteerInfoError.password_confirm}
        type="password"
        onBlurAction={() => handleSingleValidation({ password_confirm: volunteerInfo.passwordConfirm, password: volunteerInfo.password }, 'passwordConfirm', 'password')}
      />
    </InputWrapper>
    <InputWrapper alignItems="flex-end">
      <TextInput
        label="Phone Number"
        placeholder="5551234567"
        description="Please enter numbers only."
        textValue={volunteerInfo.phoneNumber}
        setTextValue={(value) => { handleChange(value, 'phoneNumber'); }}
        error={volunteerInfoError.phoneNumber}
        type="text"
        inputWidth="100%"
        onBlurAction={() => handleSingleValidation(volunteerInfo.phoneNumber, 'phoneNumber')}
      />
      <DateWrapper>
        <TextP font={fonts.font1.H17}>
          Date of Birth (MM-DD-YYYY)
          <RequiredForm />
        </TextP>
        <DateDivStyled>
          <TextInput
            label=""
            placeholder="MM"
            textValue={volunteerInfo.birthDateMonth || ''}
            setTextValue={(value) => { handleChange(value, 'birthDateMonth'); }}
            error={volunteerInfoError.birthDateMonth}
            type="text"
            inputWidth="30%"
            isRequired={false}
            onBlurAction={() => handleSingleValidation(volunteerInfo.birthDateMonth, 'birthDateMonth')}
          />
          <TextInput
            label=""
            placeholder="DD"
            textValue={volunteerInfo.birthDateDay || ''}
            setTextValue={(value) => { handleChange(value, 'birthDateDay'); }}
            error={volunteerInfoError.birthDateDay}
            type="text"
            inputWidth="30%"
            isRequired={false}
            onBlurAction={() => handleSingleValidation(volunteerInfo.birthDateDay, 'birthDateDay')}
          />
          <TextInput
            label=""
            placeholder="YYYY"
            textValue={volunteerInfo.birthDateYear || ''}
            setTextValue={(value) => { handleChange(value, 'birthDateYear'); }}
            error={volunteerInfoError.birthDateYear}
            type="text"
            inputWidth="30%"
            isRequired={false}
            onBlurAction={() => handleSingleValidation(volunteerInfo.birthDateYear, 'birthDateYear')}
          />
        </DateDivStyled>
      </DateWrapper>

    </InputWrapper>

    <InputWrapper>
      <TextInput
        label="Registration Site Code"
        description="If you were given a REACH BC registration code, enter it here."
        textValue={volunteerInfo.siteCode || ''}
        setTextValue={(value) => { handleChange(value, 'siteCode'); }}
        error={volunteerInfoError.siteCode}
        type="text"
        isRequired={false}
      />
    </InputWrapper>
  </FormStyled>
);

export default VolunteerInfoForm;
