import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from '../styles';
import { fonts } from '../../../../styles/theme';
import FormRemoveableInput from '../../../FormRemoveableInput';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { TextP } from '../../../Text';
import { TextInput } from '../../../TextInput';
import { Checkbox } from '../../../CheckBox';
import { PPO_PRINCIPAL_INVESTIGATOR } from './const';
import Button from '../../../Button';

type PPOPrincipalInvestigatorSectionProps = {
  piValue: string
  coInvestigator: string
  ppAffiliatedOrganization: string
  collaborators: { [key: string]: string }
  teamMembers: { [key: string]: { id: string, email: string, receive_email: boolean } }
  errors: { [key: string]: string }
  isDraft?: boolean
  canEdit: boolean
  handleChange: (value: string, field: string) => void
  handleAddInput: (key: string, maxNumber?: number) => void
  handleChangeAddedInput: (value: string, id: string, key: string) => void
  handleRemoveInput: (key: string, id: string) => void
  checkPI: (email: string) => void
  checkTeamMember: (user: string, email: string) => void
  handleDelete: () => void
  handleCheck: (value: boolean, field: string | number, key: string) => void
}

const MAX_ENTRIES = 10;

const PPOPrincipalInvestigatorSection = ({
  piValue, coInvestigator, ppAffiliatedOrganization, collaborators, teamMembers,
  handleRemoveInput, handleChange, handleChangeAddedInput, handleAddInput, checkPI,
  errors, isDraft, handleDelete, handleCheck, canEdit, checkTeamMember,
}: PPOPrincipalInvestigatorSectionProps) => (
  <FormSection
    title={PPO_PRINCIPAL_INVESTIGATOR.TITLE}
    isDraft={isDraft}
    handleDelete={handleDelete}
  >
    <Styled.SectiontWrapper id="pi">
      <TextInput
        label={PPO_PRINCIPAL_INVESTIGATOR.PI.LABEL}
        description={PPO_PRINCIPAL_INVESTIGATOR.PI.LABEL_DESCRIPTION}
        placeholder={PPO_PRINCIPAL_INVESTIGATOR.PI.PLACEHOLDER}
        setTextValue={(value) => handleChange(value, 'pi_email')}
        onBlurAction={() => checkPI(piValue)}
        textValue={piValue}
        isRequired
        theme="form"
        disabled={!canEdit}
        error={errors.pi || errors.pi_id}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="additional_team_member">
      <FormSubtitle subtitle={PPO_PRINCIPAL_INVESTIGATOR.ADDITIONAL_TEAM.LABEL} margin="small" />
      <TextP lineHeight="1.33rem">{PPO_PRINCIPAL_INVESTIGATOR.ADDITIONAL_TEAM.LABEL_DESCRIPTION}</TextP>
      {Object.keys(teamMembers).map((teamMember: any) => (
        <React.Fragment key={teamMember}>
          <FormRemoveableInput
            id={teamMember}
            field="teamMember"
            textValue={teamMembers[teamMember].email}
            handleChange={handleChangeAddedInput}
            handleRemoveInput={handleRemoveInput}
            onBlurAction={() => { checkTeamMember(teamMember, teamMembers[teamMember].email); }}
            disabled={!canEdit}
            placeholder={PPO_PRINCIPAL_INVESTIGATOR.ADDITIONAL_TEAM.PLACEHOLDER}
            error={errors[teamMember]}
            name={teamMember}
            increaseMargin
          />
          <Checkbox
            id={teamMember}
            label={PPO_PRINCIPAL_INVESTIGATOR.ADDITIONAL_TEAM.CHECKBOX_LABEL}
            isChecked={teamMembers[teamMember].receive_email}
            onChange={(check) => handleCheck(check, teamMember, 'receive_email')}
            textTransform="none"
          />
        </React.Fragment>
      ))}

      <Styled.ButtonWrapper>
        <Button
          id="add-team-member"
          onClick={() => {
            handleAddInput('teamMember');
          }}
          disabled={!canEdit}
        >
          <Styled.ButtonStyled>
            <FontAwesomeIcon icon="plus" />
            <Styled.ButtonTextWrapper>
              {PPO_PRINCIPAL_INVESTIGATOR.ADDITIONAL_TEAM.BUTTONS.ADD}
            </Styled.ButtonTextWrapper>
          </Styled.ButtonStyled>
        </Button>
      </Styled.ButtonWrapper>
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper>
      <TextInput
        label={PPO_PRINCIPAL_INVESTIGATOR.PATIENT_PARTNER.LABEL}
        description={PPO_PRINCIPAL_INVESTIGATOR.PATIENT_PARTNER.DESCRIPTION}
        setTextValue={(value) => handleChange(value, 'pp_co_pi')}
        textValue={coInvestigator}
        isRequired={false}
        disabled={!canEdit}
        theme="form"
        error={errors.pp_co_pi}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper>
      <TextInput
        label={PPO_PRINCIPAL_INVESTIGATOR.AFFILIATED_ORGANIZTION.LABEL}
        description={PPO_PRINCIPAL_INVESTIGATOR.AFFILIATED_ORGANIZTION.DESCRIPTION}
        setTextValue={(value) => handleChange(value, 'pp_affiliated_organization')}
        textValue={ppAffiliatedOrganization}
        isRequired={false}
        disabled={!canEdit}
        theme="form"
        error={errors.pp_affiliated_organization}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper>
      <FormSubtitle subtitle={PPO_PRINCIPAL_INVESTIGATOR.PATIENT_INITIATED.TITLE} margin="small" />
      <TextP font={fonts.font1.H14} lineHeight="1.33rem">
        {PPO_PRINCIPAL_INVESTIGATOR.PATIENT_INITIATED.DESCRIPTION}
      </TextP>
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="collaborators">
      <FormSubtitle subtitle={PPO_PRINCIPAL_INVESTIGATOR.COLLABORATORS.TITLE} margin="small" />
      <TextP lineHeight="1.33rem">
        {PPO_PRINCIPAL_INVESTIGATOR.COLLABORATORS.DESCRIPTION}
      </TextP>
      {Object.keys(collaborators).map((collaborator: any) => (
        <React.Fragment key={collaborator}>
          <FormRemoveableInput
            id={collaborator}
            field="collaborator"
            textValue={collaborators[collaborator]}
            handleChange={handleChangeAddedInput}
            handleRemoveInput={handleRemoveInput}
            disabled={!canEdit}
          />
        </React.Fragment>
      ))}

      <Styled.ButtonWrapper>
        <Button
          id="add-collaborator"
          onClick={() => {
            handleAddInput('collaborator', MAX_ENTRIES);
          }}
          disabled={!canEdit}
        >
          <Styled.ButtonStyled>
            <FontAwesomeIcon icon="plus" />
            <Styled.ButtonTextWrapper>
              <TextP>{PPO_PRINCIPAL_INVESTIGATOR.COLLABORATORS.BUTTON}</TextP>
            </Styled.ButtonTextWrapper>
          </Styled.ButtonStyled>
        </Button>
      </Styled.ButtonWrapper>
    </Styled.SectiontWrapper>
  </FormSection>
);

export default PPOPrincipalInvestigatorSection;
