import React from 'react';
import QuestionSvg from '../../assets/images/question_mark.svg';
import PublicToolTip from '../PublicTooltip';
import { LinkComponent } from '../Link';
import * as Styled from './styles';
import { RequiredForm } from '../RequiredForm';
import { NotVisible } from '../NotVisible';

type FormSubTilteProps = {
  subtitle: string,
  margin?: string
  isRequired?: boolean
  isPublic?: boolean
  isNotPublic?: boolean
  unbold?: boolean
  link?: {
    url: string
    label: string
  }
}

const FormSubtitle = ({
  subtitle, margin, isPublic = false, isRequired = false, unbold = false, link, isNotPublic = false,
}: FormSubTilteProps) => (
  <Styled.SubTitleWrapper margin={margin}>
    <Styled.TextStyled unbold={unbold}>
      {subtitle}
      {isRequired && <RequiredForm />}
      {isPublic && <PublicToolTip />}
      {link && (
        <Styled.LinkWrapper>
          <Styled.LinkImageStyled src={QuestionSvg} alt="qm" />
          <LinkComponent
            url={link.url}
            theme="formLink"
            newWindow
          >
            {link.label}
          </LinkComponent>
        </Styled.LinkWrapper>
      )}
    </Styled.TextStyled>
    {isNotPublic && <NotVisible />}
  </Styled.SubTitleWrapper>
);

export default FormSubtitle;
