import styled from 'styled-components';
import toRem from '../utils/toRem';
import { colours, fonts } from './theme';

type MessageContainerStyledProps = {
  readonly theme?: string
}

type MainStyledProps = {
  readonly theme?: string
}

type EmailVerificationMessageContainerStyledProps = {
  readonly theme?: string
}

type UserMessageStyledProps = {
  readonly theme?: string
}

type TextInputWrapperProps = {
  readonly marginBottom?: 'lg'
}

export const PageWrapper = styled.div`
  margin: 60px auto 40px;
  max-width: 1200px;
`;

export const HeaderStyled = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.875rem auto;
  max-width: 87.5%;

  @media (min-width: 768px) {
    max-width: 37.5%;
  }

  @media (min-width: 992px) {
    max-width: 50%;
  }
`;

export const MainStyled = styled.main`
  margin: 0.875rem auto;
  max-width: 87.5%;

  p {
    text-align: center;
  }

  @media (min-width: 768px) {
    max-width: 37.5%;
  }

  @media (min-width: 992px) {
    max-width: 50%;
  }
`;

export const MessageContainerStyled = styled.div<MessageContainerStyledProps>`
  ${fonts.font1.H17B}
  min-height: 1.875rem;
  line-height: 1.33rem;
  padding: 0.875rem;
  border-radius: 4px;
  text-align: center;
  margin: ${({ theme }) => {
    switch (theme) {
      case 'error':
        return '0.875rem 0.625rem 2rem';
      default:
        return '0.875rem 1.75rem 1.75rem';
    }
  }};

  border: 1px solid ${({ theme }) => {
    switch (theme) {
      case 'message':
        return colours.colour22;
      case 'noBorder':
        return 'transparent';
      default:
        return colours.colour7;
    }
  }};

  color: ${({ theme }) => {
    switch (theme) {
      case 'message':
        return colours.colour22;
      default:
        return colours.colour7;
    }
  }};;
`;

export const ButtonWrapper = styled.div<MainStyledProps>`
  margin: 1.75rem 0;
  padding: 1px;
  display: flex;
  flex-direction: column;
  align-items: ${({ theme }) => {
    switch (theme) {
      case 'left':
        return 'flex-start';
      default:
        return 'center';
    }
  }};
`;

export const HeaderParaWrapper = styled.div`
  margin: 0.875rem 0 0;
`;

export const IdleMessageContainerStyled = styled.div`
  ${fonts.font1.H17B}
  min-height: 1.875rem;
  line-height: 1.33rem;
  padding: 0.875rem;
  border-radius: 4px;
  margin: 2rem 2rem 0 2rem;
  border: 1px solid ${colours.colour7};
  color: ${colours.colour7};
`;

export const EmailVerificationMessageContainerStyled = styled.div<EmailVerificationMessageContainerStyledProps>`

  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return fonts.font1.H15;
      default:
        return fonts.font1.H17B;
    }
  }}
  min-height: 1.875rem;
  line-height: 1.33rem;
  padding: 0.875rem;
  border-radius: 4px;
  margin: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return '2rem 0.625rem 0';
      default:
        return '2rem 2rem 0';
    }
  }};

  border: 1px solid ${colours.colour22};
  color: ${colours.colour22};
`;

export const ErrorMessageWrapper = styled.div`
  color: ${colours.colour7};
  ${fonts.font1.H15};
`;

export const ButtonInline = styled.button`
  display: inline-block;
  transition: all .3s ease;
  cursor: pointer;
  border: none;
  background: transparent;
  text-decoration: underline;
  color: ${colours.colour7};
  ${fonts.font1.H15};
`;

export const UserNoticationWrapper = styled.div`
  max-width: 750px;
  margin: 0 auto;
`;

export const UserMessageStyled = styled.div<UserMessageStyledProps>`
  border: ${({ theme }) => (theme === 'warning' ? '' : `1px solid ${colours.colour22}`)};
  color:  ${({ theme }) => (theme === 'warning' ? colours.colour7 : colours.colour22)};
  border-radius: 4px;

  ${({ theme }) => {
    switch (theme) {
      case 'warning':
        return fonts.font1.H28B;
      default:
        return fonts.font1.H15B;
    }
  }};
  margin: 2rem auto 0;
  padding: 0.875rem;
  text-align: center;
  line-height: 1.33rem;

  a {
    color: ${colours.colour22};
    ${fonts.font1.H15B};
  }

  p {
    margin-bottom: ${toRem(8)};
  }

  @media (min-width: 768px) {
    max-width: 50%;
  }
`;

export const LoginWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 0 ${toRem(24)};

  @media (min-width: 600px) {
    padding: 0;
    margin-top: ${toRem(56)};
  }
`;

export const LoginHeaderStyled = styled.h1`
  margin-bottom: 1rem;
  ${fonts.font1.H24B};
  line-height: ${toRem(36)};

  @media (min-width: 600px) {
    ${fonts.font1.H40B};
    line-height: ${toRem(60)};
  }
`;

export const LoginDescriptionStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${toRem(40)};
  line-height: ${toRem(24)};
  ${fonts.font1.H16};
  
  p {
    margin-bottom: ${toRem(8)};
  }
  
  @media (min-width: 600px) {
    flex-direction: row;
    ${fonts.font1.H20};

    p {
      margin-right: 0.4rem;
      margin-bottom: 0;
    }
  }
`;

export const TextInputWrapper = styled.div<TextInputWrapperProps>`
  margin-bottom: ${({ marginBottom }) => (marginBottom === 'lg' ? toRem(40) : toRem(32))};
`;

export const LinkWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const LoginFooterStyled = styled.div`
  margin-top: ${toRem(40)};
`;
