import styled from 'styled-components';
import { fonts } from '../../../styles/theme';
import toRem from '../../../utils/toRem';

type ButtonStyledProps = {
  readonly theme?: string
}

type SectiontWrapperProps = {
  readonly theme?: 'secondary'
  readonly isLast?: boolean
}

type DivStyledProps = {
  readonly maxWidth?: string
  readonly margin?: string
}

type FlexWrapperProps = {
  readonly alignItems?: string
  readonly mobileFlex?: string
}

export const TextWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const SubTitleWrapper = styled.div`
  margin: 1rem 0;
`;

export const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  line-height: 1.33rem;
`;

export const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  flex-direction: ${({ mobileFlex }) => (mobileFlex || 'row')};
  align-items: ${({ mobileFlex }) => (mobileFlex ? 'flex-start' : 'center')};

  @media (min-width: 820px) {
      align-items: ${({ alignItems }) => alignItems || 'center'};
      flex-direction: row;
    }
`;

export const AgeInputWrapper = styled.div`
${fonts.font1.H14};

  @media (min-width: 820px) {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
`;

export const SectiontWrapper = styled.div<SectiontWrapperProps>`
  margin: ${({ theme }) => (theme === 'secondary' ? '0 0 2.5rem' : '0')} ;
  padding-bottom: ${({ isLast }) => (isLast ? '0' : '2.5rem')};
`;

export const ButtonStyled = styled.div<ButtonStyledProps>`
  display: flex;
`;

export const ButtonWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const ButtonTextWrapper = styled.div`
  margin-left: 0.425rem;
`;

export const CheckboxWrapper = styled.div`
  margin: 0.875rem 0 0.875rem 1rem;
`;

export const DivStyled = styled.div<DivStyledProps>`
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  margin: 0 0 0.875rem;
  display: flex;
  
  @media (min-width: 820px) {
    margin: ${({ margin }) => margin || '0'};
  }
`;

export const InputWrapper = styled.div`
  margin: 0 2rem;
  width: 100%;
`;

export const LabelStyled = styled.label`
  ${fonts.font1.H14}
  margin-left: 0.875rem;
`;

export const SectionDescriptionWrapper = styled.div`
  line-height: 1.33rem;
  margin: 0.5rem 0 1rem;
`;

export const MobileWrapper = styled.div`
  @media (min-width: 820px) {
    display: none;
  }
`;

export const InputWrapperStyled = styled.div`
  margin-top: 2.5rem;
`;

export const SectionDescriptioBold = styled.p`
  margin-top: ${toRem(8)};
  ${fonts.font1.H14B};
`;
