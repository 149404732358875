import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { TextInput } from '../../../../components';
import Button from '../../../../components/Button';
import { SelectInput } from '../../../../components/SelectInput';
import { TextP } from '../../../../components/Text';
import { Toggle } from '../../../../components/Toggle';
import {
  ErrorMessageWrapper,
  FooterStyled,
  InputWrapper, LinkWrapper, PopUpErrorStyled, ReCAPTCHAStyled, SectionStyled, SelectWrapper,
} from '../../../../styles/ResearcherCreateStyles';
import { researcherPosition } from '../../../../utils/researcherConst';
import { fonts } from '../../../../styles/theme';
import { LinkComponent } from '../../../../components/Link';
import { ErrorMessage } from '../../../../components/ErrorMessage';
import environment from '../../../../config/environment';
import { CREATE_RESEARCHER } from '../const';

type universityModel = {
  name: string,
  id: number,
}

type researcherInfoProps = {
  firstName: string,
  lastName: string,
  phoneNumber: string,
  extension?: string,
  mobilePhoneNumber: string,
  position: string,
  positionOther?: string,
  ha_affiliation_id?: string,
  academic_affiliation_id: string | number,
  academicAffiliationOther?: string,
  terms_of_use?: boolean,
  learnAboutReachBC?: string,
  learnAboutReachBCOther?: string
}

type CreateFormProps = {
  researcherInfo: researcherInfoProps
  researcherInfoError: { [key: string]: string }
  handleChange: (value: string | number | boolean, field: string) => void
  agreeTermsOfUse: boolean
  setAgreeTermsOfUse: (agreeTermOfUse: boolean) => void
  receiveNews: boolean
  setReciveNews: (receiveNews: boolean) => void
  universities: universityModel[]
  handleSubmit: (event: React.FormEvent) => void
  healthAuthorities: { name: string, id: number }[]
  captchaHandler: (value: any) => void
  captchaRef: React.MutableRefObject<undefined>
  handleSingleValidation: (value: any, field: string, type?: string) => void
  smsConsent: boolean
  setSmsContsent: (smsContsent: boolean) => void
}

const InfoForm = ({
  researcherInfo, researcherInfoError, handleSubmit, handleChange, smsConsent, setSmsContsent,
  agreeTermsOfUse, setAgreeTermsOfUse, receiveNews, setReciveNews, universities, healthAuthorities, captchaHandler, captchaRef, handleSingleValidation,
}: CreateFormProps) => (
  <form>
    <InputWrapper>
      <TextInput
        label={CREATE_RESEARCHER.FIRST_NAME.LABEL}
        textValue={researcherInfo.firstName}
        setTextValue={(value) => { handleChange(value, 'firstName'); }}
        error={researcherInfoError.first_name || researcherInfoError.firstName}
        type="text"
        inputWidth="49%"
        onBlurAction={() => handleSingleValidation(researcherInfo.firstName, 'firstName')}
      />
      <TextInput
        label={CREATE_RESEARCHER.LAST_NAME.LABEL}
        textValue={researcherInfo.lastName}
        setTextValue={(value) => { handleChange(value, 'lastName'); }}
        error={researcherInfoError.lastName || researcherInfoError.last_name}
        type="text"
        inputWidth="49%"
        onBlurAction={() => handleSingleValidation(researcherInfo.lastName, 'lastName')}
      />
    </InputWrapper>
    <InputWrapper>
      <TextInput
        label={CREATE_RESEARCHER.PHONE_NUMBER.LABEL}
        description={CREATE_RESEARCHER.PHONE_NUMBER.DESCRIPTION}
        textValue={researcherInfo.phoneNumber}
        setTextValue={(value) => { handleChange(value, 'phoneNumber'); }}
        error={researcherInfoError.phoneNumber || researcherInfoError.phone_number}
        type="text"
        inputWidth="75%"
        onBlurAction={() => handleSingleValidation(researcherInfo.phoneNumber, 'phoneNumber')}
      />
      <TextInput
        label={CREATE_RESEARCHER.EXTENSION.LABEL}
        description="&nbsp;"
        textValue={researcherInfo.extension || ''}
        setTextValue={(value) => { handleChange(value, 'extension'); }}
        error={researcherInfoError.extension}
        type="text"
        inputWidth="23%"
        isRequired={false}
        onBlurAction={() => handleSingleValidation(researcherInfo.extension, 'extension')}
      />
    </InputWrapper>
    <InputWrapper>
      <TextInput
        label={CREATE_RESEARCHER.MOBILE.LABEL}
        description={CREATE_RESEARCHER.MOBILE.DESCRIPTION}
        textValue={researcherInfo.mobilePhoneNumber}
        setTextValue={(value) => { handleChange(value, 'mobilePhoneNumber'); }}
        error={researcherInfoError.mobilePhoneNumber || researcherInfoError.mobile_phone_number}
        type="text"
        onBlurAction={() => handleSingleValidation(researcherInfo.mobilePhoneNumber, 'mobilePhoneNumber')}
      />
    </InputWrapper>
    <InputWrapper>
      <SelectWrapper>
        <SelectInput
          label={CREATE_RESEARCHER.POSITION.LABEL}
          value={researcherInfo.position}
          id="position"
          placeholder={CREATE_RESEARCHER.POSITION.PLACEHOLDER}
          onChange={handleChange}
          options={researcherPosition}
          theme="third"
          error={researcherInfoError.position}
        />
      </SelectWrapper>
    </InputWrapper>
    <InputWrapper>
      <TextInput
        label={CREATE_RESEARCHER.POSITION_OTHER.LABEL}
        textValue={researcherInfo.positionOther || ''}
        setTextValue={(value) => { handleChange(value, 'positionOther'); }}
        type="text"
        isRequired={false}
        error={researcherInfoError.position_other || researcherInfoError.positionOther}
        onBlurAction={() => { if (researcherInfo.position === 'Other') handleSingleValidation(researcherInfo.positionOther, 'positionOther', 'other'); }}
      />
    </InputWrapper>
    <InputWrapper>
      <SelectWrapper>
        <SelectInput
          label={CREATE_RESEARCHER.AUTHORITY_AFFILATION.LABEL}
          placeholder={CREATE_RESEARCHER.AUTHORITY_AFFILATION.PLACEHOLDER}
          id="healthAffiliation"
          onChange={(value) => { handleChange(Number(value), 'ha_affiliation_id'); }}
          options={healthAuthorities}
          value={researcherInfo.ha_affiliation_id}
          theme="third"
          error={researcherInfoError.ha_affiliation_id}
        />
      </SelectWrapper>
    </InputWrapper>
    <InputWrapper>
      <SelectWrapper>
        <SelectInput
          label={CREATE_RESEARCHER.UNIVERSITY.LABEL}
          placeholder={CREATE_RESEARCHER.UNIVERSITY.PLACEHOLDER}
          id="academicAffiliation"
          onChange={(value) => { handleChange(Number(value), 'academic_affiliation_id'); }}
          options={universities}
          value={researcherInfo.academic_affiliation_id}
          theme="third"
          error={researcherInfoError.academic_affiliation_id}
        />
      </SelectWrapper>
    </InputWrapper>
    <InputWrapper>
      <TextInput
        label={CREATE_RESEARCHER.UNIVERSITY_OTHER.LABEL}
        textValue={researcherInfo.academicAffiliationOther || ''}
        setTextValue={(value) => { handleChange(value, 'academicAffiliationOther'); }}
        error={researcherInfoError.academicAffiliationOther || researcherInfoError.academic_affiliation_other}
        type="text"
        isRequired={false}
        onBlurAction={() => { if (researcherInfo.academic_affiliation_id === '10' || researcherInfo.academic_affiliation_id === 10) handleSingleValidation(researcherInfo.academicAffiliationOther, 'academicAffiliationOther', 'other'); }}
      />
    </InputWrapper>
    <InputWrapper>
      <SelectWrapper>
        <SelectInput
          label={CREATE_RESEARCHER.LEARNED_ABOUT.LABEL}
          value={researcherInfo.learnAboutReachBC}
          id="learnAboutReachBC"
          placeholder={CREATE_RESEARCHER.LEARNED_ABOUT.PLACEHOLDER}
          onChange={handleChange}
          options={CREATE_RESEARCHER.LEARNED_ABOUT.OPTIONS}
          theme="third"
          error={researcherInfoError.position}
        />
      </SelectWrapper>
    </InputWrapper>
    <InputWrapper>
      <TextInput
        label={CREATE_RESEARCHER.LEARNED_ABOUT_OTHER.LABEL}
        textValue={researcherInfo.learnAboutReachBCOther || ''}
        setTextValue={(value) => { handleChange(value, 'learnAboutReachBCOther'); }}
        error={researcherInfoError.academicAffiliationOther || researcherInfoError.academic_affiliation_other}
        type="text"
        isRequired={false}
      // onBlurAction={() => { if (researcherInfo.academic_affiliation_id === '10' || researcherInfo.academic_affiliation_id === 10) handleSingleValidation(researcherInfo.academicAffiliationOther, 'academicAffiliationOther', 'other'); }}
      />
    </InputWrapper>

    <InputWrapper justifyContent="flex-start">
      <Toggle checked={receiveNews} name="toggle-terms" onChange={() => setReciveNews(!receiveNews)} />
      <TextP font={fonts.font1.H14}>{CREATE_RESEARCHER.NEWS}</TextP>
    </InputWrapper>
    <InputWrapper justifyContent="flex-start">
      <Toggle checked={agreeTermsOfUse} onChange={(value) => { setAgreeTermsOfUse(!agreeTermsOfUse); handleChange(value, 'terms_of_use'); }} />
      <TextP font={fonts.font1.H14}>{CREATE_RESEARCHER.CONFIDENTIALITY}</TextP>
    </InputWrapper>

    <SectionStyled>
      <PopUpErrorStyled>
        {researcherInfoError.terms_of_use && <ErrorMessage message={researcherInfoError.terms_of_use} />}
      </PopUpErrorStyled>
    </SectionStyled>

    <InputWrapper justifyContent="flex-start">
      <Toggle checked={smsConsent} onChange={setSmsContsent} />
      <TextP font={fonts.font1.H14}>{CREATE_RESEARCHER.TWO_FACTOR}
      </TextP>
    </InputWrapper>
    <InputWrapper justifyContent="center">
      <LinkComponent
        url={CREATE_RESEARCHER.UNDERTAKING_LINK.URL}
        theme="defaultTextLarge"
        newWindow
      >
        {CREATE_RESEARCHER.UNDERTAKING_LINK.LABEL}
      </LinkComponent>
    </InputWrapper>
    <FooterStyled>
      <ReCAPTCHAStyled>
        <ReCAPTCHA
          sitekey={environment.app.recapchaKey || ''}
          onChange={captchaHandler}
          grecaptcha={captchaRef}
        />
        {researcherInfoError.recaptcha && (
          <ErrorMessageWrapper>
            <ErrorMessage message={researcherInfoError.recaptcha} />
          </ErrorMessageWrapper>
        )}
      </ReCAPTCHAStyled>
      <Button
        id="create-button"
        onClick={(event) => handleSubmit(event)}
        theme="outline"
        disabled={!smsConsent}
      >
        <p>{CREATE_RESEARCHER.CONTUNUE_BUTTON}</p>
      </Button>
    </FooterStyled>
    <LinkWrapper>
      {CREATE_RESEARCHER.TERMS.START}
      <LinkComponent
        url={CREATE_RESEARCHER.TERMS.LINK_URL}
        theme="underlineSecondary"
        newWindow
      >
        {CREATE_RESEARCHER.TERMS.LINK_LABEL}
      </LinkComponent>
    </LinkWrapper>
  </form>
);

export default InfoForm;
