import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonStyled } from '../../../../components/Button/styles';
import { TextH2, TextP } from '../../../../components/Text';
import { HeaderStyled, SectionStyled } from '../../../../styles/MyProfileStyles';
import { fonts } from '../../../../styles/theme';

type MyProfileUpdateSuccessProps = {
  userType: string;
  path?: string
  setStatus?: () => void;
}

const MyProfileUpdateSuccess = ({ userType, setStatus, path }: MyProfileUpdateSuccessProps) => {
  const navigate = useNavigate();
  return (
    <HeaderStyled theme="success">
      <SectionStyled>
        <TextH2 font={fonts.font1.H26}>Success</TextH2>
      </SectionStyled>
      <SectionStyled theme="ML">
        <TextP font={fonts.font1.H15}>Your updates have been saved</TextP>
      </SectionStyled>
      {userType === 'researcher'
        ? (
          <ButtonStyled theme="outlineSecondary" buttonWidth="15.688rem" onClick={() => navigate('/dashboard')}>
            Continue
          </ButtonStyled>
        ) : (
          <ButtonStyled
            theme="outlineSecondary"
            buttonWidth="15.688rem"
            onClick={() => {
              if (setStatus) setStatus();
              if (path) navigate(path);
              if (!path) navigate('/myProfile');
            }}
          >
            Continue
          </ButtonStyled>
        )}
    </HeaderStyled>
  );
};

export default MyProfileUpdateSuccess;
