import React from 'react';
import PPOResultForm from './PPOResultForm';
import StudyResultForm from './StudyResultForm';

type ResultFormProps = {
  result: IResult
  handleToggle: (value: boolean, field: string) => void
  handleChange: (value: string | number, field: string) => void
  handleFile: (value: File, field: string) => void
  fileName?: string
  currentFile?: File
  errors: { [key: string]: string }
  projectType: string
  linkStudyOptions: { value: string, label: string }[]
  handleSingleValidation: (value: any, field: string, type?: string) => void
  handleRemoveFile: () => void
  handleGetSignedURl?: () => void
}

const ResultForm = ({
  result, fileName, handleToggle, handleChange, handleFile, handleSingleValidation,
  errors, projectType, linkStudyOptions, currentFile, handleRemoveFile, handleGetSignedURl,
}: ResultFormProps) => (
  <>
    {projectType === 'study' && (
      <StudyResultForm
        result={result}
        fileName={fileName}
        currentFile={currentFile}
        handleChange={handleChange}
        handleFile={handleFile}
        handleToggle={handleToggle}
        errors={errors}
        handleSingleValidation={handleSingleValidation}
        handleRemoveFile={handleRemoveFile}
        handleGetSignedURl={handleGetSignedURl}
      />
    )}

    {projectType === 'ppo' && (
      <PPOResultForm
        result={result}
        fileName={fileName}
        currentFile={currentFile}
        handleChange={handleChange}
        handleFile={handleFile}
        handleToggle={handleToggle}
        linkStudyOptions={linkStudyOptions}
        errors={errors}
        handleSingleValidation={handleSingleValidation}
        handleRemoveFile={handleRemoveFile}
        handleGetSignedURl={handleGetSignedURl}
      />
    )}
  </>
);

export default ResultForm;
