import React from 'react';
import { fonts } from '../../styles/theme';
import { ErrorMessage } from '../ErrorMessage';
import PublicToolTip from '../PublicTooltip';
import { TextP } from '../Text';
import {
  LabelStyled, InputStyled, InputWrapper, TextWrapper, LabelTextStyled,
} from './styles';
import { RequiredForm } from '../RequiredForm';
import { NotVisible } from '../NotVisible';

type NumberInputProps = {
  label: string;
  smallFont?: boolean
  mbFont?: boolean
  placeholder?: string;
  description?: string;
  isRequired?: boolean;
  isPublic?: boolean,
  numberValue: number | undefined
  setNumberValue: (numberValue: number | string) => void
  maxValue?: number
  minValue?: number
  theme?: string
  padding?: string
  error?: string
  disabled?: boolean
  unbold?: boolean
  isNotPublic?: boolean
  onBlurAction?: () => void
}
export const NumberInput = ({
  label, placeholder, description, isRequired = true, numberValue, setNumberValue, maxValue, theme, smallFont = false, padding, error, isPublic = false, disabled, onBlurAction, unbold, mbFont,
  minValue = 1, isNotPublic = false,
}: NumberInputProps) => (
  <InputWrapper theme={theme}>
    <LabelStyled
      htmlFor={`id-${label}`}
      isRequired={isRequired}
      smallFont={smallFont}
      mbFont={mbFont}
      unbold={unbold}
    >
      <LabelTextStyled>
        <span>
          {label}
          {isPublic && <PublicToolTip />}
          {isRequired
            && <RequiredForm />}
        </span>
        {isNotPublic && <NotVisible />}
      </LabelTextStyled>
    </LabelStyled>
    {description && (
      <TextWrapper>
        <TextP font={fonts.font1.H14} lineHeight="1.33rem">
          <p>{description}</p>
        </TextP>
      </TextWrapper>
    )}
    <InputStyled
      padding={padding}
      type="number"
      id={`id-${label}`}
      max={maxValue}
      min={minValue}
      placeholder={placeholder}
      autoComplete="off"
      value={numberValue}
      onChange={(event) => {
        const number = parseInt(event.target.value, 10);
        if (Number.isNaN(number)) {
          setNumberValue(event.target.value);
        }
        if (maxValue && number > maxValue) {
          setNumberValue(number);
        } else {
          setNumberValue(number);
        }
      }}
      disabled={disabled}
      theme={theme}
      onBlur={() => {
        if (onBlurAction) {
          onBlurAction();
        }
      }}
    />
    {error && <ErrorMessage message={error} />}
  </InputWrapper>
);
