import React from 'react';
import Button from '../Button';
import * as Styled from './styles';

type TabsPropTypes = {
  tabs: { label: string, description?: string, value: string }[]
  selectTabHandler: (tab: { label: string, description?: string, value: string }) => void
  selectedTab: { label: string, description?: string, value: string }
  projectCount?: Record<string, number>
}

export const Tabs = ({
  tabs, selectedTab, projectCount, selectTabHandler,
}: TabsPropTypes) => (
  <Styled.ContainerWrapper>
    <Styled.ContainerScrollWrapper>
      <Styled.TabsWrapper>
        {tabs.map((tab) => (
          <Button
            id="tab-my-opportunities"
            onClick={() => selectTabHandler(tab)}
            theme={selectedTab.value === tab.value ? 'tab' : 'tabSecondary'}
            key={tab.value}
          >
            <Styled.ButtonContainWrapper>

              {tab.label}
              {projectCount && (
                <Styled.CounterStyled theme={selectedTab.value === tab.value ? 'primary' : 'secondary'}>
                  <p>{projectCount[tab.value]}</p>
                </Styled.CounterStyled>
              )}
            </Styled.ButtonContainWrapper>
          </Button>
        ))}
        <Styled.TabExtraContainer />
      </Styled.TabsWrapper>
    </Styled.ContainerScrollWrapper>
    <Styled.DescriptionWrapper>
      <p>{selectedTab.description}</p>
    </Styled.DescriptionWrapper>
  </Styled.ContainerWrapper>
);
