import styled from 'styled-components';
import { colours, fonts } from './theme';
import toRem from '../utils/toRem';

type InputWrapperProps = {
  readonly justifyContent?: string
  readonly alignItems?: string
  readonly theme?: 'secondary'
}

type TextDivStyledProps = {
  readonly margin?: string
}

export const PageWrapper = styled.div`
  margin: 0px auto 40px;
`;

export const HeaderStyled = styled.div`
  border-bottom: 1px solid ${colours.colour41};
  padding-bottom: ${toRem(24)};
  
  h1 {
    ${fonts.font1.H24B};
    line-height: ${toRem(36)};
    margin-bottom: ${toRem(8)};
  }
  
  @media (min-width: 600px) {
    h1 {
      line-height: ${toRem(60)};
      ${fonts.font1.H40B};
      margin-bottom: ${toRem(16)};
    }
  }
`;

export const HeaderDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
    ${fonts.font1.H16};

  p {
    margin-right: 0.3rem;
  }

  @media (min-width: 600px) {
    ${fonts.font1.H20};
  }
`;

export const MainStyled = styled.main`
  max-width: 93.75%;
  margin: ${toRem(32)} auto 0;
  padding: 0 0.875rem;

  @media (min-width: 768px) {
    max-width: 62.5%;
  }
  
  @media (min-width: 992px) {
    max-width: 50%;
  }
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: ${({ alignItems }) => alignItems || ''};
  margin: 0 auto 1.75rem;

  border-bottom: ${({ theme }) => (theme === 'secondary' ? `1px solid ${colours.colour9}` : 'none')};
  padding-bottom: ${({ theme }) => (theme === 'secondary' ? toRem(40) : 0)};
`;

export const ButtonWrapper = styled.div`
  margin: 1.5rem 0;
`;

export const BorderDivStyled = styled.div`
  background-color: ${colours.colour4};
  box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 50px;
  padding: 1rem 1rem;
  width: 100%;
  height: 10rem;
  position: fixed;
  bottom: 0;

  @media (min-width:820px) {
    box-shadow: none;
    border: 1px solid ${colours.colour9};
    padding: 1.5rem 1.5rem 0.875rem 1.5rem;
    margin-top: 6.875rem;
    width: 28%;
    height: 28%;
    position: relative;
  }
`;

export const TextDivStyled = styled.div<TextDivStyledProps>`
  margin: ${({ margin }) => margin || 0};
  line-height: ${toRem(24)};
`;

export const DateDivStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const DateWrapper = styled.div`
  padding: 1rem 0 0 2.5%;
  width: 100%;
`;

export const FormStyled = styled.form`
  padding: 1.33rem 0 0;
`;

export const ResearcherLoginWrapper = styled.div<InputWrapperProps>`
  display: flex;
  align-items:center;
  justify-content: space-between;

  @media (min-width:820px) {
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  }
`;

export const FixedCard = styled.div`
  display: flex;
  align-items:center;
  justify-content: space-between;
  margin-bottom: 0.875rem;
  width: 100%;
  
  @media (min-width: 820px) {
    margin-right: 0.875rem;
  }
`;

export const DismissButtonWrapper = styled.div`
  @media (min-width: 820px) {
    display:none;
  }
`;

export const VolunteerLoginWrapper = styled.div<InputWrapperProps>`
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin: 0 1rem;

  @media (min-width: 820px) { 
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
    margin: 0 auto;
  } 
`;

export const SectionStyled = styled.div`
  margin-bottom: 1.5rem;
`;

export const PopUpErrorStyled = styled.div`
  display: block;
  align-items: center;
  width: 100%;
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 0.35rem;
  color: ${colours.colour7};
  margin-top: 1rem;
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
`;

export const WarningMessage = styled.div`
  ${fonts.font1.H14B};
  color: ${colours.colour7};
  margin-top: 1rem;
`;

export const EmailWrapper = styled.div`
  width: 100%;
`;
