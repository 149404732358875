import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';

type LinkStyledProps = {
  readonly theme?: string
  readonly linkWidth?: string
};

export const LinkStyled = styled.a<LinkStyledProps>`
  transition: all .3s ease;
  cursor: pointer;
  line-height: ${({ theme }) => (theme === 'buttonFourth' ? toRem(20)
    : '1.125rem')};
  width: ${({ linkWidth }) => linkWidth || ''};
  
  text-align: ${({ theme }) => {
    switch (theme) {
      case 'researcherProfile':
        return 'center';
      case 'footerIcon':
        return 'center';
      default:
    }
  }};
  
  text-decoration: ${({ theme }) => {
    switch (theme) {
      case 'button':
        return 'none';
      case 'buttonMd':
        return 'none';
      case 'buttonSm':
        return 'none';
      case 'buttonSecondary':
        return 'none';
      case 'buttonThird':
        return 'none';
      case 'buttonfifth':
      case 'buttonFourth':
        return 'none';
      case 'menuButton':
        return 'none';
      case 'heroLink':
        return 'none';
      case 'dashboardLink':
        return 'none';
      case 'returnLink':
        return 'none';
      case 'tableCell':
        return 'none';
      case 'createResearcherButton':
        return 'none';
      case 'website':
        return 'none';
      case 'websiteLg':
        return 'none';
      case 'websiteSm':
        return 'none';
      case 'websiteSpan':
        return 'none';
      case 'outline':
        return 'none';
      case 'outlineMd':
        return 'none';
      case 'outlineSm':
        return 'none';
      case 'footerIcon':
        return 'none';
      case 'modelMenu':
        return 'none';
      case 'modelMenuSecondary':
        return 'none';
      case 'formLink':
        return 'none';
      case 'accordion':
        return 'none';
      case 'plain':
        return 'none';
      case 'plainSecondary':
        return 'none';
      default:
        return 'underline';
    }
  }};

  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return fonts.font1.H14;
      case 'secondaryLg':
        return fonts.font1.H14;
      case 'tertiary':
        return fonts.font1.H13;
      case 'button':
        return fonts.font1.H14B;
      case 'buttonMd':
        return fonts.font1.H15;
      case 'buttonSm':
        return fonts.font1.H13B;
      case 'buttonSecondary':
        return fonts.font1.H14B;
      case 'buttonThird':
        return fonts.font1.H14B;
      case 'buttonfifth':
      case 'buttonFourth':
        return fonts.font1.H16B;
      case 'outline':
        return fonts.font1.H14B;
      case 'outlineMd':
        return fonts.font1.H15B;
      case 'outlineSm':
        return fonts.font1.H13B;
      case 'defaultTextLarge':
        return fonts.font1.H15;
      case 'termsOfUse':
        return fonts.font1.H14;
      case 'heroLink':
        return fonts.font1.H14B;
      case 'dashboardLink':
        return fonts.font1.H14B;
      case 'footerLink':
        return fonts.font1.H12;
      case 'returnLink':
        return fonts.font1.H14B;
      case 'selectInput':
        return fonts.font1.H14;
      case 'checkboxDesc':
        return fonts.font1.H14;
      case 'inlineLarge':
        return fonts.font1.H14;
      case 'researcherProfile':
        return fonts.font1.H14;
      case 'website':
        return fonts.font1.H15B;
      case 'websiteLg':
        return fonts.font1.H18B;
      case 'websiteSm':
        return fonts.font1.H13;
      case 'websiteSpan':
        return fonts.font1.H15B;
      case 'modelMenu':
        return fonts.font1.H18;
      case 'modelMenuSecondary':
        return fonts.font1.H16;
      case 'accordion':
        return fonts.font1.H16;
      case 'underlineSecondary':
        return fonts.font1.H13B;
      case 'footerIcon':
        return fonts.font1.H15B;
      case 'error':
        return fonts.font1.H12B;
      case 'formLink':
        return fonts.font1.H14B;
      case 'loginLink':
        return fonts.font1.H16B;
      case 'colour36':
        return fonts.font1.H14;
      case 'underlineThird':
        return fonts.font1.H16;
      case 'plainSecondary':
        return fonts.font1.H16;
      default:
        return fonts.font1.H13;
    }
  }};

  color:  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour6;
      case 'secondaryLg':
        return colours.colour6;
      case 'tertiary':
        return colours.colour16;
      case 'quaternary':
        return colours.colour4;
      case 'button':
        return colours.colour4;
      case 'buttonMd':
        return colours.colour4;
      case 'buttonSm':
        return colours.colour4;
      case 'buttonSecondary':
        return colours.colour10;
      case 'buttonThird':
        return colours.colour5;
      case 'buttonfifth':
      case 'buttonFourth':
        return colours.colour4;
      case 'outline':
        return colours.colour5;
      case 'outlineMd':
        return colours.colour5;
      case 'outlineSm':
        return colours.colour5;
      case 'inline':
        return colours.colour2;
      case 'inlineLarge':
        return colours.colour2;
      case 'menuButton':
        return colours.colour6;
      case 'heroLink':
        return colours.colour10;
      case 'dashboardLink':
        return colours.colour8;
      case 'defaultTextLarge':
        return colours.colour2;
      case 'footerLink':
        return colours.colour8;
      case 'returnLink':
        return colours.colour8;
      case 'tableCell':
        return colours.colour40;
      case 'createResearcherButton':
        return colours.colour5;
      case 'selectInput':
        return colours.colour14;
      case 'checkboxDesc':
        return colours.colour14;
      case 'website':
        return colours.colour5;
      case 'websiteLg':
        return colours.colour5;
      case 'websiteSm':
        return colours.colour5;
      case 'websiteSpan':
        return colours.colour5;
      case 'footerIcon':
        return colours.colour5;
      case 'modelMenu':
        return colours.colour5;
      case 'modelMenuSecondary':
        return colours.colour5;
      case 'accordion':
        return colours.colour5;
      case 'underlineSecondary':
        return colours.colour5;
      case 'error':
        return colours.colour7;
      case 'formLink':
        return colours.colour35;
      case 'loginLink':
        return colours.colour1;
      case 'plain':
        return colours.colour36;
      case 'underlineThird':
        return colours.colour8;
      case 'plainSecondary':
        return colours.colour1;
      default:
        return colours.colour14;
    }
  }};

  div {
    padding:  ${({ theme }) => {
    switch (theme) {
      case 'menuButton':
        return '0.5rem';
      case 'buttonfifth':
      case 'buttonFourth':
        return '0.75rem 1.5rem';
      case 'heroLink':
        return '';
      case 'dashboardLink':
        return `${toRem(10)} ${toRem(20)}`;
      case 'returnLink':
        return '';
      case 'tableCell':
        return '';
      case 'footerIcon':
        return '0';
      case 'logo':
        return '8px 0';
      case 'modelMenuSecondary':
        return '0.875rem 1.75rem';
      case 'outlineSm':
        return '0.313rem 0.625rem';
      case 'outlineMd':
        return '0.625rem 0.938rem';
      case 'buttonMd':
        return '0.625rem 0.938rem';
      case 'buttonSm':
        return '0.313rem 0.625rem';
      case 'error':
        return '0';
      default:
        return '0.5rem 1.5rem';
    }
  }};
  
  border:  ${({ theme }) => {
    switch (theme) {
      case 'outline':
        return `1px solid ${colours.colour5}`;
      case 'outlineMd':
        return `1px solid ${colours.colour5}`;
      case 'outlineSm':
        return `1px solid ${colours.colour5}`;
      case 'buttonfifth':
      case 'buttonFourth':
        return `1px solid ${colours.colour6}`;
      case 'createResearcherButton':
        return `1px solid ${colours.colour5}`;
      default:
        return 'none';
    }
  }};

    border-radius:  ${({ theme }) => {
    switch (theme) {
      case 'menuButton':
        return '0';
      case 'tableCell':
        return '';
      default:
        return '4px';
    }
  }};
    background: ${({ theme }) => {
    switch (theme) {
      case 'button':
        return colours.colour8;
      case 'buttonMd':
        return colours.colour8;
      case 'buttonSm':
        return colours.colour8;
      case 'buttonSecondary':
        return colours.colour9;
      case 'buttonThird':
        return colours.colour2;
      case 'buttonfifth':
      case 'buttonFourth':
        return colours.colour8;
      case 'heroLink':
        return colours.colour9;
      case 'outline':
        return colours.colour4;
      default:
        return '';
    }
  }};

  &:hover {
    background: ${({ theme }) => {
    switch (theme) {
      case 'buttonSecondary':
        return colours.colour12;
      case 'buttonThird':
        return colours.colour5;
      case 'menuButton':
        return 'transparent';
      case 'heroLink':
        return colours.colour12;
      case 'dashboardLink':
        return colours.colour1;
      case 'modelMenu':
        return colours.colour3;
      case 'modelMenuSecondary':
        return colours.colour2;
      default:
        return '';
    }
  }};
  }
  }

  p {
    border-bottom: ${({ theme }) => {
    switch (theme) {
      case 'website':
        return `3px solid ${colours.colour2}`;
      case 'websiteLg':
        return `3px solid ${colours.colour2}`;
      case 'websiteSm':
        return `2px solid ${colours.colour2}`;
      default:
        return 'none';
    }
  }};
    width: ${({ theme }) => {
    switch (theme) {
      case 'website':
        return 'fit-content';
      case 'websiteSM':
        return 'fit-content';
      case 'websiteLg':
        return 'fit-content';
      default:
        return '';
    }
  }};

  padding: ${({ theme }) => {
    switch (theme) {
      case 'websiteLg':
        return '0.625rem';
      default:
    }
  }
};

  &:hover {
    background: ${({ theme }) => {
    switch (theme) {
      case 'website':
        return colours.colour2;
      case 'websiteSM':
        return colours.colour2;
      case 'websiteLg':
        return colours.colour2;
      default:
        return '';
    }
  }};
  }
  }

    span {
    border-bottom: ${({ theme }) => {
    switch (theme) {
      case 'websiteSpan':
        return `3px solid ${colours.colour2}`;
      default:
        return 'none';
    }
  }};
    width: ${({ theme }) => {
    switch (theme) {
      case 'websiteSpan':
        return 'fit-content';
      default:
        return '';
    }
  }};

    &:hover {
      background: ${({ theme }) => {
    switch (theme) {
      case 'websiteSpan':
        return colours.colour2;
      default:
        return '';
    }
  }};
    }
  }

  &:hover {
  text-decoration: ${({ theme }) => {
    switch (theme) {
      case 'quaternary':
        return 'underline';
      case 'formLink':
        return 'underline';
      case 'plainSecondary':
        return 'underline';
      default:
        return 'none';
    }
  }};
  
  color:  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour6;
      case 'secondaryLg':
        return colours.colour6;
      case 'outline':
        return colours.colour5;
      case 'outlineMd':
        return colours.colour5;
      case 'outlineSm':
        return colours.colour5;
      case 'tertiary':
        return colours.colour16;
      case 'quaternary':
        return colours.colour2;
      case 'button':
        return colours.colour4;
      case 'buttonMd':
        return colours.colour4;
      case 'buttonSm':
        return colours.colour4;
      case 'buttonSecondary':
        return colours.colour10;
      case 'buttonThird':
        return colours.colour4;
      case 'buttonfifth':
      case 'buttonFourth':
        return colours.colour4;
      case 'inline':
        return colours.colour2;
      case 'inlineLarge':
        return colours.colour2;
      case 'menuButton':
        return colours.colour6;
      case 'heroLink':
        return colours.colour10;
      case 'dashboardLink':
        return colours.colour4;
      case 'returnLink':
        return colours.colour8;
      case 'tableCell':
        return colours.colour40;
      case 'website':
        return colours.colour5;
      case 'websiteLg':
        return colours.colour5;
      case 'websiteSm':
        return colours.colour5;
      case 'websiteSpan':
        return colours.colour5;
      case 'footerIcon':
        return colours.colour2;
      case 'modelMenu':
        return colours.colour5;
      case 'modelMenuSecondary':
        return colours.colour5;
      case 'accordion':
        return colours.colour5;
      case 'formLink':
        return colours.colour35;
      case 'underlineThird':
        return colours.colour8;
      case 'plainSecondary':
        return colours.colour6;
      default:
        return colours.colour15;
    }
  }};
  }

  @media (min-width: 770px) {
      ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return fonts.font1.H14;
      case 'secondaryLg':
        return fonts.font1.H17;
      case 'tertiary':
        return fonts.font1.H13;
      case 'button':
        return fonts.font1.H14B;
      case 'buttonMd':
        return fonts.font1.H15;
      case 'buttonSm':
        return fonts.font1.H13B;
      case 'buttonSecondary':
        return fonts.font1.H14B;
      case 'buttonThird':
        return fonts.font1.H14B;
      case 'buttonFourth':
        return fonts.font1.H14B;
      case 'buttonfifth':
        return fonts.font1.H16B;
      case 'outline':
        return fonts.font1.H14B;
      case 'outlineMd':
        return fonts.font1.H15;
      case 'outlineSm':
        return fonts.font1.H13B;
      case 'defaultTextLarge':
        return fonts.font1.H15;
      case 'termsOfUse':
        return fonts.font1.H14;
      case 'heroLink':
        return fonts.font1.H14B;
      case 'dashboardLink':
        return fonts.font1.H14B;
      case 'footerLink':
        return fonts.font1.H12;
      case 'returnLink':
        return fonts.font1.H14B;
      case 'selectInput':
        return fonts.font1.H14;
      case 'checkboxDesc':
        return fonts.font1.H14;
      case 'inlineLarge':
        return fonts.font1.H14;
      case 'researcherProfile':
        return fonts.font1.H14;
      case 'website':
        return fonts.font1.H15B;
      case 'websiteLg':
        return fonts.font1.H18B;
      case 'websiteSm':
        return fonts.font1.H13;
      case 'websiteSpan':
        return fonts.font1.H15B;
      case 'modelMenu':
        return fonts.font1.H18;
      case 'modelMenuSecondary':
        return fonts.font1.H16;
      case 'accordion':
        return fonts.font1.H16;
      case 'underlineSecondary':
        return fonts.font1.H13B;
      case 'underlineThird':
        return fonts.font1.H16;
      case 'footerIcon':
        return fonts.font1.H15B;
      case 'error':
        return fonts.font1.H12B;
      case 'loginLink':
        return fonts.font1.H20B;
      case 'plain':
        return fonts.font1.H14;
      case 'plainSecondary':
        return fonts.font1.H16;
      default:
        return fonts.font1.H13;
    }
  }};

    div {
      padding: ${({ theme }) => (theme === 'buttonFourth' ? `${toRem(10)} ${toRem(20)}` : '')};
    };
  }
`;
