import React from 'react';
import { Figure } from '../../components/Figure';
import { LinkComponent } from '../../components/Link';
import { TextH1, TextP } from '../../components/Text';
import {
  ButtonWrapper,
  HeaderStyled, MainStyled, MessageContainerStyled, PageWrapper,
} from '../../styles/loginStyle';
import { fonts } from '../../styles/theme';
import { imageList } from '../../utils/imageList';

const EmailVerification = () => (
  <PageWrapper>
    <HeaderStyled>
      <Figure imageSrc={imageList.logoFull} alt="logo" maxWidth="150px" marginBottom="1.375rem" />
      <TextH1 font={fonts.font1.H31B}>Email Verification</TextH1>
    </HeaderStyled>
    <MainStyled>
      <MessageContainerStyled theme="message">
        <TextP>An email with a verification link has been sent to you.</TextP>
      </MessageContainerStyled>
      <LinkComponent url="/login?status=email-verification" theme="button">
        <div>
          <p>Back to Login</p>
        </div>
      </LinkComponent>
      <ButtonWrapper>

        <LinkComponent url="/forgot-password" theme="secondary">
          <p>Having trouble signing in?</p>
        </LinkComponent>
      </ButtonWrapper>
    </MainStyled>
  </PageWrapper>
);

export default EmailVerification;
