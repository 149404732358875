import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useResetPassword } from '../../hooks';
import { TextInput } from '../../components';
import Button from '../../components/Button';
import { Figure } from '../../components/Figure';
import { TextP } from '../../components/Text';
import {
  ButtonWrapper, HeaderStyled, MainStyled, PageWrapper, UserMessageStyled,
} from '../../styles/loginStyle';
import { imageList } from '../../utils/imageList';
import { LinkComponent } from '../../components/Link';

const ResetPassword = () => {
  const location: any = useLocation();

  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    errors,
    code,
    setCode,
    handleCodePassword,
    setEmail,
    linkErrors,
  } = useResetPassword();

  useEffect(() => {
    if (location.state.email) {
      setEmail(location.state.email);
    }
  }, [location]);

  return (
    <PageWrapper>
      <HeaderStyled>
        <LinkComponent url="login">
          <Figure imageSrc={imageList.logoFull} alt="logo" maxWidth="150px" marginBottom="1.375rem" />
        </LinkComponent>
      </HeaderStyled>
      <UserMessageStyled theme="warning">
        <TextP textAlgin="center">Do not close this page</TextP>
      </UserMessageStyled>
      <UserMessageStyled>
        <TextP textAlgin="center">
          A reset code has been sent to your email
        </TextP>
        <TextP textAlgin="center">
          Open your email in a separate tab (or browser window, or app) and then return here to enter the code
        </TextP>
        <TextP textAlgin="center">
          If you do not receive an email, please check your junk/spam folders.
        </TextP>
        <TextP textAlgin="center">Email us at {' '}
          <LinkComponent
            url="mailto: admin@reachbc.ca"
          >admin@reachbc.ca
          </LinkComponent>
          {' '} if you are still experiencing issues.
        </TextP>
      </UserMessageStyled>
      <MainStyled>
        <form onSubmit={handleCodePassword} autoComplete="off">
          <TextInput
            label="Password reset code"
            type="text"
            description="Enter reset code from email"
            textValue={code}
            setTextValue={setCode}
            theme="column"
            error={errors.code}
            linkError={linkErrors
              ? [
                { text: 'Invalid code provided, please', type: 'text' },
                { text: 'click here', type: 'link', url: '/forgot-password' },
                { text: 'to request the code again', type: 'text' },
              ]
              : undefined}
          />
          <TextInput
            label="New Password"
            type="password"
            description="Your password must be at least 8 characters, and include lowercase and uppercase alphabetical characters, a number and a special character."
            textValue={password}
            setTextValue={setPassword}
            theme="column"
            error={errors.password}
          />
          <TextInput
            label="Confirm Password"
            type="password"
            textValue={confirmPassword}
            setTextValue={setConfirmPassword}
            theme="column"
            error={errors.confirmPassword}
          />
          <ButtonWrapper theme="left">
            <Button onClick={() => ''} id="loginButton">
              <p>Set New Password</p>
            </Button>
          </ButtonWrapper>
        </form>
      </MainStyled>
    </PageWrapper>
  );
};

export default ResetPassword;
