import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from '../styles';
import { fonts } from '../../../styles/theme';
import Button from '../../Button';
import { TextP } from '../../Text';
import { ModalConst } from '../../../utils/modalConst';

type ModelDefaultPropTypes = {
  modalInfo: string

  rightButtonAction: () => void
  leftButtonAction: () => void
  setModalOpen: (modalOpen: boolean) => void
}

export const ModelDefault = ({
  leftButtonAction, modalInfo, rightButtonAction, setModalOpen,
}: ModelDefaultPropTypes) => (
  <Styled.ModalBodyStyled>
    <Styled.ModalHeaderStyled>
      <Button
        onClick={() => setModalOpen(false)}
        id="CloseModel"
        theme="plain"
      >
        <Styled.ModalButtonWrapper>
          <FontAwesomeIcon icon="xmark" size="2x" />
        </Styled.ModalButtonWrapper>
      </Button>
      <TextP font={fonts.font1.H24B}>{ModalConst[modalInfo]?.title}</TextP>
    </Styled.ModalHeaderStyled>
    <Styled.ModalMainStyled>
      {ModalConst[modalInfo]?.discription?.map((disc) => (
        <Styled.TextWrapper>
          <TextP key={disc} lineHeight="1.5rem">{disc}</TextP>
        </Styled.TextWrapper>
      ))}
    </Styled.ModalMainStyled>
    <Styled.FlexBox justify="flex-end">
      <Styled.ModalButtonWrapper>
        <Button
          onClick={() => {
            leftButtonAction();
          }}
          id="LeftButtonModel"
          theme="outlineSecondary"
        >
          <TextP>{ModalConst[modalInfo]?.buttonLeft}</TextP>
        </Button>
      </Styled.ModalButtonWrapper>
      <Button
        onClick={() => {
          rightButtonAction();
        }}
        id="RightButtonModal"
      >
        <TextP>{ModalConst[modalInfo]?.buttonRight}</TextP>
      </Button>
    </Styled.FlexBox>
  </Styled.ModalBodyStyled>
);
