import jwtDecode from 'jwt-decode';
import { useState, useEffect } from 'react';
import environment from '../config/environment';
import { userInitial } from '../utils/initialValues';
import { useAPI } from './useAPI';
import { useCookies } from './useCookies';
import { RESEARCHER_DASHBOARD, TABS } from '../pages/Dashboard/ResearcherDashboard/const';

export const useDashboard = () => {
  const { getCookie, deleteCookie } = useCookies();
  const [user, setUser] = useState<IUser>(userInitial);
  const { getProjects, getSiteVolunteers } = useAPI();

  const [tableDataAll, setTableDataAll] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [siteVolunteerData, setSiteVolunteerData] = useState([]);
  const [researcherTab, setResearcherTab] = useState(RESEARCHER_DASHBOARD.TABS[0]);

  const [searchValue, setSearchValue] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(true);

  const getTableData = async () => {
    const projects = await getProjects(user.type);
    if (!projects.error) {
      setTableData(projects);
      setTableDataAll(projects);
      setIsFetching(false);
    }
  };

  const getSiteVolunteerData = async () => {
    const siteVolunteers = await getSiteVolunteers();
    if (siteVolunteers.error) {
      setSiteVolunteerData([]);
    } else {
      setSiteVolunteerData(siteVolunteers);
    }
  };

  const setResearcherTabHandler = (tab: {label: string, value: string}) => {
    setResearcherTab(tab);
    if (siteVolunteerData.length === 0 && tab.value === TABS.SITE_VOLUNTEERS) {
      getSiteVolunteerData();
    }
  };

  const setSearchValueHandler = (value: string) => setSearchValue(value);

  const searchProjectHandler = () => {
    if (searchValue === '') {
      setTableData(tableDataAll);
      return;
    }
    let projects = [...tableDataAll];
    projects = projects.filter((data: { nick_name: string; }) => data.nick_name?.toLowerCase().includes(searchValue.toLowerCase()));

    setTableData(projects);
  };

  useEffect(() => {
    try {
      const userInfo = getCookie(environment.app.cookieName);
      const decodedUser: any = jwtDecode(userInfo);
      setUser(decodedUser);
    } catch (err) {
      deleteCookie(environment.app.cookieName);
    }
  }, []);

  useEffect(() => {
    if (!user.id || !user.type || user.type === 'volunteer') return;
    getTableData();
  }, [JSON.stringify(user)]);

  useEffect(() => {
    if (user.type === 'researcher') searchProjectHandler();
  }, [searchValue]);

  useEffect(() => {
    if (!isFetching) setIsLoading(false);
  }, [isFetching]);

  return {
    user,
    tableData,
    researcherTab,
    setResearcherTab: setResearcherTabHandler,
    siteVolunteerData,
    searchValue,
    setSearchValueHandler,
    isLoading,
  };
};
