import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fonts, colours } from '../../../styles/theme';
import Button from '../../Button';
import { TextP, TextH1 } from '../../Text';
import { PROJECT_FORM } from '../const';
import * as Styled from '../styles';

type HeroBannerPropTypes = {
  changesMade?: boolean
  projectPath?: string
  heroInfo?: {
    title?: string
    topSubtitle?: string
    bottomSubtitle?: string
    patientOriented?: string
    type?: string
  }
  generalInfo: {
    title: string,
    description: string[]
    downloads?: {
      boldText?: string
      startText?: string,
      endText: string,
      linkText: string,
      downloadUrl: string
    }[]
  }

  setModalType?: (modalType: string) => void
  setModalOpen?: (modalOpen: boolean) => void
  handleNavigate?: (path: string) => void
}

export const HeroBanner = ({
  changesMade, projectPath, heroInfo, generalInfo,
  handleNavigate, setModalOpen, setModalType,
}: HeroBannerPropTypes) => (
  <Styled.SectionStyled paddingSize="hero">
    <Styled.FlexBox>
      <Button
        id="backToDashboard"
        onClick={() => {
          if (changesMade && setModalType && setModalOpen) {
            setModalType('discard');
            setModalOpen(true);
          } else if (handleNavigate) {
            handleNavigate(PROJECT_FORM.LINKS.DASHBOARD.URL);
          }
        }}
        theme="menuButton"
      >
        <Styled.FlexBox>
          <Styled.IconWrapper>
            <FontAwesomeIcon icon="chevron-left" />
          </Styled.IconWrapper>
          <TextP font={fonts.font1.H14B}>
            {PROJECT_FORM.LINKS.DASHBOARD.LABEL}
          </TextP>
        </Styled.FlexBox>
      </Button>
      {projectPath
        && (
          <Button
            id="backToDashboard"
            onClick={() => {
              if (changesMade && setModalType && setModalOpen) {
                setModalType('discardShow');
                setModalOpen(true);
              } else if (handleNavigate) {
                handleNavigate(projectPath);
              }
            }}
            theme="menuButton"
          >
            <Styled.FlexBox>
              <Styled.IconWrapper>
                <FontAwesomeIcon icon="chevron-left" />
              </Styled.IconWrapper>
              <TextP font={fonts.font1.H14B}>
                {PROJECT_FORM.LINKS.PROJECT_DETAILS.LABEL}
              </TextP>
            </Styled.FlexBox>
          </Button>
        )}
    </Styled.FlexBox>
    {heroInfo ? (
      <Styled.SectionStyled>
        <Styled.TextWrapper marginType="subheader">
          <TextP color={colours.colour25} textAlgin="center" font={fonts.font1.H14}>
            {heroInfo.type === 'study' && 'Research Study'}
            {heroInfo.type === 'ppo' && 'Patient Partner Research Opportunity'}
            {heroInfo.patientOriented && ' ｜ Patient Oriented Research'}
          </TextP>
        </Styled.TextWrapper>
        <TextP font={fonts.font1.H32B} lineHeight="3rem" textAlgin="center">
          Now editing: <Styled.UnderlineStyled>{heroInfo.title}</Styled.UnderlineStyled>
        </TextP>
        <TextH1 font={fonts.font1.H20} lineHeight="2rem">
          {heroInfo.topSubtitle}
        </TextH1>
        <TextP font={fonts.font1.H20} color={colours.colour5} textAlgin="center">
          {heroInfo.bottomSubtitle}
        </TextP>
      </Styled.SectionStyled>
    ) : (
      <>
        <Styled.TitleWrapper>
          <TextH1>{generalInfo.title}</TextH1>
        </Styled.TitleWrapper>
        {generalInfo.description.map((paragh) => (
          <Styled.HeroTextWrapper key={paragh}>
            <TextP textAlgin="center" font={fonts.font1.H14}>
              {paragh}
            </TextP>
          </Styled.HeroTextWrapper>
        ))}
      </>
    )}
  </Styled.SectionStyled>
);
