import React from 'react';
import { DropdownSelect } from '../../../DropdownSelect';
import FormSection from '../../../FormSelection';
import { TextInput } from '../../../TextInput';
import { SectiontWrapper } from '../styles';
import { STUDY_DETAILS_FORM } from './const';

type StudyDetailsProps = {
  title: string
  layTitle: string
  nickName: string
  studyType: { value: string, label: string }
  canEdit: boolean
  errors: { [key: string]: string }
  handleChange: (value: string, field: string) => void
  TypeOfResearchStudy: { value: string, label: string }[]
  handleSingleValidation: (value: any, field: string) => void
}

const StudyDetails = ({
  title, layTitle, nickName, handleChange, studyType, TypeOfResearchStudy, errors, canEdit, handleSingleValidation,
}: StudyDetailsProps) => (
  <FormSection title={STUDY_DETAILS_FORM.TITLE}>

    <SectiontWrapper id="title">
      <TextInput
        label={STUDY_DETAILS_FORM.STUDY_TITLE.LABEL}
        description={STUDY_DETAILS_FORM.STUDY_TITLE.LABEL_DESCRIPTION}
        textValue={title}
        setTextValue={(value) => handleChange(value, 'title')}
        theme="form"
        isRequired
        onBlurAction={() => handleSingleValidation(title, 'title')}
        error={errors.title}
        disabled={!canEdit}
        maxLength={500}
      />
    </SectiontWrapper>
    <SectiontWrapper id="lay_title">
      <TextInput
        label={STUDY_DETAILS_FORM.LAY_TITLE.TITLE}
        description={STUDY_DETAILS_FORM.LAY_TITLE.DESCRIPTION}
        descriptionSecondary={STUDY_DETAILS_FORM.LAY_TITLE.DESCRIPTION_SECONDARY}
        textValue={layTitle}
        setTextValue={(value) => handleChange(value, 'lay_title')}
        onBlurAction={() => handleSingleValidation(layTitle, 'lay_title')}
        theme="form"
        isRequired
        error={errors.lay_title}
        disabled={!canEdit}
        maxLength={90}
      />
    </SectiontWrapper>
    <SectiontWrapper id="nick_name">
      <TextInput
        label={STUDY_DETAILS_FORM.NICK_NAME.LABEL}
        description={STUDY_DETAILS_FORM.NICK_NAME.DESCRIPTION}
        descriptionSecondary={STUDY_DETAILS_FORM.NICK_NAME.DESCRIPTION_SECONDARY}
        textValue={nickName}
        setTextValue={(value) => handleChange(value, 'nick_name')}
        onBlurAction={() => handleSingleValidation(nickName, 'nick_name')}
        theme="form"
        isRequired
        error={errors.nick_name}
        disabled={!canEdit}
        maxLength={90}
      />
    </SectiontWrapper>
    <SectiontWrapper id="study_type">
      <DropdownSelect
        id="type-of-research-study"
        label={STUDY_DETAILS_FORM.STUDY_TYPE.LABEL}
        description={STUDY_DETAILS_FORM.STUDY_TYPE.DESCRIPTION}
        onChange={(value) => handleChange(value, 'study_type')}
        selected={{ value: studyType.value, label: studyType.label }}
        options={TypeOfResearchStudy}
        placeholder={STUDY_DETAILS_FORM.STUDY_TYPE.PLACEHOLDER}
        error={errors.study_type}
        isDisabled={!canEdit}
        theme="form"
        required
      />

    </SectiontWrapper>
  </FormSection>
);

export default StudyDetails;
