import React, { ReactNode } from 'react';
import MainFooter from '../MainFooter';
import LoginHeader from '../LoginHeader';
import { LoginWrapperStyle, BodyWrapper } from './styles';

type LoginWrapperProps = {
  children: ReactNode,
  footer?: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}
export const LoginWrapper = ({
  children, footer, isOpen, setIsOpen,
}: LoginWrapperProps) => (
  <LoginWrapperStyle>
    <LoginHeader isOpen={isOpen} setIsOpen={setIsOpen} />
    <BodyWrapper>
      {children}
    </BodyWrapper>
    {footer && <MainFooter />}
  </LoginWrapperStyle>
);
