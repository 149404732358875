import React from 'react';
import * as Styled from './styles';
import { LinkComponent } from '../../../../components/Link';
import { UNAVAILABLE } from './const';

type ProjectUnacilablePropTypes = {
  readonly interested?: boolean
}

const ProjectUnavailable = ({ interested }: ProjectUnacilablePropTypes) => (
  <Styled.BodyWrapperStyled>
    <p>{interested ? UNAVAILABLE.INTERESTED : UNAVAILABLE.GENERAL}</p>
    <LinkComponent url={UNAVAILABLE.LINK.URL} theme="dashboardLink">
      <Styled.LinkButtonWrapper>
        {UNAVAILABLE.LINK.PATH}
      </Styled.LinkButtonWrapper>
    </LinkComponent>
  </Styled.BodyWrapperStyled>
);

export default ProjectUnavailable;
