import React from 'react';
import {
  errorFieldNames, formStatus, formStatusIcons, projectStatus, resultFormStatus,
} from '../../../utils/researcherConst';
import { fonts, colours } from '../../../styles/theme';
import Button from '../../Button';
import FormStatusPicker from '../../FormStatusPicker';
import FormSubtitle from '../../FormSubtitle';
import { LinkComponent } from '../../Link';
import StatusTag from '../../StatusTag';
import { TextP } from '../../Text';
import { PROJECT_FORM } from '../const';
import { formateDate } from '../../../utils/formateDate';
import * as Styled from '../styles';
import { ErrorMessage } from '../../ErrorMessage';

type FormSideMenuPropTypes = {
  status?: string
  isResults?: boolean
  desiredStatus?: boolean
  stepBackStatus?: boolean
  selectedStatus?: string
  statusModal?: boolean
  isDraft?: boolean
  isSubmiting: boolean
  isEdit?: boolean
  changesMade?: boolean
  recruitmentEndDate?: Date
  calenderToggle: boolean
  canEdit?: boolean

  handleChange?: (value: string, field: string) => void
  setStatusModal?: (statusModal: boolean) => void
  handleDraft?: () => void
  handleSubmit: () => void
  setModalType?: (modalType: string) => void
  setModalOpen?: (modalOpen: boolean) => void
  setCalenderToggle?: (calenderToggle: boolean) => void
  errors: { [key: string]: string }
}

export const FormSideMenu = ({
  status, isResults, desiredStatus, stepBackStatus, errors, selectedStatus, statusModal, canEdit,
  isDraft, isSubmiting, changesMade, isEdit, recruitmentEndDate, calenderToggle,
  handleChange, setStatusModal, handleDraft, handleSubmit, setModalType, setModalOpen,
  setCalenderToggle,
}: FormSideMenuPropTypes) => (
  <>
    {status && (
      <Styled.StatusTagWrapper noBottomMargin={isResults}>
        <StatusTag
          label={isResults ? resultFormStatus[status] : formStatus[status]}
          theme={status}
          icon={formStatusIcons[status]}
          isRound
          useIcon={formStatusIcons[status]}
        />
      </Styled.StatusTagWrapper>
    )}

    {!isResults && (
      <Styled.DecktopWrapper>
        <Styled.SectiontWrapper id="recruitment_end">
          <FormSubtitle
            subtitle={PROJECT_FORM.RECRUITMENT_DATE.TITLE}
            isPublic={false}
            isRequired
            margin="small"
          />
          <Styled.TextWrapper marginType="description">
            <TextP>{PROJECT_FORM.RECRUITMENT_DATE.DESCRIPTION}</TextP>
          </Styled.TextWrapper>
          <Button
            id="calenderToggle"
            theme="outlineSecondary"
            onClick={() => { if (setCalenderToggle) setCalenderToggle(!calenderToggle); }}
            buttonWidth="100%"
            disabled={!canEdit}
          >
            {recruitmentEndDate ? (
              <TextP>{formateDate(recruitmentEndDate)}</TextP>
            ) : (
              <TextP>
                {PROJECT_FORM.BUTTONS.RECRUITMENT_PLACEHOLDER}
              </TextP>
            )}
          </Button>
          {errors.recruitment_end && <ErrorMessage message={errors.recruitment_end} />}
        </Styled.SectiontWrapper>

        {desiredStatus && handleChange && !stepBackStatus && (
          <FormStatusPicker
            handleChange={handleChange}
            selectedStatus={selectedStatus}
            error={errors.desired_status}
          />
        )}
      </Styled.DecktopWrapper>
    )}

    {setStatusModal && (
      <Styled.MobileWrapper>
        {desiredStatus && selectedStatus && (
          <Styled.DesiredStatusStyled>
            <TextP>
              <span>{PROJECT_FORM.PROJECT_STATUS.LABEL}</span>
              {projectStatus[selectedStatus]}
            </TextP>
          </Styled.DesiredStatusStyled>
        )}
        <Button
          id="status-mobile"
          theme="underline"
          onClick={() => setStatusModal(!statusModal)}
        >
          <Styled.TextWrapper>
            {PROJECT_FORM.BUTTONS.PROJECT_STATUS}
          </Styled.TextWrapper>
        </Button>
        <Styled.StatusDesStyled isError={!!errors.desired_status}>
          {!selectedStatus && (
            <TextP font={fonts.font1.H14}>{PROJECT_FORM.PROJECT_STATUS.DESCRIPTION} </TextP>
          )}
        </Styled.StatusDesStyled>
      </Styled.MobileWrapper>
    )}

    {desiredStatus && handleChange && stepBackStatus && (
      <div>
        <Styled.TextWrapper>
          <TextP font={fonts.font1.H14B} lineHeight="1.25">
            {PROJECT_FORM.RESEARCH_RECEIVED.TITLE}
          </TextP>
        </Styled.TextWrapper>
        <TextP font={fonts.font1.H14} lineHeight="1.33">
          {PROJECT_FORM.RESEARCH_RECEIVED.DESCRIPTION}
        </TextP>
      </div>
    )}
    <Styled.SideButtonWrapper>
      {handleDraft && isDraft && (
        <Styled.ButtonWrapper isTopButton>
          <Button
            id="submit-draft"
            theme="outline"
            onClick={handleDraft}
            disabled={isSubmiting}
            buttonWidth="100%"
          >
            {PROJECT_FORM.BUTTONS.SAVE_DRAFT}
          </Button>
        </Styled.ButtonWrapper>
      )}
      <Styled.ButtonWrapper rightButton>
        <Button
          id="submit-study"
          onClick={handleSubmit}
          disabled={isSubmiting}
          theme="primary"
          buttonWidth="100%"
        >
          {PROJECT_FORM.BUTTONS.SUBMIT_REVIEW}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.SideButtonWrapper>

    <Styled.DecktopWrapper>
      {isEdit && changesMade && (
        <Button
          id="discardChanges"
          onClick={() => {
            if (setModalType && setModalOpen) {
              setModalType('discardShow');
              setModalOpen(true);
            }
          }}
          theme="underline"
        >
          {PROJECT_FORM.BUTTONS.DISCARD}
        </Button>
      )}
    </Styled.DecktopWrapper>

    <Styled.DecktopWrapper>
      {Object.keys(errors).length > 0
        && (
          <Styled.ErrorWrapper>
            <Styled.ErrorStyled>
              <TextP color={colours.colour7} textAlgin="center">
                {PROJECT_FORM.ERROR_MESSAGES.DESCRIPTION_START}
                {Object.keys(errors).length}
                {PROJECT_FORM.ERROR_MESSAGES.DESCRIPTION_FINISH}
              </TextP>
            </Styled.ErrorStyled>
            {Object.entries(errors).map((error) => (
              <Styled.ErrorStyled key={error[0]}>
                <LinkComponent url={`#${error[0]}`}>
                  {errorFieldNames[error[0]] ? errorFieldNames[error[0]] : error[0]}
                </LinkComponent>
                <TextP color={colours.colour7}>{error[1]}</TextP>
              </Styled.ErrorStyled>
            ))}
          </Styled.ErrorWrapper>
        )}
    </Styled.DecktopWrapper>
  </>
);
