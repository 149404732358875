import React from 'react';
import {
  CheckboxStyled, InputStyled, LabelStyled, CheckboxWrapper,
} from './styles';

type CheckBoxProps = {
  label: string;
  isChecked: boolean
  onChange: (check: boolean) => void
  theme?: string
  labelTheme?: 'secondary'
  subLabel?: string
  disabled?: boolean
  textTransform?: string
  id?: string
};

export const Checkbox = ({
  label, isChecked, onChange, theme, subLabel, disabled, textTransform = 'capitalize', id,
  labelTheme,
}: CheckBoxProps) => (
  <CheckboxWrapper isChecked={isChecked}>
    <InputStyled
      id={id || `id-${label}`}
      name={id ? '' : label}
      type="checkbox"
      checked={isChecked}
      onChange={({ target: { checked } }) => {
        onChange(checked);
      }}
      theme={theme}
      disabled={disabled}
    />
    <CheckboxStyled
      isChecked={isChecked}
      onClick={disabled ? () => '' : () => { onChange(!isChecked); }}
      theme={theme}
    >
      <span />
    </CheckboxStyled>
    <LabelStyled htmlFor={id || `id-${label}`} textTransform={textTransform} theme={labelTheme}>
      {label}
      {subLabel && <span>: {subLabel}</span>}
    </LabelStyled>
  </CheckboxWrapper>
);
