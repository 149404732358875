import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
import { TextH4, TextP } from '../Text';
import { DIRECT_LINK_INTEREST_MODAL } from './const';
import * as Styled from './styles';

type IProps = {
  action: () => void
  cancel: () => void
  redirect: () => void
}

export const DirectLinkInterestModal = ({
  action, cancel, redirect,
}: IProps) => (
  <Styled.ModalStyled>
    <Styled.HeaderWapper>
      <Button
        id="model-cancel"
        theme="modelMenuPlain"
        onClick={cancel}
      >
        <FontAwesomeIcon icon="xmark" />
      </Button>
    </Styled.HeaderWapper>
    <Styled.BodyWrapper>
      <TextH4>{DIRECT_LINK_INTEREST_MODAL.TITLE}</TextH4>
      <TextP>
        {DIRECT_LINK_INTEREST_MODAL.DESCRIPTION}
      </TextP>
      <TextP>
        {DIRECT_LINK_INTEREST_MODAL.DESCRIPTION_TWO}
      </TextP>
    </Styled.BodyWrapper>

    <Styled.FooterWrapper>
      <Styled.ButtonWrapper>
        <Button
          id="model-confirm"
          onClick={action}
        >
          {DIRECT_LINK_INTEREST_MODAL.BUTTONS.CONFIRM}
        </Button>
      </Styled.ButtonWrapper>
      <Button
        id="model-cancel"
        theme="outlineSecondary"
        onClick={redirect}
      >
        {DIRECT_LINK_INTEREST_MODAL.BUTTONS.CANCEL}
      </Button>
    </Styled.FooterWrapper>
  </Styled.ModalStyled>
);
