import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useProjectDetails } from './useProjectDetails';

export const useStudyDetails = () => {
  const location = useLocation();
  const {
    user,
    params,
    project,
    setProject,
    getProjectInfo,
    isLoading,
    currentTab,
    setCurrentTab,
    researcherTabOptions,
    principalInvestigator,
    interested,
    getInterestedVolunteers,
    handleFollowUpUpdate,
    filterVolunteerHandler,
    result,
    getProjectResult,
    hideButtonStatus,
    enrollentStatusFilter,
    interestSearchValue,
    setInterestSearchValue,
    selectedInterests,
    setSelectedInterestsHandler,
    updateProjectInterestsHandler,
  } = useProjectDetails();

  const [toastIsOpen, setToastIsOpen] = useState(false);

  useEffect(() => {
    if (location.search && location.search.includes('results') && params.id) {
      setCurrentTab('Results');
      getProjectResult(parseInt(params.id, 10), 'study');
      getProjectInfo(parseInt(params.id, 10), 'study');
    } else if (params.id) {
      getProjectInfo(parseInt(params.id, 10), 'study');
    }
  }, [params]);

  return {
    user,
    project,
    setProject,
    isLoading,
    currentTab,
    setCurrentTab,
    researcherTabOptions,
    principalInvestigator,
    interested,
    getInterestedVolunteers,
    handleFollowUpUpdate,
    filterVolunteerHandler,
    result,
    getProjectResult,
    toastIsOpen,
    setToastIsOpen,
    hideButtonStatus,
    enrollentStatusFilter,
    interestSearchValue,
    setInterestSearchValue,
    selectedInterests,
    setSelectedInterestsHandler,
    updateProjectInterestsHandler,
  };
};
