import React from 'react';
import { EmptyProjectState } from './EmptyProjectState';
import { ProjectCard } from './ProjectCard';
import { Loader } from '../../Loader';
import * as Styled from './styles';
import { TableFooterWrapper } from '../styles';
import { Footer } from '../../Table/components/footer';

type ProjectDashboardPropTypes = {
  emptyState: {
    title: string,
    description: string
    path?: string
    url?: string
    list?: string[]
  }
  projects: Record<string, any[]>
  isLoading: boolean
  currentTab: { label: string, description?: string, value: string }
  updateInterest: (id: number, type: string, projectName: string) => void
  pageNumber: { value: string, label: string },
  setPageNumber: (pageNumber: any) => void,
  totalPages: number,
  rowCount: number,
  handleRowCount: (rowCount: any) => void,
  rowOption: number[],
}

export const ProjectDashboard = ({
  emptyState, projects, isLoading, currentTab, updateInterest,
  pageNumber, rowCount, rowOption, setPageNumber, handleRowCount, totalPages,
}: ProjectDashboardPropTypes) => {
  if (isLoading) {
    return (<Loader />);
  }
  return (
    <div>
      {projects[currentTab.value.toLowerCase()].length < 1 ? (
        <EmptyProjectState message={emptyState} />
      ) : (
        <>
          {projects[currentTab.value.toLowerCase()].map((project) => (
            <Styled.CardWrapper key={project.id}>
              <ProjectCard
                project={project}
                type={currentTab.value}
                updateInterest={updateInterest}
              />
            </Styled.CardWrapper>
          ))}

          <TableFooterWrapper>
            <Footer
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalPages={totalPages}
              rowCount={rowCount}
              setRowCount={handleRowCount}
              rowOption={rowOption}
            />
          </TableFooterWrapper>
        </>
      )}
    </div>
  );
};
