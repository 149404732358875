import React from 'react';
import { DropdownSelect } from '../../../DropdownSelect';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { TextInput } from '../../../TextInput';
import * as Styled from '../styles';
import { STUDY_CONTACT } from './const';

type ContactFormProps = {
  handleChange: (value: string, field: string) => void
  contactName: string
  contactPosition: string
  contactEmail: string
  contactPhone?: string
  contactExtension: string
  contactPositionOptions: { value: string, label: string }[]
  canEdit?: boolean
  contact2Name: string
  contact2Position: string
  contact2Email: string
  contact2Phone: string
  contact2Extension: string
  errors: { [key: string]: string }
  handleSingleValidation: (value: any, field: string) => void

}

const ContactForm = ({
  contactName, contactPosition, contactPositionOptions, handleChange, contactEmail, contactExtension, contactPhone, contact2Email, contact2Extension, contact2Name, contact2Phone, contact2Position, errors, handleSingleValidation,
  canEdit,
}: ContactFormProps) => (
  <FormSection title={STUDY_CONTACT.TITLE} subText={STUDY_CONTACT.DESCRIPTION}>
    <FormSubtitle subtitle={STUDY_CONTACT.CONTACT_ONE_LABEL} isRequired />

    <Styled.SectiontWrapper id="contact1_name">
      <TextInput
        label={STUDY_CONTACT.NAME.LABEL}
        textValue={contactName}
        description={STUDY_CONTACT.NAME.DESCRIPTION}
        setTextValue={(value) => handleChange(value, 'contact1_name')}
        disabled={!canEdit}
        theme="form"
        error={errors.contact1_name}
        onBlurAction={() => handleSingleValidation(contactName, 'contact1_name')}
        maxLength={250}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="contact1_position">
      <DropdownSelect
        label={STUDY_CONTACT.POSITION.LABEL}
        selected={{ value: contactPosition, label: contactPosition }}
        onChange={(value) => handleChange(value, 'contact1_position')}
        options={contactPositionOptions}
        placeholder={STUDY_CONTACT.POSITION.PLACEHOLDER}
        isDisabled={!canEdit}
        error={errors.contact1_position}
        theme="form"
        required
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="contact1_email">
      <Styled.FlexWrapper alignItems="flex-end">
        <TextInput
          label={STUDY_CONTACT.EMAIL.LABEL}
          textValue={contactEmail}
          setTextValue={(value) => handleChange(value, 'contact1_email')}
          inputWidth="75%"
          theme="form"
          placeholder={STUDY_CONTACT.EMAIL.PLACEHOLDER}
          disabled={!canEdit}
          error={errors.contact1_email}
          onBlurAction={() => handleSingleValidation(contactEmail, 'contact1_email')}
        />
        <Styled.InputWrapper id="contact1_phone">
          <TextInput
            label={STUDY_CONTACT.PHONE.LABEL}
            textValue={contactPhone}
            setTextValue={(value) => handleChange(value, 'contact1_phone')}
            error={errors.contact1_phone}
            placeholder={STUDY_CONTACT.PHONE.PLACEHOLDER}
            theme="form"
            disabled={!canEdit}
            isRequired={false}
            onBlurAction={() => handleSingleValidation(contactPhone, 'contact1_phone')}
          />
        </Styled.InputWrapper>

        <TextInput
          id="contact1_phone_extension"
          label={STUDY_CONTACT.EXTENSION.LABEL}
          textValue={contactExtension}
          setTextValue={(value) => handleChange(value, 'contact1_phone_extension')}
          inputWidth="25%"
          isRequired={false}
          disabled={!canEdit}
          theme="form"
          error={errors.contact1_phone_extension}
          onBlurAction={
            () => handleSingleValidation(
              contactExtension === '' ? null : contactExtension,
              'contact1_phone_extension',
            )
          }
        />
      </Styled.FlexWrapper>
    </Styled.SectiontWrapper>

    <FormSubtitle subtitle={STUDY_CONTACT.CONTACT_TWO_LABEL} />

    <Styled.SectiontWrapper id="contact2_name">
      <TextInput
        label={STUDY_CONTACT.NAME.LABEL}
        description={STUDY_CONTACT.NAME.DESCRIPTION}
        textValue={contact2Name}
        setTextValue={(value) => handleChange(value, 'contact2_name')}
        isRequired={false}
        disabled={!canEdit}
        theme="form"
        error={errors.contact2_name}
        onBlurAction={() => { handleSingleValidation(contact2Name === '' ? null : contact2Name, 'contact2_name'); }}
        maxLength={250}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="contact2_position">
      <DropdownSelect
        id="contact-Position2"
        label={STUDY_CONTACT.POSITION.LABEL}
        onChange={(value) => handleChange(value, 'contact2_position')}
        selected={{ value: contact2Position, label: contact2Position }}
        options={contactPositionOptions}
        placeholder={STUDY_CONTACT.POSITION.PLACEHOLDER}
        required={false}
        isDisabled={!canEdit}
        error={errors.contact2_position}
        theme="form"
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="contact2_email" isLast>
      <Styled.FlexWrapper alignItems="flex-end">
        <TextInput
          label={STUDY_CONTACT.EMAIL.LABEL}
          textValue={contact2Email}
          setTextValue={(value) => handleChange(value, 'contact2_email')}
          inputWidth="75%"
          isRequired={false}
          disabled={!canEdit}
          placeholder={STUDY_CONTACT.EMAIL.PLACEHOLDER}
          theme="form"
          error={errors.contact2_email}
          onBlurAction={() => {
            handleSingleValidation(contact2Email === '' ? null : contact2Email, 'contact2_email');
          }}
        />
        <Styled.InputWrapper id="contact2_phone">
          <TextInput
            label={STUDY_CONTACT.PHONE.LABEL}
            textValue={contact2Phone}
            setTextValue={(value) => handleChange(value, 'contact2_phone')}
            placeholder={STUDY_CONTACT.PHONE.PLACEHOLDER}
            isRequired={false}
            disabled={!canEdit}
            theme="form"
            error={errors.contact2_phone}
            onBlurAction={() => {
              handleSingleValidation(contact2Phone === '' ? null : contact2Phone, 'contact2_phone');
            }}
          />
        </Styled.InputWrapper>

        <TextInput
          id="contact2_phone_ectension"
          label={STUDY_CONTACT.EXTENSION.LABEL}
          textValue={contact2Extension}
          setTextValue={(value) => handleChange(value, 'contact2_phone_extension')}
          inputWidth="25%"
          isRequired={false}
          disabled={!canEdit}
          theme="form"
          error={errors.contact2_phone_extension}
          onBlurAction={() => { handleSingleValidation(contact2Extension === '' ? null : contact2Extension, 'contact2_phone_extension'); }}
        />
      </Styled.FlexWrapper>
    </Styled.SectiontWrapper>
  </FormSection>
);

export default ContactForm;
