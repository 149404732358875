import React from 'react';
import { ErrorMessage } from '../ErrorMessage';
import PublicToolTip from '../PublicTooltip';
import { TextP } from '../Text';
import * as Styled from './styles';
import { RequiredForm } from '../RequiredForm';
import { NotVisible } from '../NotVisible';

type TextAreaProps = {
  label?: string;
  placeholder?: string;
  description?: string;
  description2?: string[]
  descriptionList?: string[]
  isRequired?: boolean;
  textValue: string,
  setTextValue: (textValue: string) => void
  theme?: 'form'
  error?: string
  isPublic?: boolean
  isNotPublic?: boolean
  isDisabled?: boolean
  id?: string
  onBlurAction?: () => void
  maxLength?: number
  listIsSecond?: boolean
}
export const TextArea = ({
  label, placeholder, description, description2, isRequired = true, textValue, setTextValue, error, isPublic = false, isDisabled, id, onBlurAction, maxLength, theme, descriptionList, listIsSecond, isNotPublic = false,
}: TextAreaProps) => (
  <Styled.TextAreaWrapper id={id}>
    {label && (
      <Styled.LabelWrapper>
        <Styled.LabelStyled htmlFor={`id-${label}`} theme={theme}>
          <Styled.TextWrapper>
            {label}
          </Styled.TextWrapper>
          {isPublic && <PublicToolTip />}
          {isRequired
            && <RequiredForm />}
        </Styled.LabelStyled>
        {isNotPublic && <NotVisible />}
      </Styled.LabelWrapper>
    )}
    {descriptionList && !listIsSecond && (
      <Styled.DescriptionList>
        {descriptionList.map((list) => (
          <li key={list.slice(0, 5)}>{list}</li>
        ))}
      </Styled.DescriptionList>
    )}
    <Styled.DescriptionWrapper>
      <TextP>{description}</TextP>
    </Styled.DescriptionWrapper>

    {descriptionList && listIsSecond && (
      <Styled.DescriptionList>
        {descriptionList.map((list) => (
          <li key={list.slice(0, 5)}>{list}</li>
        ))}
      </Styled.DescriptionList>
    )}
    {description2 && (
      <Styled.DescriptionWrapper>
        {
          description2.map((desc) => (
            <TextP key={desc}>{desc}</TextP>
          ))
        }
      </Styled.DescriptionWrapper>
    )}
    <Styled.InputWrapper>
      <Styled.TextAreaStyled
        id={`id-${label}`}
        placeholder={placeholder}
        autoComplete="off"
        value={textValue}
        onChange={(event) => {
          setTextValue(event.target.value);
        }}
        disabled={isDisabled}
        onBlur={() => {
          if (onBlurAction) {
            onBlurAction();
          }
        }}
        maxLength={maxLength}
      />
    </Styled.InputWrapper>
    <Styled.LengthCounterStyled hasError={!!error}>
      {error && <ErrorMessage message={error} />}
      {maxLength && (
        <Styled.CounterTextStyled>
          Character count: <span>{textValue?.length}/{maxLength}</span>
        </Styled.CounterTextStyled>
      )}
    </Styled.LengthCounterStyled>
  </Styled.TextAreaWrapper>
);
