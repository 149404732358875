import styled from 'styled-components';
import { colours, fonts } from '../../../../styles/theme';

type ButtonStyledProps = {
  readonly theme: string
  readonly option?: string
  readonly noBorderBottom?: boolean
}

export const SideMenuStyled = styled.div`
  width: 14.563rem;
  border-right: 0.063rem solid ${colours.colour9};
`;

export const ButtonStyled = styled.button<ButtonStyledProps>`
  cursor: pointer;
  ${fonts.font1.H14};
  width: 14.5rem;
  background: ${colours.colour4};
  text-align: left;
  padding: ${({ noBorderBottom }) => (noBorderBottom ? '1rem 0.625rem 0' : '1rem 0.625rem')}; ;
  justify-content: space-between;
  box-shadow: none;
  font-weight: ${({ theme, noBorderBottom }) => (theme === 'active' || noBorderBottom ? 700 : 400)};
  border: none;
  border-right: 5px solid ${({ theme }) => (theme === 'active' ? colours.colour2 : colours.colour4)}; 
  border-bottom: ${({ noBorderBottom }) => (noBorderBottom ? 'none' : `1px solid ${colours.colour9}`)};
`;

export const SubButtonStyled = styled.button<ButtonStyledProps>`
  cursor: pointer;
  width: 100%;
  background: ${colours.colour4};
  text-align: left;
  padding: 0.313rem 1.625rem;
  border: none;
  box-shadow: none;
  ${fonts.font1.H14};
  font-weight: ${({ theme }) => (theme === 'active' ? 700 : 400)};
  border-right: 0.313rem solid ${({ theme }) => (theme === 'active' ? colours.colour2 : colours.colour4)}; 
`;

export const SubButtonContainer = styled.div`
  padding: 0.625rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  border-bottom:  1px solid ${colours.colour9};
`;
