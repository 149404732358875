import React from 'react';
import { TextInput } from '../../../../components';
import { ButtonStyled } from '../../../../components/Button/styles';
import { TextH1, TextP } from '../../../../components/Text';
import { fonts } from '../../../../styles/theme';
import {
  ButtonWrapper, DefaultWrapper, ErrorMessageStyled, ResearcherPasswordResetWrapper, SectionStyled,
} from '../../../../styles/MyProfileStyles';

type PasswordResetProps = {
  userType: string
  password: string
  setPassword: (password: string) => void
  oldPassword: string
  setOldPassword: (password: string) => void
  confirmPassword: string
  setConfirmPassword: (password: string) => void
  handleChangePassword: () => void
  errors: { [key: string]: string }
  handleSingleValidation?: (value: any, field?: string) => void
}

const PasswordReset = ({
  password, confirmPassword, userType, errors, oldPassword,
  setConfirmPassword, setPassword, handleChangePassword, setOldPassword, handleSingleValidation = () => '',
}: PasswordResetProps) => (
  <>
    {userType === 'volunteer' && (
      <DefaultWrapper>
        <SectionStyled>
          <TextH1 font={fonts.font1.H26} textAlgin="left">Password Reset</TextH1>
        </SectionStyled>
        {errors.fetch && (
          <ErrorMessageStyled>
            <TextP>{errors.fetch}</TextP>
          </ErrorMessageStyled>
        )}
        <TextInput
          label="Old Password"
          type="password"
          textValue={oldPassword}
          setTextValue={setOldPassword}
          theme="columnLeft"
          error={errors.oldPassword}
          onBlurAction={() => handleSingleValidation({ oldPassword, password, confirmPassword })}
        />
        <TextInput
          label="New Password"
          type="password"
          description="Your password must be at least 8 characters, and include lowercase and uppercase alphabetical characters, a number and a special character."
          textValue={password}
          setTextValue={setPassword}
          theme="columnLeft"
          error={errors.password}
          onBlurAction={() => handleSingleValidation({ oldPassword, password, confirmPassword })}
        />
        <TextInput
          label="Confirm Password"
          type="password"
          textValue={confirmPassword}
          setTextValue={setConfirmPassword}
          theme="columnLeft"
          error={errors.confirmPassword}
          onBlurAction={() => handleSingleValidation({ oldPassword, password, confirmPassword })}
        />
        <ButtonWrapper>
          <ButtonStyled
            onClick={handleChangePassword}
            theme="myProfileButton"
          >
            Reset Password
          </ButtonStyled>
        </ButtonWrapper>
      </DefaultWrapper>
    )}
    {userType === 'researcher' && (
      <ResearcherPasswordResetWrapper>
        <TextInput
          label="Old Password"
          type="password"
          textValue={oldPassword}
          setTextValue={setOldPassword}
          theme="columnLeft"
          error={errors.oldPassword}
        />
        <TextInput
          label="New Password"
          type="password"
          description="Your password must be at least 8 characters, and include lowercase and uppercase alphabetical characters, a number and a special character."
          textValue={password}
          setTextValue={setPassword}
          theme="columnLeft"
          error={errors.password}
        />
        <TextInput
          label="Confirm Password"
          type="password"
          textValue={confirmPassword}
          setTextValue={setConfirmPassword}
          theme="columnLeft"
          error={errors.confirmPassword}
        />
        <ButtonWrapper>
          <ButtonStyled
            onClick={handleChangePassword}
            theme="myProfileButton"
          >
            Reset Password
          </ButtonStyled>
        </ButtonWrapper>
      </ResearcherPasswordResetWrapper>
    )}
  </>
);

export default PasswordReset;
