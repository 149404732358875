import React from 'react';
import { TextInput } from '../../../../components';
import Button from '../../../../components/Button';
import { SelectInput } from '../../../../components/SelectInput';
import { TextH2, TextP } from '../../../../components/Text';
import { Toggle } from '../../../../components/Toggle';
import { LinkComponent } from '../../../../components/Link';
import { researcherPosition } from '../../../../utils/researcherConst';
import { fonts } from '../../../../styles/theme';
import { ButtonWrapper } from '../../../../styles/loginStyle';
import { MobilePhoneWrapper, ResearcherWrapper, SectionStyled } from '../../../../styles/MyProfileStyles';
import {
  FormUserMessageWrapper, FlexboxWrapper, InputWrapper,
} from './styles';
import { PopUpErrorStyled } from '../VolunteerMyProfile/styles';
import { ErrorMessage } from '../../../../components/ErrorMessage';
import environment from '../../../../config/environment';
import { RESEARCHER_PROFILE_INFO } from '../ResearcherProfile/const';

type ResearcherProfileInfoFormProps = {
  researcherInfo: IResearcherUser
  errors: any
  authorities: { name: string, id: number }[]
  universities: { name: string, id: number }[]
  handleChange: (value: string | number | boolean, field: string) => void
  handleValidation: (formValues: IResearcherUser) => void
  handleSingleValidation: (value: any, field: string, type?: string) => void
}

const ResearcherProfileInfoForm = ({
  researcherInfo, errors, handleValidation, handleChange, handleSingleValidation,
  authorities, universities,
}: ResearcherProfileInfoFormProps) => (
  <>
    <ResearcherWrapper theme="main">
      <SectionStyled>
        <TextH2 font={fonts.font1.H26}>{RESEARCHER_PROFILE_INFO.TITLE}</TextH2>
      </SectionStyled>
      <SectionStyled theme="M">
        <TextP font={fonts.font1.H15} textAlgin="center">{RESEARCHER_PROFILE_INFO.DESCRIPTION}</TextP>
      </SectionStyled>
    </ResearcherWrapper>
    <ResearcherWrapper theme="secondary">
      <FlexboxWrapper>
        <InputWrapper>
          <TextInput
            label={RESEARCHER_PROFILE_INFO.FIRST_NAME.LABEL}
            textValue={researcherInfo.first_name}
            setTextValue={(value) => { handleChange(value, 'first_name'); }}
            error={errors.first_name}
            type="text"
            onBlurAction={() => handleSingleValidation(researcherInfo.first_name, 'first_name')}
          />
        </InputWrapper>
        <TextInput
          label={RESEARCHER_PROFILE_INFO.LAST_NAME.LABEL}
          textValue={researcherInfo.last_name}
          setTextValue={(value) => { handleChange(value, 'last_name'); }}
          error={errors.last_name}
          type="text"
          onBlurAction={() => handleSingleValidation(researcherInfo.last_name, 'last_name')}
        />
      </FlexboxWrapper>
      <FlexboxWrapper>
        <InputWrapper>
          <TextInput
            label={RESEARCHER_PROFILE_INFO.PHONE.LABEL}
            description={RESEARCHER_PROFILE_INFO.PHONE.LABEL}
            textValue={researcherInfo.phone_number}
            setTextValue={(value) => { handleChange(value, 'phone_number'); }}
            type="text"
            onBlurAction={() => handleSingleValidation(researcherInfo.phone_number, 'phone_number')}
            inputWidth="100%"
          />
          <PopUpErrorStyled width="75%">
            {errors.phone_number && <ErrorMessage message={errors.phone_number} />}
          </PopUpErrorStyled>
        </InputWrapper>
        <InputWrapper width="23%" noMarginRight>
          <TextInput
            label={RESEARCHER_PROFILE_INFO.EXTENSION.LABEL}
            textValue={researcherInfo.extension ? String(researcherInfo.extension) : ''}
            setTextValue={(value) => { handleChange(value, 'extension'); }}
            type="text"
            isRequired={false}
            onBlurAction={() => handleSingleValidation(researcherInfo.extension, 'extension')}
          />
          <PopUpErrorStyled width="23%">
            {errors.extension && <ErrorMessage message={errors.extension} />}
          </PopUpErrorStyled>
        </InputWrapper>
      </FlexboxWrapper>

      <MobilePhoneWrapper>
        <SectionStyled>
          <TextInput
            label={RESEARCHER_PROFILE_INFO.MFA.LABEL}
            description={RESEARCHER_PROFILE_INFO.MFA.DESCRIPTION}
            setTextValue={() => null}
            textValue={`${researcherInfo.mobile_phone_number}`}
            disabled
          />
        </SectionStyled>
        <FormUserMessageWrapper>
          <TextP>{RESEARCHER_PROFILE_INFO.MFA.EDIT_MFA.LABEL}</TextP>
          <LinkComponent url={`MAILTO:${RESEARCHER_PROFILE_INFO.MFA.EDIT_MFA.LINK.URL}`}>
            {RESEARCHER_PROFILE_INFO.MFA.EDIT_MFA.LINK.LABEL}
          </LinkComponent>
        </FormUserMessageWrapper>
      </MobilePhoneWrapper>

      <MobilePhoneWrapper>
        <SectionStyled>
          <TextInput
            label={RESEARCHER_PROFILE_INFO.EMAIL.LABEL}
            setTextValue={() => null}
            textValue={`${researcherInfo.email}`}
            isRequired={false}
            disabled
          />
        </SectionStyled>
        <FormUserMessageWrapper>
          <TextP>{RESEARCHER_PROFILE_INFO.EMAIL.EDIT_EMAIL.LABEL}</TextP>
          <LinkComponent url={`MAILTO:${RESEARCHER_PROFILE_INFO.EMAIL.EDIT_EMAIL.LINK.URL}`}>
            {RESEARCHER_PROFILE_INFO.MFA.EDIT_MFA.LINK.LABEL}
          </LinkComponent>
        </FormUserMessageWrapper>
      </MobilePhoneWrapper>

      <FlexboxWrapper>
        <SelectInput
          label={RESEARCHER_PROFILE_INFO.POSITION.LABEL}
          value={researcherInfo.position}
          id="position"
          placeholder="Select your Position/Title"
          onChange={(value) => handleChange(value, 'position')}
          options={researcherPosition}
        />
      </FlexboxWrapper>
      <FlexboxWrapper>
        <TextInput
          label={RESEARCHER_PROFILE_INFO.POSITION_OTHER.LABEL}
          textValue={researcherInfo.position_other || ''}
          setTextValue={(value) => { handleChange(value, 'position_other'); }}
          error={errors.position_other}
          type="text"
          isRequired={false}
          onBlurAction={() => (researcherInfo.position === 'Other' ? handleSingleValidation(researcherInfo.position_other, 'position_other', 'other') : () => '')}
        />
      </FlexboxWrapper>
      <FlexboxWrapper>
        <SelectInput
          value={researcherInfo.ha_affiliation_id ? researcherInfo.ha_affiliation_id : 1}
          label={RESEARCHER_PROFILE_INFO.AFFILIATION.LABEL}
          placeholder={RESEARCHER_PROFILE_INFO.AFFILIATION.PLACEHOLDER}
          id="healthAffiliation"
          onChange={(value) => { handleChange(Number(value), 'ha_affiliation_id'); }}
          options={authorities}
        />
      </FlexboxWrapper>
      <FlexboxWrapper>
        <SelectInput
          value={researcherInfo.academic_affiliation_id ? researcherInfo.academic_affiliation_id : RESEARCHER_PROFILE_INFO.UNIVERSITY.PLACEHOLDER}
          label={RESEARCHER_PROFILE_INFO.UNIVERSITY.LABEL}
          placeholder={RESEARCHER_PROFILE_INFO.UNIVERSITY.PLACEHOLDER}
          id="academicAffiliation"
          onChange={(value) => { handleChange(Number(value), 'academic_affiliation_id'); }}
          options={universities}
        />
      </FlexboxWrapper>
      <FlexboxWrapper>
        <TextInput
          label={RESEARCHER_PROFILE_INFO.UNIVERSITE_OTHER.LABEL}
          textValue={researcherInfo.academic_affiliation_other || ''}
          setTextValue={(value) => { handleChange(value, 'academic_affiliation_other'); }}
          error={errors.academic_affiliation_other}
          type="text"
          isRequired={false}
          onBlurAction={() => (researcherInfo.academic_affiliation_id === 10 ? handleSingleValidation(researcherInfo.academic_affiliation_other, 'academic_affiliation_other', 'other') : () => '')}
        />
      </FlexboxWrapper>
      <FlexboxWrapper>
        <Toggle checked={researcherInfo.receive_news} onChange={() => handleChange(!researcherInfo.receive_news, 'receive_news')} />
        <TextP font={fonts.font1.H14} lineHeight="1.33rem">
          {RESEARCHER_PROFILE_INFO.NEWS_EVENTS.LABEL}
        </TextP>
      </FlexboxWrapper>
      <FlexboxWrapper>
        <Toggle checked={researcherInfo.researcher_term_accept || false} onChange={() => handleChange(!researcherInfo.researcher_term_accept, 'researcher_term_accept')} />
        <TextP font={fonts.font1.H14} lineHeight="1.33rem">
          {RESEARCHER_PROFILE_INFO.TERMS.LABEL}
        </TextP>
      </FlexboxWrapper>
      <SectionStyled>
        <PopUpErrorStyled>
          {errors.researcher_term_accept && <ErrorMessage message={errors.researcher_term_accept} />}
        </PopUpErrorStyled>
      </SectionStyled>
      <FlexboxWrapper>
        <LinkComponent
          theme="researcherProfile"
          url={`${environment.app.websiteURL}${RESEARCHER_PROFILE_INFO.TERMS_LINK.URL}`}
          newWindow
        >
          {RESEARCHER_PROFILE_INFO.TERMS_LINK.LABEL}
        </LinkComponent>
      </FlexboxWrapper>
      <ButtonWrapper>
        <Button
          id="create-button"
          onClick={() => handleValidation(researcherInfo)}
          theme="outline"
        >
          {RESEARCHER_PROFILE_INFO.BUTTONS.SUBMIT}
        </Button>
      </ButtonWrapper>
    </ResearcherWrapper>
  </>
);

export default ResearcherProfileInfoForm;
