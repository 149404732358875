/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useAPI } from './useAPI';
import { projectInitialValues } from '../utils/studyFormInitialValues';
import { userInitial } from '../utils/initialValues';
import {
  checkForURL, formatMonths, genderOrder, sexOrder, stringURlReplacement,
} from '../utils';

export const useProject = () => {
  const {
    getProject, getPrincipalInvestigatorInfo, getInterested, updateProjectInterest, updateBulkProjectInterest,
  } = useAPI();

  const [project, setProject] = useState<IProject>(projectInitialValues);
  const [interested, setInterested] = useState<{
    birth_date: string
    email: string
    enrollment_status: string
    first_name: string
    gender: string
    interest: string
    last_name: string
    phone_number: string
    volunteer_id: number
  }[]>([]);

  const [filteredInterested, setFilteredInterested] = useState<{
    birth_date: string
    email: string
    enrollment_status: string
    first_name: string
    gender: string
    interest: string
    last_name: string
    phone_number: string
    volunteer_id: number
  }[]>([]);

  const [interestSearchValue, setInterestSearchValue] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [principalInvestigator, setPrincipalInvestigator] = useState<IUser>(userInitial);
  const [errorProject, setErrorProject] = useState();
  const [enrollentStatusFilter, setEnrollentStatusFilter] = useState<string[]>([]);

  const [selectedInterests, setSelectedInterests] = useState<number[]>([]);

  const createLinkArray = (projectField: string) => {
    const projectFieldArray = projectField?.split('\r\n') || [];

    return projectFieldArray.map((para: string) => {
      if (checkForURL(para)) {
        const detailsArray = stringURlReplacement(para);
        return detailsArray;
      }
      return para;
    });
  };

  const getProjectInfo = async (id: number, type: string) => {
    try {
      if (!id || id === undefined || !type) throw new Error('Missing info');

      const projectInfo = await getProject(id, type);
      if (projectInfo.status === 'Failed') throw new Error(projectInfo.message);

      const description = createLinkArray(projectInfo.description);
      projectInfo.descriptionWithLinks = description;

      const purpose = createLinkArray(projectInfo.purpose);
      projectInfo.proposewithLinks = purpose;

      const commitment = createLinkArray(projectInfo.commitment);
      projectInfo.commitmentwithLinks = commitment;

      const inclusionCriteria = createLinkArray(projectInfo?.inclusion_criteria || '');
      projectInfo.inclusionCriteriawithLinks = inclusionCriteria;

      const exclusionCriteria = createLinkArray(projectInfo?.exclusion_criteria || '');
      projectInfo.exclusionCriteriawithLinks = exclusionCriteria;

      const orientationProvided = createLinkArray(projectInfo?.orientation_provided || '');
      projectInfo.orientationProvidedwithLinks = orientationProvided;

      const additionalEligibility = createLinkArray(projectInfo?.additional_eligibility || '');
      projectInfo.additionalEligibilitywithLinks = additionalEligibility;

      const background = createLinkArray(projectInfo?.background || '');
      projectInfo.backgroundwithLinks = background;

      projectInfo.sex_eligible = projectInfo.sex_eligible?.sort((sex: string) => sexOrder.indexOf(sex));
      projectInfo.genders_eligible = projectInfo.genders_eligible?.length === 6 ? ['All'] : projectInfo.genders_eligible?.sort((gender: string) => genderOrder.indexOf(gender));

      projectInfo.ageRange = `${formatMonths(projectInfo.min_age, projectInfo.years_months || 'months')} - ${formatMonths(projectInfo.max_age, projectInfo.years_months || 'months')}`;

      setProject(projectInfo);

      setPrincipalInvestigator(projectInfo.pi);
      setIsLoading(false);
    } catch (error: any) {
      setErrorProject(error?.message);
    }
  };

  const getPrincipalInvestigator = async (id: string) => {
    const pi = await getPrincipalInvestigatorInfo(id);
    setPrincipalInvestigator({
      first_name: pi.first_name,
      last_name: pi.last_name,
      id: pi.id,
      type: pi.type,
      email: pi.email,
      ha_affiliation: pi.ha_affiliation,
      academic_affiliation: pi.academic_affiliation,
      academic_affiliation_other: pi.academic_affiliation_other,
    });
  };

  const getInterestedVolunteers = async (id: string) => {
    const interestedVolunteers = await getInterested(id);
    setInterested(interestedVolunteers);
  };

  const handleFollowUpUpdate = async (enrollment_status: string, volunteer_id: string, projectId: string) => {
    const project_id = Number(projectId);
    updateProjectInterest({
      enrollment_status, volunteer_id, project_id, updated_at: new Date(),
    });
    const newState = interested.map((value) => {
      if (value.volunteer_id === Number(volunteer_id)) {
        return { ...value, enrollment_status };
      }
      return value;
    });
    setInterested(newState);
  };

  const updateProjectInterestsHandler = async (enrollmentStatus: string) => {
    const body = {
      enrollmentStatus,
      projectId: project.id,
      volunteerIds: selectedInterests,
      updatedAt: new Date(),
    };

    const results = await updateBulkProjectInterest(body);

    if (results.updateInterests) {
      const updatedInterested = interested.map((volunteer) => {
        if (selectedInterests.includes(volunteer.volunteer_id)) {
          const updatedVolunteer = results.updateInterests.find((resultVol: any) => resultVol.volunteer_id === volunteer.volunteer_id);
          if (!updatedVolunteer) return volunteer;
          return {
            ...volunteer,
            enrollment_status: updatedVolunteer.enrollment_status,
          };
        }
        return volunteer;
      });

      setInterested(updatedInterested);
      setSelectedInterests([]);
    }
  };

  const setSelectedInterestsHandler = (id: number, checked: boolean) => {
    if (checked) {
      if (id === 0) {
        const allFilterVolunteers = filteredInterested.map((volunteer) => volunteer.volunteer_id);
        setSelectedInterests(allFilterVolunteers);
        return;
      }
      setSelectedInterests([...selectedInterests, id]);
      return;
    }
    if (id === 0) setSelectedInterests([]);
    if (id !== 0) {
      const selectedIds = selectedInterests.filter((volunteerId) => volunteerId !== id);
      setSelectedInterests(selectedIds);
    }
  };

  const filterVolunteerHandler = async (enrollentStatus: string, check: boolean) => {
    if (enrollentStatus === 'clear') {
      setEnrollentStatusFilter([]);
      return;
    }

    if (check) {
      setEnrollentStatusFilter([...enrollentStatusFilter, enrollentStatus]);
    } else {
      const updatedFilter = enrollentStatusFilter.filter((status) => status !== enrollentStatus);
      setEnrollentStatusFilter(updatedFilter);
    }
  };

  const filterVolunteerInterseted = (searchedInterested?: any[]) => {
    const interesetedList = searchedInterested || [...interested];
    if (enrollentStatusFilter.length === 0) {
      setFilteredInterested(interesetedList);
      return;
    }

    const updatedInterest = interesetedList.filter((volunteer) => enrollentStatusFilter.includes(volunteer.enrollment_status));
    setFilteredInterested(updatedInterest);
  };

  const interestSearchHandler = () => {
    const filterSearch = interested.filter((volunteer) => {
      if (volunteer.email.toLowerCase().includes(interestSearchValue.toLowerCase())) return true;
      if (volunteer.last_name.toLowerCase().includes(interestSearchValue.toLowerCase())) return true;
      if (volunteer.first_name.toLowerCase().includes(interestSearchValue.toLowerCase())) return true;
      if (`${volunteer.first_name} ${volunteer.last_name}`.toLowerCase().includes(interestSearchValue.toLowerCase())) return true;

      return false;
    });

    filterVolunteerInterseted(filterSearch);
  };

  useEffect(() => {
    if (interestSearchValue === undefined) {
      filterVolunteerInterseted();
    } else {
      interestSearchHandler();
    }
  }, [enrollentStatusFilter, interested]);

  useEffect(() => {
    interestSearchHandler();
  }, [interestSearchValue]);

  return {
    project,
    setProject,
    principalInvestigator,
    setPrincipalInvestigator,
    isLoading,
    getProjectInfo,
    getPrincipalInvestigator,
    getInterestedVolunteers,
    filterVolunteerHandler,
    enrollentStatusFilter,
    interested: filteredInterested,
    handleFollowUpUpdate,
    errorProject,
    interestSearchValue,
    setInterestSearchValue,
    selectedInterests,
    setSelectedInterestsHandler,
    updateProjectInterestsHandler,
  };
};
