export const RESEARCH_ETHICS = {
  TITLE: 'Research Ethics Board (REB)',
  DESCRIPTION: 'Study recruitment via REACH BC must be approved by the REB.',
  SUB_DESCRIPTION: 'Once the REB has approved the use of REACH BC, come back to this form and change the answer to "yes". That will open more fields where you can enter REB information and upload the approval certificate.',
  REB_APPROVED: {
    LABEL: 'Has the REB already approved recruitment by via REACH BC?',
    LINK: {
      LABEL: 'Learn about REB',
      URL: 'https://assets.ctfassets.net/rbx11ml43wqx/5BYPxokCeDsg9mFH5fONER/a8d6e2a1de79d3af251ccaeb49ac35df/REACH_BC_-_BC_Regions.pdf',
    },
    RADIO: {
      YES: 'Yes - I attest that the REB has approved the use of REACH BC for recruitment.',
      NO: 'No - The REB has not yet approved the use of REACH BC.',
    },
  },
  REB_NUMBER: {
    LABEL: 'REB Certificate Number',
    DESCRIPTION: 'Max character limit is 80',
  },
  REB_FILE: {
    LABEL: 'REB File Upload',
    DESCRIPTION: 'Upload the approval certificate which includes the use of REACH BC for recruitment. .pdf only, maximum file size 15 MB',
  },
  REB_EXPIRY_DATE: {
    LABEL: 'REB Expiry Date (YYYY-MM-DD)',
    DESCRIPTION: 'Please note: It is the responsibility of the research team to ensure that ethics approval is renewed and amended as needed per REB requirements. After the posting is approved by REACH BC, you are not required to provide documentation of the ethics renewal or any future amendments but your study must have up to date ethics to remain posted on REACH BC.',
    PLACEHOLDER: 'YYYY-MM-DD',
  },
  ADDITIONAL_DOC: {
    LABEL: 'Additional Supporting Document',
    DESCRICPTION: '.pdf only, maximum file size 15 MB',
  },
};
