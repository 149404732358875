import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type WrapperProps = {
  readonly isOpen: boolean
  readonly type?: string
};

type ContentStyledProps = {
  readonly type?: string
};

export const ContentStyled = styled.div<ContentStyledProps>`
  background-color: ${colours.colour4};
  padding: 1.5rem;
  border-radius: ${({ type }) => (type === 'mobile' ? '0' : '8px')};
  transition: all 0.3s ease-in-out;
  max-width: 900px;
  width: ${({ type }) => (type === 'mobile' ? '100%' : 'fit-content')};
  height:  ${({ type }) => (type === 'mobile' ? '100%' : 'max-content')};
  max-width: 100%;

  @media (min-width: 600px) {
    width:fit-content;
    height:  max-content;
    border-radius: 8px;
  }
`;

export const WrapperStyled = styled.div<WrapperProps>`
  position: fixed;
  ${fonts.font1.H16}
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  overflow-y: auto;
  padding:  ${({ type }) => (type === 'mobile' ? '0' : '2rem')};
  ${ContentStyled} {
    margin-top: ${({ isOpen }) => (isOpen ? '0' : '-50px')};
  }
`;
