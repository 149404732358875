import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill1 } from '@fortawesome/free-regular-svg-icons';
import * as Styled from './styles';
import { PROJECT_CARD, PROJECT_TYPES } from './const';
import { LinkComponent } from '../../../Link';
import { Popover } from '../../../Popover';
import Button from '../../../Button';
import { TABS } from '../../const';
import { fonts } from '../../../../styles/theme';

type ProjectCardPropTypes = {
  project: any
  type: string
  updateInterest: (id: number, type: string, projectName: string) => void
}

export const ProjectCard = ({ project, type, updateInterest }: ProjectCardPropTypes) => (
  <Styled.CardWrapper>
    <Styled.ChipContainerStyled theme="secondary">
      <Styled.ClipStyled>{PROJECT_TYPES[project.type.toUpperCase()]}</Styled.ClipStyled>
      {project.patient_oriented_project && (
        <Styled.ClipStyled theme="secondary">{PROJECT_CARD.PATINET_ORIENTED}</Styled.ClipStyled>
      )}
      {(project.ppo_result_id || project.study_result_id) && (
        <Styled.ClipStyled theme="secondary">{PROJECT_CARD.RESULT}</Styled.ClipStyled>
      )}
    </Styled.ChipContainerStyled>

    <Styled.ProjectHeaderStyled>{project.title}</Styled.ProjectHeaderStyled>
    {!project.is_online && (
      <Styled.HightLightTextStyled>{project.hospital ? `${project.hospital},` : ''} {project.city}</Styled.HightLightTextStyled>
    )}

    <Styled.DescriptionTextStyled>
      {project.description}
    </Styled.DescriptionTextStyled>

    {project.commitment && (
      <Styled.DescriptionTextStyled>
        <span>{PROJECT_CARD.COMMITMENT}</span>
        {project.commitment}
      </Styled.DescriptionTextStyled>
    )}

    <Styled.ChipContainerStyled>
      {project.is_online ? (
        <Styled.ClipStyled theme="tertiary">
          <FontAwesomeIcon icon="earth-americas" />
          {PROJECT_CARD.IS_ONLINE}
        </Styled.ClipStyled>
      ) : (
        <Styled.ClipStyled theme="tertiary">
          <FontAwesomeIcon icon="user" />
          {PROJECT_CARD.IN_PERSON}
        </Styled.ClipStyled>
      )}
      {(project.min_age || project.min_age === 0) && (
        <Styled.ClipStyled theme="tertiary">
          <FontAwesomeIcon icon="birthday-cake" />
          {project.min_age}-{project.max_age} {project.isYear ? PROJECT_CARD.YEAR : PROJECT_CARD.MONTH}
        </Styled.ClipStyled>
      )}
      {project.healthy_controls && (
        <Styled.ClipStyled theme="tertiary">
          <FontAwesomeIcon icon="check" />
          {PROJECT_CARD.HEALTHY_VOLUNTEERS}
        </Styled.ClipStyled>
      )}

      {project.compensation && (
        <Styled.ClipStyled theme="tertiary">
          <FontAwesomeIcon icon={faMoneyBill1} />
          {PROJECT_CARD.COMPENSATION}
        </Styled.ClipStyled>
      )}
    </Styled.ChipContainerStyled>

    <Styled.FooterContainerStyled>
      {type === TABS.SUGGESTED && (
        <Popover
          buttonLabel={PROJECT_CARD.DELETE_DROPDOWN.LABEL}
          buttonTheme="underlineThird"
          location="bottom"
          buttonWidth="fix-content"
          buttonFont={fonts.font1.H16B}
          useArrow
          fitContent
        >
          <Styled.PopoverBodyWrapper>
            {PROJECT_CARD.DELETE_DROPDOWN.OPTIONS.map((option) => (
              <Button
                id={option.VALUE}
                key={option.VALUE}
                onClick={() => updateInterest(project.id, option.VALUE, project.title)}
                theme="menuButton"
              >
                {option.LABEL}
              </Button>
            ))}
          </Styled.PopoverBodyWrapper>
        </Popover>
      )}

      {type === TABS.PROJECT && (
        <Button
          id="archive"
          onClick={() => updateInterest(project.id, 'archived', project.title)}
          theme="outlineTertiary"
        >
          <Styled.ButtonContainWrapper>
            <FontAwesomeIcon icon="bookmark" />
            {PROJECT_CARD.BUTTONS.ARCHIVE}
          </Styled.ButtonContainWrapper>
        </Button>
      )}

      {type === TABS.ARCHIVED && (
        <Button
          id="archive"
          onClick={() => updateInterest(project.id, 'unarchive', project.title)}
          theme="outlineTertiary"
        >
          <Styled.ButtonContainWrapper>
            <FontAwesomeIcon icon="bookmark" />
            {PROJECT_CARD.BUTTONS.UNARCHIVE}
          </Styled.ButtonContainWrapper>
        </Button>
      )}

      <LinkComponent
        url={`${PROJECT_CARD.LINKS.DETAILS.URL}${project.type}/${project.id}`}
        theme="buttonFourth"
      >
        <div>
          {PROJECT_CARD.LINKS.DETAILS.LABEL}
        </div>
      </LinkComponent>
    </Styled.FooterContainerStyled>
  </Styled.CardWrapper>
);
