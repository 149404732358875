export const STUDY_CONTACT = {
  TITLE: 'Study Contact',
  DESCRIPTION: 'Who will be the study contact to receive inquiries from Volunteers? Enter up to two contacts. If contacts require online access to the study, they must also be added under Additional Team Member - REACH BC Access.',
  CONTACT_ONE_LABEL: 'Study Contact 1',
  CONTACT_TWO_LABEL: 'Study Contact 1',
  NAME: {
    LABEL: 'Full Name',
    DESCRIPTION: 'Max character limit is 250.',
  },
  POSITION: {
    LABEL: 'Position/Title',
    PLACEHOLDER: 'Choose a position/title',
  },
  EMAIL: {
    LABEL: 'Email',
    PLACEHOLDER: 'e.g. name@mail.com',
  },
  PHONE: {
    LABEL: 'Phone Number',
    PLACEHOLDER: 'e.g. 6045555555',
  },
  EXTENSION: {
    LABEL: 'Extension',
  },
};
