import React from 'react';
import { Checkbox } from '../../../CheckBox';
import { ErrorMessage } from '../../../ErrorMessage';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { DropdownSelect } from '../../../DropdownSelect';
import { TextP } from '../../../Text';
import { TextInput } from '../../../TextInput';
import * as Styled from '../styles';
import { OPPRTUNITY_LOCATIONS } from './const';

type OpportunityLocationProps = {
  handleCheck: (value: boolean, field: number | string, key: string) => void
  handleChange: (value: string, field: string) => void
  handleToggle: (value: boolean, field: string) => void
  handleSingleValidation: (value: any, field: string, type?: string) => void
  selectedhealthAuthorityRegion: number[]
  healthAuthorityRegionOptions: { id: number, name: string }[]
  hospital?: { value: string, label: string }
  hospitalList: { value: string, label: string }[]
  building: string
  department: string
  streetAddress: string
  city: string
  postalCode: string
  isOnline: boolean
  hasDirectLink: boolean
  directLinkUrl?: string
  canEdit: boolean
  errors: { [key: string]: string }
}

const OpportunityLocation = ({
  handleCheck, selectedhealthAuthorityRegion, healthAuthorityRegionOptions, isOnline, canEdit,
  hospital, hospitalList, building, department, handleChange, streetAddress, handleToggle, handleSingleValidation, hasDirectLink, directLinkUrl, city, postalCode, errors,
}: OpportunityLocationProps) => (
  <FormSection title={OPPRTUNITY_LOCATIONS.TITLE} subText={OPPRTUNITY_LOCATIONS.SUB_TEXT}>
    <Styled.SectiontWrapper id="health_authority_region">
      <FormSubtitle
        subtitle={OPPRTUNITY_LOCATIONS.REGION_SECTION.SUB_TITLE}
        link={{
          label: OPPRTUNITY_LOCATIONS.REGION_SECTION.LINK.LABEL,
          url: OPPRTUNITY_LOCATIONS.REGION_SECTION.LINK.URL,
        }}
        isRequired
        margin="small"
      />
      <TextP lineHeight="1.33rem">
        {OPPRTUNITY_LOCATIONS.REGION_SECTION.DESCRIPTION}
      </TextP>

      <Styled.CheckboxWrapper>
        <Checkbox
          label="All"
          isChecked={selectedhealthAuthorityRegion.length === healthAuthorityRegionOptions.length}
          onChange={(check) => handleCheck(check, 'All', 'health_authority_region')}
          disabled={!canEdit}
        />
      </Styled.CheckboxWrapper>
      {healthAuthorityRegionOptions.map((option) => (
        <Styled.CheckboxWrapper key={option.name || option.id}>
          <Checkbox
            label={option.name}
            isChecked={selectedhealthAuthorityRegion.includes(option.id)}
            onChange={(check) => handleCheck(check, option.id, 'health_authority_region')}
            disabled={!canEdit}
          />
        </Styled.CheckboxWrapper>
      ))}
      {errors.health_authority_region && <ErrorMessage message={errors.health_authority_region} />}
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper>
      <FormSubtitle subtitle={OPPRTUNITY_LOCATIONS.ONLINE_SECTION.SUB_TITLE} margin="small" />
      <Styled.SectionDescriptionWrapper>
        <TextP>{OPPRTUNITY_LOCATIONS.ONLINE_SECTION.DESCRIPTION}</TextP>
      </Styled.SectionDescriptionWrapper>
      <Checkbox
        isChecked={isOnline}
        label={OPPRTUNITY_LOCATIONS.ONLINE_SECTION.LABEL}
        onChange={() => handleToggle(!isOnline, 'is_online')}
        labelTheme="secondary"
        textTransform="none"
      />
    </Styled.SectiontWrapper>
    {
      isOnline ? (
        <>
          <Styled.SectiontWrapper id="has_direct_link">
            <FormSubtitle subtitle={OPPRTUNITY_LOCATIONS.ONLINE_STUDY.SUB_TITLE} margin="small" />
            <Styled.SectionDescriptionWrapper>
              <TextP>{OPPRTUNITY_LOCATIONS.ONLINE_STUDY.DESCRIPTION}</TextP>
              <TextP>{OPPRTUNITY_LOCATIONS.ONLINE_STUDY.DESCRIPTION_TWO}</TextP>
            </Styled.SectionDescriptionWrapper>
            <Checkbox
              isChecked={hasDirectLink}
              label={OPPRTUNITY_LOCATIONS.ONLINE_STUDY.LABEL}
              onChange={() => handleToggle(!hasDirectLink, 'has_direct_link')}
              labelTheme="secondary"
              textTransform="none"
            />
          </Styled.SectiontWrapper>
          {hasDirectLink && (
            <Styled.SectiontWrapper id="direct_link">
              <TextInput
                label={OPPRTUNITY_LOCATIONS.DIRECT_LINK.LABEL}
                description={OPPRTUNITY_LOCATIONS.DIRECT_LINK.DESCRIPTION}
                placeholder={OPPRTUNITY_LOCATIONS.DIRECT_LINK.PLACEHOLDER}
                isRequired
                theme="form"
                textValue={directLinkUrl}
                setTextValue={(value) => handleChange(value, 'direct_link_url')}
                onBlurAction={() => handleSingleValidation(directLinkUrl, 'direct_link_url')}
                error={errors.direct_link_url}
                disabled={!canEdit}
              />
            </Styled.SectiontWrapper>
          )}
        </>
      ) : (
        <>
          <Styled.SectiontWrapper id="hospital">
            <DropdownSelect
              id="hospital"
              label={OPPRTUNITY_LOCATIONS.HOSPITAL.LABELS.HOSPITAL}
              description={OPPRTUNITY_LOCATIONS.HOSPITAL.DESCRIPTIONS.HOSPITAL}
              placeholder={OPPRTUNITY_LOCATIONS.HOSPITAL.PLACEHOLDER.HOSPITAL}
              onChange={(value) => handleChange(value, 'hospital_id')}
              selected={hospital}
              options={hospitalList}
              error={errors.hospital_id}
              isDisabled={!canEdit}
              theme="form"
              isSearchable
            />
          </Styled.SectiontWrapper>

          <Styled.SectiontWrapper id="building">
            <TextInput
              label={OPPRTUNITY_LOCATIONS.HOSPITAL.LABELS.BUILDING}
              description={OPPRTUNITY_LOCATIONS.HOSPITAL.DESCRIPTIONS.BUILDING}
              isRequired={false}
              textValue={building}
              setTextValue={(value) => handleChange(value, 'building')}
              error={errors.building}
              disabled={!canEdit}
              theme="form"
              maxLength={120}
            />

          </Styled.SectiontWrapper>
          <Styled.SectiontWrapper id="department">
            <TextInput
              label={OPPRTUNITY_LOCATIONS.HOSPITAL.LABELS.DEPARTMENT}
              description={OPPRTUNITY_LOCATIONS.HOSPITAL.DESCRIPTIONS.DEPARTMENT}
              isRequired={false}
              textValue={department}
              setTextValue={(value) => handleChange(value, 'department')}
              error={errors.department}
              theme="form"
              disabled={!canEdit}
              maxLength={120}
            />
          </Styled.SectiontWrapper>
          <Styled.SectiontWrapper id="street_address">
            <TextInput
              label={OPPRTUNITY_LOCATIONS.HOSPITAL.LABELS.STREET}
              description={OPPRTUNITY_LOCATIONS.HOSPITAL.DESCRIPTIONS.STREET}
              isRequired
              textValue={streetAddress}
              setTextValue={(value) => handleChange(value, 'street_address')}
              error={errors.street_address}
              theme="form"
              disabled={!canEdit}
              onBlurAction={() => handleSingleValidation(streetAddress, 'street_address', 'address')}
              maxLength={120}
            />
          </Styled.SectiontWrapper>
          <Styled.SectiontWrapper id="city">
            <TextInput
              label={OPPRTUNITY_LOCATIONS.HOSPITAL.LABELS.CITY}
              description={OPPRTUNITY_LOCATIONS.HOSPITAL.DESCRIPTIONS.CITY}
              isRequired
              textValue={city}
              setTextValue={(value) => handleChange(value, 'city')}
              error={errors.city}
              theme="form"
              disabled={!canEdit}
              onBlurAction={() => handleSingleValidation(city, 'city', 'address')}
              maxLength={120}
            />
          </Styled.SectiontWrapper>
          <Styled.SectiontWrapper id="postal_code" isLast>
            <TextInput
              label={OPPRTUNITY_LOCATIONS.HOSPITAL.LABELS.POSTAL_CODE}
              description={OPPRTUNITY_LOCATIONS.HOSPITAL.DESCRIPTIONS.POSTAL_CODE}
              placeholder={OPPRTUNITY_LOCATIONS.HOSPITAL.PLACEHOLDER.POST_CODE}
              isRequired
              textValue={postalCode}
              setTextValue={(value) => handleChange(value, 'postal_code')}
              error={errors.postal_code}
              theme="form"
              disabled={!canEdit}
              onBlurAction={() => handleSingleValidation(postalCode, 'postal_code', 'address')}
              maxLength={7}
            />
          </Styled.SectiontWrapper>
        </>
      )
    }

  </FormSection>
);
export default OpportunityLocation;
