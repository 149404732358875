import React from 'react';
import { fonts } from '../../styles/theme';
import { ErrorMessage } from '../ErrorMessage';
import { LinkComponent } from '../Link';
import PublicToolTip from '../PublicTooltip';
import { TextP } from '../Text';
import {
  SelectedStyled, LabelStyled, InputWrapper, DescriptionWrapper,
} from './styles';
import { RequiredForm } from '../RequiredForm';

type SelectInputProps = {
  label: string
  placeholder?: string;
  description?: string;
  descriptionSecondary?: string
  value?: string | number
  onChange: (value: string, field: string) => void
  id: string
  isRequired?: boolean;
  isPublic?: boolean;
  options: { value?: string, id?: number, label?: string, name?: string }[]
  error?: string
  disabled?: boolean
  theme?: string
  link?: string
  linkLabel?: string
  newWindow?: boolean
}
export const SelectInput = ({
  label, value, placeholder, isRequired = true, isPublic = false, options, error, id, onChange, description, theme, link, linkLabel, descriptionSecondary,
  disabled, newWindow = false,
}: SelectInputProps) => (
  <InputWrapper theme={theme}>
    <LabelStyled htmlFor={`select-${label}`} theme={theme}>
      {label}
      {isPublic && <PublicToolTip />}
      {isRequired
        && <RequiredForm />}
    </LabelStyled>
    <DescriptionWrapper>
      <TextP font={fonts.font1.H14} lineHeight="1.33rem">{description}
        {linkLabel && (
          <LinkComponent theme="selectInput" url={link || ''} newWindow={newWindow}>
            {linkLabel}
          </LinkComponent>
        )}
      </TextP>
      {descriptionSecondary && (
        <DescriptionWrapper>
          <TextP>{descriptionSecondary}</TextP>
        </DescriptionWrapper>
      )}

    </DescriptionWrapper>
    <SelectedStyled
      id={`select-${label}`}
      name={`select-${label}`}
      value={value || 'none'}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(event.target.value, id);
      }}
      disabled={disabled}
      theme={theme}
    >
      {placeholder && <option value="none" disabled>{placeholder}</option>}
      {options.map((option) => (
        <option key={option.id || option.value} value={option.value || option.id}>
          {option.label || option.name}
        </option>
      ))}
    </SelectedStyled>
    {error && <ErrorMessage message={error} />}
  </InputWrapper>
);
