import { useState, useRef, useEffect } from 'react';

export const useScrollTo = (tabLink: string, isLoading: boolean, currentTab: string) => {
  const [activeSection, setActiveSection] = useState<string | undefined>(tabLink);
  const sectionRefs = useRef<Record<string, HTMLDivElement>>({});

  const scrollActionHandler = (scrollRef: string) => {
    const element = document.getElementById(scrollRef);
    const headerOffset = 0;
    if (element) {
      const yPostion = element.getBoundingClientRect().top + window.scrollY + headerOffset - 100;
      window.scrollTo({ top: yPostion, behavior: 'smooth' });
    }
  };

  const handleIntersection: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const { id } = entry.target;
        setActiveSection(id);
      }
    });
  };

  useEffect(() => {
    if (isLoading) return;
    if (currentTab !== 'Detail') return;
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '-50% 0% -50% 0%',
    });

    Object.values(sectionRefs.current).forEach((sectionRef) => {
      if (!sectionRef) return;
      observer.observe(sectionRef);
    });

    return () => {
      observer.disconnect();
    };
  }, [isLoading, currentTab]);

  return {
    scrollActionHandler,
    activeSection,
    sectionRefs,
  };
};
