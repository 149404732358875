import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLogin, useUser } from '../../hooks';
import {
  LinkComponent, TextP, LoginWrapper, TextInput,
} from '../../components';
import Button from '../../components/Button';
import { LOGIN } from './const';
import * as Styled from '../../styles/loginStyle';

const Login = () => {
  const params = useLocation().search;

  const { getUserInfo, user } = useUser();
  const {
    username,
    password,
    passwordError,
    usernameError,
    handleSubmit,
    setPassword,
    setUsername,
    errorMessage,
    handleResendVerification,
    handleParams,

    status,
    setStatus,
    isLoading,
    handleExistingUser,
    isOpen,
    setIsOpen,
  } = useLogin();

  useEffect(() => {
    if (params) {
      setStatus(new URLSearchParams(params).get('status') || '');
    }
  }, [params]);

  useEffect(() => {
    handleExistingUser(user, params);
  }, [user]);

  useEffect(() => {
    getUserInfo();
    if (params) {
      handleParams(new URLSearchParams(params));
    }
  }, []);

  if (isLoading) return <div />;

  return (
    <LoginWrapper isOpen={isOpen} setIsOpen={setIsOpen}>

      {status === 'idle' && (
        <Styled.IdleMessageContainerStyled theme="message">
          <TextP textAlgin="center">{LOGIN.SESSION_EXPIRED}</TextP>
        </Styled.IdleMessageContainerStyled>
      )}
      {status === 'email-verification' && (
        <Styled.EmailVerificationMessageContainerStyled theme="message">
          {LOGIN.VERIFY_SEND.map((paragraph) => (
            <TextP textAlgin="center" key={paragraph}>
              {paragraph}
            </TextP>
          ))}
        </Styled.EmailVerificationMessageContainerStyled>
      )}

      <Styled.LoginWrapper>
        <Styled.LoginHeaderStyled>{LOGIN.TITLE}</Styled.LoginHeaderStyled>

        <Styled.LoginDescriptionStyled>
          <p>{LOGIN.DESCRIPTION}</p>
          <LinkComponent url={LOGIN.DESCRIPTION_LINK.URL} theme="loginLink">
            {LOGIN.DESCRIPTION_LINK.LABEL}
          </LinkComponent>
        </Styled.LoginDescriptionStyled>

        <Styled.TextInputWrapper>
          <TextInput
            label={LOGIN.EMAIL.LABEL}
            placeholder={LOGIN.EMAIL.PLACEHOLDER}
            setTextValue={setUsername}
            textValue={username}
            error={usernameError}
            theme="form"
            isRequired={false}
          />
        </Styled.TextInputWrapper>

        <Styled.TextInputWrapper marginBottom="lg">
          <TextInput
            label={LOGIN.PASSWORD.LABEL}
            placeholder={LOGIN.PASSWORD.PLACEHOLDER}
            setTextValue={setPassword}
            textValue={password}
            error={passwordError}
            theme="form"
            type="password"
            isRequired={false}
            handleEnter={handleSubmit}
          />
          <Styled.LinkWrapper>
            <LinkComponent
              theme="plain"
              url={LOGIN.PASSWORD.LINK.URL}
            >
              {LOGIN.PASSWORD.LINK.LABEL}
            </LinkComponent>
          </Styled.LinkWrapper>
        </Styled.TextInputWrapper>

        <Button
          id="login-button"
          onClick={handleSubmit}
          theme="primary"
          buttonWidth="100%"
        >
          {LOGIN.BUTTONS.LOGIN}
        </Button>

        {errorMessage && (
          <Styled.MessageContainerStyled theme={errorMessage ? 'error' : 'noBorder'}>
            {errorMessage === 'User is not confirmed.' ? (
              <Styled.ErrorMessageWrapper>
                <TextP>
                  {LOGIN.VERIFY_ERROR.MESSAGE_START}
                  <Styled.ButtonInline onClick={() => handleResendVerification()} id="click_here">
                    {LOGIN.VERIFY_ERROR.MESSAGE_LINK}
                  </Styled.ButtonInline>
                  {LOGIN.VERIFY_ERROR.MESSAGE_END}
                </TextP>
              </Styled.ErrorMessageWrapper>
            ) : <TextP>{errorMessage}</TextP>}
          </Styled.MessageContainerStyled>
        )}

        <Styled.LoginFooterStyled>
          <LinkComponent
            url={LOGIN.TROUBLE.URL}
            theme="underlineThird"
          >
            {LOGIN.TROUBLE.LABEL}
          </LinkComponent>
        </Styled.LoginFooterStyled>
      </Styled.LoginWrapper>
    </LoginWrapper>
  );
};

export default Login;
