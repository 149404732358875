import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { imageList } from '../../utils/imageList';
import Button from '../Button';
import { Figure } from '../Figure';
import { LinkComponent } from '../Link';
import { Popover } from '../Popover';
import {
  MainHeaderStyled, LogoWrapper, PopoverWrapper, MainHeaderInnerStyled,
} from './styles';
import { useCookies } from '../../hooks/useCookies';
import environment from '../../config/environment';

const MainHeader = () => {
  const { deleteCookie } = useCookies();
  const navigate = useNavigate();
  return (
    <MainHeaderStyled>
      <MainHeaderInnerStyled>
        <LinkComponent url="/dashboard">
          <LogoWrapper>
            <Figure imageSrc={imageList.logoFull} alt="logo" />
          </LogoWrapper>
        </LinkComponent>
        <PopoverWrapper>
          <Popover
            buttonLabel="Menu"
            buttonWidth="44px"
            openOnHover
            location="bottomCenter"
            buttonTheme="plain"
            flexDirection="column"
            icon={<FontAwesomeIcon icon="bars" />}
          >
            <>
              <LinkComponent url="/myProfile" theme="menuButton">
                <div>
                  <p>My Profile</p>
                </div>
              </LinkComponent>
              <Button
                id="menu-logout"
                theme="menuButton"
                onClick={() => { deleteCookie(environment.app.cookieName); navigate('/login'); }}
                buttonWidth="100%"
              >
                <p>Logout</p>
              </Button>
            </>
          </Popover>
        </PopoverWrapper>
      </MainHeaderInnerStyled>
    </MainHeaderStyled>
  );
};

export default MainHeader;
