import React from 'react';
import FormSection from '../../../FormSelection';
import { TextInput } from '../../../TextInput';
import { SectiontWrapper } from '../styles';
import { OPPORTUNITY_DETAILS_FORM } from './const';
import { DropdownSelect } from '../../../DropdownSelect';
import { TypeOfPpo } from '../../../../utils';

type OpportunityDetailsProps = {
  title: string
  nickName: string
  relatedResearchType?: string
  errors: { [key: string]: string }
  canEdit: boolean
  handleChange: (value: string, field: string) => void
  handleSingleValidation: (value: any, field: string) => void
}

const OpportunityDetails = ({
  title, nickName, handleChange, relatedResearchType, errors, canEdit, handleSingleValidation,
}: OpportunityDetailsProps) => (
  <FormSection title={OPPORTUNITY_DETAILS_FORM.TITLE}>

    <SectiontWrapper id="title">
      <TextInput
        label={OPPORTUNITY_DETAILS_FORM.STUDY_TITLE.LABEL}
        description={OPPORTUNITY_DETAILS_FORM.STUDY_TITLE.LABEL_DESCRIPTION}
        textValue={title}
        setTextValue={(value) => handleChange(value, 'title')}
        isRequired
        onBlurAction={() => handleSingleValidation(title, 'title')}
        error={errors.title}
        disabled={!canEdit}
        maxLength={500}
        theme="form"
      />
    </SectiontWrapper>
    <SectiontWrapper id="nick_name">
      <TextInput
        label={OPPORTUNITY_DETAILS_FORM.NICK_NAME.LABEL}
        description={OPPORTUNITY_DETAILS_FORM.NICK_NAME.DESCRIPTION}
        descriptionSecondary={OPPORTUNITY_DETAILS_FORM.NICK_NAME.DESCRIPTION_SECONDARY}
        textValue={nickName}
        setTextValue={(value) => handleChange(value, 'nick_name')}
        onBlurAction={() => handleSingleValidation(nickName, 'nick_name')}
        isRequired
        disabled={!canEdit}
        error={errors.nick_name}
        maxLength={90}
        theme="form"
      />
    </SectiontWrapper>

    <SectiontWrapper id="ppo_type">
      <DropdownSelect
        id="type-of-related-research"
        label={OPPORTUNITY_DETAILS_FORM.RESEARCH_RELATED.LABEL}
        onChange={(value) => handleChange(value, 'ppo_type')}
        selected={{ value: relatedResearchType, label: relatedResearchType }}
        options={TypeOfPpo}
        placeholder={OPPORTUNITY_DETAILS_FORM.RESEARCH_RELATED.PLACEHOLDER}
        error={errors.ppo_type}
        isDisabled={!canEdit}
        theme="form"
        required
      />
    </SectiontWrapper>
  </FormSection>
);

export default OpportunityDetails;
