import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

export const FormStatusPickerStyled = styled.div`
  width: 100%;
  line-height: 1.25rem;
  ${fonts.font1.H14}
`;

export const FlexBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.875rem;
`;

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${colours.colour18};
  padding: 1rem 0;
`;

export const LabelStyled = styled.label`
  margin-left: 0.875rem;
  cursor: pointer;
`;

export const LabelTitleStyled = styled.div`
  margin-bottom: 0.5rem;
`;
