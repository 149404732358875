import environment from '../../../config/environment';

export const RESEARCHER_DASHBOARD_HERO = {
  USER_MESSAGE: 'Welcome',
  LINK: {
    LABEL: 'Researcher Help Centre',
    URL: `${environment.app.websiteURL}/researcher-resources`,
  },
  Cards: [
    {
      TITLE: 'Research Study',
      DESCRIPTION: 'For health research studies recruiting participants (patients or healthy controls), create a research study. Note that research studies require proof of Research Ethics Board (REB) approval.',
      LINK: {
        URL: '/study/new',
        LABEL: 'Add a Research Study',
      },
    },
    {
      TITLE: 'Patient Partner Research Opportunity',
      DESCRIPTION: 'Create a patient partner opportunity if you want to recruit patient partners to identify and prioritize research questions, design studies, develop grant proposals, prepare for the execution of a study, analyze and interpret data, or translate or disseminate knowledge',
      LINK: {
        URL: '/ppo/new',
        LABEL: 'Add a Patient Partner Research Opportunity',
      },
    },
  ],
  BYPASS_MESSAGE: {
    TITLE: 'Attention Researchers:',
    DESCRIPTION:
      [
        'Due to technical issues with our SMS provider, Multi-factor authentication (MFA) for researcher logins is temporarily bypassed. Rest assured your account security remains our top priority. While you may still receive verification codes on your phone, please ignore them until further notice.',
      ],
  },
};
