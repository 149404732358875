export const FUNDING_INFORMATION = {
  TITLE: 'Funding Information',
  TITLE_MODILE: 'Study Dates & Funding Information',

  CLINICAL_TRIAL: {
    TITLE: 'Is this a clinical trial?',
    LABEL: 'Yes, this is a clinical trial.',
  },
  REGISTRATION_NUMBER: {
    LABEL: 'Clinical Trial registration number',
    LABEL_DESCRIPTION: 'Please enter NCT number, i.e. NCT04029961',
  },
  FUNDING_AGENCY: {
    LABEL: 'Primary Funding Agency',
    LABEL_DESCRIPTION: 'Who is funding this project?',
  },
  RECUITMENT_DATES: {
    TITLE: 'Estimated Recruitment End Date',
    LABEL_DESCRIPTION: 'What is the estimated date for when recruitment is expected to be closed?',
    PLACEHOLDER: 'YYYY-MM-DD',
  },
};
