import React from 'react';
import { faMoneyBill1 } from '@fortawesome/free-regular-svg-icons';
import SideBarItem from '../SideBarItem';

import * as Styled from '../../styles';
import { PROJECT_DETAILS } from '../../const';
import { LinkComponent } from '../../../Link';

type SideBarProps = {
  project: IProject
  principalInvestigator: IUser
  userType?: string
}

const SideBar = ({ project, principalInvestigator, userType }: SideBarProps) => {
  const piAcademicAffiliation = () => (principalInvestigator?.academic_affiliation_other
    ? principalInvestigator?.academic_affiliation_other
    : principalInvestigator?.academic_affiliation);

  return (
    <Styled.SideBarWrapper>
      {project.registration_num && (
        <Styled.TagStyled isResearcher={userType === 'researcher'}>
          <LinkComponent
            url={`https://clinicaltrials.gov/ct2/show/${project.registration_num}`}
            newWindow
            theme="buttonSecondary"
          >
            <p>Clinicaltrials.gov - {project.registration_num}</p>
          </LinkComponent>
        </Styled.TagStyled>
      )}
      <Styled.SideBarSectionStyled>
        <Styled.SideBarTitleStyled>
          {PROJECT_DETAILS.SELECTION_TITLES.STUDY_INFORMATION}
        </Styled.SideBarTitleStyled>

        <SideBarItem subTitle={PROJECT_DETAILS.SUBTITLES.COMPENSATION} icon={faMoneyBill1}>
          <p>
            {project.compensation === true ? 'Yes' : 'No'}
          </p>
        </SideBarItem>

        <SideBarItem subTitle={PROJECT_DETAILS.SUBTITLES.EXPENSES} icon="car">
          <p>{project.expenses === true ? 'Yes' : 'No'}</p>
        </SideBarItem>

        <SideBarItem subTitle={PROJECT_DETAILS.SUBTITLES.RECRUITMENT} icon="calendar">
          {project.recruitment_end && (
            <p>{project.recruitment_end.slice(0, 10)}</p>
          )}
        </SideBarItem>

        <SideBarItem subTitle={PROJECT_DETAILS.SUBTITLES.FUNDING} icon="dollar">
          {project.funding_agency?.map((agency) => (
            <p>{agency}</p>
          ))}
        </SideBarItem>

        {
          project.collaborating_organizations
          && project.collaborating_organizations?.length > 0 && (
            <SideBarItem subTitle={PROJECT_DETAILS.SUBTITLES.COLLABORATING} icon="building">
              {project.collaborating_organizations?.map((organization) => (
                <p>
                  {organization}
                </p>
              ))}
            </SideBarItem>
          )
        }
      </Styled.SideBarSectionStyled>

      <Styled.SideBarSectionStyled>
        <Styled.SideBarTitleStyled>
          {PROJECT_DETAILS.SELECTION_TITLES.LOCATION}
        </Styled.SideBarTitleStyled>

        <SideBarItem subTitle={PROJECT_DETAILS.SUBTITLES.PARTICIPATION_TYPE} icon="map-marker-alt">
          <p>{project.is_online ? 'Online' : 'In Person'}</p>
        </SideBarItem>

        {project.hospital && (
          <SideBarItem subTitle={project.hospital.name}>

            <p>{project.building}</p>
            <p>{project.department}</p>
            {project.street_address && (
              <p>
                {project.street_address}, {project.city}&nbsp;{project.province}
              </p>
            )}
            <p>{project.postal_code}</p>
          </SideBarItem>
        )}
      </Styled.SideBarSectionStyled>

      <Styled.SideBarSectionStyled margin="small">
        <Styled.SideBarTitleStyled hasMarginBottom>
          {PROJECT_DETAILS.SELECTION_TITLES.PRINCIPAL_INVESTIGATOR}
        </Styled.SideBarTitleStyled>

        <SideBarItem
          subTitle={`${principalInvestigator.first_name} ${principalInvestigator.last_name}`}
          icon="user"
        />
        <SideBarItem
          subTitle={principalInvestigator?.ha_affiliation || ''}
          icon="building"
          theme="secondary"
        />
        <SideBarItem
          subTitle={piAcademicAffiliation() || ''}
          icon="graduation-cap"
          theme="secondary"
        />

        <SideBarItem
          subTitle={principalInvestigator.email || ''}
          icon="envelope"
          theme="secondary"
        />
      </Styled.SideBarSectionStyled>

      <Styled.SideBarSectionStyled theme="secondary" margin="small">
        <Styled.SideBarTitleStyled hasMarginBottom>
          {PROJECT_DETAILS.SELECTION_TITLES.CONTACTS}
        </Styled.SideBarTitleStyled>

        <SideBarItem
          subTitle={project.contact1_name}
          icon="user"
        />
        <SideBarItem
          subTitle={project.contact1_position}
          icon="building"
          theme="secondary"
        />

        <SideBarItem
          subTitle={project.contact1_email}
          icon="envelope"
          theme="secondary"
        />

        {project.contact1_phone && (
          <SideBarItem
            subTitle={project.contact1_phone}
            icon="phone"
            theme="secondary"
          />
        )}

        {project.contact2_name && (
          <Styled.ContainerWrapper>
            <SideBarItem
              subTitle={project.contact2_name}
              icon="user"
            />
            <SideBarItem
              subTitle={project.contact2_position || ''}
              icon="building"
              theme="secondary"
            />

            <SideBarItem
              subTitle={project.contact2_email || ''}
              icon="envelope"
              theme="secondary"
            />

            {project.contact2_phone && (
              <SideBarItem
                subTitle={project.contact2_phone}
                icon="phone"
                theme="secondary"
              />
            )}
          </Styled.ContainerWrapper>
        )}
      </Styled.SideBarSectionStyled>
    </Styled.SideBarWrapper>
  );
};

export default SideBar;
