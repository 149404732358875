import styled from 'styled-components';
import { colours } from '../../../../styles/theme';

type SelectedAreaWrapperProps = {
  readonly isEmpty: boolean
  readonly topMargin: string
}

type DivStyledProps = {
  readonly maxWidth?: string
  readonly margin?: string
}

type HealthResearchAreaWrapperProps = {
  readonly hasBorder: boolean
}
export const HealthResearchAreaWrapper = styled.div<HealthResearchAreaWrapperProps>`
  margin: 2.5rem 0;
  padding-top: 0rem;
  border-top: 1px solid ${({ hasBorder }) => (hasBorder ? colours.colour9 : 'transparent')};
  border-bottom: 1px solid ${({ hasBorder }) => (hasBorder ? colours.colour9 : 'transparent')};
`;

export const SelectedAreaWrapper = styled.div<SelectedAreaWrapperProps>`
  border: 1px solid ${colours.colour9};
  border-top: ${({ isEmpty }) => (isEmpty ? '1px' : '2px')} solid ${({ isEmpty }) => (isEmpty ? colours.colour9 : colours.colour1)};
  background-color: ${({ isEmpty }) => (isEmpty ? '' : colours.colour4)};
  padding: 0.875rem;
  min-height: 63px;
  margin: ${({ topMargin }) => topMargin} 0 0;
  display: flex;
  flex-wrap: wrap;
`;

export const CategoriesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 2.5rem 0 0;

  @media (min-width: 650px) {
  grid-template-columns: repeat(2, 1fr);

  }

  @media (min-width: 900px) {
  grid-template-columns: repeat(3, 1fr);
    
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0.109rem 0.219rem 0.109rem 0;
`;

export const H2Wrapper = styled.div`
  margin: 0.875rem 0;
`;

export const IconWrapper = styled.div`
  margin-left: 0.5rem;
  margin-top: 0.01rem;
`;

export const ButtonContentStyled = styled.div`
  display: flex;
  color: ${colours.colour6};
`;

export const AccrodionBodyStyled = styled.div`
  border: 1px solid ${colours.colour9};
  width: 100%;
`;

export const CheckBoxStyled = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${colours.colour9};
`;

export const DivStyled = styled.div<DivStyledProps>`
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  margin: ${({ margin }) => margin || ''};
`;

export const LineStyled = styled.div`
  border-bottom: 1px solid ${colours.colour17};
  height: 1px; 
  width: 25%;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.875rem 0 2.5rem;
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 0.875rem 0 2.5rem;
  justify-content: center;
`;
