import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type ButtonStyledProps = {
  readonly theme?: string
}

type FlexWrapperProps = {
  readonly alignItems?: string
  readonly increaseMargin?: boolean
}

export const ButtonTextWrapper = styled.div`
  margin-left: 0.425rem;
`;

export const ButtonStyled = styled.button<ButtonStyledProps>`
  transition: all .3s ease;
  cursor: pointer;
  ${({ theme }) => {
    switch (theme) {
      case 'remove':
        return fonts.font1.H13B;
      default:
        return fonts.font1.H14B;
    }
  }}
  display: flex;
  border: none;
  border-bottom: 3px solid transparent;
  background: transparent;
  padding: 0.875rem 0rem;
  margin: ${({ theme }) => {
    switch (theme) {
      case 'remove':
        return '0.5rem 0 0 0.875rem';
      default:
        return '0.875rem 0';
    }
  }};

  color: ${({ theme }) => {
    switch (theme) {
      case 'remove':
        return colours.colour7;
      default:
        return colours.colour24;
    }
  }};

  &:hover {
    border-bottom: 3px solid ${({ theme }) => {
    switch (theme) {
      case 'remove':
        return colours.colour7;
      default:
        return colours.colour24;
    }
  }};
  }

  &:disabled {
    cursor: not-allowed;
    border-bottom: 3px solid transparent;
  }
`;

export const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  margin: ${({ increaseMargin }) => (increaseMargin ? '0.3rem 0' : '')};
`;
