import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlass, faBars, faList,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { imageList } from '../../utils/imageList';
import { Figure } from '../Figure';
import { LinkComponent } from '../Link';
import { Popover } from '../Popover';
import { useCookies } from '../../hooks/useCookies';
import * as Styled from './styles';
import environment from '../../config/environment';
import { TextP } from '../Text';
import Button from '../Button';
import SideDraw from '../SideDraw';

type IProps = {
  isOpen: boolean
  userName: string
  userInitial: string
  setIsOpen: (isOpen: boolean) => void
}

const VolunteerHeader = ({
  userInitial, userName, isOpen, setIsOpen,
}: IProps) => {
  const { deleteCookie } = useCookies();
  const navigate = useNavigate();
  return (
    <Styled.MainHeaderStyled>
      <Styled.MainInnerStyled>
        <LinkComponent url="/dashboard">
          <Styled.LogoWrapper>
            <Figure imageSrc={imageList.logoFull} alt="logo" width="124px" />
          </Styled.LogoWrapper>
        </LinkComponent>
        <Styled.VolunteerHeaderDesktop>
          <Styled.NavStyled>
            <Styled.NavLinkStyled>
              <LinkComponent
                theme="websiteLg"
                url="/dashboard"
              >
                <TextP>My Dashboard</TextP>
              </LinkComponent>
            </Styled.NavLinkStyled>
            <Styled.NavLinkStyled>
              <LinkComponent
                url={`${environment.app.websiteURL}/studies`}
                theme="websiteLg"
                newWindow
              >
                <TextP> Find Studies </TextP>
              </LinkComponent>
            </Styled.NavLinkStyled>
          </Styled.NavStyled>
          <Styled.PopoverWrapper>
            <Popover
              buttonLabel="Menu"
              buttonWidth="44px"
              openOnHover
              location="bottomCenter"
              buttonTheme="plain"
              flexDirection="column"
              icon={<FontAwesomeIcon icon="bars" />}
            >
              <>
                <Button
                  id="my-profile"
                  theme="menuButton"
                  onClick={() => { navigate('/myProfile'); }}
                  buttonWidth="100%"
                >
                  <p>My Profile</p>
                </Button>
                <Button
                  id="menu-logout"
                  theme="menuButton"
                  onClick={() => { deleteCookie(environment.app.cookieName); navigate('/login'); }}
                  buttonWidth="100%"
                >
                  <p>Logout</p>
                </Button>
              </>
            </Popover>
          </Styled.PopoverWrapper>
        </Styled.VolunteerHeaderDesktop>

        <Styled.VolunteerHeaderMobile>
          <Button id="menu" onClick={() => setIsOpen(!isOpen)} theme="menuButton">
            <Styled.ButtonTextWrapper>
              <FontAwesomeIcon icon={faBars} size="2x" />
            </Styled.ButtonTextWrapper>
          </Button>
          <SideDraw isOpen={isOpen} setIsOpen={setIsOpen} theme="full" useIcon>
            <Styled.MobileNavWrapper>

              <Styled.MobileMenuOptions>
                <LinkComponent url="/dashboard" theme="modelMenuSecondary" linkWidth="100%">
                  <Styled.MenuOptionWrapper>
                    <Styled.IconStyled>
                      <FontAwesomeIcon icon={faList} />
                    </Styled.IconStyled>
                    <TextP textAlgin="center">My Dashboard</TextP>
                  </Styled.MenuOptionWrapper>
                </LinkComponent>
              </Styled.MobileMenuOptions>

              <Styled.MobileMenuOptions>
                <LinkComponent
                  url={`${environment.app.websiteURL}/studies`}
                  theme="modelMenuSecondary"
                  linkWidth="100%"
                  newWindow
                >
                  <Styled.MenuOptionWrapper>
                    <Styled.IconStyled>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Styled.IconStyled>
                    <TextP textAlgin="center">Find Studies</TextP>
                  </Styled.MenuOptionWrapper>
                </LinkComponent>
              </Styled.MobileMenuOptions>

              <div>
                <Styled.SubHeaderStyled>
                  <Styled.IconStyled>
                    <Styled.InitialsStyled>
                      {userInitial}
                    </Styled.InitialsStyled>
                  </Styled.IconStyled>
                  <TextP textAlgin="center">{userName}</TextP>
                </Styled.SubHeaderStyled>

                <LinkComponent
                  theme="modelMenuSecondary"
                  url="/myProfile"
                >
                  <Styled.MenuOptionWrapper>
                    <Styled.SpaceDivStyled />
                    <TextP>View Profile</TextP>
                  </Styled.MenuOptionWrapper>
                </LinkComponent>
                <Button
                  id="menu-logout"
                  theme="modelMenuSecondary"
                  onClick={() => { deleteCookie(environment.app.cookieName); navigate('/login'); }}
                  buttonWidth="100%"
                >
                  <Styled.MenuOptionWrapper>
                    <Styled.SpaceDivStyled2 />
                    <TextP>Log Out</TextP>
                  </Styled.MenuOptionWrapper>
                </Button>
              </div>

            </Styled.MobileNavWrapper>
          </SideDraw>
        </Styled.VolunteerHeaderMobile>
      </Styled.MainInnerStyled>
    </Styled.MainHeaderStyled>
  );
};

export default VolunteerHeader;
