import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  HealthResearchAreaWrapper, SelectedAreaWrapper, CategoriesWrapper, ButtonWrapper,
  IconWrapper, ButtonContentStyled, AccrodionBodyStyled, CheckBoxStyled,
} from './styles';
import Button from '../../../../components/Button';
import Accrodion from '../../../../components/Accrodion';
import { Checkbox } from '../../../../components/CheckBox';

type VolunteerHealthResearchAreasProps = {
  categoriesMap: { category: string, items: {id: number, item: string, category: string}[] }[]
  selectedResearchAreas: { category: string, id: number, name: string }[]
  disabled?: boolean
  handleChange: (name: string, id: number, category: string) => void
}
const VolunteerHealthResearchAreas = ({
  categoriesMap, selectedResearchAreas, disabled, handleChange,
}: VolunteerHealthResearchAreasProps) => {
  const [openAccrodion, setOpenAccrodion] = useState('');

  const getCategoryCount = (categoryName: string) => {
    if (!selectedResearchAreas) return 0;
    const list = Object.values(selectedResearchAreas).filter((area: { name: string, category: string, id: number}) => categoryName === area.category);
    return list.length;
  };

  const formatCategory = (name: string) => {
    const nameArr = name.split(' ');
    const newNames = nameArr.map((category) => category[0] + category.substring(1).toLowerCase());
    return newNames.join(' ');
  };

  return (
    <HealthResearchAreaWrapper hasBorder={false}>
      <SelectedAreaWrapper
        isEmpty={!Object.keys(selectedResearchAreas).length}
        topMargin="0"
      >
        {Object.values(selectedResearchAreas).map((area) => (
          <ButtonWrapper key={area.id}>
            <Button
              id={area.name}
              onClick={() => handleChange(area.name, area.id, area.category)}
              theme="tag"
              buttonWidth="fit-content"
              disabled={disabled}
            >
              <ButtonContentStyled>
                {area.name}
                <IconWrapper>
                  <FontAwesomeIcon icon="xmark" />
                </IconWrapper>
              </ButtonContentStyled>
            </Button>
          </ButtonWrapper>
        ))}
      </SelectedAreaWrapper>
      <CategoriesWrapper>
        {categoriesMap.map((value) => (
          <div key={`${value.category}-count`}>
            <Accrodion
              isOpen={openAccrodion === value.category}
              label={formatCategory(value.category)}
              count={getCategoryCount(value.category)}
              setIsOpen={() => { setOpenAccrodion(openAccrodion === value.category ? '' : value.category); }}
              disabled={disabled}
            >
              <AccrodionBodyStyled>
                {value.items.map((area: {id: number, item: string, category: string}) => (
                  <CheckBoxStyled key={area.id}>
                    <Checkbox
                      id={area.id.toString()}
                      key={area.id}
                      theme="small"
                      label={area.item}
                      isChecked={selectedResearchAreas.some((selected) => selected.id === area.id)}
                      onChange={() => handleChange(area.item, area.id, area.category)}
                      disabled={disabled}
                    />
                  </CheckBoxStyled>
                ))}
              </AccrodionBodyStyled>
            </Accrodion>
          </div>
        ))}
      </CategoriesWrapper>
    </HealthResearchAreaWrapper>
  );
};

export default VolunteerHealthResearchAreas;
