export const PPO_PRINCIPAL_INVESTIGATOR = {
  TITLE: 'Principal Investigator & Study Team Members',
  PI: {
    LABEL: 'Principal Investigator',
    LABEL_DESCRIPTION: "Enter Principal Investigator's email. The PI must have a REACH BC Researcher Account and be listed as the PI on the REB certificate.",
    PLACEHOLDER: 'e.g. name@mail.com',
  },
  ADDITIONAL_TEAM: {
    LABEL: 'Additional Team Members - REACH BC Access',
    LABEL_DESCRIPTION: "Enter other team member's email. Add team members who require the ability to view and edit the posting or manage any matched Volunteers.",
    PLACEHOLDER: 'e.g. name@mail.com',
    CHECKBOX_LABEL: 'Send automated emails',
    BUTTONS: {
      ADD: 'Add a team member',
    },
  },
  COLLABORATORS: {
    TITLE: 'Collaborators',
    DESCRIPTION: "Enter Principal Investigator's email. The PI must have a REACH BC Researcher Account and be listed as the PI on the REB certificate.",
    BUTTON: 'Add a Collaborator',
  },
  PATIENT_INITIATED: {
    TITLE: 'Patient-initiated Research',
    DESCRIPTION: 'If this is a patient-initiated research project, select “patient-initiated research” from health research categories section at the end of this form. Patient-initiated research is defined as a project that comes from a patient or a group of patients who have a research topic or question that they would like to explore and who partner on a team comprised of various members with complementary knowledge and expertise.',
  },
  PATIENT_PARTNER: {
    LABEL: 'Patient Partner Co-Investigator Name',
    DESCRIPTION: 'If a patient partner is a co-investigator on this project and they would like to have their information publicly displayed, enter first and last name. If not, you can leave this blank.',
  },
  AFFILIATED_ORGANIZTION: {
    LABEL: 'Patient Partner Affiliated Organization',
    DESCRIPTION: "Enter the patient partner's affiliated organization if applicable.",
  },
};
