export const OPPRTUNITY_LOCATIONS = {
  TITLE: 'Opportunity Location',
  SUB_TEXT: 'Where are you seeking Volunteers from?',
  REGION_SECTION: {
    SUB_TITLE: 'Applicable Regions',
    DESCRIPTION: 'Select all that apply.',
    LINK: {
      LABEL: 'Learn about the applicable regions',
      URL: 'https://assets.ctfassets.net/rbx11ml43wqx/5BYPxokCeDsg9mFH5fONER/a8d6e2a1de79d3af251ccaeb49ac35df/REACH_BC_-_BC_Regions.pdf',
    },
  },
  ONLINE_SECTION: {
    SUB_TITLE: 'Is this opportunity an online or remote opportunity?',
    LABEL: 'Yes, this is an online or remote opportunity.',
    DESCRIPTION: 'E.g. No in-person participation?',
  },
  HOSPITAL: {
    LABELS: {
      HOSPITAL: 'Hospital or Institution',
      BUILDING: 'Building',
      DEPARTMENT: 'Department',
      STREET: 'Street Address',
      CITY: 'City',
      POSTAL_CODE: 'Postal Code',
    },
    DESCRIPTIONS: {
      HOSPITAL: 'Please enter the address of the primary physical location that you would like shared in the posting.',
      BUILDING: 'Please enter the address of the primary physical location that you would like shared in the posting. (Max character limit is 120)',
      DEPARTMENT: 'Please enter the address of the primary physical location that you would like shared in the posting. (Max character limit is 120)',
      STREET: 'Please enter the address of the primary physical location that you would like shared in the posting. (Max character limit is 120)',
      CITY: 'Please enter the address of the primary physical location that you would like shared in the posting. (Max character limit is 120)',
      POSTAL_CODE: 'Please enter the address of the primary physical location that you would like shared in the posting.',
    },
    PLACEHOLDER: {
      HOSPITAL: 'Choose a hospital or institution',
      POST_CODE: 'e.g. V5Z 1M9',
    },
  },
  ONLINE_STUDY: {
    SUB_TITLE: 'Does this opportunity require only one direct online opportunity link?',
    DESCRIPTION: "This means that instead of the research teams contacting volunteers using the info provided via REACH BC, volunteers will be directed to your participation link when they click on 'I'm interested'.",
    DESCRIPTION_TWO: '** If this is a study, the links must be REB approved. **',
    LABEL: 'Yes, this opportunity requires a participation link?',
  },
  DIRECT_LINK: {
    LABEL: 'Online opportunity link URL',
    DESCRIPTION: 'Please enter one online opportunity link URL.',
    PLACEHOLDER: 'e.g. https://www.example.com',
  },
};
