export const MANAGER_VOLUNTEER_TABLE = {
  NOTIFICATOIN: {
    DESCRIPTION: [
      "REACH BC will be anonymizing volunteer information for enhanced privacy and security once the study status is changed to 'Project Completed'",
    ],
  },
  INPUTS: {
    FILTER: {
      LABEL: 'Filter by:',
    },
    SEARCH: {
      PLACEHOLDER: 'Search for Volunteer name or email',
    },
    ACTION: {
      LABEL: 'Action:',
      PLACEHOLDER: 'Follow-up Status',
    },
  },
  BUTTON: {
    CLEAR: 'Clear Selections',
    DEFAULT_POPOVER: 'Follow-Up Status',
    POPOVER_SELECTED: '',
  },
};
