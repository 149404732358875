import React from 'react';
import FormSection from '../../../FormSelection';
import { TextArea } from '../../../TextArea.tsx';
import { COMMENTS } from './const';

type AdminCommentsProps = {
  comments: string
  errors: { [key: string]: string }
  canEdit: boolean
  handleChange: (value: string, field: string) => void
}
const AdminComments = ({
  comments, errors, canEdit, handleChange,
}: AdminCommentsProps) => (
  <FormSection title={COMMENTS.TITLE} theme="secondary">
    <TextArea
      id="comments"
      description={COMMENTS.DESCRIPTION}
      descriptionList={COMMENTS.DESCRIPTION_LIST}
      description2={[COMMENTS.DESCRIPTION_TWO]}
      listIsSecond
      textValue={comments}
      theme="form"
      setTextValue={(value) => handleChange(value, 'comments')}
      error={errors.comments}
      isRequired={false}
      isDisabled={!canEdit}
    />
  </FormSection>
);

export default AdminComments;
