export const OPPORTUNITY_DETAILS_FORM = {
  TITLE: 'Opportunity Details',
  STUDY_TITLE: {
    LABEL: 'Study Title',
    LABEL_DESCRIPTION: 'The full title of the research study (i.e. Protocol title). (Max character limit is 500).',
  },

  NICK_NAME: {
    LABEL: 'Opportunity Headline',
    DESCRIPTION: 'Give your study a catchy lay-friendly headline that will attract Volunteers.',
    DESCRIPTION_SECONDARY: "E.g. Can we predict Alzheimer's disease?.  This will also be used as your quick reference to access the study on your dashboard. (Max character limit is 90)",
  },
  RESEARCH_RELATED: {
    LABEL: 'What type of PPO research is this?',
    PLACEHOLDER: 'Choose a type of related research',
  },
};
