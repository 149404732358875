import styled from 'styled-components';
import { fonts } from '../../../../styles/theme';

type TextWrapperProps = {
  readonly type?: string
}

export const LinkContentWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

export const IconWrapper = styled.span`
  margin-right: 0.5rem;
`;

export const LinkWrapper = styled.div`
  margin: 1.875rem 0;
`;

export const LinkButtonWrapper = styled.div`
  display: flex;
`;

export const DetailBarStyled = styled.div`
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  border-bottom: 3px solid #ff7900;
  border-top: 3px solid #ff7900;
  color: rgba(0,0,0,.85);
  font-size: 3rem;
  font-weight: 700;
  height: 0;
  letter-spacing: .05em;
  line-height: 1;
  margin: 1rem 0;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1.5em;
`;

export const TextWrapper = styled.div <TextWrapperProps>`
    margin: ${({ type }) => {
    switch (type) {
      case 'header':
        return '0.875rem 0';
      case 'subHeader':
        return '1.5rem 0 0.875rem';
      case 'link':
        return '0 0 0.875rem';
      case 'button':
        return '0 0 0 1rem';
      case 'text':
        return '0 0 1.375rem';
      default:
        return '0 0 1.375rem';
    }
  }};
`;

export const SectionStyled = styled.section`
  margin: 0.875rem;

  @media (min-width: 768px) {
    margin: 0.875rem 0;
  }
`;

export const ButtonSectionStyled = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0.875rem 0;
  padding-right: 0.875rem;

  @media (min-width: 600px) {
    padding-right: 0;
  justify-content: flex-end;

  }
`;

export const BoldTextStyled = styled.span`
  ${fonts.font1.H14B}
`;

export const PageWrapper = styled.div`
  padding-top: 2.25rem;

  @media (min-width: 600px) {
    padding: 0.875rem;
  }
`;
