import styled from 'styled-components';
import toRem from '../../../utils/toRem';
import { fonts } from '../../../styles/theme';

export const HeroWrapper = styled.div`
  padding: ${toRem(40)} ${toRem(16)} ${toRem(24)};
  ${fonts.font1.H24B};
`;

export const SearchBarWrapper = styled.div`
  margin-top: ${toRem(24)};
`;
