import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
import { ErrorMessage } from '../ErrorMessage';
import PublicToolTip from '../PublicTooltip';
import { TextP } from '../Text';
import * as Styled from './styles';
import { RequiredForm } from '../RequiredForm';
import { NotVisible } from '../NotVisible';

type TextInputProps = {
  label?: string;
  placeholder?: string;
  description?: string;
  descriptionSecondary?: string;
  isRequired?: boolean;
  textValue?: string,
  setTextValue: (textValue: string) => void
  onBlurAction?: () => void
  theme?: string
  type?: string
  error?: string
  inputWidth?: string
  isPublic?: boolean
  isNotPublic?: boolean
  id?: string
  itemId?: string
  disabled?: boolean
  handleEnter?: (event: any) => void
  linkError?: { text: string, type: string, url?: string }[]
  maxLength?: number
  isSearchBar?: boolean
}
export const TextInput = ({
  label,
  placeholder,
  description,
  descriptionSecondary,
  isRequired = true,
  textValue,
  setTextValue,
  onBlurAction,
  theme,
  type = 'text',
  error,
  inputWidth,
  isPublic = false,
  id,
  itemId = 'item',
  disabled = false,
  handleEnter,
  linkError,
  maxLength,
  isNotPublic = false,
  isSearchBar = false,
}: TextInputProps) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  return (
    <Styled.TextinputWrapper id={id} width={inputWidth} isPublic={isPublic}>
      {label && (
        <Styled.LabelStyled htmlFor={`id-${label}`} theme={theme}>
          {label}
          {isPublic && <PublicToolTip />}
          {isRequired
            && <RequiredForm />}
          {isNotPublic && <NotVisible />}
        </Styled.LabelStyled>
      )}
      {description && (
        <Styled.DescriptionWrapper>
          <TextP>{description}</TextP>
          {descriptionSecondary && (
            <TextP>{descriptionSecondary}</TextP>
          )}
        </Styled.DescriptionWrapper>
      )}
      <Styled.InputWrapper>
        {isSearchBar && (
          <Styled.IconWrapper iconPosition="left" theme={theme}>
            <FontAwesomeIcon icon="magnifying-glass" />
          </Styled.IconWrapper>
        )}
        <Styled.InputStyled
          type={!passwordVisibility ? type : 'text'}
          id={`id-${itemId}-${label}`}
          placeholder={placeholder}
          autoComplete="off"
          value={textValue}
          onChange={(event) => {
            setTextValue(event.target.value);
          }}
          onKeyDown={(event: any) => {
            if (event.key === 'Enter' && handleEnter) {
              event.preventDefault();
              handleEnter(event);
            }
          }}
          onBlur={() => {
            if (onBlurAction) {
              onBlurAction();
            }
          }}
          theme={theme}
          disabled={disabled}
          maxLength={maxLength}
        />
        {type === 'password' && (
          <Styled.IconWrapper theme="inputIcon">
            <Button
              id={`${itemId} - passwordIcon`}
              onClick={(event: React.FormEvent) => {
                event.preventDefault();
                setPasswordVisibility(!passwordVisibility);
              }}
              theme="inputIcon"
            >
              <p>{passwordVisibility ? 'Hide' : 'Show'}</p>
            </Button>
          </Styled.IconWrapper>
        )}
      </Styled.InputWrapper>
      <Styled.LengthCounterStyled hasError={!!(error || linkError)}>
        {(error || linkError) && <ErrorMessage message={error || ''} linkError={linkError} />}
        {maxLength && (
          <Styled.CounterTextStyled>
            Character count: <span>{textValue?.length}/{maxLength}</span>
          </Styled.CounterTextStyled>
        )}
      </Styled.LengthCounterStyled>
    </Styled.TextinputWrapper>
  );
};
