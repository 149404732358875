import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from './styles';

type SideBarItemProps = {
  icon?: any
  subTitle: string
  theme?: 'secondary'
  children?: React.ReactNode;
}
const SideBarItem = ({
  icon, subTitle, children, theme,
}: SideBarItemProps) => (
  <Styled.SideBarItemStyled>
    <Styled.IconWrapper>
      {icon && (
        <FontAwesomeIcon icon={icon} />
      )}
    </Styled.IconWrapper>
    <Styled.SideBarItemBodyStyled>
      <Styled.SubTitleStyled theme={theme}>{subTitle}</Styled.SubTitleStyled>
      {children}
    </Styled.SideBarItemBodyStyled>
  </Styled.SideBarItemStyled>
);

export default SideBarItem;
