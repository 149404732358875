import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useProject } from './useProject';
import { useResults } from './useResults';
import { useUser } from './useUser';

export const useProjectDetails = () => {
  const params = useParams();
  const { getUserInfo, user } = useUser();
  const {
    project,
    setProject,
    isLoading,
    getProjectInfo,
    principalInvestigator,
    getPrincipalInvestigator,
    setPrincipalInvestigator,
    interested,
    getInterestedVolunteers,
    filterVolunteerHandler,
    handleFollowUpUpdate,
    enrollentStatusFilter,
    interestSearchValue,
    setInterestSearchValue,
    selectedInterests,
    setSelectedInterestsHandler,
    updateProjectInterestsHandler,
  } = useProject();

  const { result, getProjectResult } = useResults();

  const [currentTab, setCurrentTab] = useState('Detail');
  const [researcherTabOptions, setResearcherTabOptions] = useState(['Detail']);

  const hideButtonStatus = ['closed_recruitment', 'on_hold', 'completed_project', 'pending_approval'];

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (user?.type === 'researcher' && project.access) {
      setResearcherTabOptions(['Detail', 'Manage Volunteers', 'Results']);
    } else if (project.resultId) {
      setResearcherTabOptions(['Detail', 'Results']);
    } else {
      setResearcherTabOptions(['Detail']);
    }
  }, [project]);

  return {
    user,
    params,
    project,
    setProject,
    getProjectInfo,
    isLoading,
    currentTab,
    setCurrentTab,
    researcherTabOptions,
    principalInvestigator,
    getPrincipalInvestigator,
    setPrincipalInvestigator,
    interested,
    getInterestedVolunteers,
    handleFollowUpUpdate,
    filterVolunteerHandler,
    result,
    getProjectResult,
    hideButtonStatus,
    enrollentStatusFilter,
    interestSearchValue,
    setInterestSearchValue,
    selectedInterests,
    setSelectedInterestsHandler,
    updateProjectInterestsHandler,
  };
};
