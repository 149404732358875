import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type LabelStyledProps = {
  readonly isRequired: boolean;
  readonly smallFont?: boolean;
  readonly mbFont?: boolean
  readonly unbold?: boolean;
};

type InputWrapperProps = {
  readonly theme: string
}

type InputStyledProps = {
  readonly padding?: string
  readonly theme?: string
}

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  flex-direction: ${({ theme }) => (theme === 'row' ? 'row' : 'column')};
  align-items: ${({ theme }) => (theme === 'row' ? 'center' : '')};
`;

export const LabelStyled = styled.label<LabelStyledProps>`
  ${({ smallFont, mbFont }) => {
    if (smallFont) return fonts.font1.H13;
    if (mbFont) return fonts.font1.H16B;
    return fonts.font1.H17B;
  }};
  font-weight: ${({ unbold }) => (unbold ? 500 : 700)};
  text-transform: capitalize;
  line-height: 1.33rem;
  color: ${colours.colour6};
  margin: 0.25rem 0;
`;

export const LabelTextStyled = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const InputStyled = styled.input<InputStyledProps>`
  ${fonts.font1.H14};
  line-height: 0.9rem;
  margin-top: 0.475rem;

  border: ${({ theme }) => (theme === 'secondary' ? `1px solid ${colours.colour9}` : `1px solid ${colours.colour5}`)};
  border-radius: ${({ theme }) => (theme === 'secondary' ? 0 : '0.25rem')};

  padding: ${({ padding }) => {
    switch (padding) {
      case 'md':
        return '0.875rem';
      case 'normal':
        return '0.67857143rem 1rem';
      default:
        return '0.3em 0.5em';
    }
  }};

  &:disabled {
    cursor: not-allowed;
    color: ${colours.colour6};
    border: 1px solid ${colours.colour6};  
    background: ${colours.colour39};
  }
`;

export const ParagrahStyled = styled.p`
  ${fonts.font1.H14}
  overflow-wrap: break-word;
`;

export const TextWrapper = styled.div`
  margin-bottom: 0.875rem;
`;
