import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from '../styles';
import { Checkbox } from '../../../CheckBox';
import { ErrorMessage } from '../../../ErrorMessage';
import FormRemoveableInput from '../../../FormRemoveableInput';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { TextP } from '../../../Text';
import { STUDY_AFFIATIONS } from './const';
import Button from '../../../Button';

type AffiliationsFromProps = {
  collaboratingOrganizations: { [key: string]: string }
  bcSupportUnit: boolean
  regionalCentersList: { [key: string]: string }
  selectedRegionalCenters: string[]
  errors: { [key: string]: string }
  canEdit: boolean
  handleAddInput: (key: string, maxNumber?: number) => void
  handleChangeAddedInput: (value: string, id: string, key: string) => void
  handleRemoveInput: (key: string, id: string) => void
  handleToggle: (value: boolean, field: string) => void
  handleCheck: (value: boolean, field: string, key: string) => void
}

const AffiliationsForm = ({
  collaboratingOrganizations, handleAddInput, handleChangeAddedInput, handleRemoveInput, bcSupportUnit,
  handleToggle, regionalCentersList, handleCheck, selectedRegionalCenters, errors, canEdit,
}: AffiliationsFromProps) => (
  <FormSection title={STUDY_AFFIATIONS.TITLE}>
    <Styled.SectiontWrapper id="collaborating_organizations">
      <FormSubtitle subtitle={STUDY_AFFIATIONS.ORGANIZATIONS.LABEL} />
      <TextP lineHeight="1.33rem">{STUDY_AFFIATIONS.ORGANIZATIONS.LABEL_DESCRIPTION}</TextP>
      {Object.keys(collaboratingOrganizations).map((organizations: any) => (
        <React.Fragment key={organizations}>
          <FormRemoveableInput
            id={organizations}
            field="collaboratingOrganizations"
            textValue={collaboratingOrganizations[organizations]}
            handleChange={handleChangeAddedInput}
            handleRemoveInput={handleRemoveInput}
            disabled={!canEdit}
          />
        </React.Fragment>
      ))}

      <Styled.ButtonWrapper>
        <Button
          id="add-collaborating-Organizations"
          onClick={() => {
            handleAddInput('collaboratingOrganizations');
          }}
        >
          <Styled.ButtonStyled>
            <FontAwesomeIcon icon="plus" />
            <Styled.ButtonTextWrapper>
              {STUDY_AFFIATIONS.ORGANIZATIONS.BUTTON}
            </Styled.ButtonTextWrapper>
          </Styled.ButtonStyled>
        </Button>
      </Styled.ButtonWrapper>

    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper isLast>
      <FormSubtitle
        subtitle={STUDY_AFFIATIONS.SUPPORT_UNIT.TITLE}
        margin="small"
        link={{
          url: STUDY_AFFIATIONS.SUPPORT_UNIT.LINK_URL,
          label: STUDY_AFFIATIONS.SUPPORT_UNIT.LINK,
        }}
      />
      <Styled.TextWrapper>
        <TextP lineHeight="1.33rem">{STUDY_AFFIATIONS.SUPPORT_UNIT.DESCRIPTION}</TextP>
      </Styled.TextWrapper>
      <Styled.FlexWrapper id="bc_support_unit">
        <Checkbox
          label={STUDY_AFFIATIONS.SUPPORT_UNIT.CHECKBOX_LABEL}
          labelTheme="secondary"
          isChecked={bcSupportUnit}
          onChange={() => handleToggle(!bcSupportUnit, 'bc_support_unit')}
          disabled={!canEdit}
          textTransform="none"
        />
      </Styled.FlexWrapper>

      {bcSupportUnit && (
        <Styled.ButtonWrapper>
          <TextP lineHeight="1.33rem">{STUDY_AFFIATIONS.REGIONAL_CENTRE.LABEL}</TextP>
          <Styled.CheckboxWrapper>
            <Checkbox
              label={STUDY_AFFIATIONS.REGIONAL_CENTRE.ALL_LABEL}
              isChecked={selectedRegionalCenters.length === Object.keys(regionalCentersList).length}
              onChange={(check) => handleCheck(check, 'All', 'regionalCenters')}
            />
          </Styled.CheckboxWrapper>
          {Object.keys(regionalCentersList).map((region) => (
            <Styled.CheckboxWrapper key={region}>
              <Checkbox
                label={regionalCentersList[region]}
                isChecked={selectedRegionalCenters.includes(region)}
                onChange={(check) => handleCheck(check, region, 'regionalCenters')}
              />
            </Styled.CheckboxWrapper>
          ))}
          {errors.regional_centers && <ErrorMessage message={errors.regional_centers} />}
        </Styled.ButtonWrapper>
      )}
    </Styled.SectiontWrapper>

  </FormSection>
);

export default AffiliationsForm;
