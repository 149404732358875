import styled from 'styled-components';
import { colours } from '../../styles/theme';

type MainStyledProps = {
  readonly theme?: string
}

export const MainHeaderStyled = styled.header`
  border-top: 3px solid ${colours.colour1};
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  width: 100%;
`;

export const MainHeaderInnerStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const LogoWrapper = styled.div`
  padding: 1.563rem 0.875rem;
  max-width: 161px;
`;

export const ButtonLoginWrapper = styled.div<MainStyledProps>`
  padding: 1px;
  display: flex;
  flex-direction: column;
  align-items: ${({ theme }) => {
    switch (theme) {
      case 'left':
        return 'flex-start';
      default:
        return 'center';
    }
  }};
`;

export const ButtonContainerWrapper = styled.div<MainStyledProps>`
  display: flex;
`;

export const LoginHeaderButtonWrapper = styled.div`
  margin-right: 0.625rem;
`;

export const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 820px) {
    display: flex;
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  padding-right: 0.875rem;

  @media (min-width: 820px) {
    display: none;
  }
`;

export const ButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MobileDivWrapper = styled.div`
  margin-bottom: 1.875rem;
`;

export const MobileItemWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const LinkStyled = styled.div`
  width: fit-content;
`;

export const DrawBodyWrapper = styled.div`
  padding: 1.875rem;
`;
