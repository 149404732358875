import React from 'react';
import FormSection from '../../../FormSelection';
import { TextArea } from '../../../TextArea.tsx';
import { PPO_COMMENTS } from './const';

type OpportunityCommentsProps = {
  comments: string
  canEdit: boolean
  errors: { [key: string]: string }
  handleChange: (value: string, field: string) => void
}
const OpportunityComments = ({
  comments, canEdit, errors, handleChange,
}: OpportunityCommentsProps) => (
  <FormSection title={PPO_COMMENTS.TITLE} theme="secondary">
    <TextArea
      id="comments"
      description={PPO_COMMENTS.DESCRIPTION}
      descriptionList={PPO_COMMENTS.DESCRIPTION_LIST}
      description2={[PPO_COMMENTS.DESCRIPTION_TWO]}
      listIsSecond
      textValue={comments}
      setTextValue={(value) => handleChange(value, 'comments')}
      isDisabled={!canEdit}
      error={errors.comments}
    />
  </FormSection>
);

export default OpportunityComments;
