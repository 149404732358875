import styled from 'styled-components';

export const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 770px) {
    display: block;
  }
`;

export const MobileWrapper = styled.div`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 42rem;

  @media (min-width: 770px) {
    display: none;
  }
`;

export const FormWrapper = styled.div`
  margin: 0 auto;
  max-width: 100%;

  @media (min-width: 800px) {
    padding: 0;
    margin: 0;
  }
`;
