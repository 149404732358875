export const RESEARCHER_PROFILE_INFO = {
  TITLE: 'Personal Information and Contact Preferences',
  DESCRIPTION: 'Tell us a little about yourself and how we can keep in touch.',

  FIRST_NAME: {
    LABEL: 'First Name',
  },
  LAST_NAME: {
    LABEL: 'Last Name',
  },

  PHONE: {
    LABEL: 'Phone Number',
    DESCRIPTION: 'Please enter numbers only. Example: 6045555555',
  },
  EXTENSION: {
    LABEL: 'Extension',
  },
  MFA: {
    LABEL: 'Two-Factor (2FA) Authentication Set Up',
    DESCRIPTION: 'Your mobile phone will be used to receive a code must be entered during the login process for added security',
    EDIT_MFA: {
      LABEL: 'To edit your mobile phone number, please contact',
      LINK: {
        URL: 'admin@reachbc.ca',
        LABEL: 'admin@reachbc.ca',
      },
    },
  },
  EMAIL: {
    LABEL: 'Email',
    EDIT_EMAIL: {
      LABEL: 'To update your email address, please contact ',
      LINK: {
        URL: 'admin@reachbc.ca',
        LABEL: 'admin@reachbc.ca',
      },
    },
  },

  POSITION: {
    LABEL: 'Position/Title',
  },
  POSITION_OTHER: {
    LABEL: "If 'Other', please specify",
  },
  AFFILIATION: {
    LABEL: 'What is your primary health authority affiliation?',
    PLACEHOLDER: 'Select your health authority affiliation',
  },
  UNIVERSITY: {
    LABEL: 'What is your university affiliation?',
    PLACEHOLDER: 'Select your university affiliation',
  },
  UNIVERSITE_OTHER: {
    LABEL: "If 'Other', please specify",
  },
  NEWS_EVENTS: {
    LABEL: 'I would like to receive news and events related to REACH BC.',
  },
  TERMS: {
    LABEL: 'I have read and accept the Researcher Confidentiality Undertaking and Terms of Use below.',
  },
  TERMS_LINK: {
    LABEL: 'Researcher Confidentiality Undertaking and Terms of Use',
    URL: '/researcher-confidentiality-undertaking',
  },
  BUTTONS: {
    SUBMIT: 'Submit Edit',
  },
};
