import React from 'react';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { TextP } from '../../../Text';
import { TextArea } from '../../../TextArea.tsx';
import { FlexWrapper, SectiontWrapper, TextWrapper } from '../styles';
import { ESTIMATED_COMMITMENT } from './const';
import { Checkbox } from '../../../CheckBox';

type EstimatedCommitmentProps = {
  commitment: string
  compensation: boolean
  expenses: boolean
  proceduresRequired: boolean
  proceduresDescription: string
  errors: { [key: string]: string }
  canEdit: boolean
  handleChange: (value: string, field: string) => void
  handleToggle: (value: boolean, field: string) => void
  handleSingleValidation: (value: string, field: string) => void
}

const EstimatedCommitment = ({
  commitment, proceduresDescription, proceduresRequired, compensation, expenses, errors, canEdit, handleSingleValidation,
  handleChange, handleToggle,
}: EstimatedCommitmentProps) => (
  <FormSection title={ESTIMATED_COMMITMENT.TITLE}>
    <SectiontWrapper id="commitment">
      <TextArea
        label={ESTIMATED_COMMITMENT.COMMITMENT.LABEL}
        description2={ESTIMATED_COMMITMENT.COMMITMENT.DESCRIPTION}
        isPublic
        isRequired
        textValue={commitment}
        setTextValue={(value) => handleChange(value, 'commitment')}
        error={errors.commitment}
        isDisabled={!canEdit}
        theme="form"
        onBlurAction={() => handleSingleValidation(commitment, 'commitment')}
        maxLength={300}
      />
    </SectiontWrapper>
    <SectiontWrapper id="procedures_required">
      <FormSubtitle subtitle={ESTIMATED_COMMITMENT.PROCEDURE.LABEL} margin="small" />
      <TextWrapper>
        <TextP lineHeight="1.33rem">{ESTIMATED_COMMITMENT.PROCEDURE.DESCRIPTION}</TextP>
      </TextWrapper>
      <FlexWrapper>
        <Checkbox
          label={ESTIMATED_COMMITMENT.PROCEDURE.CHECKBOX_LABEL}
          isChecked={proceduresRequired}
          onChange={() => handleToggle(!proceduresRequired, 'procedures_required')}
          labelTheme="secondary"
          disabled={!canEdit}
          textTransform="none"
        />
      </FlexWrapper>

      {proceduresRequired && (
        <TextArea
          label=""
          textValue={proceduresDescription}
          setTextValue={(value) => handleChange(value, 'procedures_description')}
          error={errors.procedures_description}
          isDisabled={!canEdit}
          onBlurAction={() => handleSingleValidation(proceduresDescription, 'procedures_description')}
          maxLength={450}
        />
      )}
    </SectiontWrapper>

    <SectiontWrapper id="compensation">
      <FormSubtitle subtitle={ESTIMATED_COMMITMENT.COMPENSATION.LABEL} margin="small" />
      <TextWrapper>
        <TextP lineHeight="1.33rem">{ESTIMATED_COMMITMENT.COMPENSATION.DESCRIPTION}</TextP>
      </TextWrapper>
      <FlexWrapper>
        <Checkbox
          label={ESTIMATED_COMMITMENT.COMPENSATION.CHECKBOX_LABEL}
          isChecked={compensation}
          labelTheme="secondary"
          onChange={() => handleToggle(!compensation, 'compensation')}
          disabled={!canEdit}
          textTransform="none"
        />
      </FlexWrapper>
    </SectiontWrapper>

    <SectiontWrapper id="expenses" isLast>
      <FormSubtitle subtitle={ESTIMATED_COMMITMENT.REIMBURSED.LABEL} margin="small" />
      <TextWrapper>
        <TextP lineHeight="1.33rem">{ESTIMATED_COMMITMENT.REIMBURSED.DESCRIPTION} </TextP>
        <TextP lineHeight="1.33rem">{ESTIMATED_COMMITMENT.REIMBURSED.DESCRIPTION_TWO} </TextP>
      </TextWrapper>
      <FlexWrapper>
        <Checkbox
          label={ESTIMATED_COMMITMENT.REIMBURSED.CHECKBOX_LABEL}
          isChecked={expenses}
          onChange={() => handleToggle(!expenses, 'expenses')}
          labelTheme="secondary"
          disabled={!canEdit}
          textTransform="none"
        />
      </FlexWrapper>
    </SectiontWrapper>
  </FormSection>
);

export default EstimatedCommitment;
