import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as Styled from './styles';
import { TextP } from '../Text';
import { LinkComponent } from '../Link';

type UserNoticeTypeProps = {
  title: string
  description?: string[]
  link?: {
    label: string
    path: string,
    theme?: string
  }
  secondaryLink?: {
    label: string
    path: string,
  }
  embededLink?: {
    descriptionStart: string
    decriptionLink: {
      url: string
      label: string
    }
    descriptionEnd: string
  }
  icon?: IconProp
}

export const UserNotice = ({
  description, title, link, secondaryLink, embededLink, icon,
}: UserNoticeTypeProps) => (
  <Styled.NoticeStyled>
    <Styled.NoticeHeaderStyled>
      <TextP>
        {icon && <Styled.IconWraper><FontAwesomeIcon icon={icon} /></Styled.IconWraper>}
        {title}
      </TextP>
    </Styled.NoticeHeaderStyled>
    {description && description.map((paraph) => (
      <Styled.NoticeDescriptionStyled key={paraph.slice(0, 10)}>
        {paraph}
      </Styled.NoticeDescriptionStyled>
    ))}

    {embededLink && (
      <Styled.NoticeDescriptionStyled theme="secondary">
        {embededLink.descriptionStart}
        <LinkComponent url={embededLink.decriptionLink.url} theme="secondary">
          {embededLink.decriptionLink.label}
        </LinkComponent>
        {embededLink.descriptionEnd}
      </Styled.NoticeDescriptionStyled>
    )}
    <Styled.LinkWrapperContainer>
      {link && (
        <Styled.LinkWrapperStyled theme={link.theme}>
          <LinkComponent
            url={link.path}
            newWindow
            theme="button"
          >
            <div>
              {link.label}
            </div>
          </LinkComponent>
        </Styled.LinkWrapperStyled>
      )}

      {secondaryLink && (
        <Styled.LinkWrapperStyled>
          <LinkComponent
            url={secondaryLink.path}
            newWindow
            theme="outline"
          >
            <div>
              {secondaryLink.label}
            </div>
          </LinkComponent>
        </Styled.LinkWrapperStyled>
      )}
    </Styled.LinkWrapperContainer>

  </Styled.NoticeStyled>
);
