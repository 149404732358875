export const StudyCriticalFields = [
  'pi_id', // 'Principal Investigator',
  'pi_email', // 'Principal Investigator Email',
  'collaborator', // 'Collaborator',
  'collaborating_organizations', // 'Collaborating Organizations',
  'pp_co_pi', // 'Collaborating Organizations',
  'pp_affiliated_organization',
  'title', // 'Study Title',
  'lay_title', // 'Lay Study Title',
  'nick_name', // 'Study Headline',
  'purpose', // 'Study Details',
  'description', // 'What is Involved',
  'commitment', // 'Estimated Commitment',
  'min_age', // 'Age Requirement',
  'max_age', // 'Age Requirement',
  'years_months', // 'Age Requirement',
  'sex_eligible', // 'Sexes Eligible',
  'genders_eligible', // 'Genders Eligible',
  'inclusion_criteria', // 'Inclusion Criteria',
  'exclusion_criteria', // 'Exclusion Criteria',
  'reb_number', // 'REB certificate number',
  'reb_file', // 'REB File upload',
  'reb_expiry', // 'REB Expiry Date',
  'additional_doc', // 'Additional Supporting Document',
  'procedures_description', // Procedures Description
];

export const PPOCriticalFields = [
  'pi_id', // Principal Investigator
  'pi_email', // 'Principal Investigator Email',
  'pp_co_pi', // Patient Partner Co-investigator Email
  'pp_affiliated_organization', // Patient Partner Affiliated Organization
  'collaborators', // 'Collaborators',
  'collaborating_organizations', // 'Collaborating Organizations',
  'collaboratingOrganizations',
  'title', // 'Study Title/name',
  'nick_name', // 'Study Headline',
  'health_authority_region', // Applicable regions
  'purpose', // 'Study Details',
  'description', // 'What is Involved',
  'orientation_provided', // orientation provided
  'background', // Background
  'commitment', // Estimated Commitment
  'min_age', // 'Age Requirement',
  'max_age', // 'Age Requirement',
  'years_months', // 'Age Requirement',
  'sex_eligible', // 'Sexes Eligible',
  'genders_eligible', // 'Genders Eligible',
  'additional_eligibility', // Additional Eligibility Criteria
];
