import environment from '../../../config/environment';

export const CREATE_RESEARCHER = {
  FIRST_NAME: {
    LABEL: 'First Name',
  },
  LAST_NAME: {
    LABEL: 'Last Name',
  },
  PHONE_NUMBER: {
    LABEL: 'Phone Number',
    DESCRIPTION: 'Please enter numbers only. Example: 6045555555',
  },
  EXTENSION: {
    LABEL: 'Extension',
  },
  MOBILE: {
    LABEL: 'Mobile Phone Number',
    DESCRIPTION: 'Your mobile phone will be used to send a code for two-factor authentication when logging into the application.',
  },
  POSITION: {
    LABEL: 'Position/Title',
    PLACEHOLDER: 'Select your Position/Title',
  },
  POSITION_OTHER: {
    LABEL: "If 'Other', please specify",
  },
  AUTHORITY_AFFILATION: {
    LABEL: 'What is your primary health authority affiliation?',
    PLACEHOLDER: 'Select your health authority affiliation',
  },
  UNIVERSITY: {
    LABEL: 'What is your university affiliation?',
    PLACEHOLDER: 'Select your university affiliation',
  },
  UNIVERSITY_OTHER: {
    LABEL: "If 'Other', please specify",
  },
  LEARNED_ABOUT: {
    LABEL: 'How did you hear about REACH BC?',
    PLACEHOLDER: 'Please select an option',
    OPTIONS: [
      { value: 'Online Search (Google, Bing, etc.)', label: 'Online Search' },
      { value: 'Social Media (Facebook, Twitter, Instagram, etc.)', label: 'Social Media' },
      { value: 'Email', label: 'Email' },
      { value: 'News Article/Blog Post', label: 'News Article/Blog Post' },
      { value: 'Event/Conference', label: 'Event/Conference' },
      { value: 'Webinar/Podcast', label: 'Webinar/Podcast' },
      { value: 'College/University/Tech School', label: 'College/University/Tech School' },
      { value: 'Hospital/Clinic Registration Site', label: 'Hospital/Clinic Registration Site' },
      { value: 'BC Support Unit', label: 'BC SUPPORT Unit' },
      { value: 'Printed Material (Flyer, Brochure, Poster)', label: 'Printed Material' },
      { value: 'Referral from a Friend or Family Member', label: 'Referral from Friend/Family' },
      { value: 'Other Website', label: 'Other Website (Specify if possible)' },
      { value: 'Workplace or Colleague Recommendation', label: 'Workplace or Colleague Recommendation' },
      { value: 'Other', label: 'Other (Please Specify)' },
    ],
  },
  LEARNED_ABOUT_OTHER: {
    LABEL: "If 'Other', please specify",
  },
  NEWS: 'I would like to receive news and events related to REACH BC.',
  CONFIDENTIALITY: 'I have read and accept the Researcher Confidentiality Undertaking below.',
  TWO_FACTOR: ' I consent to receiving SMS text messages with a code for two-factor authentication when logging in',
  UNDERTAKING_LINK: {
    LABEL: 'Researcher Confidentiality Undertaking.',
    URL: `${environment.app.websiteURL}/researcher-confidentiality-undertaking`,
  },
  CONTUNUE_BUTTON: 'Continue',
  TERMS: {
    START: 'By clicking Continue, you are acknowledging your agreement with the ',
    LINK_URL: `${environment.app.websiteURL}/privacystatement`,
    LINK_LABEL: 'Privacy Statement and Terms of Use.',
  },
};
