import styled from 'styled-components';
import { colours } from '../../styles/theme';

type ToggleProps = {
  readonly checked: boolean;
}

export const ToggleWrapperStyled = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 51px;
  height: 22px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 1rem 0 0;
  input {
    display: none;
  }
`;

export const ToggleBodyStyled = styled.div<ToggleProps>`
  width: 50px;
  height: 21px;
  background: ${({ checked }) => (checked ? colours.colour20 : colours.colour12)};
  border-radius: 5000px;
  display: flex;
  align-items: center;
  padding: 0 2px;
  transition: all .2s ease;

  &:focus-within {
    background: ${colours.colour21};
  }
`;

export const ToggleBallStyled = styled.div<ToggleProps>`
  border-radius: 100%;
  background: white;
  height: 19px;
  width: 19px;
  transform: ${({ checked }) => (checked ? 'translateX(145%)' : 'translateX(0)')};
  transition: all .2s ease;
`;
