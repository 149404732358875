import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from '../styles';
import { fonts } from '../../../styles/theme';
import Button from '../../Button';
import FormStatusPicker from '../../FormStatusPicker';
import { TextH3, TextP } from '../../Text';
import { FORM_MODELS } from '../const';

type ModelStatusPropTypes = {
  desiredStatus?: boolean
  stepBackStatus?: boolean
  selectedStatus?: string

  handleChange?: (value: string, field: string) => void
  setStatusModal?: (statusModal: boolean) => void

  errors: { [key: string]: string }
}

export const ModelStatus = ({
  errors, desiredStatus, handleChange, setStatusModal, stepBackStatus, selectedStatus,
}: ModelStatusPropTypes) => (
  <div>
    <Styled.ModelHeader>
      <Styled.StatusDesHeaderStyled>
        <TextH3>{FORM_MODELS.STATUS.TITLE}</TextH3>
        <TextP font={fonts.font1.H14}>{FORM_MODELS.STATUS.DESCRIPTION}</TextP>
      </Styled.StatusDesHeaderStyled>
      {setStatusModal && (
        <Button
          id="status-close"
          onClick={() => setStatusModal(false)}
          theme="plain"
        >
          <FontAwesomeIcon icon="xmark" size="lg" />
        </Button>
      )}
    </Styled.ModelHeader>
    {desiredStatus && handleChange && !stepBackStatus && (
      <FormStatusPicker
        handleChange={handleChange}
        selectedStatus={selectedStatus}
        error={errors.desired_status}
        hideHeader
      />
    )}
  </div>
);
