import React, { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { TextH1, TextP } from '../../components/Text';
import * as Styled from '../../styles/VolunteerCreateStyles';
import { fonts } from '../../styles/theme';
import HealthResearchAreas from '../../components/HealthResearchAreas';
import VolunteerInfoForm from './component/VolunteerInfoForm';
import { LinkComponent } from '../../components/Link';
import Button from '../../components/Button';
import { Checkbox } from '../../components/CheckBox';
import { ErrorMessage } from '../../components/ErrorMessage';
import environment from '../../config/environment';
import LoginHeader from '../../components/LoginHeader';

import { useCreateVolunteerHook } from './useCreateVolunteerHook';
import { CREATE_VOLUNTEER } from './const';
import { AreResearcherCard } from './component/AreResearcherCard';
import { SelectInput } from '../../components/SelectInput';
import { TextInput } from '../../components';

const VolunteerCreate = () => {
  const hook = useCreateVolunteerHook();

  useEffect(() => {
    hook.getHealthCategoryInfo();
  }, []);

  return (
    <Styled.PageWrapper>
      <LoginHeader isOpen={hook.isOpen} setIsOpen={hook.setIsOpen} />
      <Styled.VolunteerLoginWrapper>
        <Styled.MainStyled>
          <Styled.HeaderStyled>
            <TextH1 textAlgin="left">{CREATE_VOLUNTEER.TITLE}</TextH1>
            <Styled.HeaderDescriptionWrapper>
              <p>{CREATE_VOLUNTEER.HAVE_ACCOUNT.DESCRIPTION}</p>
              <LinkComponent url={CREATE_VOLUNTEER.HAVE_ACCOUNT.URL} theme="loginLink">
                {CREATE_VOLUNTEER.HAVE_ACCOUNT.LABEL}
              </LinkComponent>
            </Styled.HeaderDescriptionWrapper>
          </Styled.HeaderStyled>
          {hook.volunteerInfoError.fetch && (
            <Styled.ErrorWrapper>
              <TextP>{hook.volunteerInfoError.fetch}</TextP>
            </Styled.ErrorWrapper>
          )}
          <Styled.TextDivStyled margin="1.5rem 0 0 0rem">
            <TextP font={fonts.font1.H16}>
              {CREATE_VOLUNTEER.DESCRIPTION}
            </TextP>
          </Styled.TextDivStyled>
          <VolunteerInfoForm
            volunteerInfo={hook.volunteerInfo}
            volunteerInfoError={hook.volunteerInfoError}
            handleSubmit={hook.handleSubmit}
            handleChange={hook.handleChange}
            checkEmail={hook.checkEmail}
            handleSingleValidation={hook.handleSingleValidation}
            researcherEmailType={hook.researcherEmailType}
            hookPasswordRequirements={hook.hookPassword.hookPasswordRequirements}
            hookHandlePasswordChecking={hook.hookPassword.hookHandlePasswordChecking}
            hookDisplayValidationText={hook.hookPassword.hookDisplayValidationText}
          />
          <HealthResearchAreas
            categoriesMap={hook.healthCategory}
            selectedResearchAreas={hook.selectedResearchAreas}
            handleChange={hook.toggleResearchArea}
            forceChange={hook.forceChange}
          />
          <Styled.SectionStyled>
            <Styled.PopUpErrorStyled>
              {hook.volunteerInfoError.healthCategories && <ErrorMessage message={hook.volunteerInfoError.healthCategories} />}
            </Styled.PopUpErrorStyled>
          </Styled.SectionStyled>

          <Styled.InputWrapper>
            <SelectInput
              label={CREATE_VOLUNTEER.LEARNED_ABOUT.LABEL}
              id="learnAboutReachBC"
              onChange={hook.handleChange}
              value={hook.volunteerInfo.learnAboutReachBC}
              options={CREATE_VOLUNTEER.LEARNED_ABOUT.OPTIONS}
              placeholder={CREATE_VOLUNTEER.LEARNED_ABOUT.PLACEHOLDER}
              error={hook.volunteerInfoError.learnAboutReachBC}
              theme="third"
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper theme="secondary">
            <TextInput
              label={CREATE_VOLUNTEER.LEARNED_ABOUT_OTHER.LABEL}
              textValue={hook.volunteerInfo.learnAboutReachBCOther}
              setTextValue={(value) => { hook.handleChange(value, 'learnAboutReachBCOther'); }}
              error={hook.volunteerInfoError.learnAboutReachBCOther}
              type="text"
              isRequired={false}
              inputWidth="100%"
            />
          </Styled.InputWrapper>

          <div>
            <Styled.InputWrapper justifyContent="flex-start">
              <Checkbox
                isChecked={hook.receiveNews}
                onChange={hook.setReciveNews}
                label={CREATE_VOLUNTEER.RECEIVE_NEWS_LABEL}
                textTransform="none"
              />
            </Styled.InputWrapper>

            <ReCAPTCHA
              sitekey={environment.app.recapchaKey || ''}
              onChange={hook.captchaHandler}
              grecaptcha={hook.captchaRef}
            />
            {hook.volunteerInfoError.recaptcha && (
              <Styled.ErrorMessageWrapper>
                <ErrorMessage message={hook.volunteerInfoError.recaptcha} />
              </Styled.ErrorMessageWrapper>
            )}

            <Styled.ButtonWrapper>
              <Button
                id="create-button"
                onClick={hook.handleSubmit}
              >
                {CREATE_VOLUNTEER.BUTTONS.CREATE}
              </Button>
            </Styled.ButtonWrapper>
            <TextP lineHeight="1.33rem" font={fonts.font1.H14}>
              {CREATE_VOLUNTEER.LINKS.PRIVACY.DESCRIPTIONS}
              <LinkComponent
                url={CREATE_VOLUNTEER.LINKS.PRIVACY.URL}
                theme="inlineLarge"
                newWindow
              >
                {CREATE_VOLUNTEER.LINKS.PRIVACY.LABEL}
              </LinkComponent>.
            </TextP>
          </div>

        </Styled.MainStyled>
        {hook.isCardOpen && (
          <AreResearcherCard
            setIsOpen={hook.setIsCardOpen}
          />
        )}
      </Styled.VolunteerLoginWrapper>
    </Styled.PageWrapper>
  );
};

export default VolunteerCreate;
