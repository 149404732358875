import styled from 'styled-components';
import { colours, breakpoints, fonts } from '../../styles/theme';

type MobileLinkWrapperProps = {
  readonly theme?: string
}

export const NavStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 4rem;
`;

export const NavLinkStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.6;
`;

export const MainHeaderStyled = styled.header`
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  width: 100%;
  border-top: 3px solid ${colours.colour1};
  background: ${colours.colour4};
`;

export const MainInnerStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    margin: 0 auto;
    max-width: 1200px;
`;

export const VolunteerHeaderDesktop = styled.div`
  display: none;
  @media (min-width: ${breakpoints.desktop}) {
    display: flex;
  }
`;

export const VolunteerHeaderMobile = styled.div`
  z-index: 1;  
  display: flex;
  padding: 0 1rem;

  @media (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;

export const MenuOptionWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const IconStyled = styled.span`
  margin-right: 1.25rem;
`;

export const LogoWrapper = styled.div`
  padding: 1.563rem 0.875rem;
  max-width: 161px;
`;

export const PopoverWrapper = styled.div`
  min-width: 95px;
`;

export const ButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MobileNavWrapper = styled.nav`
  margin-bottom: 1.875rem;
`;

export const MobileMenuOptions = styled.div<MobileLinkWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  border-bottom: ${({ theme }) => (theme === 'accordion' ? `1px solid ${colours.colour28}` : `1px solid ${colours.colour9}`)};
  padding: ${({ theme }) => (theme === 'accordion' ? '1rem' : '0.5rem 0')};
`;

export const SubHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  ${fonts.font1.H16B};
  padding: 1.25rem 1.75rem;
`;

export const InitialsStyled = styled.div`
  border-radius: 500px;
  background: ${colours.colour29};
  ${fonts.font1.H14B};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

export const SpaceDivStyled = styled.span`
  height: 100%;
  width: 60px;
`;

export const SpaceDivStyled2 = styled.span`
  height: 100%;
  width: 65px;
`;
