export const OPPORTUNITY_PURPOSE = {
  TITLE: 'Opportunity Purpose',
  PURPOSE: {
    LABEL: 'Opportunity Purpose',
    DESCRIPTION_LIST: [
      'Explain the purpose of this opportunity in 3-4 sentences. ',
      'Use lay-friendly language, and make sure any acronyms are spelled out on first use.',
      'Use any key words that would help a Volunteer find your opportunity when searching in the REACH Directory.',
    ],
    DESCRIPTION: 'E.g. To examine the effect of a peer support intervention on improving and sustaining glycemic control and diabetes-specific quality of life. (Max character limit is 450).',
  },
  INVOLVED: {
    LABEL: 'What is Involved',
    DESCRIPTION: 'Explain the detailed logistics of the opportunity in 4-5 sentences. What will the participant do in the opportunity? (Max character limit is 700).',
  },
  ENGAGEMENT: {
    LABEL: 'Level of Engagement',
    DESCRIPTION: 'Check all that apply. Select the most appropriate level of engagement for the patient partner. Refer to IAP2 for more information about the levels of engagement.',
  },
  ORIENTATION: {
    LABEL: 'Orientation Provided',
    DESCRIPTION: 'Describe any orientation that will be provided to patient partners to prepare them in their role. E.g. Project documents will be provided and an initial orientation phone call will be provided. (Max character limit is 300).',
  },
  BACKGROUND: {
    LABEL: 'Background',
    DESCRIPTION: 'Describe any orientation that will be provided to patient partners to prepare them in their role. E.g. Project documents will be provided and an initial orientation phone call will be provided. (Max character limit is 300).',
  },
};
