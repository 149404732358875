import React from 'react';
import ReactSelect from 'react-select';
import PublicToolTip from '../PublicTooltip';
import { fonts } from '../../styles/theme';
import { TextP } from '../Text';
import { ErrorMessage } from '../ErrorMessage';
import * as Styled from './styles';
import { RequiredForm } from '../RequiredForm';
import { NotVisible } from '../NotVisible';

type SelectOption = {
  label: string,
  value: string
}

type SelectProps = {
  label?: string;
  id?: string;
  description?: string;
  selected?: { label?: string, value?: string };
  onChange: (value: any) => void;
  options?: SelectOption[];
  isMulti?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isPublic?: boolean;
  isNotVisible?: boolean;
  width?: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
  theme?: string;
  menuPlacement?: string;
  isSearchable?: boolean
}

export const DropdownSelect = ({
  label, id: name, description, selected, onChange, options = [], isMulti = false, isDisabled = false, isLoading = false, isPublic = false, width = '100%', className, required = false, placeholder, error, theme, menuPlacement, isSearchable = false, isNotVisible = false,
}: SelectProps) => (
  <Styled.WrapperStyled id={name} width={width} className={className}>
    <Styled.LabelStyled htmlFor={name} theme={theme}>
      {(label || description) && (
        <Styled.TextWrapper>
          {label && (
            <Styled.LabelWrapper>
              <Styled.LabelTextStyled>
                {label}
                {isPublic && <PublicToolTip />}
                {required && <RequiredForm />}
              </Styled.LabelTextStyled>
              {isNotVisible && <NotVisible />}
            </Styled.LabelWrapper>
          )}
          {description && (
            <Styled.DescriptionWrapper>
              <TextP font={fonts.font1.H14} lineHeight="1.33rem">{description}</TextP>
            </Styled.DescriptionWrapper>
          )}
        </Styled.TextWrapper>
      )}
      <Styled.DesktopWrapper>
        <ReactSelect
          isDisabled={isDisabled}
          options={options}
          onChange={onChange}
          defaultValue={selected}
          value={selected?.value ? selected : ''}
          styles={Styled.SelectStyles(theme)}
          isMulti={isMulti}
          name={name}
          isLoading={isLoading}
          placeholder={placeholder}
          menuPlacement={(
            menuPlacement === 'auto'
            || menuPlacement === 'bottom'
            || menuPlacement === 'top'
          ) ? menuPlacement : 'auto'}
          isSearchable={isSearchable}
        />
      </Styled.DesktopWrapper>

      <Styled.MobileWrapper>
        <ReactSelect
          isDisabled={isDisabled}
          options={options}
          onChange={onChange}
          defaultValue={selected}
          value={selected}
          styles={Styled.SelectMobileStyles(theme)}
          isMulti={isMulti}
          name={name}
          isLoading={isLoading}
          placeholder={placeholder}
          menuPlacement={(
            menuPlacement === 'auto'
            || menuPlacement === 'bottom'
            || menuPlacement === 'top'
          ) ? menuPlacement : 'auto'}
          isSearchable={isSearchable}
        />
      </Styled.MobileWrapper>
    </Styled.LabelStyled>
    {error && <ErrorMessage message={error} />}
  </Styled.WrapperStyled>
);
