import { useState } from 'react';
import { useUser } from './useUser';
import environment from '../config/environment';

const getCookie = (name: string) => {
  try {
    const value = document.cookie;
    if (!value) {
      throw new Error('');
    }
    const cookie = value.split('; ').filter((cookieValue) => {
      if (!cookieValue) return false;
      const cookieName = cookieValue.split('=')[0];
      return cookieName === name;
    });
    return cookie[0]?.split('=')[1];
  } catch (err) {
    return '';
  }
};

export const useCheckLogin = () => {
  const [cookie] = useState(getCookie(environment.app.cookieName));
  const { getUserInfo } = useUser();

  const renewCookie = () => {
    const value = getCookie(environment.app.cookieName);
    const cognito = getCookie(environment.app.cognitoName);
    if (!value) {
      window.location.href = '/login?status=idle';
      return;
    }
    const user = getUserInfo();
    const now = new Date();
    const expireTime = new Date();
    if (user.type === 'researcher') {
      expireTime.setTime(now.getTime() + 1000 * 60 * 30);
    } else {
      expireTime.setTime(now.getTime() + 1000 * 60 * 60);
    }
    document.cookie = `${environment.app.cookieName}=${value}; expires=${new Date(expireTime).toUTCString()}; path=/;`;
    if (cognito) {
      document.cookie = `${environment.app.cognitoName}=${cognito}; expires=${new Date(expireTime).toUTCString()}; path=/;`;
    }
  };

  return {
    hookCookie: cookie,
    hookRenewCookie: renewCookie,
  };
};
