import React from 'react';
import {
  fundingAgencyOptions, levelsOfEngagements, regionalCentre,
  sexEligible, gendersEligible, researcherPosition,
} from '../../utils/researcherConst';
import DatesFundingForm from './components/DatesFundingForm';
import OpportunityAdminComments from './components/OpportunityAdminComments';
import OpportunityAffiliations from './components/OpportunityAffiliations';
import OpportunityComments from './components/OpportunityComments';
import OpportunityContactForm from './components/OpportunityContactForm';
import OpportunityDetails from './components/OpportunityDetails';
import OpportunityEligibilityCriteria from './components/OpportunityEligibilityCriteria';
import OpportunityEstimatedCommitment from './components/OpportunityEstimatedCommitment';
import OpportunityLocation from './components/OpportunityLocation';
import OpportunityPurpose from './components/OpportunityPurpose';
import PPOPrincipalInvestigatorSection from './components/PPOPrincipalInvestigator';

type PPOFormProps = {
  formInfo: PatientPartnerResearchOpportunityValues
  formErrors: { [key: string]: string }
  collaborators: { [key: string]: string }
  teamMembers: { [key: string]: { id: string, email: string, receive_email: boolean } }
  collaboratingOrganizations: { [key: string]: string }
  healthAuthorityRegionOptions: { id: number, name: string }[]
  hospitalList: { value: string, label: string }[]
  healthCategories: { category: string, items: { id: number, item: string, category: string }[] }[]
  selectedResearchAreas: { category: string, id: number, name: string }[]
  adminComments?: string
  isDraft?: boolean
  canEdit: boolean
  handleChange: (value: string | number | boolean | Date, field: string) => void
  handleToggle: (value: boolean, field: string) => void
  handleAddInput: (key: string, maxNumber?: number) => void
  handleChangeAddedInput: (value: string, id: string, key: string) => void
  handleRemoveInput: (key: string, id: string) => void
  checkPI: (email: string) => void
  checkTeamMember: (user: string, email: string) => void
  handleCheck: (value: boolean, field: string | number, key: string) => void
  handleChangeResearchAreas: (name: string, id: number, category: string) => void
  handleDelete: () => void
  forceChange: boolean
  handleSingleValidation: (value: any, field: string) => void
}

const PPOForm = ({
  formInfo,
  formErrors,
  teamMembers,
  collaboratingOrganizations,
  collaborators,
  healthAuthorityRegionOptions,
  healthCategories,
  hospitalList,
  selectedResearchAreas,
  adminComments,
  isDraft,
  canEdit,
  handleChange,
  handleAddInput,
  handleChangeAddedInput,
  handleChangeResearchAreas,
  handleCheck,
  handleRemoveInput,
  handleToggle,
  checkPI,
  checkTeamMember,
  handleDelete,
  forceChange,
  handleSingleValidation,
}: PPOFormProps) => (
  <>
    <PPOPrincipalInvestigatorSection
      piValue={formInfo.pi_email || ''}
      coInvestigator={formInfo.pp_co_pi || ''}
      ppAffiliatedOrganization={formInfo.pp_affiliated_organization || ''}
      teamMembers={teamMembers}
      collaborators={collaborators}
      errors={formErrors}
      handleChange={handleChange}
      handleAddInput={handleAddInput}
      handleChangeAddedInput={handleChangeAddedInput}
      handleRemoveInput={handleRemoveInput}
      checkPI={checkPI}
      checkTeamMember={checkTeamMember}
      isDraft={isDraft}
      handleDelete={handleDelete}
      handleCheck={handleCheck}
      canEdit={canEdit}
    />
    <OpportunityAffiliations
      collaboratingOrganizations={collaboratingOrganizations}
      bcSupportUnitToggle={formInfo.bc_support_unit}
      selectedRegionalCenters={formInfo.regional_centers || []}
      regionalCentersList={regionalCentre}
      errors={formErrors}
      canEdit={canEdit}
      handleAddInput={handleAddInput}
      handleChangeAddedInput={handleChangeAddedInput}
      handleRemoveInput={handleRemoveInput}
      handleToggle={handleToggle}
      handleCheck={handleCheck}
    />

    <OpportunityDetails
      handleChange={handleChange}
      title={formInfo.title}
      nickName={formInfo.nick_name}
      relatedResearchType={formInfo.ppo_type}
      errors={formErrors}
      canEdit={canEdit}
      handleSingleValidation={handleSingleValidation}
    />

    <DatesFundingForm
      handleCheck={handleCheck}
      handleChange={handleChange}
      selectedFundingAgency={formInfo.funding_agency}
      fundingAgencyOptions={fundingAgencyOptions}
      recruitmentEndDate={formInfo.recruitment_end}
      errors={formErrors}
      canEdit={canEdit}
    />
    <OpportunityLocation
      handleCheck={handleCheck}
      handleChange={handleChange}
      handleToggle={handleToggle}
      isOnline={formInfo.is_online}
      selectedhealthAuthorityRegion={formInfo.health_authority_region}
      healthAuthorityRegionOptions={healthAuthorityRegionOptions}
      hospital={formInfo.hospital_id}
      hospitalList={hospitalList || []}
      building={formInfo.building || ''}
      city={formInfo.city}
      streetAddress={formInfo.street_address}
      postalCode={formInfo.postal_code}
      department={formInfo.department || ''}
      hasDirectLink={formInfo.has_direct_link}
      directLinkUrl={formInfo.direct_link_url}
      canEdit={canEdit}
      errors={formErrors}
      handleSingleValidation={handleSingleValidation}
    />
    <OpportunityPurpose
      levelsOfEngagements={levelsOfEngagements}
      selectedEngagements={formInfo.level_of_engagement}
      handleCheck={handleCheck}
      handleChange={handleChange}
      involved={formInfo.description}
      purpose={formInfo.purpose}
      opportunityBackground={formInfo.background}
      opportunityOrientation={formInfo.orientation_provided}
      canEdit={canEdit}
      errors={formErrors}
      handleSingleValidation={handleSingleValidation}
    />
    <OpportunityEstimatedCommitment
      handleChange={handleChange}
      handleToggle={handleToggle}
      commitment={formInfo.commitment}
      compensation={formInfo.compensation}
      expenses={formInfo.expenses}
      canEdit={canEdit}
      errors={formErrors}
      handleSingleValidation={handleSingleValidation}
    />

    <OpportunityEligibilityCriteria
      handleChange={handleChange}
      handleChangeResearchAreas={handleChangeResearchAreas}
      handleCheck={handleCheck}
      errors={formErrors}
      maxAge={formInfo.max_age}
      minAge={formInfo.min_age}
      yearsMonths={formInfo.years_months}
      sexEligible={formInfo.sex_eligible}
      sexEligibleOptions={sexEligible}
      gendersEligibleOptions={gendersEligible}
      gendersEligible={formInfo.genders_eligible}
      additionalCriteria={formInfo.additional_eligibility || ''}
      categoriesMap={healthCategories}
      selectedResearchAreas={selectedResearchAreas}
      patients={formInfo.target_patients}
      controls={formInfo.target_controls}
      forceChange={forceChange}
      canEdit={canEdit}
      handleSingleValidation={handleSingleValidation}
    />
    <OpportunityContactForm
      handleChange={handleChange}
      contactName={formInfo.contact1_name}
      contactPosition={{ value: formInfo.contact1_position, label: formInfo.contact1_position }}
      contactPositionOptions={researcherPosition}
      contactEmail={formInfo.contact1_email}
      contactPhone={formInfo.contact1_phone}
      contactExtension={formInfo.contact1_phone_extension || ''}
      contact2Email={formInfo.contact2_email || ''}
      contact2Phone={formInfo.contact2_phone || ''}
      contact2Extension={formInfo.contact2_phone_extension || ''}
      contact2Name={formInfo.contact2_name || ''}
      contact2Position={{ value: formInfo.contact2_position, label: formInfo.contact2_position } || ''}
      errors={formErrors}
      canEdit={canEdit}
      handleSingleValidation={handleSingleValidation}
    />

    <OpportunityComments
      handleChange={handleChange}
      comments={formInfo.comments}
      canEdit={canEdit}
      errors={formErrors}
    />

    <OpportunityAdminComments
      handleChange={handleChange}
      adminComments={adminComments || ''}
    />
  </>
);

export default PPOForm;
