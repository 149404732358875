import React, { useState } from 'react';
import { Checkbox } from '../../../CheckBox';
import { formateDate } from '../../../../utils/formateDate';
import Button from '../../../Button';
import { Calendar } from '../../../Calendar';
import { ErrorMessage } from '../../../ErrorMessage';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { TextP } from '../../../Text';
import * as Styled from '../styles';
import { FUNDING_INFORMATION } from './const';

type DateFundingFormProps = {
  handleCheck: (value: boolean, field: number | string, key: string) => void
  selectedFundingAgency: string[]
  fundingAgencyOptions: { value: string, label: string }[]
  recruitmentEndDate: Date
  handleChange: (value: string | Date, field: string) => void
  errors: { [key: string]: string }
  canEdit: boolean
}
const DatesFundingForm = ({
  selectedFundingAgency, fundingAgencyOptions, errors, recruitmentEndDate,
  handleChange, canEdit, handleCheck,
}: DateFundingFormProps) => {
  const [calenderToggle, setCalenderToggle] = useState(false);

  return (
    <FormSection
      title={FUNDING_INFORMATION.TITLE}
      titleMobile={FUNDING_INFORMATION.TITLE_MODILE}
    >
      <Styled.SectiontWrapper id="funding_agency" isLast>
        <FormSubtitle subtitle={FUNDING_INFORMATION.FUNDING_AGENCY.LABEL} isRequired />
        <TextP lineHeight="1.33rem">{FUNDING_INFORMATION.FUNDING_AGENCY.LABEL_DESCRIPTION}</TextP>
        {fundingAgencyOptions.map((option) => (
          <Styled.CheckboxWrapper key={option.label || option.value}>
            <Checkbox
              label={option.label}
              isChecked={selectedFundingAgency.includes(option.value)}
              onChange={(check) => handleCheck(check, option.value, 'funding_agency')}
              disabled={!canEdit}
              textTransform="none"
            />
          </Styled.CheckboxWrapper>
        ))}
        {errors.funding_agency && <ErrorMessage message={errors.funding_agency} />}
      </Styled.SectiontWrapper>

      <Styled.MobileWrapper>
        <Styled.SectiontWrapper id="recruitment_end">
          <FormSubtitle
            subtitle={FUNDING_INFORMATION.RECUITMENT_DATES.TITLE}
            isPublic
            isRequired
            margin="small"
          />

          <Styled.TextWrapper>
            <TextP>
              {FUNDING_INFORMATION.RECUITMENT_DATES.LABEL_DESCRIPTION}
            </TextP>
          </Styled.TextWrapper>
          <Button
            id="calenderToggle"
            theme="outlineSecondary"
            onClick={() => setCalenderToggle(!calenderToggle)}
            buttonWidth="100%"
            disabled={!canEdit}
          >
            {recruitmentEndDate ? (
              <TextP>{formateDate(recruitmentEndDate)}</TextP>
            ) : (
              <TextP>
                {FUNDING_INFORMATION.RECUITMENT_DATES.PLACEHOLDER}
              </TextP>
            )}
          </Button>
          <Calendar
            onApply={(value) => {
              handleChange(value, 'recruitment_end');
              setCalenderToggle(false);
            }}
            date={recruitmentEndDate ? new Date(`${recruitmentEndDate}`?.slice(0, 15) || new Date()) : new Date()}
            isOpen={calenderToggle}
            setIsOpen={setCalenderToggle}
          />
          {errors.recruitment_end && <ErrorMessage message={errors.recruitment_end} />}
        </Styled.SectiontWrapper>
      </Styled.MobileWrapper>
    </FormSection>
  );
};

export default DatesFundingForm;
