import jwtDecode from 'jwt-decode';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import environment from '../config/environment';
import { userInitial } from '../utils/initialValues';
import { useAPI } from './useAPI';
import { useCookies } from './useCookies';
import { useGenericCookies } from './useGenericCookies';
import { VOLUNTEER_DASHBOARD } from '../components/VolunteerDashboard/const';

export const useVolounteerProjectDetails = (suggestedDataProp: any[], page = 'dashboard') => {
  const {
    getProjects,
    getProjectInterest,
    getArchivedInterest,
    createProjectInterest,
    updateProjectInterest,
    updateUser,
  } = useAPI();
  const { getCookie, deleteCookie } = useCookies();
  const cookieHook = useGenericCookies();
  const navigate = useNavigate();

  const [user, setUser] = useState<IUser>(userInitial);

  const [searchValue, setSearchValue] = useState('');
  const [currentTab, setCurrentTab] = useState<{ label: string, description?: string, value: string }>(VOLUNTEER_DASHBOARD.TABS[0]);
  const [pageNumber, setPageNumber] = useState({ label: '1', value: '1' });
  const [rowCount, setRowCount] = useState(10);
  const rowOption = [5, 10, 20, 25, 50, 100];

  const [suggestedDataUnfiltered, setSuggestedDataUnfiltered] = useState(suggestedDataProp);
  const [suggestedData, setSuggestedData] = useState(suggestedDataUnfiltered);
  const [suggestedLength, setSuggestLength] = useState(suggestedData.length);

  const [totalPages, setTotalPages] = useState(0);

  const [myProjectDataUnfiltered, setMyProjectDataUnfiltered] = useState([]);
  const [myProjectData, setMyProjectData] = useState([]);
  const [myProjectsLength, setMyProjectsLength] = useState(myProjectData.length);

  const [archivedProjectDataUnfiltered, setArchivedProjectDataUnfiltered] = useState([]);
  const [archivedProjectsData, setArchivedProjects] = useState([]);
  const [archivedProjectsLength, setArchivedProjectsLength] = useState(archivedProjectsData.length);

  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastUndo, setToastUndo] = useState('');
  const [toastProject, setToastProject] = useState({ name: '', id: 0 });

  const [interestType, setInterestType] = useState<{ [key: string]: { value: string, label: string } }>({ 0: { value: 'delete', label: '' } });

  const [interestConfirmationModal, setInterestConfirmationModal] = useState({ projectName: '', projectId: 0, type: '' });
  const [modelIsOpen, setModelIsOpen] = useState(false);

  const [directLinkInterestModel, setDirectLinkInterestModel] = useState(false);

  // ON LOAD GETTERS
  useEffect(() => {
    try {
      const userInfo = getCookie(environment.app.cookieName);
      const decodedUser: any = jwtDecode(userInfo);
      setUser(decodedUser);
    } catch (err) {
      deleteCookie(environment.app.cookieName);
    }
  }, []);

  const handleRowCount = (rowObject: { value: string, label: string }) => {
    setRowCount(Number(rowObject.value));
    cookieHook.createCookie('ReachRowCount', `${Number(rowObject.value)}`, '2100-01-01');
  };

  const getMyProjectsTableData = async () => {
    if (user.type === 'volunteer') {
      const projects = await getProjectInterest();
      if (projects.error) {
        setMyProjectDataUnfiltered([]);
      } else {
        setMyProjectDataUnfiltered(projects);
      }
    }
    return [];
  };

  const getArchivedProjectsTableData = async () => {
    if (user.type === 'volunteer') {
      const projects = await getArchivedInterest();
      if (projects.error) {
        setArchivedProjectDataUnfiltered([]);
      } else {
        setArchivedProjectDataUnfiltered(projects);
      }
    }
    return [];
  };

  const getSuggestedTableData = async () => {
    if (user.type === 'researcher' || user.type === 'volunteer') {
      const projects = await getProjects(user.type);
      if (projects.error) {
        setSuggestedDataUnfiltered([]);
      } else {
        setSuggestedDataUnfiltered(projects);
      }
    }
    return [];
  };

  useEffect(() => {
    if (!user.id || user.type !== 'volunteer') return;
    if (page !== 'dashboard') return;
    getSuggestedTableData();
    getMyProjectsTableData();
    getArchivedProjectsTableData();
  }, [JSON.stringify(user)]);

  // OVERALL PAGINATOR LOGIC
  useEffect(() => {
    switch (currentTab.value) {
      case 'Suggested For You':
        setTotalPages(Math.ceil(suggestedLength / rowCount));
        break;
      case 'My Projects':
        setTotalPages(Math.ceil(myProjectsLength / rowCount));
        break;
      case 'Archived Projects':
        setTotalPages(Math.ceil(archivedProjectsLength / rowCount));
        break;
      default:
    }
  }, [rowCount, currentTab, suggestedLength, archivedProjectsLength, myProjectsLength]);

  // useEffect(() => {
  //   switch (currentTab) {
  //     case 'Suggested For You':
  //       setSuggestedData(suggestedData);
  //       break;
  //     case 'My Projects':
  //       setMyProjectData(myProjectData);
  //       break;
  //     case 'My Archived Projects':
  //       setArchivedProjects(archivedProjectsData);
  //       break;
  //     default:
  //       throw new Error('Invalid tab');
  //   }
  // }, [currentTab]);

  // FILTER UPDATES
  useEffect(() => {
    setSuggestedData(suggestedDataUnfiltered.filter((data: { title: string; }) => data.title.toLowerCase().includes(searchValue.toLowerCase())));
  }, [searchValue, suggestedDataUnfiltered]);

  useEffect(() => {
    setMyProjectData(myProjectDataUnfiltered.filter((data: { title: string; }) => data.title.toLowerCase().includes(searchValue.toLowerCase())));
  }, [searchValue, myProjectDataUnfiltered]);

  useEffect(() => {
    setArchivedProjects(archivedProjectDataUnfiltered.filter((data: { title: string; }) => data.title.toLowerCase().includes(searchValue.toLowerCase())));
  }, [searchValue, archivedProjectDataUnfiltered]);

  // LENGTH UPDATES
  useEffect(() => {
    setSuggestLength(suggestedData.length);
  }, [suggestedData]);

  useEffect(() => {
    setMyProjectsLength(myProjectData.length);
  }, [myProjectData]);

  useEffect(() => {
    setArchivedProjectsLength(archivedProjectsData.length);
  }, [archivedProjectsData]);

  // SET INTEREST FUNCTIONALITY
  const handleConfirmationModel = (projectID: number, interest: string, projectName: string) => {
    setInterestConfirmationModal({
      projectName,
      projectId: projectID,
      type: interest,
    });
    if (interest === 'withdrawInterest') {
      setModelIsOpen(true);
    }
  };

  const handleDirectLinkInterest = async (projectId: number) => {
    try {
      setDirectLinkInterestModel(true);
      const date = new Date();
      const result = await createProjectInterest({ projectID: projectId, interest: 'interested', created_at: date });
      return result;
    } catch (error) {
      return false;
    }
  };

  const handleDirectLinkRedirect = async (directLink: string) => {
    window.open(directLink, '_blank');
    setDirectLinkInterestModel(false);
  };

  const handleDirectLinkInterestCancel = () => {
    setDirectLinkInterestModel(false);
    navigate('/dashboard');
  };

  const handleProjectConfirmation = async (id?: number, type?: string, name?: string) => {
    const projectInterestType = interestConfirmationModal.type || type;
    const projectId = interestConfirmationModal.projectId || id;
    const projectName = interestConfirmationModal.projectName || name;
    if (!projectId) return;

    setToastIsOpen(false);
    let updatedValues: any = {};
    const date = new Date();
    try {
      switch (projectInterestType) {
        case 'interested':
          await createProjectInterest({ projectID: projectId, interest: 'interested', created_at: date });
          updatedValues = { interest: 'interested' };
          setToastMessage('interestedCreate');
          break;
        case 'not_interested':
          await createProjectInterest({ projectID: projectId, interest: 'not_interested', created_at: date });
          setToastUndo('notInterested');
          updatedValues = { value: 'not_interested', label: "I'm not interested" };
          setToastMessage('interestedDelete');
          break;
        case 'not_eligible':
          await createProjectInterest({ projectID: projectId, interest: 'not_eligible', created_at: date });
          setToastMessage('interestedDelete');
          updatedValues = { value: 'not_eligible', label: "I'm not eligible" };
          setToastUndo('notEligible');
          break;
        case 'archived':
          await updateProjectInterest({ projectID: projectId, archived: true, updated_at: date });
          setToastMessage('interestedArchived');
          updatedValues = { archived: 'true' };
          setToastUndo('archived');
          break;
        case 'unarchive':
          await updateProjectInterest({ projectID: projectId, archived: false, updated_at: date });
          setToastMessage('interestedUnarchived');
          updatedValues = { archived: 'false' };
          setToastUndo('unarchive');
          break;
        case 'withdrawInterest':
          await updateProjectInterest({ projectID: projectId, interest: 'withdraw_interest', updated_at: date });
          setToastMessage('withdrawInterest');
          updatedValues = { interest: 'width_drawn' };
          setToastUndo('withdrawInterest');
          break;
        default:
          throw new Error(`${projectInterestType} is not a valid interest`);
      }
      if (projectName) {
        setToastProject({ name: projectName, id: projectId });
      }
      setModelIsOpen(false);
      if (projectInterestType !== 'interested' || (user.interest_toast_message === true && projectInterestType === 'interested')) {
        setToastIsOpen(true);
      }
      if (page === 'dashboard') {
        getSuggestedTableData();
      }

      if (currentTab.value === 'My Projects' || currentTab.value === 'My Archived Projects') {
        getMyProjectsTableData();
        getArchivedProjectsTableData();
      }

      const updateInterestType = interestType;
      updateInterestType[projectId] = updatedValues;
      setInterestConfirmationModal({ projectName: '', projectId: 0, type: '' });
      setInterestType(updateInterestType);
      return updatedValues;
    } catch (error) {
      return false;
    }
  };

  const handleToastCheck = async (projectId: number) => {
    await updateUser({ interest_toast_message: true, projectId });
  };

  const handleToastUndo = async (projectID?: number) => {
    if (!projectID) return;
    let updatedValues: any = {};

    try {
      const date = new Date();

      switch (toastUndo) {
        case 'notInterested':
          await updateProjectInterest({ projectID, interest: 'delete', updated_at: date });
          setToastMessage('interestedDeleteUndo');
          updatedValues = { value: '', label: 'interested', interest: 'withdraw_interest' };
          break;
        case 'notEligible':
          await updateProjectInterest({ projectID, interest: 'delete', updated_at: date });
          setToastMessage('interestedDeleteUndo');
          updatedValues = { value: '', label: 'Delete' };
          break;
        case 'archived':
          await updateProjectInterest({ projectID, archived: false, updated_at: date });
          setToastMessage('interestedArchivedUndo');
          updatedValues = { archived: 'false' };
          break;
        case 'unarchive':
          await updateProjectInterest({ projectID, archived: true, updated_at: date });
          updatedValues = { archived: 'true' };
          setToastMessage('interestedUnarchivedUndo');
          break;
        case 'withdrawInterest':
          await createProjectInterest({ projectID, interest: 'interested', created_at: date });
          updatedValues = { interest: 'interested' };
          setToastMessage('withdrawInterestUndo');
          break;
        default:
          throw new Error('Undo interest active failed');
      }

      if (page === 'dashboard') {
        getSuggestedTableData();
      }

      if (currentTab.label === 'My Projects' || currentTab.label === 'My Archived Projects') {
        getMyProjectsTableData();
        getArchivedProjectsTableData();
      }
      setInterestType(updatedValues);
      return updatedValues;
    } catch (error) {
      return false;
    }
  };

  const handleGetRowCount = () => {
    const rowNumber = cookieHook.getCookie('ReachRowCount');
    setRowCount(Number(rowNumber) || 10);
  };

  const setCurrentTabHandler = (tab: { label: string, description?: string, value: string }) => setCurrentTab(tab);

  return {
    handleConfirmationModel,
    suggestedLength,
    myProjectsLength,
    myProjectDataUnfiltered,
    archivedProjectDataUnfiltered,
    archivedProjectsLength,
    currentTab,
    setCurrentTab: setCurrentTabHandler,
    suggestedData,
    myProjectData,
    archivedProjectsData,
    searchValue,
    setSearchValue,
    rowCount,
    setRowCount,
    pageNumber,
    setPageNumber,
    totalPages,
    rowOption,
    handleRowCount,
    toastIsOpen,
    setToastIsOpen,
    toastMessage,
    handleToastUndo,
    handleToastCheck,
    user,
    toastProject,
    interestType,
    modelIsOpen,
    setModelIsOpen,
    interestConfirmationModal,
    handleProjectConfirmation,
    handleGetRowCount,
    directLinkInterestModel,
    setDirectLinkInterestModel,
    handleDirectLinkInterest,
    handleDirectLinkInterestCancel,
    handleDirectLinkRedirect,
  };
};
