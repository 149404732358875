import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type SectionStyledProps = {
  readonly theme?: string
  readonly paddingSize?: string
}

type TextWrapperProps = {
  readonly marginType?: string
}

type FlexBoxProps = {
  readonly justify?: string
}

type ButtonWrapperProps = {
  readonly rightButton?: boolean
  readonly isTopButton?: boolean
}

type StatusDesStyledProps = {
  readonly isError: boolean
}

type StatusTagWrapperProps = {
  readonly noBottomMargin?: boolean
}

export const SectionStyled = styled.section<SectionStyledProps>`
  margin-bottom: 0.875rem;
  background: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour24;
      default:
        return colours.colour23;
    }
  }};
  color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour4;
      default:
        return colours.colour6;
    }
  }};
  padding: ${({ paddingSize }) => {
    switch (paddingSize) {
      case 'hero':
        return '0.875rem 1.5rem 2.5rem';
      default:
        return '0.875rem';
    }
  }};

  @media (min-width: 820px) {
    padding: ${({ paddingSize }) => {
    switch (paddingSize) {
      case 'hero':
        return '0.875rem 1rem 2.5rem';
      default:
        return ' 0.875rem';
    }
  }};
    }
`;

export const TextWrapper = styled.div<TextWrapperProps>`
  line-height: 1.33rem;
  margin: ${({ marginType }) => {
    switch (marginType) {
      case 'subheader':
        return '0.0rem 0rem 0.5rem';
      case 'description':
        return '0.5rem 0 1.5rem';
      default:
        return '0.5rem 0';
    }
  }};
`;

export const HeroTextWrapper = styled.div`
  max-width: 70%;
  margin: 1rem auto;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 0.875rem;
  margin-top: 0.875rem;
  ${fonts.font1.H28B};

  @media (min-width: 820px) {
    margin-top: 0;
  }
`;

export const SpiltSectionStyled = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;

  @media (min-width: 820px) {
    flex-direction: row;
  }
`;

export const StickyDivStyled = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  bottom: 0;
  background: ${colours.colour4};
  z-index: 1;
  width: 100%;
  border-top: 1px solid ${colours.colour41};
  padding-top: 0.625rem;
  
  @media (min-width: 820px) {
    position: sticky;
    top: 0;
    max-width: 25%;
    padding: 0.875rem 0 0.875rem 0.875rem;
    border: none;
    max-height: 100vh;
    overflow: auto;

    /* hide scroll bar for side menu */
    -ms-overflow-style: none; 
    scrollbar-width: none;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none;
    }
  }
`;

export const ChildrenWrapper = styled.section`
  width: 100%;
  padding: 0.875rem 0 13rem;

  @media (min-width: 820px) {
    width: 75%;
    padding: 0.875rem 0.875rem 0.875rem 0;
  }
`;

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  width: 100%;
  margin-right: ${({ rightButton }) => (!rightButton ? '0.875rem' : '0')};

  @media (min-width: 820px) {
    margin-bottom: ${({ isTopButton }) => (isTopButton ? '1rem' : 0)};
    margin-right: 0;
  }
`;

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  justify-content: ${({ justify }) => justify || ''};
`;

export const IconWrapper = styled.span`
  margin-right: 0.35rem;
`;

export const ErrorWrapper = styled.div`
  border: 1px solid ${colours.colour7};
  padding: 0.875rem;
  margin: 4rem 0.875rem 0.875rem;
  border-radius: 4px;
  ${fonts.font1.H14}
`;

export const ErrorStyled = styled.div`
  margin-bottom: 0.875rem;
  a {
    text-decoration: underline;
    line-height: 1.33rem;
  }
`;

export const UnderlineStyled = styled.span`
  text-decoration: underline;
`;

export const StatusTagWrapper = styled.div<StatusTagWrapperProps>`
  margin: 1rem;

  @media (min-width: 820px) {
    margin: 0;
    margin-bottom: ${({ noBottomMargin }) => (noBottomMargin ? '0' : '2rem')};
  }
`;

export const ModalButtonWrapper = styled.div`
  margin-right: 1rem;
`;

export const ModalHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 820px) {
    min-width: 600px;
    max-width: 600px;
  }
`;

export const ModalMainStyled = styled.div`
  flex: 1;
  margin-bottom: 1rem;
`;

export const DecktopWrapper = styled.div`
  display: none;
  
  @media (min-width: 820px) {
    display: block;
  }
`;

export const MobileWrapper = styled.div`
  display: block;
  padding: 0 0.825rem;
  line-height: 1.25rem;
  
  @media (min-width: 820px) {
    display: none;
  }
`;

export const SideButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 1.25rem 1.5rem 2rem; 

  @media (min-width: 820px) {
    flex-direction: column;
    padding: 0;
    margin-top: 3.5rem;
  }
`;

export const ModelHeader = styled.div`
  display: flex;
  ${fonts.font1.H16B}
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

export const StatusDesStyled = styled.div<StatusDesStyledProps>`
  color: ${({ isError }) => (isError ? colours.colour7 : colours.colour5)};
`;

export const StatusDesHeaderStyled = styled.div`
  ${fonts.font1.H12};
  line-height: 1.25rem;

  h3 {
    ${fonts.font1.H16B};
    margin-bottom: 0.625rem;
  }
`;

export const DesiredStatusStyled = styled.div`
  display: flex;
  width: 100%;
  color: ${colours.colour35};
    ${fonts.font1.H14};
    padding: 0 0 1rem;

  span {
    ${fonts.font1.H14B};
  }
`;

export const BoldStyled = styled.strong`
  ${fonts.font1.H14B};
`;

export const SectiontWrapper = styled.div`
  margin: 0 0 4rem;
  ${fonts.font1.H14}
  line-height: 20px;
`;
