import styled from 'styled-components';

export const P404Wrapper = styled.div`
  top: 6.25rem;
  padding: 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6.25rem;
`;

export const NumWrapper = styled.div`
  padding: 1.25rem 0 0.625rem;
`;

export const HeaderWrapper = styled.div`
  padding: 0.625rem 0 2.5rem;
`;

export const Text1Wrapper = styled.div`
  padding: 0 0 1.434rem;
`;
