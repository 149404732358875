import { useState } from 'react';
import { PasswordRequirementValidationSchema } from '../utils/validation';

const defaultValue: Record<string, boolean> = {
  length: true,
  uppercase: true,
  lowercase: true,
  number: true,
  character: true,
};

export const usePasswordRequirement = () => {
  const [passwordRequirements, setPasswordRequirements] = useState<Record<string, boolean>>(defaultValue);
  const [displayValidationText, setDisplayValidationText] = useState(false);
  const handlePasswordChecking = async (
    value: string,
  ) => {
    const errors: { [key: string]: string } = {};
    const errorValue = { ...defaultValue };
    setDisplayValidationText(false);
    try {
      await PasswordRequirementValidationSchema.validate({
        length: value,
        uppercase: value,
        lowercase: value,
        number: value,
        character: value,
      }, { abortEarly: false });
    } catch (error: any) {
      setDisplayValidationText(true);
      if (error.inner) {
        error.inner.forEach((element: any) => {
          errors[element.path] = element.message;
        });
      }
      const errorArray = Object.keys(errors || {});
      Object.keys(passwordRequirements).forEach((requirement: any) => {
        errorValue[requirement] = !errorArray.includes(requirement);
      });
    }

    setPasswordRequirements(errorValue);
  };
  return {
    hookHandlePasswordChecking: handlePasswordChecking,
    hookPasswordRequirements: passwordRequirements,
    hookDisplayValidationText: displayValidationText,
  };
};
