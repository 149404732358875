import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';

type DayItemProps = {
  readonly isToday?: boolean;
};

type WrapperProps = {
  readonly isOpen: boolean;
};

export const WrapperStyled = styled.div<WrapperProps>`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
`;

export const InnerStyled = styled.div<WrapperProps>`
  max-width: 940px;
  max-height: 550px;
  background-color: ${colours.colour4};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  margin-top: ${({ isOpen }) => (isOpen ? '0' : '-50px')};
`;

export const BodyStyled = styled.div`
  width: 316px;
  display: flex;
  flex-direction: column;
  &:nth-of-type(2) {
    margin-left: 3rem;
  }
`;

export const WeekListStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const WeekItemStyled = styled.div`
  padding: 0 ${toRem(12)};
  width: calc(100% / 7);
  ${fonts.font1.H14B}
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  color: ${colours.colour6};
`;

export const DayListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  min-height: 220px;
`;

export const DayItemStyled = styled.label<DayItemProps>`
  ${fonts.font1.H14}
  width: calc(100% / 7);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  color: ${colours.colour6};
  cursor: pointer;
  border-radius: 5px;
  ${({ isToday }) => {
    if (isToday) {
      return `
        text-decoration: underline;
        text-decoration-color: ${colours.colour2};
        text-decoration-thickness: 2px;
        text-underline-position: under;
      `;
    }
  }}
  &:hover {
    background-color: ${colours.colour4};
    color: ${colours.colour1};
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
  input {
    display: none;
    &:checked + span {
      background-color: ${colours.colour2};
      color: ${colours.colour6};
      font-weight: 700;
    }
  }
`;

export const InvalidDayStyled = styled(DayItemStyled)`
  color: ${colours.colour35};
  cursor: default;
  &:hover {
    color: ${colours.colour35};
    background-color: transparent;
  }
`;

export const MonthWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const SelectionStyled = styled.select`
  cursor: pointer;
  border: none;
  background-color: transparent;
  ${fonts.font1.H14}
`;

export const DateAreaStyled = styled.div`
  display: flex;
`;

export const RangeSelectorContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colours.colour15};
  margin-right: 2rem;
  padding: 0 2rem 0 0;
`;

export const RangeSelectorButtonStyled = styled.button`
  margin-bottom: 1.8rem;
  text-align: left;
  background-color: transparent;
  border: none;
  ${fonts.font1.H14}
  color: ${colours.colour1};
  cursor: pointer;
  &:hover {
    color: ${colours.colour2};
  }
`;

export const FooterStyled = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${colours.colour15};
  justify-content: flex-end;
  align-items: center;
  padding-top: ${toRem(24)};
  button {
    margin-left: 1rem;
  }
`;

export const BodyTopStyled = styled.div`
  display: flex;
`;
