import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

export const NotVisibleStyled = styled.p`
  color: ${colours.colour1};
  width: fit-content;
  ${fonts.font1.H14B};
  vertical-align: middle;

  svg {
    margin-right: 0.5rem;
  }
`;
