import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from './styles';

export const NotVisible = () => (
  <Styled.NotVisibleStyled>
    <FontAwesomeIcon icon="eye-slash" size="xs" />
    Not visible to public
  </Styled.NotVisibleStyled>
);
