import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import { LinkComponent } from '../../components/Link';
import { TextP } from '../../components/Text';
import Toast from '../../components/Toast';
import WebsiteAppWrapper from '../../components/WebsiteAppWrapper';
import { useProjectInterestEmail } from '../../hooks';
import * as Styled from '../../styles/ProjectInterestEmailStyles';

const ProjectInterestEmail = () => {
  const location: any = useLocation();
  const {
    project,
    createProjectInterest,
    deleteProjectInterest,
    setToastIsOpen,
    toastIsOpen,
    toastMessage,
    error,
    isLoading,
  } = useProjectInterestEmail();

  useEffect(() => {
    createProjectInterest(new URLSearchParams(location.search));
  }, [location]);

  return (
    <WebsiteAppWrapper>
      <Styled.BodyStyled>
        {!isLoading && (
          <div>
            <div>
              {project && (
                <TextP>
                  {project.type === 'study' ? 'Study: ' : 'Patient Partner Research Opportunity: '}
                  {project.title}
                </TextP>
              )}
              <Styled.MessageStyled>
                {error ? (
                  <TextP>
                    Failed to remove. Please try again
                  </TextP>
                ) : (
                  <TextP>
                    You have successfully removed this study from your match list.
                  </TextP>
                )}
              </Styled.MessageStyled>
            </div>
            <Styled.ButtonWrapper>
              <LinkComponent url="/" theme="buttonThird">
                <div>
                  View All My Matches
                </div>
              </LinkComponent>
            </Styled.ButtonWrapper>

            {project && (
              <Styled.FooterWrapper>
                <Styled.FooterTextWrapper>
                  <TextP>Didn&apos;t mean to remove it?</TextP>
                </Styled.FooterTextWrapper>
                <Button
                  id="restoreMatch"
                  onClick={() => deleteProjectInterest(new URLSearchParams(location.search))}
                  theme="underline"
                >
                  <TextP>Restore Match</TextP>
                </Button>
              </Styled.FooterWrapper>
            )}
          </div>
        )}
      </Styled.BodyStyled>
      <Toast
        message={toastMessage}
        isOpen={toastIsOpen}
        setIsOpen={setToastIsOpen}
        checkmark
      />
    </WebsiteAppWrapper>
  );
};

export default ProjectInterestEmail;
