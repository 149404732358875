import React from 'react';
import * as Styled from './styles';
import { EmptyProjectIcon } from '../Icons';
import { LinkComponent } from '../Link';

type EmptyStatePropTypes = {
  message: {
    title: string,
    description: string
    path?: string
    url?: string
    list?: string[]
  }
}
export const EmptyState = ({ message }: EmptyStatePropTypes) => (
  <Styled.EmptyStateWrapper>
    <EmptyProjectIcon />

    <Styled.ContainWrapperStyled>
      <Styled.TitleStyled>
        {message.title}
      </Styled.TitleStyled>
      <Styled.DescriptionStyled>{message.description}</Styled.DescriptionStyled>
      {message.url && (
        <Styled.LinkWrapper>
          <LinkComponent url={message.url} theme="button">
            <div>
              {message.path}
            </div>
          </LinkComponent>
        </Styled.LinkWrapper>
      )}
    </Styled.ContainWrapperStyled>

    {message.list && (
      <Styled.ContainWrapperStyled theme="secondary">
        <Styled.ListStyled>
          {message.list.map((item) => (
            <Styled.ListItemStyled key={item.slice(0, 6)}>
              {item}
            </Styled.ListItemStyled>
          ))}
        </Styled.ListStyled>
      </Styled.ContainWrapperStyled>
    )}
  </Styled.EmptyStateWrapper>
);
