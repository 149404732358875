import React from 'react';
import * as Styled from './styles';
import { PROJECT_DETAILS } from '../../../../components/ProjectDetails/const';
import Button from '../../../../components/Button';

type TabSwitcherProps = {
  currentTab: string
  activeSection?: string
  setCurrentTab: (currentTab: string) => void
  hasResults?: number
  scrollHandler: (id: string) => void
}

const TabSwitcher = ({
  currentTab, setCurrentTab, hasResults, scrollHandler, activeSection,
}: TabSwitcherProps) => (
  <Styled.TabWrapper>
    <Styled.TabsContainerStyled>
      {PROJECT_DETAILS.TABS.map((tab) => (
        <Styled.LinkStyled
          selected={activeSection === tab.URL}
          key={tab.LABEL}
          id={`link-${tab.URL}`}
          onClick={() => scrollHandler(tab.URL)}
        >
          {tab.LABEL}
        </Styled.LinkStyled>
      ))}
    </Styled.TabsContainerStyled>

    {!!hasResults && (
      <Styled.ButtonWrapper>
        <Button
          key="result"
          id="tab-opportunities-results"
          onClick={() => setCurrentTab(currentTab === 'Results' ? 'Detail' : 'Results')}
          theme="defaultSm"
        >
          <>
            <Styled.DynamicContainer isMobile>
              {currentTab === 'Results' ? 'Details' : 'View Results'}
            </Styled.DynamicContainer>
            <Styled.DynamicContainer>
              {currentTab === 'Results' ? 'Details' : 'View Study Results'}
            </Styled.DynamicContainer>
          </>
        </Button>
      </Styled.ButtonWrapper>
    )}

  </Styled.TabWrapper>
);

export default TabSwitcher;
