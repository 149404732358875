import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';

type TextWrapperProps = {
  readonly type?: string
}

type IconWrapperPropTypes = {
  readonly theme?: 'secondary'
}

type SubTitleStyledPropTypes = {
  readonly theme?: 'secondary'
}

type SectionGridStyledPropTypes = {
  readonly theme?: 'secondary'
}

type SectionStyledPropTypes = {
  readonly theme?: 'secondary'
}

type SideBarSectionStyledPropTypes = {
  readonly theme?: 'secondary'
  readonly margin?: 'small'
}

type SideBarTitleStyledPropTypes = {
  readonly hasMarginBottom?: boolean
}

type TagStyledPropTypes = {
  readonly isResearcher: boolean
}
export const ProjectDetailStyled = styled.div`
  display: flex;
  flex-direction: column;
  ${fonts.font1.H15};
  line-height: 1.33rem;
  
  @media (min-width: 768px) {
    flex-direction: row;
    column-gap: ${toRem(40)};
  }
`;

export const MainStyled = styled.section`
  width: 100%;
  padding: 0 0.875rem;
  word-wrap: break-word;  

  @media (min-width: 768px) {
    width: 75%;
    padding: 0 0 0.875rem;
  }
`;

export const SideBarStyled = styled.section`
  padding: 0 ${toRem(14)} ${toRem(14)};

  @media (min-width: 768px) {
    width: 25%;
    min-width: ${toRem(380)};
    padding: 0;    
  }
`;

export const TextWrapper = styled.div <TextWrapperProps>`
  margin: ${({ type }) => {
    switch (type) {
      case 'header':
        return '0.875rem 0';
      case 'subHeader':
        return '1.5rem 0 0.875rem';
      case 'tag':
        return '0 0.875rem';
      case 'button':
        return '0 0 0 0.5rem';
      case 'text':
        return '0 0 0.5rem';
      default:
        return '0 0 1.375rem';
    }
  }};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TagStyled = styled.div<TagStyledPropTypes>`
  -webkit-font-smoothing: antialiased;
  background: ${colours.colour21};
  border: 2px solid ${colours.colour26};
  border-radius: 4px;
  color: ${colours.colour5};
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 6px 10px;
  margin-top: ${({ isResearcher }) => (isResearcher ? toRem(16) : 0)};
`;

export const SectionStyled = styled.section<SectionStyledPropTypes>`
  margin: ${toRem(50)} 0;
  padding: 0 0 ${toRem(50)};
  ${fonts.font1.H16};
  line-height: ${toRem(26)};
  border-bottom: ${({ theme }) => (theme === 'secondary' ? 'none' : `1px solid ${colours.colour29}`)};
`;

export const TextItemWrapper = styled.div`
  margin-top: 0.875rem;
`;

export const LinkWrapper = styled.div`
  display: flex;
`;

export const IconWrapper = styled.span<IconWrapperPropTypes>`
  margin-right: ${toRem(8)};

  color: ${({ theme }) => (theme === 'secondary' ? colours.colour1 : 'inherit')};
`;

export const ListStyled = styled.p`
  margin-bottom: ${toRem(16)}; 

  span {
    ${fonts.font1.H16B};
    text-transform: capitalize;
  }
`;

export const TitleStyled = styled.h3`
  ${fonts.font1.H24B};
  margin-bottom: ${toRem(30)};
`;

export const SubTitleStyled = styled.p<SubTitleStyledPropTypes>`
  ${fonts.font1.H16B};
  margin-bottom: ${toRem(10)};
  margin-top: ${({ theme }) => (theme === 'secondary' ? 0 : toRem(30))};
`;

export const SectionGridStyled = styled.div<SectionGridStyledPropTypes>`
  display: ${({ theme }) => (theme === 'secondary' ? 'flex' : 'grid')};
  grid-template-columns: repeat( 2, minmax(45%, 1fr));
  flex-wrap: wrap;
    gap: ${({ theme }) => (theme === 'secondary' ? toRem(14) : `${toRem(30)} ${toRem(14)}`)};

  @media (min-width: 370px) {
    grid-template-columns:  ${({ theme }) => (theme === 'secondary' ? 'repeat( 1, minmax(1fr, 1fr))' : 'repeat( 3, minmax(33%, 1fr))')};
  }

  @media (min-width: 400px) {
    grid-template-columns:  repeat( 3, minmax(33%, 1fr));
  }

  @media (min-width: 500px) {
    display: grid;
    grid-template-columns: repeat( 3, minmax(33%, 1fr));
    gap: ${({ theme }) => (theme === 'secondary' ? 0 : `${toRem(30)} ${toRem(14)}`)};
  }
`;

export const GridItemContainer = styled.div`
  display: flex;
  line-height: ${toRem(16)};
`;

export const SideBarSectionStyled = styled.div<SideBarSectionStyledPropTypes>`
  display: flex;
  padding: ${toRem(32)};
  border-radius: 16px;
  flex-direction: column;
  gap: ${({ margin }) => (margin === 'small' ? toRem(8) : toRem(24))};
  background: ${({ theme }) => (theme === 'secondary' ? colours.colour50 : colours.colour4)};
  border: 1px solid ${({ theme }) => (theme === 'secondary' ? colours.colour28 : colours.colour39)};
`;

export const SideBarWrapper = styled.div`
  display: flex;
  gap: ${toRem(40)};
  flex-direction: column;
`;

export const SideBarTitleStyled = styled.h3<SideBarTitleStyledPropTypes>`
  ${fonts.font1.H20B};
  margin-bottom: ${({ hasMarginBottom }) => (hasMarginBottom ? toRem(8) : 0)};
`;

export const ContainerWrapper = styled.div`
  margin-top: ${toRem(8)};
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
`;
