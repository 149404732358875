import styled from 'styled-components';
import { colours, fonts } from '../../../styles/theme';

type AccordionChildernStyledProps = {
  readonly isOpen: boolean
}

type IconWrapperProps = {
  readonly theme?: string
}

type ButtonStyledProps = {
  readonly theme?: string
}

export const AccordionChildernStyled = styled.div<AccordionChildernStyledProps>`
  transition: all 1s ease-out;
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? '2000px' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  width: 100%;
`;

export const ButtonStyled = styled.button<ButtonStyledProps>`
  cursor: pointer;
  transition: all .3s ease;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: ${({ theme }) => (theme === 'modelMenu' ? 'center' : 'left')};
  border: none;
  border-top: ${({ theme }) => (theme === 'modelMenu' ? 'none' : `1px solid ${colours.colour5}`)};
  border-bottom: ${({ theme }) => (theme === 'modelMenu' ? `3px solid ${colours.colour2}` : 'none')};
  box-shadow: none;
  background: transparent;
  padding: ${({ theme }) => (theme === 'modelMenu' ? '0.5rem 0' : '0.938rem 0')};
  ${({ theme }) => (theme === 'modelMenu' ? fonts.font1.H18 : fonts.font1.H15B)};
`;

export const WrapperStyled = styled.div`
  display: flex;
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  margin: ${({ theme }) => (theme === 'modelMenu' ? '0 0 0 0.65rem' : '0 0.65rem 0 0')};
`;

export const ButtonLabelStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
