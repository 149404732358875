import styled from 'styled-components';
import { breakpoints, colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';
import { TextP } from '../Text';

type ButtonStyledProps = {
  readonly selected: boolean
}

type TableItemsStyledProps = {
  readonly odd: boolean
}

export const VolunteerDashboardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;

  @media (min-width: 600px) {
    padding: 0;
  }
`;

export const MobileWrapper = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  max-width: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    width: 100%;
    padding: 0;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid ${colours.colour19};
`;

export const DescriptionWrapper = styled.div`
  padding: 1rem 0 ${toRem(32)} 0;
`;

export const ButtonStyled = styled.button<ButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  cursor: pointer;
  ${fonts.font1.H12B}
  border: none;
  border-bottom: 2px solid ${({ selected }) => (selected ? colours.colour1 : 'transparent')};
  background: transparent;
  padding: 0.75rem 0.875rem;
  margin-bottom: -2px;

  &:hover {
    border-bottom: 2px solid ${colours.colour6};
  }

  ${TextP} {
    color: ${({ selected }) => (selected ? colours.colour1 : colours.colour6)};
    text-align: center;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${fonts.font1.H14B};
    padding: 0.75rem 1rem;
  }
`;

export const PageCountWrapper = styled.div`
  padding: 4px 8px;
  margin-left: 8px;
  background-color: ${colours.colour28};
  border-radius: 6px;
  color: ${colours.colour34};
`;

export const TableItemsWrapper = styled.div`
border-bottom: 1px solid ${colours.colour12};
`;

export const TableItemStyled = styled.div<TableItemsStyledProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  background-color: ${({ odd }) => (odd ? colours.colour28 : colours.colour4)};
  border-bottom: 1px solid ${colours.colour41};
`;

export const SearchBarWrapper = styled.div`
  padding-bottom: 1rem;
`;

export const TableFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
`;

export const LinkButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colours.colour2};

  ${TextP} {
    padding-left: ${toRem(10)};
  } 
`;

export const TableItemContentWrapper = styled.div`
  display: flex;
  padding-top: ${toRem(24)};
`;

export const DeleteWrapper = styled.div`
  margin-right: 1rem;
`;

export const WithdrawButtonWrapper = styled.div`
  margin-right: 1rem;
`;

export const WithdrawButtonTextWrapper = styled.div`
  display: flex;
  align-items: center;
  ${TextP} {
    padding-left: ${toRem(10)};
  } 
`;

export const StatusTagWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;

export const NoOpportunitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;

  ${TextP} {
    margin-bottom: .5rem;
  }
`;

export const ProfileButtonWrapper = styled.div`
  margin-top: .5rem;
`;

export const ProfileIncompleteNotificationWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  padding: 1rem 1.5rem;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  border-width: 0.063rem 0.063rem 0.063rem 0.25rem;
  border-style: solid;
  border-color: ${colours.colour1};
  border-radius: 0.375rem;
  filter: drop-shadow(0px 2px 8px rgba(32, 37, 50, 0.08)) drop-shadow(0px 2px 4px rgba(32, 37, 50, 0.03));

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ImportantWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;
  margin-bottom: 0.5rem;
`;
