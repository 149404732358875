export const useGenericCookies = () => {
  const createCookie = (name: string, value: string, expireTime: string) => {
    document.cookie = `${name}=${value}; expires=${new Date(expireTime).toUTCString()}; path=/;`;
  };

  const getCookie = (name: string) => {
    try {
      const value = document.cookie;
      const cookie = value.split('; ').filter((cookieValue) => {
        const cookieName = cookieValue.split('=')[0];
        return cookieName === name;
      });
      return cookie[0].split('=')[1];
    } catch (err) {
      return '';
    }
  };

  return {
    createCookie, getCookie,
  };
};
