import React from 'react';
import AppWrapper from '../../../components/AppWrapper';
import ProjectForm from '../../../components/ProjectForm';
import { ppoForm } from '../../../utils/researcherConst';
import PPOForm from '../../../components/PPOForm';
import { usePPONew } from './usePPONew';

const PPONew = () => {
  const hook = usePPONew();

  return (
    <AppWrapper>
      <ProjectForm
        generalInfo={ppoForm}
        selectedStatus={hook.formHook.formInfo.desired_status}
        desiredStatus
        handleSubmit={hook.handleFormSubmit}
        handleDraft={hook.handleCreateDraft}
        handleChange={hook.formHook.handleChange}
        toastIsOpen={hook.formHook.toastIsOpen}
        toastText={hook.formHook.toastMessage}
        isSubmiting={hook.formHook.isSubmiting}
        setToastIsOpen={hook.formHook.setToastIsOpen}
        setModalType={hook.formHook.setModalType}
        rightButtonAction={hook.handleRightButtonAction}
        leftButtonAction={hook.handleLeftButtonAction}
        modalOpen={hook.formHook.modalIsOpen}
        setModalOpen={hook.formHook.setModalIsOpen}
        modalInfo={hook.formHook.modalType}
        errors={hook.formHook.formErrors}
        changesMade={hook.formHook.changesMade}
        handleNavigate={hook.handleNavigate}
        setStatusModal={hook.formHook.setStatusModal}
        statusModal={hook.formHook.statusModal}
        canEdit
        recruitmentEndDate={hook.formHook.formInfo.recruitment_end}
      >
        <PPOForm
          formInfo={hook.formHook.formInfo}
          formErrors={hook.formHook.formErrors}
          teamMembers={hook.formHook.teamMembers}
          collaboratingOrganizations={hook.formHook.collaboratingOrganizations}
          collaborators={hook.formHook.collaborators}
          healthAuthorityRegionOptions={hook.formHook.healthRegions}
          healthCategories={hook.formHook.healthCategory}
          hospitalList={hook.formHook.hospitals}
          selectedResearchAreas={hook.formHook.selectedResearchAreas}
          adminComments={hook.formHook.formInfo.admin_comments}
          isDraft={hook.formHook.isDraft}
          handleChange={hook.formHook.handleChange}
          handleAddInput={hook.formHook.handleAddInput}
          handleChangeAddedInput={hook.formHook.handleChangeAddedInput}
          handleChangeResearchAreas={hook.formHook.toggleResearchArea}
          handleCheck={hook.formHook.handleCheck}
          handleRemoveInput={hook.formHook.handleRemoveInput}
          handleToggle={hook.formHook.handleToggle}
          checkPI={hook.formHook.checkPI}
          checkTeamMember={hook.formHook.checkTeamMember}
          handleDelete={() => {
            hook.formHook.setModalType('delete');
            hook.formHook.setModalIsOpen(true);
          }}
          canEdit
          forceChange={hook.formHook.forceChange}
          handleSingleValidation={hook.formHook.handleSingleValidation}
        />
      </ProjectForm>
    </AppWrapper>
  );
};

export default PPONew;
