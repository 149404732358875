import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import { fonts } from '../../../styles/theme';
import Button from '../../Button';
import { LinkComponent } from '../../Link';
import { TextP } from '../../Text';
import AccordionWeb from '../Accordion';
import { Figure } from '../../Figure';
import { imageList } from '../../../utils/imageList';
import SideDraw from '../../SideDraw';
import environment from '../../../config/environment';

type IProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const Header = ({ isOpen, setIsOpen }: IProps) => (
  <Styled.HeaderStyled>
    <Styled.EndWrappers isLeftEnd>
      <Styled.ImageWrapper>
        <LinkComponent url={`${environment.app.websiteURL}/`}>
          <Figure imageSrc={imageList.logoFull} alt="logo" />
        </LinkComponent>
      </Styled.ImageWrapper>
    </Styled.EndWrappers>

    <Styled.EndWrappers>
      <Styled.DesktopWrapper>
        <Styled.ItemWrapper>
          <LinkComponent url="/volunteer/create" theme="buttonMd">
            <div>
              <TextP>Sign Up</TextP>
            </div>
          </LinkComponent>
        </Styled.ItemWrapper>
        <LinkComponent url="/login" theme="outlineMd">
          <div>
            <TextP>
              Log In
            </TextP>
          </div>
        </LinkComponent>
      </Styled.DesktopWrapper>

      <Styled.MobileWrapper>

        <Button id="menu" onClick={() => setIsOpen(!isOpen)} theme="menuButton">
          <Styled.ButtonTextWrapper>
            <FontAwesomeIcon icon={faBars} />
            <TextP font={fonts.font1.H15}>Menu</TextP>
          </Styled.ButtonTextWrapper>
        </Button>
      </Styled.MobileWrapper>

    </Styled.EndWrappers>

    <SideDraw isOpen={isOpen} setIsOpen={setIsOpen}>
      <Styled.DrawBodyWrapper>
        <Styled.MobileNavWrapper>
          <Styled.MobileLinkWrapper>
            <LinkComponent url={`${environment.app.websiteURL}/volunteers`} theme="modelMenu">
              <TextP>For Volunteers</TextP>
            </LinkComponent>
          </Styled.MobileLinkWrapper>

          <Styled.MobileLinkWrapper>
            <LinkComponent url={`${environment.app.websiteURL}/researchers`} theme="modelMenu">
              <TextP>For Researchers</TextP>
            </LinkComponent>
          </Styled.MobileLinkWrapper>

          <Styled.MobileLinkWrapper>
            <LinkComponent url={`${environment.app.websiteURL}/studies/search`} theme="modelMenu">
              <TextP>Find Studies</TextP>
            </LinkComponent>
          </Styled.MobileLinkWrapper>

          <Styled.MobileLinkWrapper>
            <LinkComponent url={`${environment.app.websiteURL}/about`} theme="modelMenu">
              <TextP>About REACH BC</TextP>
            </LinkComponent>
          </Styled.MobileLinkWrapper>

          <AccordionWeb label="Newsroom" theme="modelMenu">
            <Styled.MobileLinkWrapper theme="accordion">
              <LinkComponent url={`${environment.app.websiteURL}/news-room`} theme="accordion">
                <TextP>Stories</TextP>
              </LinkComponent>
            </Styled.MobileLinkWrapper>

            <Styled.MobileLinkWrapper theme="accordion">
              <LinkComponent url={`${environment.app.websiteURL}/videos`} theme="accordion">
                <TextP>Videos</TextP>
              </LinkComponent>
            </Styled.MobileLinkWrapper>
          </AccordionWeb>

        </Styled.MobileNavWrapper>

        <Styled.MobileDivWrapper>
          <Styled.MobileItemWrapper>
            <LinkComponent
              url="/volunteer/create"
              theme="button"
            >
              <Styled.LinkStyled>
                <TextP>Sign Up</TextP>
              </Styled.LinkStyled>
            </LinkComponent>
          </Styled.MobileItemWrapper>

          <LinkComponent url="/login" theme="outline">
            <Styled.LinkStyled>
              <TextP>
                Log In
              </TextP>
            </Styled.LinkStyled>
          </LinkComponent>
        </Styled.MobileDivWrapper>

        <Styled.MobileItemWrapper>
          <LinkComponent url="/privacystatement" theme="websiteSm">
            <TextP>Privacy Statement and Terms of Use</TextP>
          </LinkComponent>
        </Styled.MobileItemWrapper>
        <Styled.MobileItemWrapper>
          <LinkComponent url="/" theme="websiteSm">
            <TextP>Contact Us</TextP>
          </LinkComponent>
        </Styled.MobileItemWrapper>
      </Styled.DrawBodyWrapper>
    </SideDraw>
  </Styled.HeaderStyled>
);

export default Header;
