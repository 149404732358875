export const STUDY_PURPOSE = {
  TITLE: 'Study Purpose',
  PURPOSE: {
    LABEL: 'Study Purpose',
    DESCRIPTION_LIST: [
      'Explain the purpose of this study in 3-4 sentences. ',
      'Use lay-friendly language, and make sure any acronyms are spelled out on first use.',
      'Use any key words that would help a Volunteer find your study when searching in the REACH Directory.',
    ],
    DESCRIPTION: 'E.g. To examine the effect of a peer support intervention on improving and sustaining glycemic control and diabetes-specific quality of life. (Max character limit is 450).',
  },
  INVOLVED: {
    LABEL: 'What is Involved',
    DESCRIPTION: 'Explain the detailed logistics of the study in 4-5 sentences. What will the participant do in the study? (Max character limit is 700).',
  },
};
