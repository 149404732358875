import styled from 'styled-components';
import { colours, fonts } from '../../../../styles/theme';
import toRem from '../../../../utils/toRem';

type ButtonStyledProps = {
  readonly selected: boolean
}

export const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid ${colours.colour19};
  margin-bottom: ${toRem(32)};
`;

export const ButtonStyled = styled.button<ButtonStyledProps>`
  transition: all .3s ease;
  cursor: pointer;
  ${({ selected }) => (selected ? fonts.font1.H14B : fonts.font1.H14)};
  border: none;
  border-bottom: 2px solid ${({ selected }) => (selected ? colours.colour1 : colours.colour19)};
  color: ${({ selected }) => (selected ? colours.colour5 : colours.colour16)};
  background: transparent;
  padding: 0.75rem 1rem;
  margin-bottom: -2px;

  &:hover {
    border-bottom: 2px solid ${colours.colour6};
  }
`;
