import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconWrapper, TagStyled, TagWrapper } from './styles';
import { TextP } from '../Text';

type StatusTagProps = {
  label: string
  theme?: string
  isRound?: boolean
  icon?: any
  useIcon?: any
  fontSize?: string
  padding?: string
  marginLeft?: boolean
}

const StatusTag = ({
  label, theme, isRound = false, icon, fontSize, padding, useIcon, marginLeft,
}: StatusTagProps) => (
  <TagWrapper>
    <TagStyled
      theme={theme}
      isRound={isRound}
      fontSize={fontSize}
      padding={padding}
      marginLeft={marginLeft}
    >
      <TextP textAlgin="center">
        {icon
          && (
            <IconWrapper>
              <FontAwesomeIcon icon={useIcon || 'circle-check'} size="lg" />
            </IconWrapper>
          )}
        {label}
      </TextP>
    </TagStyled>
  </TagWrapper>
);

export default StatusTag;
