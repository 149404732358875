const environment = {
  api: {
    baseUrl: process.env.REACT_APP_API_URL as string,
  },
  app: {
    userSingInKey: process.env.REACT_APP_SIGNIN_KEY as string,
    recapchaKey: process.env.REACT_APP_CAPTCHA_KEY as string,
    cookieName: process.env.REACT_APP_USER_COOKIE_NAME as string,
    cognitoName: process.env.REACT_APP_USER_COGNITO_COOKIE_NAME as string,
    websiteURL: process.env.REACT_APP_WEBSITE_URL as string,
  },
};

export default environment;
