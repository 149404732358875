import styled from 'styled-components';
import { colours } from '../../styles/theme';

export const IconWrapper = styled.div`
`;

export const PublicToolTipStyled = styled.div`
  margin: 0 0.5rem;
  display: inline-block;
`;

export const ToolTipBodyStyled = styled.div`
  position: absolute;
  display: 'block';
  transition: all .5s ease;
  width: 85%;
  left: 0;
  right: 0;

  @media (min-width: 600px) {
    width: 260px;
    left: inherit;
    right: inherit;
  }
  `;

export const ToolTipTextWrapper = styled.div`
  -webkit-box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
  background: ${colours.colour4};
  padding: 0.5rem;
  position: absolute;
  border: 1px solid ${colours.colour9};
  border-radius: 4px;
  top: -40px;
  left: 16px;

  @media (min-width: 600px) {
    left: -1rem;
    top: -45px;
    right: inherit;
  }
`;

export const ArrowStyled = styled.div`
  width: 10px;
  height: 10px;
  border-right: 1px solid ${colours.colour9};
  border-top: 1px solid ${colours.colour9};
  transform: rotate(135deg);
  margin-top: -11px;
  background: ${colours.colour4};
  left: 0.1rem;
  position: absolute;
  display: none;

  @media (min-width: 600px) {
    display: block;
  }
`;
