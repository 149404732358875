import React, { useEffect, useState } from 'react';
import { Figure } from '../../components/Figure';
import { LinkComponent } from '../../components/Link';
import { LogoWrapper } from '../../components/LoginHeader/styles';
import { TextH2 } from '../../components/Text';
import environment from '../../config/environment';
import { useCookies } from '../../hooks/useCookies';
import { fonts } from '../../styles/theme';
import { imageList } from '../../utils/imageList';
import {
  HeaderWrapper, NumWrapper, P404Wrapper, Text1Wrapper,
} from './styles';

const P404 = () => {
  const { checkCookie } = useCookies();
  const [url, setUrl] = useState('/login');

  useEffect(() => {
    const cookieExists = checkCookie();
    if (cookieExists) setUrl('/dashboard');
  }, []);

  return (
    <P404Wrapper>
      <LinkComponent url={url}>
        <LogoWrapper>
          <Figure imageSrc={imageList.logoFull} alt="logo" />
        </LogoWrapper>
      </LinkComponent>
      <NumWrapper>
        <TextH2 font={fonts.font1.H32B}>404</TextH2>
      </NumWrapper>
      <HeaderWrapper>
        <TextH2 font={fonts.font1.H28}>This page cannot be found or no longer exists.</TextH2>
      </HeaderWrapper>
      <Text1Wrapper>
        <TextH2 font={fonts.font1.H15}>Please check the link you are using or go back to {' '}
          <LinkComponent url={`${environment.app.websiteURL}/`}>reachbc.ca</LinkComponent>
          {' '} to find the page you are looking for. You can also clear your browser cache and try again.
        </TextH2>
      </Text1Wrapper>
      <TextH2 font={fonts.font1.H15}>If you are still experiencing problems, please e-mail us at {' '}
        <LinkComponent url="mailto: admin@reachbc.ca">admin@reachbc.ca</LinkComponent>
        {' '} so we can help sort it out.
      </TextH2>
    </P404Wrapper>
  );
};

export default P404;
