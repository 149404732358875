import environment from '../../config/environment';

export const PROJECT_FORM = {
  LINKS: {
    DASHBOARD: {
      LABEL: 'Dashboard',
      URL: '/dashboard',
    },
    PROJECT_DETAILS: {
      LABEL: 'Research Details',
    },
  },
  RESEARCHER_FAQ: {
    TITLE: 'Visit our FAQs page for guidance on filling out your research study application.',
    DESCRIPTION: 'For additional resources like the Standard Operating Procedures (SOPs) and documents, please visit our Help Centre to learn more.',
    LINKS: {
      FAQ: {
        LABEL: 'Read FAQs',
        URL: 'https://assets.ctfassets.net/rbx11ml43wqx/2ziCOT0pZ5HTKWSjcF1QqT/336df8feeb08ddcc9311f86bce9019b2/Document_213_-_REACH_BC_FAQ_for_Researchers_2023Feb17.pdf',
      },
      HELP_CENTRE: {
        LABEL: 'Visit Researcher Help Centre',
        URL: `${environment.app.websiteURL}/researcher-resources`,
      },
    },
  },
  RESULT_FAQ: {
    TITLE: 'Learn more about posting a closed research study for sharing results.',
    DESCRIPTION: 'For additional resources like the Standard Operating Procedures (SOPs) and documents, please visit our Help Centre to learn more.',
    LINKS: {
      FAQ: {
        LABEL: 'Learn About Sharing Study Results',
        URL: 'https://assets.ctfassets.net/rbx11ml43wqx/7znyH95kebm2Ggu7oPBS5Q/5f2db40f7f7e145d889bf243fd6570be/Document_212_-_Closed_Research_Study_Guide.pdf',
      },
      HELP_CENTRE: {
        LABEL: 'Visit Researcher Help Centre',
        URL: `${environment.app.websiteURL}/researcher-resources`,
      },
    },
  },
  RESEARCH_RECEIVED: {
    TITLE: 'Your research changes have been received.',
    DESCRIPTION: 'The REACH BC Office will review your research opportunity and contact you regarding next steps. Your research opportunity will be hidden on REACH BC directory until the changes are approved. This may take up to 3 business days.',
  },
  RECRUITMENT_DATE: {
    TITLE: 'Estimated Recruitment End Date',
    DESCRIPTION: 'What is the estimated date for when recruitment is expected to be closed?',
  },
  BUTTONS: {
    SAVE_DRAFT: 'Save as Draft',
    SUBMIT_REVIEW: 'Submit for Review',
    RECRUITMENT_PLACEHOLDER: 'YYYY-MM-DD',
    PROJECT_STATUS: 'Choose Project Status',
    DISCARD: 'Discard Changes',
  },
  PROJECT_STATUS: {
    DESCRIPTION: 'Please select a desired status for your research study upon approval by the REACH BC Office.',
    LABEL: 'Desired status ',
  },

  ERROR_MESSAGES: {
    DESCRIPTION_START: 'There are ',
    DESCRIPTION_FINISH: ' errors with the following fields',
  },
};

export const FORM_MODELS = {
  STATUS: {
    TITLE: 'Choose Project Status',
    DESCRIPTION: 'Please select a desired status for your research study upon approval by the REACH BC Office.',
  },
};
