import logoFull from '../assets/images/logo.png';
import backgroundImage from '../assets/images/rolling-waves.jpg';
import volunteerLogin from '../assets/images/volunteerLogin.png';
import researcherLogin from '../assets/images/researcherLogin.png';
import bcAhsnlogo from '../assets/images/logo-bc-ahsn.png';

export const imageList = {
  logoFull,
  backgroundImage,
  volunteerLogin,
  researcherLogin,
  bcAhsnlogo,
};
