import React, { useEffect } from 'react';
import { Table } from '../../../../components/Table';
import { columnsVolunteer, enrollmentStatusOptions } from '../../../../utils/researcherConst';
import { TextInput, UserNotice } from '../../../../components';
import { MANAGER_VOLUNTEER_TABLE } from './const';
import { Popover } from '../../../../components/Popover';
import * as Styled from './styles';
import { Checkbox } from '../../../../components/CheckBox';
import Button from '../../../../components/Button';
import { SelectInput } from '../../../../components/SelectInput';

type VolunteerTableProps = {
  volunteerInfo?: any[]
  projectId: string
  getInterested: (id: string) => void
  handleFollowUpUpdate?: (enrollment_status: string, volunteer_id: string, project_id: string) => void
  updateFilterHandler: (value: string, check: boolean) => void
  enrollentStatusFilter: string[]
  searchValueHandler: (searchValue: string) => void
  searchValue: string
  selectedInterests: number[]
  setSelectedInterestsHandler: (selectedInterests: number, check: boolean) => void
  updateProjectInterestsHandler: (enrollmentStatus: string) => void
}

const VolunteerTable = ({
  volunteerInfo, getInterested, projectId, handleFollowUpUpdate, updateFilterHandler, enrollentStatusFilter,
  searchValue, searchValueHandler, selectedInterests, setSelectedInterestsHandler, updateProjectInterestsHandler,
}: VolunteerTableProps) => {
  useEffect(() => {
    getInterested(projectId);
  }, []);

  return (
    <div>
      <UserNotice
        description={MANAGER_VOLUNTEER_TABLE.NOTIFICATOIN.DESCRIPTION}
        title=""
      />
      <Styled.SearchBarWrapper>
        <TextInput
          setTextValue={searchValueHandler}
          textValue={searchValue}
          placeholder={MANAGER_VOLUNTEER_TABLE.INPUTS.SEARCH.PLACEHOLDER}
          theme="searchSecondary"
          isSearchBar
        />
      </Styled.SearchBarWrapper>
      {selectedInterests.length > 0 ? (
        <Styled.SelectBarActionWrapper>
          <Styled.SelectedButtonWrapper>
            <Button
              onClick={() => setSelectedInterestsHandler(0, false)}
              id="clear-volunteer"
              theme="outlineFouth"
            >
              {MANAGER_VOLUNTEER_TABLE.BUTTON.CLEAR}
            </Button>
            <p>{selectedInterests.length} selected</p>
          </Styled.SelectedButtonWrapper>
          <Styled.SelectedActionWrapper>

            <SelectInput
              label={MANAGER_VOLUNTEER_TABLE.INPUTS.ACTION.LABEL}
              value=""
              placeholder={MANAGER_VOLUNTEER_TABLE.INPUTS.ACTION.PLACEHOLDER}
              onChange={updateProjectInterestsHandler}
              id="bulk-emrollmentStatus"
              options={enrollmentStatusOptions}
              isRequired={false}
              theme="secondary"
            />
          </Styled.SelectedActionWrapper>
        </Styled.SelectBarActionWrapper>
      ) : (
        <Styled.PopoverWrapper>
          <Styled.PopoverLabel>{MANAGER_VOLUNTEER_TABLE.INPUTS.FILTER.LABEL}</Styled.PopoverLabel>
          <Popover
            buttonLabel="Follow-Up Status"
            buttonTheme={enrollentStatusFilter.length > 0 ? 'popoverOutlineSecondarySelected' : 'popoverOutlineSecondary'}
            location="bottom"
            buttonCount={enrollentStatusFilter.length !== 0 ? enrollentStatusFilter.length : undefined}
            useArrow
          >
            <Styled.PopoverBodyWrapper>
              {enrollmentStatusOptions.map((option) => (
                <Checkbox
                  isChecked={enrollentStatusFilter.includes(option.value)}
                  label={option.label}
                  onChange={(check) => updateFilterHandler(option.value, check)}
                />
              ))}
              <Styled.PopoverFooterStyled>
                <Button
                  theme="plainPopover"
                  onClick={() => updateFilterHandler('clear', true)}
                  id="clear_filter"
                >
                  {MANAGER_VOLUNTEER_TABLE.BUTTON.CLEAR}
                </Button>
              </Styled.PopoverFooterStyled>
            </Styled.PopoverBodyWrapper>
          </Popover>
        </Styled.PopoverWrapper>
      )}

      <Table
        columnHeaders={columnsVolunteer}
        tableData={volunteerInfo || []}
        tableType="volunteer_table"
        handleFollowUpUpdate={handleFollowUpUpdate}
        projectId={projectId}
        checkboxHandler={setSelectedInterestsHandler}
        selectedCheckboxs={selectedInterests}
      />
    </div>
  );
};

export default VolunteerTable;
