import React from 'react';
import FormSection from '../../../FormSelection';
import { TextArea } from '../../../TextArea.tsx';
import { ADMIN_COMMENTS } from './const';

type CommentsProps = {
  adminComments: string
}
const Comments = ({ adminComments }: CommentsProps) => (
  <FormSection title={ADMIN_COMMENTS.TITLE} theme="secondary">
    <TextArea
      textValue={adminComments}
      setTextValue={() => ''}
      isDisabled
      isRequired={false}
    />
  </FormSection>
);

export default Comments;
