import React, { ReactNode, useState } from 'react';

import Toast from '../Toast';
import { Modal } from '../Modal';
import { PROJECT_FORM } from './const';
import { UserNotice } from '../UserNotice';
import {
  FormSideMenu, HeroBanner, ModelDefault, ModelStatus,
} from './components';
import { Calendar } from '../Calendar';
import * as Styled from './styles';

type ProjectFormProps = {
  children: ReactNode
  generalInfo: {
    title: string,
    description: string[]
    downloads?: {
      boldText?: string
      startText?: string,
      endText: string,
      linkText: string,
      downloadUrl: string
    }[]
  }
  heroInfo?: {
    title?: string
    topSubtitle?: string
    bottomSubtitle?: string
    patientOriented?: string
    type?: string
  }
  isDraft?: boolean
  isSubmiting: boolean
  status?: string
  isEdit?: boolean
  canEdit?: boolean
  errors: { [key: string]: string }
  selectedStatus?: string
  desiredStatus?: boolean
  recruitmentEndDate?: Date
  projectPath?: string
  toastIsOpen?: boolean
  toastText?: string
  modalOpen?: boolean
  modalInfo?: string
  changesMade?: boolean
  stepBackStatus?: boolean
  setToastIsOpen?: (toastIsOpen: boolean) => void
  setModalOpen?: (modalOpen: boolean) => void
  handleChange?: (value: string | number | boolean | Date, field: string) => void
  handleSubmit: () => void
  handleDraft?: () => void
  rightButtonAction?: () => void
  leftButtonAction?: () => void
  setModalType?: (modalType: string) => void
  handleNavigate?: (path: string) => void
  setStatusModal?: (statusModal: boolean) => void
  statusModal?: boolean
  isResults?: boolean
}

const ProjectForm = ({
  children,
  generalInfo,
  isSubmiting,
  errors,
  status,
  selectedStatus,
  isDraft = true,
  desiredStatus,
  recruitmentEndDate,
  heroInfo,
  projectPath,
  toastIsOpen = false,
  toastText,
  modalInfo = '',
  modalOpen = false,
  changesMade = false,
  stepBackStatus = false,
  setModalType = () => '',
  isEdit,
  setModalOpen = () => '',
  setToastIsOpen = () => '',
  rightButtonAction = () => '',
  leftButtonAction = () => '',
  handleNavigate = () => '',
  handleChange,
  handleSubmit,
  handleDraft,
  setStatusModal,
  statusModal,
  isResults,
  canEdit,
}: ProjectFormProps) => {
  const [calenderToggle, setCalenderToggle] = useState(false);
  return (
    <>
      <HeroBanner
        generalInfo={generalInfo}
        changesMade={changesMade}
        handleNavigate={handleNavigate}
        heroInfo={heroInfo}
        projectPath={projectPath}
        setModalOpen={setModalOpen}
        setModalType={setModalType}
      />

      <UserNotice
        title={isResults ? PROJECT_FORM.RESULT_FAQ.TITLE : PROJECT_FORM.RESEARCHER_FAQ.TITLE}
        description={isResults ? [PROJECT_FORM.RESULT_FAQ.DESCRIPTION] : [PROJECT_FORM.RESEARCHER_FAQ.DESCRIPTION]}
        link={{
          label: isResults ? PROJECT_FORM.RESULT_FAQ.LINKS.FAQ.LABEL : PROJECT_FORM.RESEARCHER_FAQ.LINKS.FAQ.LABEL,
          path: isResults ? PROJECT_FORM.RESULT_FAQ.LINKS.FAQ.URL : PROJECT_FORM.RESEARCHER_FAQ.LINKS.FAQ.URL,
        }}
        secondaryLink={{
          label: isResults ? PROJECT_FORM.RESULT_FAQ.LINKS.HELP_CENTRE.LABEL : PROJECT_FORM.RESEARCHER_FAQ.LINKS.HELP_CENTRE.LABEL,
          path: isResults ? PROJECT_FORM.RESULT_FAQ.LINKS.HELP_CENTRE.URL : PROJECT_FORM.RESEARCHER_FAQ.LINKS.HELP_CENTRE.URL,
        }}
      />

      <Styled.SpiltSectionStyled>
        <Styled.ChildrenWrapper>
          {children}
        </Styled.ChildrenWrapper>

        <Styled.StickyDivStyled>
          <FormSideMenu
            errors={errors}
            handleSubmit={handleSubmit}
            isSubmiting={isSubmiting}
            changesMade={changesMade}
            desiredStatus={desiredStatus}
            handleChange={handleChange}
            handleDraft={handleDraft}
            isDraft={isDraft}
            isEdit={isEdit}
            isResults={isResults}
            selectedStatus={selectedStatus}
            setModalOpen={setModalOpen}
            setModalType={setModalType}
            setStatusModal={setStatusModal}
            status={status}
            statusModal={statusModal}
            stepBackStatus={stepBackStatus}
            calenderToggle={calenderToggle}
            canEdit={canEdit}
            recruitmentEndDate={recruitmentEndDate}
            setCalenderToggle={setCalenderToggle}
          />
        </Styled.StickyDivStyled>
      </Styled.SpiltSectionStyled>

      <Modal isOpen={modalOpen}>
        <ModelDefault
          modalInfo={modalInfo}
          setModalOpen={setModalOpen}
          leftButtonAction={leftButtonAction}
          rightButtonAction={rightButtonAction}
        />
      </Modal>

      <Modal isOpen={statusModal || false} type="mobile">
        <ModelStatus
          errors={errors}
          desiredStatus={desiredStatus}
          handleChange={handleChange}
          selectedStatus={selectedStatus}
          setStatusModal={setStatusModal}
          stepBackStatus={stepBackStatus}
        />
      </Modal>

      <Calendar
        onApply={(value) => {
          if (handleChange) handleChange(value, 'recruitment_end');
          setCalenderToggle(false);
        }}
        date={recruitmentEndDate ? new Date(`${recruitmentEndDate}`?.slice(0, 15) || new Date()) : new Date()}
        isOpen={calenderToggle}
        setIsOpen={setCalenderToggle}
      />

      <Toast
        message={toastText}
        isOpen={toastIsOpen}
        setIsOpen={setToastIsOpen}
      />
    </>
  );
};

export default ProjectForm;
