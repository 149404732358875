import styled from 'styled-components';
import { colours } from '../../styles/theme';
import toRem from '../../utils/toRem';

type PopoverWrapperProps = {
  readonly isOpen: boolean
  readonly location?: string
};

type ButtonWrapperProps = {
  readonly flexDirection?: string
}

type PopoverWrapperPropTypes = {
  readonly fitContent: boolean
}

type PopoverTextStylesPropTypes = {
  readonly font: string;
}

export const PopoverWrapper = styled.div<PopoverWrapperPropTypes>`
  position: relative;
  width: ${({ fitContent }) => (fitContent ? 'fit-content' : '100%')};
`;

export const PopoverBodyWrapper = styled.div<PopoverWrapperProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  background: ${colours.colour4};
  border: 1px solid ${colours.colour17};
  border-radius: 0;
  -webkit-box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
  color: ${colours.colour6};
  line-height: 1.33;
  /* max-width: 250px; */
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  width: 100%;
  left: ${({ location }) => {
    switch (location) {
      case 'bottomCenter': {
        return '-1rem';
      }
      default:
        return 0;
    }
  }};;
  right: ${({ location }) => {
    switch (location) {
      case 'bottomCenter': {
        return 0;
      }
      default:
        return '';
    }
  }};
  top: ${({ location }) => {
    switch (location) {
      case 'bottom':
        return '100%';
      case 'bottomCenter': {
        return '100%';
      }
      default:
        return 0;
    }
  }};
  z-index: 1;
`;

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: space-between;
  width: 100%;
`;

export const IconWrapper = styled.div`
  margin: 0 0 0.3rem;
`;

export const ArrowWrapper = styled.span`
  margin-left: ${toRem(8)};
  margin-top: 2px;
`;

export const ButtonCountStyled = styled.span`
  background: ${colours.colour2};
  color: ${colours.colour8};
  margin-left: ${toRem(16)};
  padding: ${toRem(0)} ${toRem(5)} ${toRem(1)};
  border-radius: ${toRem(4)};
`;

export const PopoverTextStyled = styled.p<PopoverTextStylesPropTypes>`
  ${({ font }) => font};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
