import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {
  ForceChangePasswordSchema, ForgotResetPasswordSchema, ResetPasswordSchema, ResetPasswordOnBlurSchema,
} from '../utils/validation';
import { useAPI } from './useAPI';
import { useCookies } from './useCookies';
import environment from '../config/environment';

export const useResetPassword = () => {
  const navigate = useNavigate();
  const { deleteCookie, createCookie } = useCookies();

  const { resetForgottenPassword, changePassword, authChallange } = useAPI();

  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<{[key: string]: string}>({});
  const [linkErrors, setLinkErrors] = useState(false);

  const handlePasswordSingleValidation = async (value: any) => {
    let errs: { [key: string]: string } = {};

    try {
      await ResetPasswordOnBlurSchema.validate(value, { abortEarly: false });
      errs = errors;
      delete errs.password;
      delete errs.passwordConfirm;
      delete errs.confirmPassword;
      delete errs.oldPassword;
      setErrors({ ...errs });
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        errs[err.path] = err.message;
      });
      setErrors(errs);
    }
  };

  const handleCodePassword = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await ForgotResetPasswordSchema.validate({
        password,
        confirmPassword,
        code,
      }, { abortEarly: false });
      const user = await resetForgottenPassword({ code, email, password });
      if (user.error) throw new Error(user.error);
      if (user.success) {
        navigate('/login', { state: { email } });
      }
    } catch (error: any) {
      if (error.inner) {
        const errorsMessage: {[key: string]: string } = {};
        error.inner.forEach((element: any) => {
          errorsMessage[element.path] = element.message;
        });
        setErrors(errorsMessage);
      } else if (error.message.includes('Attempt limit exceeded')) {
        setErrors({ code: error.message });
      } else if (error.message.includes('Invalid verification code')) {
        setErrors({ code: error.message });
      } else if (error.message.includes('request a code')) {
        setLinkErrors(true);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleChangePassword = async () => {
    try {
      setErrors({});
      await ResetPasswordSchema.validate({
        password,
        confirmPassword,
        oldPassword,
      }, { abortEarly: false });
      const user = await changePassword({ newPassword: password, oldPassword });
      if (user.error) throw new Error(user.error);
      if (user.passwordChange) {
        setOldPassword('');
        setPassword('');
        setConfirmPassword('');
        return true;
      }
    } catch (error: any) {
      if (error.inner) {
        const errorsMessage: {[key: string]: string } = {};
        error.inner.forEach((element: any) => {
          errorsMessage[element.path] = element.message;
        });
        setErrors(errorsMessage);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  const handleForceChangePassword = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrors({});
    try {
      await ForceChangePasswordSchema.validate({
        password,
        confirmPassword,
      }, { abortEarly: false });

      const userToken = await authChallange(password);
      if (userToken.error) throw new Error(userToken.error);
      if (userToken) {
        deleteCookie('authChallenge');
        const decodedUser: any = jwtDecode(userToken.jwtToken);
        createCookie(environment.app.cookieName, JSON.stringify(userToken.jwtToken), decodedUser.type);
        createCookie(environment.app.cognitoName, JSON.stringify(userToken.cognitoToken), decodedUser.type);
        navigate('/dashboard');
      }
    } catch (error: any) {
      if (error.inner) {
        const errorMessages:{[key: string]: string} = {};
        error.inner.forEach((element: any) => {
          errorMessages[element.path] = element.message;
        });
        setErrors(errorMessages);
      } else {
        setErrors({ fetch: error.message });
      }
    }
  };

  return {
    code,
    setCode,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    oldPassword,
    setOldPassword,
    errors,
    handleCodePassword,
    handleChangePassword,
    handleForceChangePassword,
    setEmail,
    handlePasswordSingleValidation,
    linkErrors,
  };
};
