import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import environment from '../../../config/environment';
import { useCookies } from '../../../hooks/useCookies';
import { useEditPPOForms } from '../../../hooks/useEditPPOForm';
import { StatusRecruitmentEndUpdate } from '../../../utils/researcherConst';
import { PPODraftSchema, PPOEditSchema } from '../../../utils/validation';

export const usePPOEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { getCookie } = useCookies();

  const hook = useEditPPOForms();

  const handleValidationPreps = () => {
    hook.setIsSubmiting(true);

    const updatedValues = Object.fromEntries(Object.entries(hook.formInfo).filter(([_, v]) => v != null));
    updatedValues.collaborators = Object.values(hook.collaborators);
    updatedValues.additional_team_member = Object.keys(hook.teamMembers).map((key) => ({
      id: hook.teamMembers[key].id, receive_email: hook.teamMembers[key].receive_email,
    })).filter((researcher) => typeof researcher.id === 'number');

    updatedValues.collaborating_organizations = Object.values(hook.collaboratingOrganizations);
    updatedValues.health_categories = hook.selectedResearchAreas.map((value) => value.id);
    updatedValues.hospital_id = hook.formInfo.hospital_id?.value ? Number(hook.formInfo.hospital_id.value) : null;

    if (Number.isNaN(updatedValues?.hospital_id)) updatedValues.hospital_id = null;

    updatedValues.criticalFieldUpdated = hook.criticalFieldUpdate;
    updatedValues.status = updatedValues.status === 'draft' ? 'pending_approval' : updatedValues.status;
    updatedValues.changesTracked = hook.changes.join(', ');
    delete updatedValues.pi_email;

    updatedValues.regional_centers = updatedValues.regional_centers?.filter((centre: string) => centre !== '' && centre !== 'undefined');
    if (!updatedValues.bc_support_unit
      && (updatedValues.regional_centers?.length > 0 || (updatedValues.regional_centers?.length === 1 && updatedValues.regional_centers[0] === undefined))
    ) {
      updatedValues.regional_centers = null;
    }

    if (Number.isNaN(updatedValues.target_patients)) delete updatedValues.target_patients;
    if (Number.isNaN(updatedValues.target_controls)) delete updatedValues.target_controls;

    Object.keys(updatedValues).forEach((value: string) => {
      if (typeof updatedValues[value] === 'string') {
        if (updatedValues[value].length === 0) return;
        const tirmValue = updatedValues[value]?.trim() || updatedValues[value];
        updatedValues[value] = tirmValue;
      }
    });

    return updatedValues;
  };

  const handleUpdateDraft = async () => {
    hook.setIsSubmiting(true);

    const updatedValues = Object.fromEntries(Object.entries(hook.formInfo).filter(([_, v]) => v != null));

    if (hook.formInfo.hospital_id && hook.formInfo.hospital_id.value) updatedValues.hospital_id = Number(hook.formInfo.hospital_id.value);

    if (Number.isNaN(updatedValues.hospital_id)) updatedValues.hospital_id = null;

    updatedValues.collaborators = Object.values(hook.collaborators);
    updatedValues.additional_team_member = Object.keys(hook.teamMembers).map((key) => ({
      id: hook.teamMembers[key].id, receive_email: hook.teamMembers[key].receive_email,
    })).filter((researcher) => typeof researcher.id === 'number');

    updatedValues.collaborating_organizations = Object.values(hook.collaboratingOrganizations);
    updatedValues.health_categories = hook.selectedResearchAreas.map((value) => value.id);

    updatedValues.status = 'draft';
    updatedValues.criticalFieldUpdated = false;
    updatedValues.changesTracked = hook.changes.join(', ');

    delete updatedValues.pi_email;

    updatedValues.regional_centers = updatedValues.regional_centers?.filter((centre: string) => centre !== '' && centre !== 'undefined');
    if (!updatedValues.bc_support_unit && updatedValues.regional_centers?.length > 0) {
      updatedValues.regional_centers = null;
    }

    if (Number.isNaN(updatedValues.target_patients)) delete updatedValues.target_patients;
    if (Number.isNaN(updatedValues.target_controls)) delete updatedValues.target_controls;

    Object.keys(updatedValues).forEach((value: string) => {
      if (typeof updatedValues[value] === 'string') {
        if (updatedValues[value].length === 0) return;
        const tirmValue = updatedValues[value]?.trim() || updatedValues[value];
        updatedValues[value] = tirmValue;
      }
    });

    updatedValues.desiredStatusOrignal = hook.currentDesiredStatus;

    try {
      await hook.handleValidation(updatedValues, PPODraftSchema);
      const res = await hook.handleSubmit(updatedValues);
      if (res.id) {
        hook.resetChanges();
        hook.setFormErrors({});
        window.onbeforeunload = null;
        navigate('/success/ppo', { state: { id: hook.formInfo.id, type: 'draft', draftType: 'ppo' } });
      }
    } catch (error: any) {
      const err = await JSON.parse(error.message);
      if (hook.formErrors.pi_id) err.pi_id = hook.formErrors.pi_id;
      hook.setFormErrors(err);
    }
  };

  const handleFormSubmit = async () => {
    hook.setIsSubmiting(true);

    const updatedValues = Object.fromEntries(Object.entries(hook.formInfo).filter(([_, v]) => v != null));
    updatedValues.collaborators = Object.values(hook.collaborators);
    updatedValues.additional_team_member = Object.keys(hook.teamMembers).map((key) => ({
      id: hook.teamMembers[key].id, receive_email: hook.teamMembers[key].receive_email,
    })).filter((researcher) => typeof researcher.id === 'number');

    updatedValues.collaborating_organizations = Object.values(hook.collaboratingOrganizations);
    updatedValues.health_categories = hook.selectedResearchAreas.map((value) => value.id);
    updatedValues.hospital_id = hook.formInfo.hospital_id?.value ? Number(hook.formInfo.hospital_id.value) : null;

    if (Number.isNaN(updatedValues.hospital_id)) updatedValues.hospital_id = null;

    updatedValues.criticalFieldUpdated = hook.criticalFieldUpdate;
    updatedValues.status = updatedValues.status === 'draft' ? 'pending_approval' : updatedValues.status;
    updatedValues.changesTracked = hook.changes.join(', ');
    delete updatedValues.pi_email;

    updatedValues.regional_centers = updatedValues.regional_centers?.filter((centre: string) => centre !== '' && centre !== 'undefined');
    if (!updatedValues.bc_support_unit
      && (updatedValues.regional_centers?.length > 0 || (updatedValues.regional_centers?.length === 1 && updatedValues.regional_centers[0] === undefined))
    ) {
      updatedValues.regional_centers = null;
    }

    updatedValues.wasDraft = hook.isDraft;
    updatedValues.desiredStatusOrignal = hook.currentDesiredStatus;

    if (!StatusRecruitmentEndUpdate.includes(updatedValues.status)
      && (StatusRecruitmentEndUpdate.includes(updatedValues.status) || StatusRecruitmentEndUpdate.includes(updatedValues.desired_status))
    ) {
      updatedValues.recruitment_end = new Date();
      if (!updatedValues.changesTracked.includes('recruitment_end')) {
        updatedValues.changesTracked = updatedValues.changesTracked.length > 0 ? `${updatedValues.changesTracked}, recruitment_end` : 'recruitment_end';
      }
    }

    if (Number.isNaN(updatedValues.target_patients)) delete updatedValues.target_patients;
    if (Number.isNaN(updatedValues.target_controls)) delete updatedValues.target_controls;

    Object.keys(updatedValues).forEach((value: string) => {
      if (typeof updatedValues[value] === 'string') {
        if (updatedValues[value].length === 0) return;
        const tirmValue = updatedValues[value]?.trim() || updatedValues[value];
        updatedValues[value] = tirmValue;
      }
    });

    let validationFailed = false;

    try {
      await hook.handleValidation({ ...updatedValues, new: hook.isDraft }, PPOEditSchema);
      hook.setFormErrors({});
    } catch (error: any) {
      const err = error;
      if (hook.formErrors.pi_id) err.pi_id = hook.formErrors.pi_id;
      hook.setFormErrors(err);
      validationFailed = true;
    }
    try {
      if (Object.entries(hook.teamMembers).length < 1) throw new Error('{"Team Member": "Please add Additional Team Member"}');

      await hook.checkTeamMembers(Object.entries(hook.teamMembers));
      if (validationFailed === true) return;
    } catch (error: any) {
      const err = await JSON.parse(error.message);
      hook.setFormErrors({ ...hook.formErrors, ...err });
      return;
    }
    try {
      const res = await hook.handleSubmit(updatedValues);
      if (res.id) {
        hook.resetChanges();
        hook.setFormErrors({});
        window.onbeforeunload = null;
        if (hook.isDraft && res.state !== 'draft') {
          navigate('/success/ppo', { state: { id: res.id, type: 'ppo' } });
        } else {
          navigate(
            '/success/ppo',
            {
              state:
                { id: hook.formInfo.id, type: hook.criticalFieldUpdate ? 'ppo' : 'ppoStatus' },
            },
          );
        }
      }
    } catch (error: any) {
      if (error.message && typeof error !== 'object') {
        const err = await JSON.parse(error.message);
        hook.setFormErrors(err);
      }
      hook.setFormErrors({ message: error.message });
    }
  };

  const handleDelete = async () => {
    try {
      const deletedProject = await hook.deleteDraft(hook.formInfo);
      if (deletedProject) {
        navigate('/dashboard');
      }
    } catch (error: any) {
      hook.setFormErrors(error);
    }
  };

  const handleSubmitClick = async () => {
    hook.setIsSubmiting(true);

    try {
      hook.setFormErrors({});

      const projectInfo = handleValidationPreps();

      await hook.handleValidation({ ...projectInfo, new: hook.isDraft }, PPOEditSchema);
      await hook.checkTeamMembers(Object.entries(hook.teamMembers));

      if (hook.criticalFieldUpdate === true && !hook.isDraft) {
        hook.setModalType('editCriticalField');
        hook.setModalIsOpen(true);
      } else if ((
        hook.formInfo.status === 'completed_project' || hook.formInfo.status === 'closed_recruitment')
        && (hook.formInfo.desired_status === 'on_hold' || hook.formInfo.desired_status === 'approved')
      ) {
        hook.setModalType('reopenResearch');
        hook.setModalIsOpen(true);
      } else {
        handleFormSubmit();
      }
    } catch (error: any) {
      await hook.checkTeamMembers(Object.entries(hook.teamMembers));
      hook.setFormErrors(error);
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleRightButtonAction = () => {
    switch (hook.modalType) {
      case 'delete':
        handleDelete();
        break;
      case 'discard':
        hook.setModalIsOpen(false);
        break;
      case 'discardShow':
        hook.setModalIsOpen(false);
        break;
      case 'editCriticalField':
        handleFormSubmit();
        hook.setModalIsOpen(false);
        break;
      case 'reopenResearch':
        handleFormSubmit();
        hook.setModalIsOpen(false);
        break;
      default:
        break;
    }
  };

  const handleLeftButtonAction = () => {
    switch (hook.modalType) {
      case 'delete':
        hook.setModalIsOpen(false);
        break;
      case 'discard':
        handleNavigate('/dashboard');
        break;
      case 'discardShow':
        handleNavigate(`/project/ppo/${hook.formInfo.id}`);
        break;
      case 'editCriticalField':
        hook.setModalIsOpen(false);
        hook.setIsSubmiting(false);
        break;
      case 'reopenResearch':
        hook.setModalIsOpen(false);
        hook.setIsSubmiting(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    hook.fetchValues();

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (hook.changesMade && window) {
      window.onbeforeunload = function () {
        if (!getCookie(environment.app.cookieName)) {
          window.onbeforeunload = null;
          return;
        }
        return 'Are you sure you want to leave?';
      };
    }
  }, [hook.changesMade]);

  useEffect(() => {
    if (params.id && hook.isLoading) {
      hook.getProjectInfo(parseInt(params.id, 10));
    }
  }, [params]);

  return {
    formHook: hook,

    handleLeftButtonAction,
    handleRightButtonAction,
    handleSubmitClick,
    handleUpdateDraft,
    handleNavigate,
  };
};
