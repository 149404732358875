import React from 'react';
import Button from '../../../../components/Button';
import { DropdownSelect } from '../../../../components/DropdownSelect';
import { ButtonWrapperStyled, SelectSpacer } from './styles';

type VolunteerButtonsProps = {
  type: string,
  project: IProject,
  handleConfirmationModel: (id: number, type: string, projectName: string) => void
}

const VolunteerButtons = ({
  project, type, handleConfirmationModel,
}: VolunteerButtonsProps) => {
  const { id, projectInterest, nick_name: nickName } = project;

  return (
    <ButtonWrapperStyled type={type}>
      <SelectSpacer>
        {projectInterest?.interest === 'interested' ? (
          <Button
            id="archive-button"
            onClick={() => {
              handleConfirmationModel(id, projectInterest.archived ? 'unarchive' : 'archived', nickName || '');
            }}
            theme="underlineSecondary"
          >
            {projectInterest.archived ? 'Unarchived' : 'Archive'}
          </Button>
        ) : (
          <DropdownSelect
            onChange={(e) => handleConfirmationModel(id, e.value, nickName || '')}
            placeholder="Delete"
            options={[
              { value: 'not_interested', label: "I'm not interested" },
              { value: 'not_eligible', label: "I'm not eligible" },
            ]}
            theme="delete"
            menuPlacement={type === 'mobile' ? 'top' : 'auto'}
          />
        )}
      </SelectSpacer>
      <Button
        id="interested-button"
        onClick={() => {
          handleConfirmationModel(
            id,
            projectInterest?.interest === 'interested' ? 'withdrawInterest' : 'interested',
            nickName || '',
          );
        }}
        theme={projectInterest?.interest !== 'interested' ? 'interestedButton' : 'withdrawButton'}
        buttonWidth={type === 'mobile' ? '175px' : '306px'}
      >
        {projectInterest?.interest === 'interested' ? 'Withdraw Interest' : "I'm Interested"}
      </Button>
    </ButtonWrapperStyled>
  );
};

export default VolunteerButtons;
