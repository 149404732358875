import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
import { Checkbox } from '../CheckBox';
import { TextH2, TextP } from '../Text';
import { fonts } from '../../styles/theme';
import {
  HealthResearchAreaWrapper, SelectedAreaWrapper, CategoriesWrapper, ButtonWrapper,
  H2Wrapper, IconWrapper, ButtonContentStyled, AccrodionBodyStyled, CheckBoxStyled, LineStyled, DivStyled, FlexWrapper, ErrorWrapper,
} from './styles';
import { ErrorMessage } from '../ErrorMessage';
import Accrodion from '../Accrodion';
import { RequiredForm } from '../RequiredForm';

type HealthResearchAreasProps = {
  categoriesMap: { category: string, items: { id: number, item: string, category: string }[] }[]
  selectedResearchAreas: { category: string, id: number, name: string }[]
  handleChange: (name: string, id: number, category: string) => void
  isReseacher?: boolean
  error?: string
  disabled?: boolean
  forceChange?: boolean
}
const HealthResearchAreas = ({
  categoriesMap, selectedResearchAreas, handleChange, isReseacher, error, disabled, forceChange,
}: HealthResearchAreasProps) => {
  const [selectedResearchAreasState, setSelectedResearchAreasState] = useState(selectedResearchAreas);
  const [openAccrodion, setOpenAccrodion] = useState('');

  const getCategoryCount = (categoryName: string) => {
    if (!selectedResearchAreas) return 0;
    const list = Object.values(selectedResearchAreas).filter((area: { name: string, category: string, id: number }) => categoryName === area.category);
    return list.length;
  };

  const formatCategory = (name: string) => {
    const nameArr = name.split(' ');
    const newNames = nameArr.map((category) => category[0] + category.substring(1).toLowerCase());
    return newNames.join(' ');
  };

  useEffect(() => {
    setSelectedResearchAreasState(selectedResearchAreas);
  }, [forceChange]);

  return (
    <HealthResearchAreaWrapper hasBorder={!isReseacher}>
      {isReseacher ? (
        <FlexWrapper>
          <LineStyled />
          <DivStyled maxWidth="60%" margin="0 0.875rem">
            <TextP font={fonts.font1.H24B} textAlgin="center">Choose From the Categories Below</TextP>
          </DivStyled>
          <LineStyled />
        </FlexWrapper>
      ) : (
        <>
          <H2Wrapper>
            <TextH2 font={fonts.font1.H26}>
              What health research areas are you interested in?
              <RequiredForm />
            </TextH2>
          </H2Wrapper>
          <TextP font={fonts.font1.H14} lineHeight="1.7rem">
            Select at least one health research category you are interested in. You can always come back and add more later.
          </TextP>
        </>
      )}

      <CategoriesWrapper>
        {categoriesMap.map((value) => (
          <div key={`${value.category}-count`}>
            <Accrodion
              isOpen={openAccrodion === value.category}
              label={formatCategory(value.category)}
              count={getCategoryCount(value.category)}
              setIsOpen={() => { setOpenAccrodion(openAccrodion === value.category ? '' : value.category); }}
              disabled={disabled}
            >
              <AccrodionBodyStyled>
                {value.items.map((area: { id: number, item: string, category: string }) => (
                  <CheckBoxStyled key={area.id}>
                    <Checkbox
                      id={area.id.toString()}
                      key={area.id}
                      theme="small"
                      label={area.item}
                      isChecked={!!selectedResearchAreasState.some((selected) => selected.id === area.id)}
                      onChange={() => handleChange(area.item, area.id, area.category)}
                      disabled={disabled}
                    />
                  </CheckBoxStyled>
                ))}
              </AccrodionBodyStyled>
            </Accrodion>
          </div>
        ))}
        {error && <ErrorWrapper><ErrorMessage message={error} /></ErrorWrapper>}
      </CategoriesWrapper>

      {(selectedResearchAreas.length > 0) && (
        <SelectedAreaWrapper
          isEmpty={!Object.keys(selectedResearchAreasState).length}
          topMargin={isReseacher ? '0' : '2.5rem'}
        >
          {Object.values(selectedResearchAreasState).map((area) => (
            <ButtonWrapper key={area.id}>
              <Button
                id={area.name}
                onClick={() => handleChange(area.name, area.id, area.category)}
                theme="tag"
                buttonWidth="fit-content"
                disabled={disabled}
              >
                <ButtonContentStyled>
                  {area.name}
                  <IconWrapper>
                    <FontAwesomeIcon icon="xmark" />
                  </IconWrapper>
                </ButtonContentStyled>
              </Button>
            </ButtonWrapper>
          ))}
        </SelectedAreaWrapper>
      )}
    </HealthResearchAreaWrapper>
  );
};

export default HealthResearchAreas;
