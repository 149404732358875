import React from 'react';
import AppWrapper from '../../../components/AppWrapper';
import Hero from '../../../components/Hero';
import TabSwitcher from '../components/TabSwitcher';
import ProjectDetails from '../../../components/ProjectDetails';
import * as Styled from '../../../styles/StudyStyles';
import VolunteerTable from '../components/VolunteerTable';
import ResultTab from '../components/ResultTab';
import VolunteerButtons from '../components/VolunteerButtons';
import { useStudyDetails } from '../../../hooks/useStudyDetails';
import Toast from '../../../components/Toast';
import { Modal } from '../../../components/Modal';
import ProjectInterestModal from '../../../components/projectInterestModal';
import ProjectUnavailable from '../components/ProjectUnavailable';
import { DirectLinkInterestModal, UserNotice } from '../../../components';
import { STUDY_DETAILS } from './const';
import { useVolounteerProjectDetails } from '../../../hooks/useVolounteerProjectDetails';
import TabSwitcherResearcher from '../components/TabSwitcherResearcher';
import { useScrollTo } from '../../../hooks';
import { PROJECT_DETAILS } from '../../../components/ProjectDetails/const';

const Study = () => {
  const hook = useStudyDetails();
  const volunteerHook = useVolounteerProjectDetails([], 'details');
  const scrollHook = useScrollTo(PROJECT_DETAILS.TABS[0].URL, hook.isLoading, hook.currentTab);

  const handleInterestChange = async (id?: number, type?: string, projectName?: string) => {
    const updated = await volunteerHook.handleProjectConfirmation(id, type, projectName);
    if (updated) {
      hook.setProject({
        ...hook.project,
        projectInterest: {
          id: hook.project.id,
          interest: updated.interest || hook.project.projectInterest?.interest || '',
          archived: updated.archived ? updated.archived === 'true' : hook.project.projectInterest?.archived || false,
        },
      });
    }
  };

  const handleDirectLinkInterest = async () => {
    if (!hook.project.direct_link_url) return;
    const updated = await volunteerHook.handleDirectLinkInterest(hook.project.id);

    if (updated) {
      hook.setProject({
        ...hook.project,
        projectInterest: {
          id: hook.project.id,
          interest: 'interested',
          archived: false,
        },
      });
    }
  };

  const handleUndoInterestChange = async (projectID: number) => {
    const updated = await volunteerHook.handleToastUndo(projectID);
    if (updated) {
      hook.setProject({
        ...hook.project,
        projectInterest: {
          id: hook.project.id,
          interest: updated.interest || hook.project.projectInterest?.interest || '',
          archived: updated.archived ? updated.archived === 'true' : hook.project.projectInterest?.archived || false,
        },
      });
    }
  };

  const handleButtonClick = (id: number, type: string, projectName: string) => {
    if (type === 'withdrawInterest') {
      volunteerHook.handleConfirmationModel(id, type, projectName);
    } else if (hook.project.has_direct_link && type === 'interested') {
      handleDirectLinkInterest();
    } else {
      handleInterestChange(id, type, projectName);
    }
  };

  return (
    <AppWrapper
      footer
      alternateFooter={hook.user?.type === 'volunteer'}
    >
      <Hero
        type="study"
        title={hook.project.lay_title}
        topSubtitle={hook.project.nick_name}
        bottomSubtitle={hook.project.title}
        patientOriented={hook.project.patient_oriented_project}
        status={hook.project.status}
        currentTab={hook.currentTab}
        setCurrentTab={hook.setCurrentTab}
      />

      <Styled.ProjectBodyStyled>
        {hook.project.showProject && (
          <>
            <div>
              {hook.user?.type === 'researcher' && hook.project.access ? (
                <TabSwitcherResearcher
                  tabs={hook.researcherTabOptions}
                  currentTab={hook.currentTab}
                  setCurrentTab={hook.setCurrentTab}
                />
              ) : (
                <div>

                  {!hook.hideButtonStatus.includes(hook.project.status) && hook.project.status !== '' && hook.user?.type !== 'researcher' && (
                    <>
                      {hook.project.projectInterest?.interest === 'interested' && hook.currentTab !== 'Results' && (
                        <UserNotice
                          title={STUDY_DETAILS.PROJECT_INTEREST_MESSAGE.TITLE}
                          description={hook.project.has_direct_link ? STUDY_DETAILS.PROJECT_INTEREST_MESSAGE.DIRECT_LINK_INTEREST : STUDY_DETAILS.PROJECT_INTEREST_MESSAGE.DEAULT_INTEREST}
                          link={hook.project.has_direct_link ? {
                            label: STUDY_DETAILS.PROJECT_INTEREST_MESSAGE.DIRECT_LINK_BUTTON,
                            path: hook.project.direct_link_url || '',
                          } : undefined}
                        />
                      )}
                      {hook.currentTab !== 'Results' && (
                        <Styled.VolunteerButtonsWrapperStyled>
                          <VolunteerButtons
                            type="desktop"
                            project={hook.project}
                            handleConfirmationModel={handleButtonClick}
                          />
                        </Styled.VolunteerButtonsWrapperStyled>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>

            {(hook.user?.type === 'volunteer' || !hook.project.access) && hook.currentTab !== 'Results' && !hook.isLoading && (
              <TabSwitcher
                currentTab={hook.currentTab}
                setCurrentTab={hook.setCurrentTab}
                hasResults={hook.project.resultId}
                scrollHandler={scrollHook.scrollActionHandler}
                activeSection={scrollHook.activeSection}
              />
            )}

            {hook.currentTab === 'Detail' && (
              <ProjectDetails
                project={hook.project}
                userType={hook.user?.type || 'volunteer'}
                principalInvestigator={hook.principalInvestigator}
                toastIsOpen={hook.toastIsOpen}
                toastText="changeInterest"
                sectionRefs={scrollHook.sectionRefs}
              />
            )}

            {hook.currentTab === 'Manage Volunteers' && hook.user?.type === 'researcher' && (
              <VolunteerTable
                volunteerInfo={hook.interested}
                getInterested={hook.getInterestedVolunteers}
                projectId={`${hook.project.id}`}
                handleFollowUpUpdate={hook.handleFollowUpUpdate}
                updateFilterHandler={hook.filterVolunteerHandler}
                enrollentStatusFilter={hook.enrollentStatusFilter}
                searchValue={hook.interestSearchValue}
                searchValueHandler={hook.setInterestSearchValue}
                selectedInterests={hook.selectedInterests}
                setSelectedInterestsHandler={hook.setSelectedInterestsHandler}
                updateProjectInterestsHandler={hook.updateProjectInterestsHandler}
              />
            )}

            {hook.currentTab === 'Results' && (
              <ResultTab
                projectId={hook.project.id}
                projectType={hook.project.type}
                resultId={hook.project.resultId}
                result={hook.result}
                userType={hook.user?.type || 'volunteer'}
                getResult={hook.getProjectResult}
                access={hook.project.access || false}
              />
            )}
          </>
        )}

        {!hook.project.showProject && (
          <ProjectUnavailable interested={hook.project?.projectInterest?.interest === 'interested'} />
        )}
      </Styled.ProjectBodyStyled>

      {volunteerHook.toastMessage
        && (
          <Toast
            message={volunteerHook.toastMessage}
            isOpen={volunteerHook.toastIsOpen}
            setIsOpen={volunteerHook.setToastIsOpen}
            type={volunteerHook.toastMessage !== 'interestedCreate' ? 'interest' : ''}
            name={hook.project.nick_name}
            handleButton={() => handleUndoInterestChange(hook.project.id)}
            handleCheck={
              volunteerHook.toastMessage === 'interestedCreate' || volunteerHook.user.interest_toast_message === true
                ? () => volunteerHook.handleToastCheck(hook.project.id)
                : undefined
            }
          />
        )}
      <Modal
        isOpen={volunteerHook.modelIsOpen}
      >
        <ProjectInterestModal
          projectName={volunteerHook.interestConfirmationModal.projectName}
          type={volunteerHook.interestConfirmationModal.type}
          action={handleInterestChange}
          cancel={() => volunteerHook.setModelIsOpen(false)}
        />
      </Modal>
      <Modal isOpen={volunteerHook.directLinkInterestModel}>
        <DirectLinkInterestModal
          action={() => volunteerHook.handleDirectLinkRedirect(hook.project.direct_link_url || '')}
          cancel={() => volunteerHook.setDirectLinkInterestModel(false)}
          redirect={volunteerHook.handleDirectLinkInterestCancel}
        />
      </Modal>
      {hook.user?.type === 'volunteer' && hook.currentTab !== 'Results' && (
        <VolunteerButtons
          type="mobile"
          project={hook.project}
          handleConfirmationModel={handleButtonClick}
        />
      )}

    </AppWrapper>
  );
};

export default Study;
