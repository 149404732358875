import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type LabelStyledProps = {
  readonly theme?: string
};

type InputStyledProps = {
  readonly theme?: string
}

type TextinputWrapperProps = {
  readonly width?: string
}

type FileInputStyledProps = {
  readonly disabled?: boolean
  readonly fileHover: boolean
}

type FileButtonStyledPropType = {
  readonly theme?: 'secondary'
}

type FileWrapperPropType = {
  readonly theme?: 'secondary'
}

type ButtonWrapperPropTypes = {
  readonly theme?: 'secondary'
}

export const ContainerStyled = styled.div<LabelStyledProps>`
  ${fonts.font1.H18B};
  color: ${colours.colour6};
  line-height: 1.33rem;
  display: ${({ theme }) => (theme === 'column' ? 'block' : '')};
  text-align: ${({ theme }) => (theme === 'column' ? 'center' : 'left')};
  max-width: 100%;
  overflow: hidden;
`;

export const InputStyled = styled.input<InputStyledProps>`
  ${fonts.font1.H14};
  line-height: 0.9rem;
  display: hidden;
  padding: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '0.3rem';
      default:
        return '0.67857143rem 1rem';
    }
  }};

  border:  ${({ theme }) => {
    switch (theme) {
      case 'round':
        return `3px solid ${colours.colour19}`;
      default:
        return `1px solid ${colours.colour5}`;
    }
  }};

  border-radius: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '30px';
      default:
        return '4px';
    }
  }};
  margin-top: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '0';
      case 'column':
        return '0';
      default:
        return '0.475rem';
    }
  }};

  width: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '100%';
      default:
        return '100%';
    }
  }};
  &:disabled {
    cursor: not-allowed;
    color: ${colours.colour6};
    border: 1px solid ${colours.colour6};  
    background: ${colours.colour39};
  }
`;

export const TextinputWrapper = styled.div<TextinputWrapperProps>`
  width: ${({ width }) => width || '100%'};
  max-width: 100%;
`;

export const TextWrapper = styled.p`
  margin-top: 0.35rem;
  display: inline-block;
`;

export const DescriptionWrapper = styled.p`
  margin-top: 0.25rem;
  line-height: 1.33rem;
  ${fonts.font1.H14};
`;

export const FileWrapper = styled.div<FileWrapperPropType>`
  background: ${colours.colour4};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${fonts.font1.H16}
  padding: 0.75rem 1rem;
  border-radius: 6px;
  color: ${colours.colour40};
  width: 100%;
  max-width: ${({ theme }) => (theme === 'secondary' ? '85.2%' : '100%')};
`;

export const FileInputStyled = styled.div<FileInputStyledProps>`
  ${fonts.font1.H16B};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1.5rem;
  padding: 2rem;
  border-radius: 6px;
  margin-top: 1rem;
  min-height: 88px;
  width: 100%;

  background: ${({ disabled }) => (disabled ? colours.colour39 : colours.colour4)};
  color: ${({ disabled }) => (disabled ? colours.colour7 : colours.colour40)};
  border: ${({ disabled, fileHover }) => {
    if (disabled) return `1px dashed ${colours.colour7}`;

    if (fileHover) {
      return `1px solid ${colours.colour1}`;
    }
    return `1px dashed ${colours.colour1}`;
  }};
`;

export const FileButtonStyled = styled.label<FileButtonStyledPropType>`
  color: ${colours.colour4};
  background: ${colours.colour8};
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  margin-bottom: ${({ theme }) => (theme === 'secondary' ? '0' : '1rem')};
  margin-left: ${({ theme }) => (theme === 'secondary' ? '1rem' : '0')};
  ${({ theme }) => (theme === 'secondary' ? fonts.font1.H16 : '')}
`;

export const CurrentFileWrapper = styled.div`
  display: flex;
  flex: 1 0;
  margin-top: 0.5rem;
  max-width: 100%;
`;

export const ButtonTextWrapper = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ButtonWrapper = styled.div<ButtonWrapperPropTypes>`
  max-width: ${({ theme }) => (theme === 'secondary' ? '100%' : '85%')};
  display: flex;
`;
