import styled from 'styled-components';
import { colours, fonts } from './theme';

type DivStyleProps = {
  readonly theme: string;
}

type ResearcherPasswordResetWrapper = {
  readonly theme: string;
}

type ResearcherWrapperProps = {
  readonly theme?: string;
}

type HeaderStyledProps = {
  readonly theme?: string;
}

type ErrorMessageStyledProps = {
  readonly sucess?: boolean
}

export const SectionStyled = styled.div<DivStyleProps>`
  margin-bottom: ${({ theme }) => {
    switch (theme) {
      case 'L':
        return '5rem';
      case 'M':
        return '2rem';
      case 'ML':
        return '3.5rem';
      default:
        return '1.5rem';
    }
  }};
`;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: stretch;
  gap: 3.063rem;
  height: 100%;
  flex-direction: column;
  margin-top: 0.625rem;
  padding: 0 0.875rem;

  @media (min-width: 770px) {
    margin-top: 2.5rem;
    flex-direction: row;
  }
`;

export const DefaultWrapper = styled.div`
  max-width: 42rem;
  height: 100%;
`;

export const ResearcherPasswordResetWrapper = styled.div<ResearcherPasswordResetWrapper>`
  width: 27rem;
  max-width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const FlexboxStyled = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  align-items: flex-end;
`;

export const FlexboxStyledCenter = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  margin: 1.875rem 0rem 2.5rem;
  padding: 11px 0;
`;

export const CheckBoxErrorStyled = styled.div`
  width:100%;
`;

export const ResearcherWrapper = styled.div<ResearcherWrapperProps>`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: ${({ theme }) => (theme === 'secondary' ? '27rem' : 'auto')};
  max-width: 100%;
  text-align: ${({ theme }) => (theme === 'main' ? 'center' : '')};
`;

export const HeaderStyled = styled.header<HeaderStyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.875rem auto;
  max-width: 87.5%;

  margin-top: ${({ theme }) => (theme === 'success' ? '10rem' : '')};  
  @media (min-width: 992px) {
    max-width: 62.5%;
  }
`;

export const MobilePhoneWrapper = styled.div`
  display: block;
  margin: 1.75rem auto 1.75rem;
`;

export const ErrorMessageStyled = styled.div<ErrorMessageStyledProps>`
  ${fonts.font1.H15}
  min-height: 1.875rem;
  line-height: 1.33rem;
  padding: 0.875rem;
  border-radius: 4px;
  margin: 2rem ${({ sucess }) => (sucess ? 'auto' : '2rem')};

  border: 1px solid ${({ sucess }) => (sucess ? colours.colour22 : colours.colour7)};
  color: ${({ sucess }) => (sucess ? colours.colour22 : colours.colour7)};
  max-width: ${({ sucess }) => (sucess ? '432px' : '')};
`;
