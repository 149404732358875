import React from 'react';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { DropdownSelect } from '../../../DropdownSelect';
import { TextInput } from '../../../TextInput';
import * as Styled from '../styles';
import { PPO_STUDY_CONTACT } from './const';

type OpportunityContactFormProps = {
  handleChange: (value: string, field: string) => void
  contactName: string
  contactPosition: { value: string, label: string }
  contactEmail: string
  contactPhone?: string
  contactExtension: string
  contactPositionOptions: { value: string, label: string }[]
  contact2Name: string
  contact2Position: { value?: string, label?: string }
  contact2Email: string
  contact2Phone: string
  contact2Extension: string
  canEdit: boolean
  errors: { [key: string]: string }
  handleSingleValidation: (value: any, field: string) => void
}

const OpportunityContactForm = ({
  contactName, contactPosition, contactPositionOptions, handleChange, contactEmail, contactExtension, contactPhone, contact2Email, contact2Extension, contact2Name, contact2Phone, contact2Position, errors, canEdit, handleSingleValidation,
}: OpportunityContactFormProps) => (
  <FormSection
    title={PPO_STUDY_CONTACT.TITLE}
    subText={PPO_STUDY_CONTACT.DESCRIPTION}
  >
    <FormSubtitle subtitle={PPO_STUDY_CONTACT.CONTACT_ONE_LABEL} isRequired />
    <Styled.SectiontWrapper id="contact1_name">
      <TextInput
        label={PPO_STUDY_CONTACT.NAME.LABEL}
        textValue={contactName}
        description={PPO_STUDY_CONTACT.NAME.DESCRIPTION}
        setTextValue={(value) => handleChange(value, 'contact1_name')}
        error={errors.contact1_name}
        theme="form"
        disabled={!canEdit}
        onBlurAction={() => handleSingleValidation(contactName, 'contact1_name')}
        maxLength={250}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="contact1_position">
      <DropdownSelect
        id="contact-Position"
        label={PPO_STUDY_CONTACT.POSITION.LABEL}
        onChange={(value) => handleChange(value, 'contact1_position')}
        selected={{ value: contactPosition.value, label: contactPosition.label }}
        options={contactPositionOptions}
        placeholder={PPO_STUDY_CONTACT.POSITION.PLACEHOLDER}
        error={errors.contact1_position}
        isDisabled={!canEdit}
        theme="form"
        required
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="contact1_email">
      <Styled.FlexWrapper alignItems="flex-end">
        <TextInput
          label={PPO_STUDY_CONTACT.EMAIL.LABEL}
          placeholder={PPO_STUDY_CONTACT.EMAIL.PLACEHOLDER}
          textValue={contactEmail}
          setTextValue={(value) => handleChange(value, 'contact1_email')}
          inputWidth="75%"
          theme="form"
          disabled={!canEdit}
          error={errors.contact1_email}
          onBlurAction={() => handleSingleValidation(contactEmail, 'contact1_email')}
        />
        <Styled.InputWrapper id="contact1_phone">
          <TextInput
            label={PPO_STUDY_CONTACT.PHONE.LABEL}
            placeholder={PPO_STUDY_CONTACT.PHONE.PLACEHOLDER}
            textValue={contactPhone}
            setTextValue={(value) => handleChange(value, 'contact1_phone')}
            isRequired={false}
            error={errors.contact1_phone}
            theme="form"
            disabled={!canEdit}
            onBlurAction={() => handleSingleValidation(contactPhone, 'contact1_phone')}
          />
        </Styled.InputWrapper>

        <TextInput
          id="contact1_phone_extension"
          label={PPO_STUDY_CONTACT.EXTENSION.LABEL}
          textValue={contactExtension}
          setTextValue={(value) => handleChange(value, 'contact1_phone_extension')}
          inputWidth="25%"
          theme="form"
          isRequired={false}
          error={errors.contact1_phone_extension}
          disabled={!canEdit}
          onBlurAction={() => () => handleSingleValidation(
            contactExtension === '' ? null : contactExtension,
            'contact1_phone_extension',
          )}
        />
      </Styled.FlexWrapper>
    </Styled.SectiontWrapper>

    <FormSubtitle subtitle={PPO_STUDY_CONTACT.CONTACT_TWO_LABEL} />

    <Styled.SectiontWrapper id="contact2_name">
      <TextInput
        label={PPO_STUDY_CONTACT.NAME.LABEL}
        textValue={contact2Name}
        description={PPO_STUDY_CONTACT.NAME.DESCRIPTION}
        setTextValue={(value) => handleChange(value, 'contact2_name')}
        isRequired={false}
        error={errors.contact2_name}
        disabled={!canEdit}
        theme="form"
        onBlurAction={() => { handleSingleValidation(contact2Name === '' ? null : contact2Name, 'contact2_name'); }}
        maxLength={250}
      />
    </Styled.SectiontWrapper>
    <Styled.SectiontWrapper id="contact2_position">
      <DropdownSelect
        id="contact2_Position"
        label={PPO_STUDY_CONTACT.POSITION.LABEL}
        onChange={(value) => handleChange(value, 'contact2_position')}
        selected={{ value: contact2Position.value, label: contact2Position.label }}
        options={contactPositionOptions}
        placeholder={PPO_STUDY_CONTACT.POSITION.PLACEHOLDER}
        error={errors.contact2_position}
        isDisabled={!canEdit}
        theme="form"
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="contact2_email" isLast>
      <Styled.FlexWrapper alignItems="flex-end">
        <TextInput
          label={PPO_STUDY_CONTACT.EMAIL.LABEL}
          placeholder={PPO_STUDY_CONTACT.EMAIL.PLACEHOLDER}
          textValue={contact2Email}
          setTextValue={(value) => handleChange(value, 'contact2_email')}
          inputWidth="75%"
          isRequired={false}
          error={errors.contact2_email}
          disabled={!canEdit}
          theme="form"
          onBlurAction={() => { handleSingleValidation(contact2Email === '' ? null : contact2Email, 'contact2_email'); }}
        />
        <Styled.InputWrapper id="contact2_phone">
          <TextInput
            label={PPO_STUDY_CONTACT.PHONE.LABEL}
            placeholder={PPO_STUDY_CONTACT.PHONE.PLACEHOLDER}
            textValue={contact2Phone}
            setTextValue={(value) => handleChange(value, 'contact2_phone')}
            isRequired={false}
            error={errors.contact2_phone}
            disabled={!canEdit}
            theme="form"
            onBlurAction={() => { handleSingleValidation(contact2Phone === '' ? null : contact2Phone, 'contact2_phone'); }}
          />
        </Styled.InputWrapper>

        <TextInput
          id="contact2_phone_ectension"
          label={PPO_STUDY_CONTACT.EXTENSION.LABEL}
          textValue={contact2Extension}
          setTextValue={(value) => handleChange(value, 'contact2_phone_extension')}
          inputWidth="25%"
          isRequired={false}
          error={errors.contact2_phone_extension}
          disabled={!canEdit}
          theme="form"
          onBlurAction={() => { handleSingleValidation(contact2Extension === '' ? null : contact2Extension, 'contact2_phone_extension'); }}
        />
      </Styled.FlexWrapper>
    </Styled.SectiontWrapper>
  </FormSection>
);

export default OpportunityContactForm;
