import React from 'react';
import AppWrapper from '../../../components/AppWrapper';
import ProjectForm from '../../../components/ProjectForm';
import { studyForm } from '../../../utils/researcherConst';
import StudyForm from '../../../components/StudyForm';
import { useEditStudy } from './useEditHook';

const StudyEdit = () => {
  const hook = useEditStudy();

  return (
    <AppWrapper>
      <ProjectForm
        generalInfo={studyForm}
        heroInfo={hook.formHook.heroInfo}
        handleSubmit={hook.handleSubmitClick}
        handleDraft={hook.handleUpdateDraft}
        handleChange={hook.formHook.handleEditChange}
        desiredStatus
        isEdit
        selectedStatus={hook.formHook.formInfo.desired_status}
        projectPath={`/project/study/${hook.formHook.formInfo.id}`}
        isSubmiting={hook.formHook.isSubmiting}
        isDraft={hook.formHook.isDraft}
        status={hook.formHook.formInfo.status}
        setToastIsOpen={hook.formHook.setToastIsOpen}
        setModalType={hook.formHook.setModalType}
        rightButtonAction={hook.handleRightButtonAction}
        leftButtonAction={hook.handleLeftButtonAction}
        modalOpen={hook.formHook.modalIsOpen}
        setModalOpen={hook.formHook.setModalIsOpen}
        modalInfo={hook.formHook.modalType}
        changesMade={hook.formHook.changesMade}
        handleNavigate={hook.handleNavigate}
        errors={hook.formHook.formErrors}
        toastIsOpen={hook.formHook.toastIsOpen}
        toastText={hook.formHook.toastMessage}
        stepBackStatus={hook.formHook.stepBackStatus}
        setStatusModal={hook.formHook.setStatusModal}
        statusModal={hook.formHook.statusModal}
        canEdit={hook.formHook.canEdit}
        recruitmentEndDate={hook.formHook.formInfo.recruitment_end}
      >
        <StudyForm
          formInfo={hook.formHook.formInfo}
          formErrors={hook.formHook.formErrors}
          collaborators={hook.formHook.collaborators}
          teamMembers={hook.formHook.teamMembers}
          collaboratingOrganizations={hook.formHook.collaboratingOrganizations}
          healthAuthorityRegionOptions={hook.formHook.healthRegions}
          hospitalList={hook.formHook.hospitals}
          healthCategories={hook.formHook.healthCategories}
          selectedResearchAreas={hook.formHook.selectedResearchAreas}
          adminComments={hook.formHook.formInfo.admin_comments}
          RebFileCurrent={hook.formHook.rebFile}
          rebFileName={hook.formHook.formInfo.reb_file}
          additionalDocCurrent={hook.formHook.additionalDoc}
          additionalDocName={hook.formHook.formInfo.additional_doc}
          isDraft={hook.formHook.isDraft}
          handleChange={hook.formHook.handleEditChange}
          handleAddInput={hook.formHook.handleAddInput}
          handleChangeAddedInput={hook.formHook.handleEditChangeAddedInput}
          handleRemoveInput={hook.formHook.handleRemoveInput}
          handleChangeResearchAreas={hook.formHook.toggleResearchArea}
          handleCheck={hook.formHook.handleEditCheck}
          handleToggle={hook.formHook.handleEditToggle}
          handleFile={hook.formHook.handleEditFile}
          checkPI={hook.formHook.checkPI}
          checkTeamMember={hook.formHook.checkTeamMember}
          handleDelete={() => {
            hook.formHook.setModalType('delete');
            hook.formHook.setModalIsOpen(true);
          }}
          canEdit={hook.formHook.canEdit}
          forceChange={hook.formHook.forceChange}
          handleSingleValidation={hook.formHook.handleSingleValidation}
          handleRemoveFile={() => ''}
        />
      </ProjectForm>
    </AppWrapper>
  );
};

export default StudyEdit;
