import { useState } from 'react';
import { StudyCriticalFields } from '../utils/ProjectCriticalFields';
import { useAPI } from './useAPI';
import { useForms } from './useForms';

export const useEditForms = () => {
  const {
    getProject,
    updateProject,
  } = useAPI();

  const {
    formInfo,
    formErrors,
    hospitals,
    healthRegions,
    healthCategories,
    collaboratingOrganizations,
    collaborators,
    teamMembers,
    additionalDoc,
    selectedResearchAreas,
    rebFile,
    isDraft,
    setIsDraft,
    toggleResearchArea,
    setFormErrors,
    setFormInfo,
    fetchValues,
    checkPI,
    checkTeamMember,
    handleAddInput,
    handleChangeAddedInput,
    handleCheck,
    handleFile,
    handleToggle,
    handleChange,
    handleRemoveInput,
    setCollaboratingOrganizations,
    setCollaborators,
    setTeamMembers,
    setSelectedResearchArea,
    deleteDraft,
    changesMade,
    setChangeMade,
    modalType,
    modalIsOpen,
    setModalIsOpen,
    setModalType,
    setToastIsOpen,
    setToastMessage,
    toastIsOpen,
    toastMessage,
    changes,
    resetChanges,
    forceChange,
    setForceChange,
    handleSingleValidation,
    checkTeamMembers,
    setStatusModal,
    statusModal,
  } = useForms();

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [criticalFieldUpdate, setCriticalFieldUpdate] = useState(false);

  const [stepBackStatus, setStepBackStatus] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const [currentDesiredStatus, setCurrentDesiredStatus] = useState();
  const [heroInfo, setHeroInfo] = useState({});

  const checkCriticalField = (field: string) => {
    const criticalField = StudyCriticalFields.includes(field);
    if (criticalField) {
      setCriticalFieldUpdate(true);
    }
  };

  const getProjectInfo = async (projectId: number) => {
    const project = await getProject(projectId, 'study');
    project.pi_email = project.pi.email;

    setCanEdit(project.canEdit);
    setCurrentDesiredStatus(project.desired_status);

    if (project.hospital_id) {
      project.hospital_id = { label: project?.hospital.name, value: project.hospital.id };
    }

    if (!project.recruitment_end) {
      delete project.recruitment_end;
    } else {
      const date = new Date(project.recruitment_end).setTime(new Date(project.recruitment_end).getTime() + (10 * 60 * 60 * 1000));
      project.recruitment_end = new Date(date);
    }

    project.pi_id = project.pi.pi_id;
    project.health_authority_region = project.healthAuthoritiesRegionIds;

    setSelectedResearchArea(project?.healthCategoriesIds || []);

    delete project?.healthAuthoritiesRegionIds;
    delete project?.hospital;
    delete project?.healthCategoriesIds;
    delete project?.pi;

    setFormInfo(project);
    if (project.collaborating_organizations) {
      const orgaiztions: { [key: string]: string } = {};
      project.collaborating_organizations.forEach((orgaiztion: string) => {
        orgaiztions[orgaiztion] = orgaiztion;
      });
      setCollaboratingOrganizations(orgaiztions);
    }

    if (project.collaborators) {
      const projectCollaborators: { [key: string]: string } = {};
      project.collaborators.forEach((collaborator: string) => {
        projectCollaborators[collaborator] = collaborator;
      });
      setCollaborators(projectCollaborators);
    }
    if (project.additional_team_member) {
      const members: { [key: string]: any } = {};
      project.additional_team_member.map((member: any) => {
        members[member.id] = member;
      });
      setTeamMembers(members);
    }

    setIsDraft(project.status === 'draft');
    setHeroInfo({
      title: project.lay_title,
      topSubtitle: project.nick_name,
      bottomSubtitle: project.title,
      patientOriented: project.patient_oriented_project,
      type: project.type,
    });
    setForceChange(!forceChange);
    setIsLoading(false);
  };

  const handleEditChange = (value: any, field: string) => {
    if (!criticalFieldUpdate) checkCriticalField(field);
    handleChange(value, field);

    if (field === 'reb_certificate' && !value) {
      handleSingleValidation(value, field);
    }
  };

  const handleEditToggle = (value: boolean, field: string) => {
    if (!criticalFieldUpdate) checkCriticalField(field);
    handleToggle(value, field);
    if (field === 'is_online' && value) {
      handleSingleValidation(value, field);
    }
  };

  const handleEditCheck = (checked: boolean, field: string | number, key: string) => {
    if (!criticalFieldUpdate) checkCriticalField(key);
    handleCheck(checked, field, key);
  };

  const handleEditFile = (value: File, field: string) => {
    if (!criticalFieldUpdate) checkCriticalField(field);
    handleFile(value, field);
  };

  const handleSubmit = async (formValues: StudyValuesTypes | any) => {
    try {
      const data = new FormData();

      Object.keys(formValues).forEach((key) => {
        if (key === 'additional_team_member') {
          const value = JSON.stringify(formValues[key]);
          data.append(key, value);
        } else {
          data.append(key, formValues[key]);
        }
      });

      if (rebFile) {
        data.append('rebFile', rebFile);
      }
      if (additionalDoc) {
        data.append('additionalDoc', additionalDoc);
      }
      const res = await updateProject(data);
      setFormInfo((prev) => ({
        ...prev,
        status: res.status,
      }));

      setChangeMade(false);
      setCanEdit(res.canEdit);

      if (res.stepBackStatus) {
        setStepBackStatus(res.stepBackStatus);
      }
      return res;
    } catch (error: any) {
      setIsSubmiting(false);
      throw error;
    }
  };

  const handleEditChangeAddedInput = (value: string, id: string, key: string) => {
    if (!criticalFieldUpdate) checkCriticalField(key);
    handleChangeAddedInput(value, id, key);
  };

  const handleValidation = async (formValues: StudyValuesTypes | any, validation: any) => {
    setIsSubmiting(true);
    let validationFailed = false;

    try {
      await validation.validate({
        ...formValues,
        purpose: formValues.purpose.replace(/\r?\n|\r/g, ' '),
        description: formValues.description.replace(/\r?\n|\r/g, ' '),
        commitment: formValues.commitment.replace(/\r?\n|\r/g, ' '),
        inclusion_criteria: formValues.inclusion_criteria?.replace(/\r?\n|\r/g, ' '),
        exclusion_criteria: formValues.exclusion_criteria?.replace(/\r?\n|\r/g, ' '),
        procedures_description: formValues.procedures_description?.replace(/\r?\n|\r/g, ' '),
      }, { abortEarly: false });

      const teamError = await checkTeamMembers(Object.entries(teamMembers));
      if (teamError.length > 0) {
        validationFailed = true;
        throw new Error(JSON.stringify({ ...teamError }));
      }

      setFormErrors({});
    } catch (error: any) {
      let errors: { [key: string]: string } = {};

      if (!validationFailed) {
        const teamMemberErrors = await checkTeamMembers(Object.entries(teamMembers));
        if (teamMemberErrors.length) {
          teamMemberErrors.forEach((teamMember) => {
            errors = {
              ...errors,
              ...teamMember,
            };
          });
        }
      }
      error.inner.forEach((element: any) => {
        errors[element.path] = element.message;
      });
      setIsSubmiting(false);
      setFormErrors(errors);
      throw new Error('failed Validation');
    }
  };

  const handleCheckPI = async (email: string) => {
    setIsSubmiting(true);
    try {
      await checkPI(email);
      setIsSubmiting(false);
    } catch (error) {
      setIsSubmiting(false);
    }
  };

  const handleCheckTeamMember = async (user: string, email: string) => {
    setIsSubmiting(true);
    await checkTeamMember(user, email);
    setIsSubmiting(false);
  };

  const handleRemoveInputEdit = (key: string, id: string, name?: string) => {
    handleRemoveInput(key, id, name);
    setIsSubmiting(false);
  };

  return {
    formInfo,
    formErrors,
    healthRegions,
    hospitals,
    healthCategories,
    collaborators,
    teamMembers,
    collaboratingOrganizations,
    isSubmiting,
    additionalDoc,
    selectedResearchAreas,
    rebFile,
    criticalFieldUpdate,
    setCriticalFieldUpdate,
    isLoading,
    isDraft,
    heroInfo,
    stepBackStatus,
    getProjectInfo,
    fetchValues,
    setFormErrors,
    handleEditChange,
    handleEditToggle,
    handleEditCheck,
    handleRemoveInput: handleRemoveInputEdit,
    handleEditFile,
    toggleResearchArea,
    handleEditChangeAddedInput,
    handleAddInput,
    checkPI: handleCheckPI,
    checkTeamMember: handleCheckTeamMember,
    handleValidation,
    handleSubmit,
    deleteDraft,
    changesMade,
    modalType,
    modalIsOpen,
    setModalIsOpen,
    setModalType,
    setToastIsOpen,
    setToastMessage,
    toastIsOpen,
    toastMessage,
    canEdit,
    changes,
    resetChanges,
    forceChange,
    handleSingleValidation,
    checkTeamMembers,
    setStatusModal,
    statusModal,
    setIsSubmiting,
    currentDesiredStatus,
  };
};
