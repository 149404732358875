import React from 'react';
import { fonts } from '../../../../styles/theme';
import { Checkbox } from '../../../CheckBox';
import { DropdownSelect } from '../../../DropdownSelect';
import { ErrorMessage } from '../../../ErrorMessage';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import HealthResearchAreas from '../../../HealthResearchAreas';
import { NumberInput } from '../../../NumberInput';
import { TextP } from '../../../Text';
import { TextArea } from '../../../TextArea.tsx';
import { TextInput } from '../../../TextInput';
import * as Styled from '../styles';
import { ELIGILITY_CRITERIA } from './const';

type EligibilityCriteriaProps = {
  maxAge: string
  minAge: string
  yearsMonths: string
  healthyControls: boolean
  sexEligibleOptions: string[]
  sexEligible: string[]
  gendersEligibleOptions: string[]
  gendersEligible: string[]
  inclusionCriteria: string
  exclusionCriteria: string
  categoriesMap: { category: string, items: { id: number, item: string, category: string }[] }[]
  selectedResearchAreas: { category: string, id: number, name: string }[]
  canEdit: boolean
  controls: number | undefined
  patients: number | undefined
  errors: { [key: string]: string }
  handleChange: (value: string | number, field: string) => void
  handleToggle: (value: boolean, field: string) => void
  handleCheck: (value: boolean, field: string, key: string) => void
  handleChangeResearchAreas: (name: string, id: number, category: string) => void
  forceChange: boolean
  handleSingleValidation: (value: any, field: string) => void
}

const EligibilityCriteria = ({
  maxAge, minAge, yearsMonths, handleChange, handleToggle, handleCheck, healthyControls, sexEligible, sexEligibleOptions, gendersEligible, gendersEligibleOptions, inclusionCriteria, exclusionCriteria,
  categoriesMap, selectedResearchAreas, handleChangeResearchAreas, controls, patients, errors, canEdit, forceChange, handleSingleValidation,
}: EligibilityCriteriaProps) => (
  <FormSection title={ELIGILITY_CRITERIA.TITLE} subText={ELIGILITY_CRITERIA.DESCRIPTION}>

    <Styled.SectiontWrapper>
      <FormSubtitle subtitle={ELIGILITY_CRITERIA.AGE.LABEL} isRequired margin="small" />
      <Styled.AgeInputWrapper>
        <TextP>{ELIGILITY_CRITERIA.AGE.MIN_LABEL}</TextP>
        <Styled.DivStyled margin="0 0.875rem" id="min_age">
          <TextInput
            textValue={minAge}
            setTextValue={(value) => handleChange(value, 'min_age')}
            theme="labelless"
            disabled={!canEdit}
            error={errors.min_age}
            onBlurAction={() => handleSingleValidation(minAge, 'min_age')}
          />
        </Styled.DivStyled>
        <TextP>{ELIGILITY_CRITERIA.AGE.MAX_LABEL}</TextP>
        <Styled.DivStyled margin="0 0.875rem" id="max_age">
          <TextInput
            textValue={maxAge}
            setTextValue={(value) => handleChange(value, 'max_age')}
            disabled={!canEdit}
            error={errors.max_age}
            onBlurAction={() => handleSingleValidation(maxAge, 'max_age')}
            theme="labelless"
          />
        </Styled.DivStyled>
        <Styled.DivStyled maxWidth="30%" margin="0 0.875rem" id="years_months">
          <DropdownSelect
            id="ageSelect"
            placeholder=""
            onChange={(value) => handleChange(value, 'years_months')}
            selected={{ value: yearsMonths, label: yearsMonths }}
            options={[
              { value: 'years', label: 'years' },
              { value: 'months', label: 'months' },
            ]}
            isDisabled={!canEdit}
            required={false}
            error={errors.years_months}
            theme="form"
          />
        </Styled.DivStyled>
        <TextP>{ELIGILITY_CRITERIA.AGE.END_LABEL}</TextP>
      </Styled.AgeInputWrapper>
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="healthy_controls">
      <FormSubtitle subtitle={ELIGILITY_CRITERIA.HEALTHEY_CONTROLS.LABEL} margin="small" />
      <Styled.TextWrapper>
        <TextP font={fonts.font1.H14}>{ELIGILITY_CRITERIA.HEALTHEY_CONTROLS.DESCRIPTION}</TextP>
      </Styled.TextWrapper>
      <Styled.FlexWrapper>
        <Checkbox
          label={ELIGILITY_CRITERIA.HEALTHEY_CONTROLS.CHECKBUT_LABEL}
          labelTheme="secondary"
          isChecked={healthyControls}
          onChange={() => handleToggle(!healthyControls, 'healthy_controls')}
          disabled={!canEdit}
          textTransform="none"
        />
      </Styled.FlexWrapper>
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="sex_eligible">
      <FormSubtitle subtitle={ELIGILITY_CRITERIA.SEXES.LABEL} isRequired margin="small" />
      <TextP font={fonts.font1.H14}>{ELIGILITY_CRITERIA.SEXES.DESCRIPTION}</TextP>
      <Styled.CheckboxWrapper>
        <Checkbox
          id="sex-all"
          label="All"
          isChecked={sexEligible?.length === sexEligibleOptions.length}
          onChange={(check) => handleCheck(check, 'All', 'sex_eligible')}
          disabled={!canEdit}
        />
      </Styled.CheckboxWrapper>
      {sexEligibleOptions.map((option) => (
        <Styled.CheckboxWrapper key={`sex-${option}`}>
          <Checkbox
            label={option}
            isChecked={sexEligible?.includes(option)}
            onChange={(check) => handleCheck(check, option, 'sex_eligible')}
            disabled={!canEdit}
          />
        </Styled.CheckboxWrapper>
      ))}
      {errors.sex_eligible && <ErrorMessage message={errors.sex_eligible} />}

    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="genders_eligible">
      <FormSubtitle subtitle={ELIGILITY_CRITERIA.GENDERS.LABEL} isRequired margin="small" />
      <Styled.TextWrapper>
        <TextP font={fonts.font1.H14} lineHeight="1.33rem">
          {ELIGILITY_CRITERIA.GENDERS.DESCRIPTION}
        </TextP>
      </Styled.TextWrapper>

      <TextP font={fonts.font1.H14} lineHeight="1.33rem">
        {ELIGILITY_CRITERIA.GENDERS.DESCRIPTION_TWO}
      </TextP>

      <Styled.CheckboxWrapper id="gender-all">
        <Checkbox
          label="All"
          isChecked={gendersEligible.length === gendersEligibleOptions.length}
          onChange={(check) => handleCheck(check, 'All', 'genders_eligible')}
          disabled={!canEdit}
        />
      </Styled.CheckboxWrapper>
      {gendersEligibleOptions.map((option) => (
        <Styled.CheckboxWrapper key={`gender-${option}`}>
          <Checkbox
            label={option}
            isChecked={gendersEligible.includes(option)}
            onChange={(check) => handleCheck(check, option, 'genders_eligible')}
            disabled={!canEdit}
          />
        </Styled.CheckboxWrapper>
      ))}
      {errors.genders_eligible && <ErrorMessage message={errors.genders_eligible} />}
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="inclusion_criteria">
      <TextArea
        label={ELIGILITY_CRITERIA.INCLUSION.LABEL}
        description={ELIGILITY_CRITERIA.INCLUSION.DESCRIPTION}
        textValue={inclusionCriteria}
        setTextValue={(value) => handleChange(value, 'inclusion_criteria')}
        error={errors.inclusion_criteria}
        isDisabled={!canEdit}
        theme="form"
        isRequired={false}
        onBlurAction={() => handleSingleValidation(inclusionCriteria, 'inclusion_criteria')}
        maxLength={3000}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper>
      <TextArea
        label={ELIGILITY_CRITERIA.EXCLUSION.LABEL}
        description={ELIGILITY_CRITERIA.EXCLUSION.DESCRIPTION}
        textValue={exclusionCriteria}
        setTextValue={(value) => handleChange(value, 'exclusion_criteria')}
        error={errors.exclusion_criteria}
        isDisabled={!canEdit}
        isRequired={false}
        onBlurAction={() => handleSingleValidation(exclusionCriteria, 'exclusion_criteria')}
        theme="form"
        maxLength={3000}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="health_categories" isLast>
      <FormSubtitle
        subtitle={ELIGILITY_CRITERIA.HEALTH_CATEGORIES.LABEL}
        margin="small"
        isRequired
      />
      <TextP font={fonts.font1.H14} lineHeight="1.33rem">
        {ELIGILITY_CRITERIA.HEALTH_CATEGORIES.DESCRIPTION}
      </TextP>
      <HealthResearchAreas
        categoriesMap={categoriesMap}
        selectedResearchAreas={selectedResearchAreas}
        handleChange={handleChangeResearchAreas}
        isReseacher
        error={errors.health_categories}
        disabled={!canEdit}
        forceChange={forceChange}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="target_patients">
      <FormSubtitle subtitle={ELIGILITY_CRITERIA.TARGETS.TITLE} margin="small" />
      <NumberInput
        label="Patients/Non-control/Experimental Group"
        numberValue={patients}
        setNumberValue={(value) => handleChange(value, 'target_patients')}
        minValue={0}
        isRequired
        padding="md"
        mbFont
        error={errors.target_patients}
        isNotPublic
        disabled={!canEdit}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="target_controls" isLast>
      <NumberInput
        label="Controls"
        numberValue={controls}
        setNumberValue={(value) => handleChange(value, 'target_controls')}
        isRequired={healthyControls}
        padding="md"
        minValue={0}
        mbFont
        error={errors.target_controls}
        disabled={!canEdit}
      />
    </Styled.SectiontWrapper>
  </FormSection>
);

export default EligibilityCriteria;
