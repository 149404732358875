import styled from 'styled-components';
import { colours, fonts } from './theme';

export const HeroStyled = styled.div`
  width: 100%;
  position: relative;
  padding: 5.25rem 3rem;
  margin-bottom: 0.875rem;
  overflow: hidden;
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.6;
  width: 100%;
  height: 350px;

  @media (min-width: 820px) {
    height: 295px;
  }
`;

export const HeroButtonWrapper = styled.div`
  display: flex;
  margin-top: 1.375rem;
  margin-bottom: 0.875rem;
  flex-direction: column;
  
  @media (min-width: 820px) {
    flex-direction: row;
    margin-top: 1.375rem;
  }
`;

export const HeroLinkStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.875rem;
  @media (min-width: 820px) {
    padding: 0;
    padding-right: 0.875rem;
    margin: 0;
  }
`;

export const HeroIconWrapper = styled.span`
  background: rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0.875rem;
  margin-right: 0.5rem;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  min-height: 100%;
  line-height: 1.33rem;
`;

export const DivWrapper = styled.div`
    margin-right: 0.875rem;
  
`;

export const HeroTitleWrapper = styled.div`
  margin: 1.375rem 0 0.875rem;
  ${fonts.font1.H31B};
  color: ${colours.colour4};
`;

export const ResearcherButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  
`;

export const ResearcherLinkStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  width: 100%;
  height: 60%;
`;
