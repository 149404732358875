import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { HeaderStyled, ResearcherWrapper } from '../../../../styles/MyProfileStyles';
import { MainStyled, ProgressBarStyled, ProgressBarWrapper } from '../../../../styles/ResearcherCreateStyles';
import EditProfile from '../ResearcherEditProfile';
import { useResearcherProfile } from '../../../../hooks/useResearcherProfile';
import { useCookies } from '../../../../hooks/useCookies';
import ResearcherProfileInfoForm from '../ResearcherInfoForm';
import MyProfileUpdateSuccess from '../MyProfileUpdateSuccess';
import { useResetPassword } from '../../../../hooks';
import environment from '../../../../config/environment';

const ResearcherProfile = () => {
  const { getCookie, deleteCookie } = useCookies();
  const {
    user,
    authorities,
    universities,
    currentStep,
    formErrors,
    getUserInfo,
    getUniversity,
    getAuthority,
    handleChange,
    handleStep,
    handleValidation,
    handleSingleValidation,
    setUpdateStatus,
    updateStatus,
  } = useResearcherProfile();

  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    oldPassword,
    setOldPassword,
    handleChangePassword,
    errors,
  } = useResetPassword();

  const updatePassword = async () => {
    setUpdateStatus(false);
    const updated = await handleChangePassword();
    if (updated) {
      setUpdateStatus(true);
    }
  };

  useEffect(() => {
    try {
      const userInfo = getCookie(environment.app.cookieName);
      const decodedUser: any = jwtDecode(userInfo);
      getUserInfo(parseInt(decodedUser.id, 10), 'researcher');
      getUniversity();
      getAuthority();
    } catch (err) {
      deleteCookie(environment.app.cookieName);
    }
  }, []);

  return (
    <>
      <HeaderStyled>
        {currentStep < 2 && (
          <ProgressBarWrapper>
            <ProgressBarStyled futureStep={currentStep < 0} />
            <ProgressBarStyled futureStep={currentStep < 1} />
          </ProgressBarWrapper>
        )}
      </HeaderStyled>
      <ResearcherWrapper>
        <MainStyled>
          {currentStep === 0 && (
            <EditProfile
              password={password}
              setPassword={setPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              oldPassword={oldPassword}
              setOldPassword={setOldPassword}
              handleChangePassword={updatePassword}
              errors={errors}
              handleStep={handleStep}
              updateStatus={updateStatus}
            />
          )}
          {currentStep === 1 && (
            <ResearcherProfileInfoForm
              researcherInfo={user}
              errors={formErrors}
              handleValidation={handleValidation}
              handleChange={handleChange}
              authorities={authorities}
              universities={universities}
              handleSingleValidation={handleSingleValidation}
            />
          )}
          {currentStep === 2 && (
            <MyProfileUpdateSuccess userType="researcher" />
          )}
        </MainStyled>
      </ResearcherWrapper>
    </>
  );
};

export default ResearcherProfile;
