import React, { ReactNode } from 'react';
import { ButtonStyled } from './styles';

type IProps = {
  id: string,
  children: ReactNode,
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined,
  theme?: string,
  disabled?: boolean,
  rightSpace?: boolean
  buttonWidth?: string
}

const Button: React.FC<IProps> = ({
  id,
  theme,
  onClick,
  children,
  disabled = false,
  rightSpace = false,
  buttonWidth,
}) => (
  <ButtonStyled
    id={id}
    theme={theme}
    onClick={onClick}
    disabled={disabled}
    rightSpace={rightSpace}
    buttonWidth={buttonWidth}
  >
    {children}
  </ButtonStyled>
);

export default Button;
