import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import environment from '../../../config/environment';
import { usePPOForms } from '../../../hooks';
import { useCookies } from '../../../hooks/useCookies';
import { PPODraftSchema, PPOSchema } from '../../../utils/validation';

export const usePPONew = () => {
  const navigate = useNavigate();
  const { getCookie } = useCookies();

  const hook = usePPOForms();

  const handleCreateDraft = async () => {
    hook.setIsSubmiting(true);

    const formValues: any = { ...hook.formInfo };

    if (hook.formInfo.hospital_id && hook.formInfo.hospital_id.value) formValues.hospital_id = Number(hook.formInfo.hospital_id.value);
    formValues.collaborators = Object.values(hook.collaborators);
    formValues.additional_team_member = Object.keys(hook.teamMembers).map((key) => ({
      id: hook.teamMembers[key].id, receive_email: hook.teamMembers[key].receive_email,
    })).filter((researcher) => typeof researcher.id === 'number');

    formValues.collaborating_organizations = Object.values(hook.collaboratingOrganizations);
    formValues.health_categories = hook.selectedResearchAreas.map((value) => value.id);

    formValues.status = 'draft';

    if (!formValues.recruitment_end) {
      delete formValues.recruitment_end;
    }

    delete formValues.pi_email;

    Object.keys(formValues).forEach((value: string) => {
      if (typeof formValues[value] === 'string') {
        if (formValues[value].length === 0) return;
        const tirmValue = formValues[value]?.trim() || formValues[value];
        formValues[value] = tirmValue;
      }
    });

    try {
      await hook.handleValidation(formValues, PPODraftSchema);
      const res = await hook.handleSubmit(formValues);
      if (res.id) {
        hook.setIsDraft(true);
        hook.handleChange(res.id, 'id');
        hook.handleChange('draft', 'status');
        hook.setFormErrors({});
        window.onbeforeunload = null;
        navigate('/success/ppo', { state: { id: res.id, type: 'draft', draftType: 'ppo' } });
      }
    } catch (error: any) {
      const err = await JSON.parse(error.message);
      if (hook.formErrors.pi) err.pi = hook.formErrors.pi;
      hook.setFormErrors(err);
    }
  };

  const handleFormSubmit = async () => {
    hook.setIsSubmiting(true);

    const formValues: any = { ...hook.formInfo };
    if (formValues.status !== 'draft') formValues.status = 'pending_approval';
    const funding = hook.formInfo.funding_agency;
    formValues.funding_agency = funding;
    if (hook.formInfo.contact1_position) {
      const contact1Position = hook.formInfo.contact1_position;
      formValues.contact1_position = contact1Position;
    }
    if (hook.formInfo.contact2_position) {
      const contact2Position = hook.formInfo.contact2_position;
      formValues.contact2_position = contact2Position;
    }
    if (hook.formInfo.hospital_id && hook.formInfo.hospital_id.value) formValues.hospital_id = Number(hook.formInfo.hospital_id.value);
    formValues.collaborators = Object.values(hook.collaborators);

    formValues.additional_team_member = Object.keys(hook.teamMembers).map((key) => ({
      id: hook.teamMembers[key].id, receive_email: hook.teamMembers[key].receive_email,
    })).filter((researcher) => typeof researcher.id === 'number');

    formValues.collaborating_organizations = Object.values(hook.collaboratingOrganizations);
    formValues.health_categories = hook.selectedResearchAreas.map((value) => value.id);
    delete formValues.pi_email;

    let validationFailed = false;

    Object.keys(formValues).forEach((value: string) => {
      if (typeof formValues[value] === 'string') {
        if (formValues[value].length === 0) return;
        const tirmValue = formValues[value]?.trim() || formValues[value];
        formValues[value] = tirmValue;
      }
    });

    try {
      await hook.handleValidation({ ...formValues, new: true }, PPOSchema);
      hook.setFormErrors({});
    } catch (error: any) {
      const err = await JSON.parse(error.message);
      if (hook.formErrors.pi) err.pi = hook.formErrors.pi;
      hook.setFormErrors(err);
      validationFailed = true;
    }
    try {
      await hook.checkTeamMembers(Object.entries(hook.teamMembers));
      if (validationFailed === true) return;
    } catch (error: any) {
      const err = await JSON.parse(error.message);
      hook.setFormErrors({ ...hook.formErrors, ...err });
      return;
    }
    try {
      let updateProject = false;
      if (formValues.status === 'draft') {
        formValues.wasDraft = true;
        updateProject = true;
      }
      formValues.status = 'pending_approval';
      const res = updateProject ? await hook.handleUpdateSubmit(formValues) : await hook.handleSubmit(formValues);
      if (res.id) navigate('/success/ppo', { state: { id: res.id, type: 'ppo' } });
    } catch (error: any) {
      const err = await JSON.parse(error.message);
      hook.setFormErrors(err);
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleRightButtonAction = () => {
    switch (hook.modalType) {
      case 'discard':
        hook.setModalIsOpen(false);
        break;
      case 'discardShow':
        hook.setModalIsOpen(false);
        break;
      default:
        break;
    }
  };

  const handleLeftButtonAction = () => {
    switch (hook.modalType) {
      case 'delete':
        hook.setModalIsOpen(false);
        break;
      case 'discard':
        navigate('/dashboard');
        break;
      case 'discardShow':
        handleNavigate(`/project/ppo/${hook.formInfo.id}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    hook.fetchValues(true);

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (hook.changesMade && window) {
      window.onbeforeunload = function () {
        if (!getCookie(environment.app.cookieName)) {
          window.onbeforeunload = null;
          return;
        }
        return 'Are you sure you want to leave?';
      };
    }
  }, [hook.changesMade]);
  return {
    formHook: hook,

    handleLeftButtonAction,
    handleRightButtonAction,
    handleCreateDraft,
    handleFormSubmit,
    handleNavigate,
  };
};
