import React, { ReactNode } from 'react';
import Button from '../Button';
import { TextP } from '../Text';
import * as Styled from './styles';

type FormSectionProps = {
  title?: string
  titleMobile?: string
  subText?: string
  noHeader?: boolean
  theme?: 'secondary'
  isDraft?: boolean
  handleDelete?: () => void
  children: ReactNode
}

const FormSection = ({
  title, titleMobile, subText, noHeader, isDraft, handleDelete, children, theme,
}: FormSectionProps) => (
  <Styled.SectionStyled>
    {!noHeader && (
      <Styled.TitleWrapper theme={theme}>
        <div>
          <Styled.TitleStyled>
            <Styled.MobileWrapper>
              <TextP>{titleMobile || title}</TextP>
            </Styled.MobileWrapper>
            <Styled.DesktopWrapper>
              <TextP>{title}</TextP>
            </Styled.DesktopWrapper>
          </Styled.TitleStyled>
          {subText && (
            <Styled.SubtextStyled>{subText}</Styled.SubtextStyled>
          )}
        </div>
        {isDraft && handleDelete
          && (
            <div>
              <Button
                id="formDraftDelete"
                onClick={handleDelete}
                theme="delete"
              >
                <TextP>Delete Draft</TextP>
              </Button>
            </div>
          )}
      </Styled.TitleWrapper>
    )}
    {children}
  </Styled.SectionStyled>
);

export default FormSection;
