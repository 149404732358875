import { useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { AuthCodeSchema } from '../utils/validation';
import { useAPI } from './useAPI';
import { useCookies } from './useCookies';
import environment from '../config/environment';

export const useAuthChallange = () => {
  const navigate = useNavigate();

  const { createCookie, deleteCookie } = useCookies();
  const { authChallange } = useAPI();

  const [code, setCode] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [redirect, setRedirect] = useState<{ id: string, type: string } | undefined>();

  const handleSubmitCode = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrors({});
    let errorType = 'validation';
    try {
      await AuthCodeSchema.validate({ code });
      errorType = 'fetch';
      const userToken = await authChallange(code);
      if (!userToken) throw new Error('login failed');

      const decodedUser: any = jwtDecode(userToken.jwtToken);
      createCookie(environment.app.cookieName, JSON.stringify(userToken.jwtToken), decodedUser.type);
      createCookie(environment.app.cognitoName, JSON.stringify(userToken.cognitoToken), decodedUser.type);
      deleteCookie('authChallenge');
      if (redirect) {
        navigate(`/project/${redirect.type}/${redirect.id}`);
      } else {
        navigate('/dashboard');
      }
    } catch (error: any) {
      if (errorType === 'fetch') {
        setErrors({ fetch: error.message });
      } else {
        setErrors({ code: error.message });
      }
    }
  };

  return {
    code,
    setCode,
    errors,
    setErrors,
    handleSubmitCode,
    setRedirect,
  };
};
