import styled from 'styled-components';
import { colours, fonts } from '../../../../styles/theme';
import { TextP } from '../../../../components/Text';
import toRem from '../../../../utils/toRem';

export const BodyWrapperStyled = styled.div`
  height: 100%;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${fonts.font1.H24B};
  text-align: center;
  margin: 0 auto;
  padding: ${toRem(16)};
  line-height: ${toRem(36)};
  
  @media (min-width: 820px) {
    min-height: 300px;
    width: 50%;
  }
`;

export const LinkButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colours.colour2};
  margin-top: ${toRem(30)};

  ${TextP} {
    padding-left: ${toRem(10)};
  } 
`;
