import styled from 'styled-components';
import toRem from '../../../utils/toRem';

export const TableWrapper = styled.div`
  padding: 0 0.875rem;

  @media (min-width: 820px) {
    padding: 0 2.5rem;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: ${toRem(24)};
`;
