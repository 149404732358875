export const OPPORTUNITY_AFFILIATION = {
  TITLE: 'Opportunity Affiliations',
  COLLABORATING_ORGANIZATION: {
    LABEL: 'Collaborating Organizations',
    DESCRIPTION: 'Are you collaborating with another organization i.e. a society, community organization, or other research institution that you are not affiliated with?',
    BUTTON: 'Add an organization',
  },
  SUPPORT_UNIT: {
    TITLE: 'Is this a BC SUPPORT Unit affiliation?',
    DESCRIPTION: 'Is the BC SUPPORT Unit helping provide services, i.e. financial support, coordination, or training etc., to support this opportunity?',
    CHECKBOX_LABEL: 'Yes, the BC SUPPORT Unit is helping provide services to support this opportunity.',
    LINK: 'Learn about BC SUPPORT Unit',
    LINK_URL: 'https://healthresearchbc.ca/bc-support-unit/info-and-resources/information-for-researchers/',
  },
  REGIONAL_CENTRE: {
    LABEL: 'Select Regional Centre(s)',
    ALL_LABEL: 'All',
  },
};
