import styled from 'styled-components';
import toRem from '../../../../utils/toRem';
import { colours, fonts } from '../../../../styles/theme';

export const PopoverWrapper = styled.div`
  max-width: ${toRem(431)};
  display: flex;
  align-items: center;
  gap: ${toRem(24)};
  margin-bottom: ${toRem(32)};
`;

export const PopoverLabel = styled.p`
  ${fonts.font1.H14};
  min-width: fit-content;
`;

export const PopoverBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(10)};
  padding: ${toRem(16)};
`;

export const PopoverFooterStyled = styled.div`
  border-top: 1px solid ${colours.colour41};
`;

export const SearchBarWrapper = styled.div`
  margin: ${toRem(24)} 0;
`;

export const SelectBarActionWrapper = styled.div`
  display: flex;
  gap: ${toRem(16)};
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SelectedButtonWrapper = styled.div`
  display: flex;
  gap: ${toRem(16)};
  align-items: center;
`;

export const SelectedActionWrapper = styled.div`
  max-width: ${toRem(421)};
`;
