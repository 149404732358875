import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import environment from '../config/environment';
import { useCookies } from './useCookies';

export const useUser = () => {
  const { getCookie, deleteCookie } = useCookies();

  const [user, setUser] = useState<IUser>();

  const getUserInfo = () => {
    try {
      const userInfo = getCookie(environment.app.cookieName);
      const decodedUser: any = jwtDecode(userInfo);
      setUser(decodedUser);
      return decodedUser;
    } catch (err) {
      deleteCookie(environment.app.cookieName);
    }
  };

  return {
    user,
    getUserInfo,
  };
};
