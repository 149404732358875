/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { EngagementsText } from '../../utils';
import { LinkComponent } from '../Link';
import { TextP } from '../Text';
import SideBar from './components/sideBar';
import Toast from '../Toast';
import TextBlockWithLink from '../TextBlockWithLinks';
import { PROJECT_DETAILS } from './const';
import * as Styled from './styles';

type ProjectDetailsProps = {
  project: IProject
  userType: string
  principalInvestigator: IUser
  toastIsOpen?: boolean
  toastText?: any
  setToastIsOpen?: (toastIsOpen: boolean) => void
  sectionRefs: React.MutableRefObject<Record<string, HTMLDivElement | null>>
}

const ProjectDetails = ({
  project,
  userType,
  principalInvestigator,
  toastIsOpen = false,
  toastText,
  setToastIsOpen = () => '',
  sectionRefs,
}: ProjectDetailsProps) => (
  <Styled.ProjectDetailStyled>
    <Styled.MainStyled>
      {userType === 'researcher' && (
        <Styled.ButtonsWrapper>
          {project.access && (
            <>
              <Styled.TextWrapper type="button">
                <LinkComponent
                  url={project.type === 'study' ? `/study/edit/${project.id}` : `/ppo/edit/${project.id}`}
                  theme="buttonSecondary"
                >
                  <Styled.LinkWrapper>
                    <Styled.IconWrapper>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </Styled.IconWrapper>
                    <TextP>Edit {project.type === 'study' ? 'Study' : 'Opportunity'} Details</TextP>
                  </Styled.LinkWrapper>
                </LinkComponent>
              </Styled.TextWrapper>
              {!project.resultId && (
                <Styled.TextWrapper type="button">
                  <LinkComponent
                    url={`${project.id}/results-form`}
                    theme="buttonSecondary"
                  >
                    <Styled.LinkWrapper>
                      <Styled.IconWrapper>
                        <FontAwesomeIcon icon="plus" />
                      </Styled.IconWrapper>
                      <TextP>Add Results</TextP>
                    </Styled.LinkWrapper>
                  </LinkComponent>
                </Styled.TextWrapper>
              )}
            </>
          )}
        </Styled.ButtonsWrapper>
      )}

      <Styled.SectionStyled
        id={PROJECT_DETAILS.LINK_POINTS.PURPOSE}
        ref={(ref) => (sectionRefs.current[PROJECT_DETAILS.LINK_POINTS.PURPOSE] = ref as HTMLDivElement)}
      >
        <Styled.TitleStyled>{PROJECT_DETAILS.SELECTION_TITLES.PURPOSE}</Styled.TitleStyled>
        {project.proposewithLinks ? (
          <TextBlockWithLink paragraphArray={project.proposewithLinks} />
        ) : (
          <>
            {project.purpose.split('\r\n').map((para: string) => (
              <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                {para.length === 0 && (<br />)}
                <p>{para}</p>
              </React.Fragment>
            ))}
          </>
        )}
      </Styled.SectionStyled>

      <Styled.SectionStyled
        id={PROJECT_DETAILS.LINK_POINTS.DETAILS}
        ref={(ref) => (sectionRefs.current[PROJECT_DETAILS.LINK_POINTS.DETAILS] = ref as HTMLDivElement)}
      >
        <Styled.TitleStyled>{PROJECT_DETAILS.SELECTION_TITLES.DETAILS}</Styled.TitleStyled>

        <Styled.SubTitleStyled>{PROJECT_DETAILS.SUBTITLES.COMMITMENT}</Styled.SubTitleStyled>
        {project.commitmentwithLinks ? (
          <TextBlockWithLink paragraphArray={project.commitmentwithLinks} />
        ) : (
          <>
            {project.commitment.split('\r\n').map((para: string) => (
              <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                {para.length === 0 && (<br />)}
                <p>{para}</p>
              </React.Fragment>
            ))}
          </>
        )}

        {project.description && (
          <>
            <Styled.SubTitleStyled>{PROJECT_DETAILS.SUBTITLES.INVOLVED}</Styled.SubTitleStyled>

            {project.descriptionWithLinks ? (
              <TextBlockWithLink paragraphArray={project.descriptionWithLinks} />
            ) : (
              <>
                {
                  project.description.split('\r\n').map((para: string) => (
                    <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                      {para.length === 0 && (<br />)}
                      <p>{para}</p>
                    </React.Fragment>
                  ))
                }
              </>
            )}
          </>
        )}

        {project.procedures_description && (
          <>
            <Styled.SubTitleStyled>{PROJECT_DETAILS.SUBTITLES.PROCEDURES}</Styled.SubTitleStyled>
            {project.procedures_required ? (
              <div>
                {
                  project.procedures_description?.split('\r\n').map((para: string) => (
                    <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                      {para.length === 0 && (<br />)}
                      <p>{para}</p>
                    </React.Fragment>
                  ))
                }
              </div>
            ) : (
              <div>
                {project.type === 'study' && <p>No</p>}
              </div>
            )}
          </>
        )}

        {
          project.level_of_engagement && (
            <>
              <Styled.SubTitleStyled>{PROJECT_DETAILS.SUBTITLES.ENGAGEMENT}</Styled.SubTitleStyled>

              {project.level_of_engagement.map((level) => (
                <Styled.ListStyled key={level}>
                  <span>{level}: </span>
                  {EngagementsText[level]}
                </Styled.ListStyled>
              ))}
            </>
          )
        }

        {
          project.orientation_provided && (
            <>
              <Styled.SubTitleStyled>{PROJECT_DETAILS.SUBTITLES.ORIENTATION}</Styled.SubTitleStyled>
              {project.orientationProvidedwithLinks ? (
                <TextBlockWithLink paragraphArray={project.orientationProvidedwithLinks} />
              ) : (
                <>
                  {project.orientation_provided.split('\r\n').map((para: string) => (
                    <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                      {para.length === 0 && (<br />)}
                      <p>{para}</p>
                    </React.Fragment>
                  ))}
                </>
              )}
            </>
          )
        }

        {
          project.background && (
            <>
              <Styled.SubTitleStyled>{PROJECT_DETAILS.SUBTITLES.BACKGROUND}</Styled.SubTitleStyled>
              {project.backgroundwithLinks ? (
                <TextBlockWithLink paragraphArray={project.backgroundwithLinks} />
              ) : (
                <>
                  {project.background.split('\r\n').map((para: string) => (
                    <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                      {para.length === 0 && (<br />)}
                      <p>{para}</p>
                    </React.Fragment>
                  ))}
                </>
              )}
            </>
          )
        }
      </Styled.SectionStyled>

      <Styled.SectionStyled
        id={PROJECT_DETAILS.LINK_POINTS.ELIGIBILITY}
        ref={(ref) => (sectionRefs.current[PROJECT_DETAILS.LINK_POINTS.ELIGIBILITY] = ref as HTMLDivElement)}
      >
        <Styled.TitleStyled>{PROJECT_DETAILS.SELECTION_TITLES.ELIGIBILITY}</Styled.TitleStyled>

        <Styled.SectionGridStyled>
          <Styled.GridItemContainer>
            <Styled.IconWrapper theme="secondary">
              <FontAwesomeIcon icon="birthday-cake" />
            </Styled.IconWrapper>
            <div>
              <Styled.SubTitleStyled theme="secondary">
                {PROJECT_DETAILS.SUBTITLES.AGE}
              </Styled.SubTitleStyled>
              <p>{project.ageRange}</p>
            </div>
          </Styled.GridItemContainer>

          <Styled.GridItemContainer>
            <Styled.IconWrapper theme="secondary">
              <FontAwesomeIcon icon="venus-mars" />
            </Styled.IconWrapper>
            <div>

              <Styled.SubTitleStyled theme="secondary">
                {PROJECT_DETAILS.SUBTITLES.SEXES}
              </Styled.SubTitleStyled>
              <p>{project.sex_eligible.join(' / ')}</p>
            </div>
          </Styled.GridItemContainer>

          <Styled.GridItemContainer>
            <Styled.IconWrapper theme="secondary">
              <FontAwesomeIcon icon="user" />
            </Styled.IconWrapper>
            <div>
              <Styled.SubTitleStyled theme="secondary">
                {PROJECT_DETAILS.SUBTITLES.GENDER}
              </Styled.SubTitleStyled>
              <p>{project.genders_eligible.join(', ')}</p>
            </div>
          </Styled.GridItemContainer>

          <Styled.GridItemContainer>
            <Styled.IconWrapper theme="secondary">
              <FontAwesomeIcon icon="check" />
            </Styled.IconWrapper>
            <div>
              <Styled.SubTitleStyled theme="secondary">
                {PROJECT_DETAILS.SUBTITLES.HEALTHY_VOLUNTEER}
              </Styled.SubTitleStyled>
              <p>{project.healthy_controls === true ? 'Yes' : 'No'}</p>
            </div>
          </Styled.GridItemContainer>
        </Styled.SectionGridStyled>

        {project.inclusion_criteria && (
          <>
            <Styled.SubTitleStyled>{PROJECT_DETAILS.SUBTITLES.INCLUSION}</Styled.SubTitleStyled>
            {project.inclusionCriteriawithLinks ? (
              <TextBlockWithLink paragraphArray={project.inclusionCriteriawithLinks} />
            ) : (
              <div>
                {project.inclusion_criteria.split('\r\n').map((para: string) => (
                  <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                    {para.length === 0 && (<br />)}
                    <p>{para}</p>
                  </React.Fragment>
                ))}
              </div>
            )}
          </>
        )}

        {project.exclusion_criteria && (
          <>
            <Styled.SubTitleStyled>{PROJECT_DETAILS.SUBTITLES.EXCLUSION}</Styled.SubTitleStyled>
            {project.exclusionCriteriawithLinks ? (
              <TextBlockWithLink paragraphArray={project.exclusionCriteriawithLinks} />
            ) : (
              <div>
                {
                  project.exclusion_criteria.split('\r\n').map((para: string) => (
                    <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                      {para.length === 0 && (<br />)}
                      <p>{para}</p>
                    </React.Fragment>
                  ))
                }
              </div>
            )}
          </>
        )}

        {
          project.additional_eligibility && (
            <div>
              <Styled.SubTitleStyled>
                {PROJECT_DETAILS.SUBTITLES.ADDITIONAL_ELIGIBILITY}
              </Styled.SubTitleStyled>

              {project.additionalEligibilitywithLinks ? (
                <TextBlockWithLink paragraphArray={project.additionalEligibilitywithLinks} />
              ) : (
                <>
                  {project.additional_eligibility.split('\r\n').map((para: string) => (
                    <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                      {para.length === 0 && (<br />)}
                      <p>{para}</p>
                    </React.Fragment>
                  ))}
                </>
              )}
            </div>
          )
        }
      </Styled.SectionStyled>

      <Styled.SectionStyled theme="secondary">
        <Styled.TitleStyled>{PROJECT_DETAILS.SELECTION_TITLES.HEALTH}</Styled.TitleStyled>
        <Styled.SectionGridStyled theme="secondary">
          {project?.health_categories.map((category) => (
            <p>{category}</p>
          ))}
        </Styled.SectionGridStyled>
      </Styled.SectionStyled>

      <Toast
        message={toastText}
        isOpen={toastIsOpen}
        setIsOpen={setToastIsOpen}
      />
    </Styled.MainStyled>

    <Styled.SideBarStyled>
      <SideBar
        project={project}
        principalInvestigator={principalInvestigator}
        userType={userType}
      />
    </Styled.SideBarStyled>
  </Styled.ProjectDetailStyled>
);

export default ProjectDetails;
