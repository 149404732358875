import { useState } from 'react';
import * as Yup from 'yup';
import { InitialResearcherUser } from '../utils/profileConst';
import { ResearcherOtherFields, UpdateResearcherProfileSchema } from '../utils/validation';
import { useAPI } from './useAPI';

export const useResearcherProfile = () => {
  const {
    getUser, getAuthorities, getUniversities, updateUser,
  } = useAPI();
  const [currentStep, setCurrentStep] = useState(0);
  const [user, setUser] = useState<IResearcherUser>(InitialResearcherUser);
  const [authorities, setAuthorities] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [universityOther, setUniversityOther] = useState();
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [updateStatus, setUpdateStatus] = useState(false);

  const handleSingleValidation = async (value: any, field: string, type?: string) => {
    let errors: { [key: string]: string } = {};
    try {
      if (type === 'other') {
        await Yup.reach(ResearcherOtherFields, field).validate(value);
      } else {
        await Yup.reach(UpdateResearcherProfileSchema, field).validate(value);
      }
      errors = formErrors;
      delete errors[field];
      setFormErrors({ ...errors });
    } catch (error: any) {
      errors[field] = error.message;
      setFormErrors({ ...formErrors, ...errors });
    }
  };

  const getUserInfo = async (id: number, type: string) => {
    const userInfo = await getUser(id, type);
    setUser(userInfo);
  };

  const getUniversity = async () => {
    const university = await getUniversities();
    const other = university.filter((uni: {name: string, id: number}) => uni.name === 'Other');
    if (user && other.length > 0) {
      setUniversityOther(other[0].id);
    }
    setUniversities(university);
  };

  const getAuthority = async () => {
    const healthAuthorities = await getAuthorities();
    setAuthorities(healthAuthorities);
  };

  const handleStep = () => {
    if (currentStep === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(0);
    }
  };

  const handleChange = (value: string | number | boolean, field: string) => {
    if (field === 'extension') {
      if (value === '') {
        const info = user;
        delete info.extension;
        setUser({ ...info });
      } else {
        setUser((prev) => ({
          ...prev,
          [field]: Number(value),
        }));
      }
    } else {
      setUser((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
    if ((field === 'position' && value !== 'Other') || (field === 'academic_affiliation_id' && value !== 10)) {
      const error = formErrors;
      const errorToDelete = (field === 'position') ? 'position_other' : 'academic_affiliation_other';
      delete error[errorToDelete];
      setFormErrors({ ...error });
    }
    handleSingleValidation(value, field);
  };

  const handleValidation = async (userInfo: IResearcherUser) => {
    const errors: { [key: string]: string } = {};
    try {
      const researcherUser = {
        ...userInfo,
        position_other: userInfo.position !== 'Other' ? null : userInfo.position_other,
        academic_affiliation_other: universityOther !== userInfo.academic_affiliation_id ? null : userInfo.academic_affiliation_other,
      };

      await UpdateResearcherProfileSchema.validate({
        ...userInfo,
        academic_other: universityOther === userInfo.academic_affiliation_id,
      }, { abortEarly: false });
      setFormErrors({});
      await updateUser(researcherUser);
      setCurrentStep(currentStep + 1);
    } catch (error: any) {
      if (formErrors.academic_affiliation_other) errors.academic_affiliation_other = formErrors.academic_affiliation_other;
      if (formErrors.position_other) errors.position_other = formErrors.position_other;
      if (error.inner) {
        error.inner.forEach((element: any) => {
          errors[element.path] = element.message;
        });
      }
      setFormErrors(errors);
    }
  };

  return {
    currentStep,
    user,
    authorities,
    universities,
    formErrors,
    getAuthority,
    getUniversity,
    getUserInfo,
    handleChange,
    handleStep,
    handleValidation,
    handleSingleValidation,
    setUpdateStatus,
    updateStatus,
  };
};
