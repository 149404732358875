import React from 'react';

export const EmptyProjectIcon = () => (
  <svg width="252" height="200" viewBox="0 0 252 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="252" height="200" fill="#E3E3E3" />
    <g id="Volunteer Dashboard-Incomplete profileSuggested">
      <rect width="430" height="1240" transform="translate(-89 -704)" fill="#F9F9F9" />
      <g id="Empty state (my projects)">
        <g id="Layer_1" clipPath="url(#clip0_3714_21862)">
          <path id="Vector" opacity="0.05" d="M250.891 85.5851C249.083 109.017 235.977 132.414 218.593 148.973C197.461 169.1 169.339 180.046 141.039 185.429C-14.9075 215.092 -37.6438 79.7108 57.5907 24.5369C94.2008 3.32556 138.721 -4.45816 179.964 6.16617C200.605 11.4824 221.013 22.0418 235.249 38.1771C247.787 52.3883 252.171 68.9956 250.891 85.5851Z" fill="#EAEAEB" />
          <g id="Group">
            <path id="Vector_2" d="M162.859 60.5387L181.585 166.751C182.727 173.226 178.404 179.4 171.928 180.542L62.8616 199.771C58.801 200.487 54.8346 199.489 51.7019 197.297C48.5725 195.105 46.277 191.718 45.56 187.656L27.3612 84.4268C26.2191 77.9522 30.5425 71.7778 37.0186 70.6358L149.068 50.8812C155.543 49.7391 161.717 54.0625 162.859 60.5387Z" fill="#BEC0C5" />
            <path id="Vector_3" d="M105.424 35.8251L87.5058 103.213C85.8163 109.567 89.5976 116.087 95.9514 117.776L191.619 143.214C197.973 144.903 204.493 141.122 206.183 134.768L224.1 67.3804C225.79 61.0266 222.009 54.5063 215.655 52.8169L119.987 27.3795C113.633 25.6901 107.113 29.4713 105.424 35.8251Z" fill="white" />
            <path id="Vector_4" d="M67.6483 55.9121L57.5343 124.904C56.5807 131.409 61.081 137.455 67.586 138.409L165.531 152.767C172.036 153.721 178.082 149.221 179.036 142.716L189.15 73.7239C190.104 67.2189 185.603 61.1724 179.098 60.2188L81.1534 45.8604C74.6484 44.9067 68.6019 49.4071 67.6483 55.9121Z" fill="#F2F2F3" />
            <path id="Vector_5" d="M53.1099 90.1612L51.09 159.861C50.8995 166.433 56.0726 171.915 62.6444 172.105L161.595 174.973C168.166 175.163 173.648 169.99 173.839 163.418L175.859 93.7185C176.049 87.1467 170.876 81.6648 164.304 81.4743L65.3541 78.6067C58.7823 78.4163 53.3004 83.5894 53.1099 90.1612Z" fill="white" />
            <path id="Vector_6" opacity="0.2" d="M64.3359 78.5803L164.311 81.4809L64.8729 74.9253L64.3359 78.5803Z" fill="black" />
            <path id="Vector_7" opacity="0.11" d="M101.929 48.9117L179.109 60.232L102.86 45.4092L101.929 48.9117Z" fill="black" />
            <g id="Group_2">
              <path id="Vector_8" d="M185.148 83.4891C191.622 82.347 197.798 86.672 198.939 93.1465L199.532 96.516L210.958 161.581C212.095 168.054 207.772 174.223 201.301 175.364L127.565 188.363L77.5724 197.175L77.5497 197.18L75.1455 183.491L66.1239 132.117C64.9867 125.644 69.3101 119.475 75.783 118.334L134.542 107.974C138.398 107.294 141.674 104.762 143.302 101.201L145.841 95.6497C147.47 92.0888 150.745 89.5564 154.602 88.875L185.149 83.4891H185.148Z" fill="#D9DADD" />
              <path id="Vector_9" d="M75.1228 183.289L77.5708 197.179L62.8843 199.768C70.7313 198.384 76.0621 191.108 75.1228 183.289Z" fill="#D9DADD" />
            </g>
          </g>
          <g id="Group_3">
            <path id="Vector_10" d="M155.793 200C177.574 200 195.23 182.343 195.23 160.562C195.23 138.781 177.574 121.125 155.793 121.125C134.012 121.125 116.355 138.781 116.355 160.562C116.355 182.343 134.012 200 155.793 200Z" fill="#838791" />
            <g id="Group_4">
              <path id="Vector_11" d="M155.792 142.865V178.258" stroke="white" strokeWidth="9.08479" strokeLinecap="round" strokeLinejoin="round" />
              <path id="Vector_12" d="M138.095 160.562H173.49" stroke="white" strokeWidth="9.08479" strokeLinecap="round" strokeLinejoin="round" />
            </g>
          </g>
          <g id="Group_5">
            <path id="Vector_13" d="M170.066 28.5132L164.129 17.8516" stroke="#BEC0C5" strokeWidth="5.67799" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_14" d="M195.743 36.2839L206.403 30.3447" stroke="#BEC0C5" strokeWidth="5.67799" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_15" d="M185.243 23.3608L191.082 2.83887" stroke="#BEC0C5" strokeWidth="5.67799" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3714_21862">
        <rect width="250.21" height="200" fill="white" transform="translate(0.89502)" />
      </clipPath>
    </defs>
  </svg>
);
