import styled from 'styled-components';

export const BodyWrapper = styled.main`
  max-width: 1200px;
  padding: 1rem;

  @media (min-width: 820px) {
    padding: 0;
    margin: 0 auto;
  }
`;

export const LoginWrapperStyle = styled.div`
  margin: 0 auto;
`;
