import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TextInput } from '../../components';
import Button from '../../components/Button';
import { Figure } from '../../components/Figure';
import { LinkComponent } from '../../components/Link';
import { TextH1, TextP } from '../../components/Text';
import {
  ButtonWrapper, HeaderStyled, MainStyled, MessageContainerStyled, PageWrapper,
} from '../../styles/loginStyle';
import { fonts } from '../../styles/theme';
import { imageList } from '../../utils/imageList';
import { useAuthChallange } from '../../hooks/useAuthChallange';

const TwoFactorAuth = () => {
  const location: any = useLocation();

  const {
    code,
    setCode,
    errors,
    handleSubmitCode,
    setRedirect,
  } = useAuthChallange();

  useEffect(() => {
    if (location.state?.redirect) {
      setRedirect(location.state.redirect);
    }
  }, []);

  return (
    <PageWrapper>
      <HeaderStyled>
        <Figure imageSrc={imageList.logoFull} alt="logo" maxWidth="150px" marginBottom="1.375rem" />
        <TextH1 font={fonts.font1.H31B}>Two Factor Authentication</TextH1>
      </HeaderStyled>
      <MainStyled>
        {errors.fetch ? (
          <MessageContainerStyled theme="error">
            {errors.fetch}
          </MessageContainerStyled>
        ) : (
          <MessageContainerStyled theme="message">
            <TextP>A text message with your 6 digit verification code was just sent to your mobile phone.</TextP>
          </MessageContainerStyled>
        )}
        <form onSubmit={handleSubmitCode}>
          <TextInput
            label="Enter the 6-digit code here to sign in to your account."
            type="text"
            textValue={code}
            setTextValue={setCode}
            isRequired={false}
            theme="column"
            handleEnter={handleSubmitCode}
            error={errors.code}
          />
          <ButtonWrapper>
            <Button onClick={() => ''} id="loginButton" buttonWidth="100%">
              <p>Submit</p>
            </Button>
          </ButtonWrapper>
        </form>
        <LinkComponent url="/login" theme="button">
          <div>
            <p>Back to Login</p>
          </div>
        </LinkComponent>
        <ButtonWrapper>

          <LinkComponent url="/forgot-password" theme="secondary">
            <p>Having trouble signing in?</p>
          </LinkComponent>
        </ButtonWrapper>
      </MainStyled>
    </PageWrapper>
  );
};

export default TwoFactorAuth;
