import styled from 'styled-components';
import toRem from '../../../../utils/toRem';
import { colours, fonts } from '../../../../styles/theme';

type ClipStyledPropTypes = {
  readonly theme?: 'primary' | 'secondary' | 'tertiary'
}

type ChipContainerStyledPropTypes = {
  readonly theme?: 'primary' | 'secondary'
}

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${toRem(24)} ${toRem(24)} ${toRem(32)};
  background: ${colours.colour4};
  border-radius: ${toRem(16)};
  box-shadow: 0px 4px 8px 0px rgba(26, 26, 26, 0.02), 0px 6px 24px -2px rgba(26, 26, 26, 0.08);
  white-space: normal;

  @media (min-width: 650px) {
    padding: ${toRem(32)} ${toRem(32)} ${toRem(48)};
  }
`;

export const ClipStyled = styled.div<ClipStyledPropTypes>`
  display: flex;
  padding:${toRem(8)} ${toRem(12)};
  align-items: center;
  text-transform: capitalize;
  ${fonts.font1.H12N};
  gap: ${toRem(8)};

  border-radius: ${({ theme }) => {
    switch (theme) {
      case 'tertiary':
        return toRem(25);
      default:
        return toRem(6);
    }
  }};

  border:  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return `1px solid ${colours.colour8}`;
      case 'tertiary':
        return `1px solid ${colours.colour41}`;
      default:
        return `2px solid ${colours.colour26}`;
    }
  }};

  background: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour4;
      case 'tertiary':
        return colours.colour41;
      default:
        return colours.colour38;
    }
  }};
`;

export const ProjectHeaderStyled = styled.p`
  ${fonts.font1.H20B};
  margin-top: ${toRem(16)};
  line-height: normal;

  @media (min-width: 650px) {
    margin-top: ${toRem(32)};
  }
`;

export const HightLightTextStyled = styled.p`
  ${fonts.font1.H14N};
  line-height: ${toRem(20)};
  color: ${colours.colour1};
  margin-top: ${toRem(8)};
`;

export const ChipContainerStyled = styled.div<ChipContainerStyledPropTypes>`
  display: flex;
  gap: ${toRem(10)};
  flex-wrap: wrap;
  margin: ${({ theme }) => (theme === 'secondary' ? 0 : `${toRem(32)} 0`)};

  @media (min-width: 650px) {
  margin: ${({ theme }) => (theme === 'secondary' ? 0 : `${toRem(32)} 0 ${toRem(56)}`)};
  }
`;

export const FooterContainerStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  gap: ${toRem(16)};

  @media (min-width: 650px) {
    justify-content: flex-start;
    gap: ${toRem(64)};
  }
`;

export const PopoverBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: fit-content;
`;

export const ButtonContainWrapper = styled.div`
  display: flex;
  gap: ${toRem(8)};
  white-space: nowrap;
  align-items: center;
  font-style: normal;
`;

export const DescriptionTextStyled = styled.div`
  display: none;
  ${fonts.font1.H14};
  line-height: ${toRem(20)};
  overflow-wrap: break-word;
  max-width: 100%;

  @media (min-width: 650px) {
    display: block;
    margin-top: ${toRem(16)};

    span {
      ${fonts.font1.H14B};
    }
  }
`;
