import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import AppWrapper from '../../components/AppWrapper';
import { useCookies } from '../../hooks/useCookies';
import VolunteerProfile from './component/VolunteerProfile';
import ResearcherProfile from './component/ResearcherProfile';
import environment from '../../config/environment';

const MyProfile = () => {
  const location = useLocation();
  const [userType, setUserType] = useState('');
  const [page, setPage] = useState('myProfile');
  const [subMenu, setSubMenu] = useState('');
  const { getCookie, deleteCookie } = useCookies();

  useEffect(() => {
    try {
      const userInfo = getCookie(environment.app.cookieName);
      const decodedUser: any = jwtDecode(userInfo);
      setUserType(decodedUser.type);
    } catch (err) {
      deleteCookie(environment.app.cookieName);
    }
  }, []);

  useEffect(() => {
    if (location.pathname) {
      const path = location.pathname.split('/');
      if (path[path.length - 1] !== page) {
        setPage(path[path.length - 1]);
      }
    }
    if (location.hash) {
      const hash = location.hash.split('#');
      setSubMenu(hash[1]);
    } else {
      setSubMenu('');
    }
  }, [location]);

  return (
    <AppWrapper>
      {userType === 'researcher' && <ResearcherProfile />}
      {userType === 'volunteer' && <VolunteerProfile page={page} subMenu={subMenu} />}
    </AppWrapper>
  );
};

export default MyProfile;
