import React from 'react';
import FormSection from '../../../FormSelection';
import { TextArea } from '../../../TextArea.tsx';
import { PPO_ADMIN_COMMENTS } from './const';

type OpportunityAdminCommentsProps = {
  adminComments: string
  handleChange: (value: string, field: string) => void
}
const OpportunityAdminComments = ({ adminComments, handleChange }: OpportunityAdminCommentsProps) => (
  <FormSection title={PPO_ADMIN_COMMENTS.TITLE} theme="secondary">
    <TextArea
      textValue={adminComments}
      setTextValue={(value) => handleChange(value, 'admin_comments')}
      isDisabled
      isRequired={false}
    />
  </FormSection>
);

export default OpportunityAdminComments;
