export const STUDY_RESULT = {
  STUDY_COMPLETED: {
    TITLE: 'Is this study completed?',
    CHECKBOX_LABEL: 'Yes, this study is completed.',
  },
  SUMMARY: {
    LABEL: 'Summary of Results',
    DESCRIPTION: 'In a brief paragraph, summarize the results of the research study in lay language. If a clinical trial, provide results related to primary and secondary endpoints (in neutral, unbiased language), side effects, next steps (e.g. another study, publication, licensing, what this means for people who live with the condition studied e.g. clinical care, etc.) (Max character limit is 900).',
  },
  PATIENT_ORIENTED: {
    LABEL: 'Is this a patient-oriented research project.',
    DESCRIPTION: 'Patient-oriented research (POR) is research done in partnership with patients, that answers research questions that matter to patients and aims to improve health care.',
    CHECKBOX_LABEL: 'Yes, this project is a patient-oriented research (POR) project.',
    LINK: {
      LABEL: 'Learn about POR',
      URL: 'https://healthresearchbc.ca/bc-support-unit/info-and-resources/',
    },
  },
  LIMITATIONS: {
    LABEL: 'Study Limitations',
    DESCRIPTION: 'In 3-4 sentences, describe any limitations to the study?',
    DESCRIPTION_TWO: 'E.g. any populations that were excluded from the study other limitations, and how these may be addressed in the future. (Max character limit is 450)',
  },
  PATIENT_PARTNER_COUNT: {
    LABEL: 'Number of Patient Partners engaged',
    DESCRIPTION: 'How many patient partners were engaged on your team?',
  },
  PATIENT_PARTNER_ENGAGE: {
    LABEL: 'How were Patient Partners engaged?',
    DESCRIPTION: 'Briefly explain in 5-6 sentences how patient partners were engaged?',
    DESCRIPTION_TWO: 'Example: Patient partners helped design and carry out the research questions, communicating the study findings by recommending ways and places to share the results, and helped develop reports for stakeholders. (Max character limit is 900)',
  },
  RESOURCES: {
    TITLE: 'Additional Information/Resources',
    SUBTEXT: 'Share information about the outcomes or impacts of the project here. This might include knowledge translation products such as links to your organization, project websites or videos, publications, tools that you have created.',
    WEB_LINK: {
      LABEL: 'Weblink URL',
      DESCRIPTION: 'Do you have a website or video that describes the results of your opportunity? Paste the link below.',
      PLACEHOLDER: 'e.g. https://www.example.com',
    },
    NAME_WEBSITE: {
      LABEL: 'Name of website/linked resource',
      DESCRIPTION: 'This text will be displayed on your Results page and will link to the website/resource.',
      DESCRIPTION_TWO: 'Example: BC SUPPORT Unit homepage',
      PALCEHOLDER: 'e.g. https://www.example.com',
    },
    UPLOAD_FILE: {
      LABEL: 'Document Upload',
      DESCRIPTION: 'Pdf file only. Maximum file size 15MB.',
    },
    FILE_NAME: {
      LABEL: 'Name of document/file',
      DESCRIPTION: 'This text will be displayed on your Results page and will link to the document. Example: Kidney Disease Patients Focus Group Results and Infographic',
    },
  },
  COMMENT: {
    LABEL: 'Comments',
    DESCRIPTION: 'List any additional information to assist the REACH BC Office with approving your results.',
    DESCRIPTION_TWO: 'Example: Study results are complete or results have been updated to include a new resource/document available.',
  },
  ADMIN_COMMENTS: {
    LABEL: 'Admin Comments',
  },
};

export const PPO_RESULT = {
  STUDY_COMPLETED: {
    LABEL: 'Is this opportunity completed?',
    CHECKBOX_LABEL: 'Yes, this opportunity is completed.',
  },
  LINK_STUDY: {
    LABEL: 'Linked Research Study',
    DESCRIPTION: 'Was this Patient Partner Research Opportunity related to a Research Study that was posted on REACH BC to find study participants?',
    CHECKBOX_LABEL: 'Yes, this Patient Partner Research Opportunity is related to a research study.',
    SELECT_DESCRIPTION: 'Select a REACH BC Study so that the study results can be linked to this Patient Partner Research Opportunity.',
    SELECT_DESCRIPTION_TWO: ' Studies in a “Pending Approval” state are not available in the list.',
    SELECT_POSTSCRIPT: 'Please complete the Research Study Results form instead. You do NOT need to complete this one.',
  },
  SUMMARY: {
    LABEL: 'Summary of Results',
    DESCRIPTION: 'In a brief paragraph, summarize the results of the research study in lay language. If a clinical trial, provide results related to primary and secondary endpoints (in neutral, unbiased language), side effects, next steps (e.g. another study, publication, licensing, what this means for people who live with the condition studied e.g. clinical care, etc.) (Max character limit is 900).',
  },
  PATIENT_PARTNER_COUNT: {
    LABEL: 'Number of Patient Partners engaged',
    DESCRIPTION: 'How many patient partners were engaged on your team?',
  },
  PATIENT_PARTNER_ENGAGED: {
    LABEL: 'How were Patient Partners engaged?',
    DESCRIPTION: 'Briefly explain in 5-6 sentences how patient partners were engaged?',
    DESCRIPTION_TWO: 'Example: Patient partners helped design and carry out the research questions, communicating the study findings by recommending ways and places to share the results, and helped develop reports for stakeholders. (Max character limit is 900).',
  },
  RESOURCES: {
    TITLE: 'Additional Information/Resources',
    SUBTEXT: 'Share information about the outcomes or impacts of the project here. This might include knowledge translation products such as links to your organization, project websites or videos, publications, tools that you have created.',
    WEB_LINK: {
      LABEL: 'Weblink URL',
      DESCRIPTION: 'Do you have a website or video that describes the results of your opportunity? Paste the link below.',
      PLACEHOLDER: 'e.g. https://www.example.com',
    },
    NAME_WEBSITE: {
      LABEL: 'Name of website/linked resource',
      DESCRIPTION: 'This text will be displayed on your Results page and will link to the website/resource.',
      DESCRIPTION_TWO: 'Example: BC SUPPORT Unit homepage',
      PALCEHOLDER: 'e.g. https://www.example.com',
    },
    UPLOAD_FILE: {
      LABEL: 'Document Upload',
      DESCRIPTION: 'Pdf file only. Maximum file size 15MB.',
    },
    FILE_NAME: {
      LABEL: 'Name of document/file',
      DESCRIPTION: 'This text will be displayed on your Results page and will link to the document. Example: "Kidney Disease Patients Focus Group Results and Infographic"',
    },
  },
  COMMENT: {
    LABEL: 'Comments',
    DESCRIPTION: 'List any additional information to assist the REACH BC Office with approving your results.',
    DESCRIPTION_TWO: 'Example: Study results are complete or results have been updated to include a new resource/document available.',
  },
  ADMIN_COMMENTS: {
    LABEL: 'Admin Comments',
  },
};
