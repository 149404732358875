import styled from 'styled-components';
import { colours, fonts } from '../../../../styles/theme';
import toRem from '../../../../utils/toRem';

type InputWrapperProps = {
  readonly width?: string
  readonly noMarginRight?: boolean
}

export const FormUserMessageWrapper = styled.div`
  border: 1px solid ${colours.colour43};
  background-color: ${colours.colour42};
  border-radius: ${toRem(6)};
  padding: ${toRem(16)} ${toRem(24)};
  ${fonts.font1.H13};
  line-height: 1.33rem;
`;

export const FlexboxWrapper = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  flex-direction: column;
  
  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  margin-bottom: 0.875rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    width: ${({ width }) => width || '100%'};
    margin-right:${({ noMarginRight }) => (noMarginRight ? '0' : '0.625rem')};
    justify-content: flex-end;
  }
`;
