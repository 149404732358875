import React from 'react';
import { TextP } from '../../../../components/Text';
import { TabWrapper, ButtonStyled } from './styles';

type TabSwitcherProps = {
  tabs: string[]
  currentTab: string
  setCurrentTab: (currentTab: string) => void
}

const TabSwitcherResearcher = ({
  tabs, currentTab, setCurrentTab,
}: TabSwitcherProps) => (
  <TabWrapper>
    {tabs.map((tab) => (
      <ButtonStyled
        key={tab}
        id={`tab-opportunities-${tab}`}
        onClick={() => setCurrentTab(tab)}
        selected={currentTab === tab}
      >
        <TextP>{tab}</TextP>
      </ButtonStyled>
    ))}
  </TabWrapper>
);

export default TabSwitcherResearcher;
