/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';

type InputContainerProps = {
  readonly width: string;
}

type LabelStyledProps = {
  theme?: string;
}

export const SelectStyles = (theme?: string) => {
  const styles = {
    borderRadius: 2,
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (_: any, { isFocused, isSelected }: any) => ({
      backgroundColor: isFocused ? colours.colour28 : isSelected ? colours.colour3 : 'white',
      cursor: 'pointer',
      padding: `${toRem(10)} ${toRem(16)} `,
      fontSize: `${toRem(16)}`,
      fontWeight: 400,
      fontFamily: 'Mulish, Helvetica Neue, Arial, Helvetica, sans-serif',
      width: '100%',
    }),
    valueContainer: () => ({
      display: 'flex',
      alignItems: 'center',
      padding: theme === 'delete' ? `0 ${toRem(5)}` : `0 ${toRem(11)} 0 0`,
      width: '100%',
      fontSize: `${toRem(16)}`,
      fontWeight: 400,
      fontFamily: 'Mulish, Helvetica Neue, Arial, Helvetica, sans-serif',
    }),
    singleValue: () => ({
      color: `${colours.colour6}`,
    }),
    dropdownIndicator: () => ({
      color: `${colours.colour40}`,
    }),
    indicatorsContainer: () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    placeholder: () => ({
      fontSize: `${toRem(16)}`,
      fontFamily: 'Mulish, Helvetica Neue, Arial, Helvetica, sans-serif',
      color: `${colours.colour36}`,
      fontWeight: theme === 'bolded' ? 700 : 400,
    }),
    control: (_: any, { isDisabled }: any) => ({
      backgroundColor: isDisabled ? colours.colour39 : colours.colour4,
      boxShadow: 'none',
      borderBottom: theme === 'delete' ? `2px solid ${colours.colour2}` : '',
      border: theme === 'delete' ? '' : (theme === 'form' ? `1px solid ${colours.colour5}` : `1px solid ${colours.colour29} `), // TODO: FIX note: switch cases seemingly dont work
      display: 'flex',
      cursor: 'pointer',
      borderRadius: theme === 'delete' ? '' : '4px',
      padding: theme === 'delete' ? '' : `0 ${toRem(10)} 0 1rem`,
      height: theme === 'delete' ? '20px' : (theme === 'form' ? '38px' : '40px'),
      width: '100%',
    }),
    menu: (css: any) => ({
      ...css,
      width: theme === 'form' ? '100%' : 'max-content',
    }),
  };
  return styles;
};
export const SelectMobileStyles = (theme?: string) => {
  const styles = {
    borderRadius: 2,
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (_: any, { isSelected }: any) => ({
      backgroundColor: isSelected ? colours.colour3 : 'white',
      cursor: 'pointer',
      padding: `${toRem(10)} ${toRem(16)} `,
      fontSize: `${toRem(14)}`,
      fontWeight: 400,
      fontFamily: 'Mulish, Helvetica Neue, Arial, Helvetica, sans-serif',
      width: '100%',
    }),
    valueContainer: () => ({
      display: 'flex',
      alignItems: 'center',
      padding: theme === 'delete' ? `0 ${toRem(5)}` : `0 ${toRem(11)} 0 0`,
      width: '100%',
      fontSize: `${toRem(12)}`,
      fontWeight: 400,
      fontFamily: 'Mulish, Helvetica Neue, Arial, Helvetica, sans-serif',
    }),
    singleValue: () => ({
      color: `${colours.colour6}`,
    }),
    dropdownIndicator: () => ({
      color: `${colours.colour40}`,
    }),
    indicatorsContainer: () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    placeholder: () => ({
      fontSize: `${toRem(12)}`,
      fontFamily: 'Mulish, Helvetica Neue, Arial, Helvetica, sans-serif',
      color: `${colours.colour36}`,
      fontWeight: theme === 'bolded' ? 700 : 400,
    }),
    control: (_: any, { isDisabled }: any) => ({
      backgroundColor: isDisabled ? colours.colour39 : colours.colour4,
      boxShadow: 'none',
      borderBottom: theme === 'delete' ? `2px solid ${colours.colour2}` : '',
      border: theme === 'delete' ? '' : (theme === 'form' ? `1px solid ${colours.colour5}` : `1px solid ${colours.colour29} `), // TODO: FIX note: switch cases seemingly dont work
      display: 'flex',
      cursor: 'pointer',
      borderRadius: theme === 'delete' ? '' : '4px',
      padding: theme === 'delete' ? '' : `0 ${toRem(10)} 0 1rem`,
      height: theme === 'delete' ? '20px' : (theme === 'form' ? '38px' : '40px'),
      width: '100%',
    }),
    menu: (css: any) => ({
      ...css,
      width: theme === 'form' ? '100%' : 'max-content',
    }),

  };
  return styles;
};

export const WrapperStyled = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => (width)};
`;

export const LabelStyled = styled.label<LabelStyledProps>`
  ${fonts.font1.H17B};
  color: ${colours.colour6};
  font-weight: ${({ theme }) => (theme === 'form' ? 700 : 500)};
  line-height: 1.33rem;
  display: ${({ theme }) => (theme === 'column' ? 'block' : '')};
  text-align: ${({ theme }) => (theme === 'column' ? 'center' : 'left')};
`;

export const LabelTextStyled = styled.span`
  display: flex;
  justify-content: flex-start;
`;

export const ErrorStyled = styled.span`
  margin-top: 0.3rem;
  color: ${colours.colour14};
  ${fonts.font1.H12B}
`;

export const DescriptionWrapper = styled.div`
  margin-top: 0.25rem;
`;

export const TextWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

export const DesktopWrapper = styled.div`
display: none;
@media (min-width: 770px) {
  display: block;
}
`;

export const MobileWrapper = styled.div`
  display: block;

  @media (min-width: 770px) {
    display: none;
}
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
