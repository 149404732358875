import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextP } from '../Text';
import { fonts } from '../../styles/theme';

export const PasswordCheckerWrapper = styled.div`
  display: flex;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

export const StyledTextP = styled(TextP)`
  ${fonts.font1.H14};
  line-height: 1.33rem;
`;
