import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import AppWrapper from '../../../../components/AppWrapper';
import ProjectForm from '../../../../components/ProjectForm';
import ResultForm from '../../../../components/ResultsForm';
import { studyResultSchema, ppoResultSchema } from '../../../../utils/validation';
import { ppoResultForm, studyResultForm } from '../../../../utils/researcherConst';
import { useEditResultForm } from '../../../../hooks/useEditResultForm';

const ResultEdit = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    formInfo,
    resourceFile,
    formErrors,
    projectType,
    linkStudies,
    handleToggle,
    handleChange,
    handleFile,
    handleValidation,
    handleSubmit,
    setProjectType,
    getLinkStudies,
    getProjectResult,
    changes,
    resetChanges,
    handleSingleValidation,
    setFormErrors,
    handleRemoveFile,
    handleGetSignedURl,
  } = useEditResultForm();

  const handleSubmitResults = async () => {
    const updatedValues = Object.fromEntries(Object.entries(formInfo).filter(([_, v]) => v != null));
    updatedValues.type = projectType;
    updatedValues.changesTracked = changes.join(', ');
    if (changes.length > 0) {
      updatedValues.status = 'pending_approval';
    }

    if (params.id) {
      updatedValues.project_id = parseInt(params.id, 10);
    }

    if (updatedValues.type === 'ppo') {
      delete updatedValues.patient_oriented_project;
    }

    const website = !!updatedValues.resource_website_url;
    if (!!updatedValues.resource_website_url && !updatedValues.resource_website_name) {
      updatedValues.resource_website_name = updatedValues.resource_website_url;
    }
    const checkFile = !!(updatedValues.resource_file_name && !updatedValues.fileLink);
    const needFileName: boolean = !!resourceFile || false;

    try {
      const validitionSchema = projectType === 'study' ? studyResultSchema : ppoResultSchema;
      await handleValidation({
        ...updatedValues, website, needFileName, resource_file: resourceFile, checkFile,
      }, validitionSchema);

      const res = await handleSubmit(updatedValues);
      resetChanges();
      if (res.project_id) {
        navigate('/success/result', { state: { id: res.project_id, type: 'result', resultType: projectType } });
      }
    } catch (error: any) {
      setFormErrors(error);
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    if (location.pathname) {
      if (location.pathname.includes('study')) {
        setProjectType('study');
      } else {
        setProjectType('ppo');
        getLinkStudies();
      }
    }
  }, [location]);

  useEffect(() => {
    if (projectType && params.id) {
      getProjectResult(parseInt(params.id, 10));
    }
  }, [projectType]);

  return (
    <AppWrapper>
      <ProjectForm
        generalInfo={projectType === 'study' ? studyResultForm : ppoResultForm}
        isSubmiting={false}
        errors={formErrors}
        status={formInfo.status}
        isResults
        handleSubmit={handleSubmitResults}
        handleNavigate={handleNavigate}
      >
        <ResultForm
          handleToggle={handleToggle}
          handleChange={handleChange}
          handleFile={handleFile}
          result={formInfo}
          fileName={resourceFile?.name || ''}
          currentFile={resourceFile}
          errors={formErrors}
          projectType={projectType}
          linkStudyOptions={linkStudies}
          handleSingleValidation={handleSingleValidation}
          handleRemoveFile={handleRemoveFile}
          handleGetSignedURl={handleGetSignedURl}
        />
      </ProjectForm>
    </AppWrapper>
  );
};

export default ResultEdit;
