import styled from 'styled-components';
import { colours } from '../../styles/theme';

export const MainHeaderStyled = styled.header`
  border-top: 3px solid ${colours.colour1};
  justify-content: space-between;
  padding: 1rem 0;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  z-index: 1;
  position: relative;
`;

export const LogoWrapper = styled.div`
  padding: 1.563rem 0.875rem;
  max-width: 161px;
`;

export const PopoverWrapper = styled.div`
  min-width: 95px;
`;

export const MainHeaderInnerStyled = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;
