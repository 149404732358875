import React from 'react';
import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';
import Router from './routes';
import './utils/faLibrary';
import './styles/reset.css';
import './styles/fonts.css';
import reportWebVitals from './utils/reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const TRACKING_ID = 'UA-164547599-4';
ReactGA.initialize(TRACKING_ID);
root.render(
  <Router />,
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);
