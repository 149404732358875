import styled from 'styled-components';
import { colours } from '../../styles/theme';

type SideDrawStyledProps = {
  readonly isOpen: boolean
  readonly theme?: string
}

type DrawHeaderStyledProps = {
  readonly icon?: boolean
}

export const SideDrawStyled = styled.div<SideDrawStyledProps>`
  position: fixed;
  z-index: 1;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? 0 : '-100vw')};
  width: ${({ theme }) => {
    switch (theme) {
      case 'full':
        return '100%';
      default:
        return '325px';
    }
  }};
  height: 100vh;
  max-width: 100%;
  background: ${colours.colour4};
  transition: 0.5s;
  box-shadow: ${({ isOpen }) => (isOpen ? '-20px 0px 70px -10px rgba(0,0,0,0.5)' : 'none')};
  overflow: hidden;
  border-top: ${({ theme }) => (theme === 'full' ? `3px solid ${colours.colour1}` : '')};
`;

export const DrawHeaderStyled = styled.div<DrawHeaderStyledProps>`
  display: flex;
  justify-content: ${({ icon }) => (icon ? 'space-between' : 'flex-end')};
  box-shadow: ${({ icon }) => (icon ? '0 2px 4px 0 rgb(0 0 0 / 20%)' : '')};
  padding: 1.1rem 1.5rem;

`;

export const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
