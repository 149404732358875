import React, { ReactNode, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import * as Styled from './styles';

type IProps = {
  children: ReactNode
}

const WebsiteAppWrapper = ({ children }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Styled.AppWrapperStyled displayLock={isOpen} id="#top">
      <Header isOpen={isOpen} setIsOpen={setIsOpen} />
      <Styled.BodyWrapper>
        {children}
      </Styled.BodyWrapper>
      <Footer />
    </Styled.AppWrapperStyled>
  );
};

export default WebsiteAppWrapper;
