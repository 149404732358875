export const ppoInitialValues: PatientPartnerResearchOpportunityValues = {
  title: '',
  nick_name: '',
  funding_agency: [],
  onsite_presence_needed: false,
  street_address: '',
  city: '',
  postal_code: '',
  purpose: '',
  description: '',
  level_of_engagement: [],
  orientation_provided: '',
  background: '',
  commitment: '',
  compensation: false,
  expenses: false,
  min_age: '',
  max_age: '',
  sex_eligible: [
    'Female',
    'Male',
  ],
  genders_eligible: [
    'Woman',
    'Man',
    'Non-binary',
    'Trans woman',
    'Trans man',
    'Two-spirit',
  ],
  additional_eligibility: '',
  contact1_name: '',
  contact1_position: '',
  contact1_email: '',
  pi_email: '',
  additional_team_member: [],
  health_categories: [],
  health_authority_region: [],
  comments: '',
  pp_co_pi: '',
  pp_affiliated_organization: '',
  collaborators: [],
  collaborating_organizations: [],
  bc_support_unit: false,
  regional_centers: [],
  has_direct_link: false,
  is_online: false,
  desired_status: '',

  // hardcoded value - requried by server, but we don't present this as an option on the front end
  notifications_to: 'All Volunteers',
  years_months: 'years',
  province: 'BC',
  status: '',
  type: 'ppo',
};
