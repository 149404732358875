import React from 'react';
import { LinkStyled } from './styles';

type LinkProps = {
  children: React.ReactNode;
  theme?: string
  url: string
  linkWidth?: string
  newWindow?: boolean
};

export const LinkComponent = ({
  children, theme, url, linkWidth, newWindow,
}: LinkProps) => (
  <LinkStyled
    href={url}
    theme={theme}
    linkWidth={linkWidth}
    target={newWindow ? '_blank' : ''}
  >
    {children}
  </LinkStyled>
);
