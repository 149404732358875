import styled from 'styled-components';
import { breakpoints, colours } from '../../../../styles/theme';
import toRem from '../../../../utils/toRem';

type ButtonWrapperStyledProps = {
  type: string;
}

export const ButtonWrapperStyled = styled.div<ButtonWrapperStyledProps>`
  display: ${({ type }) => (type === 'desktop' ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  position: sticky;
  height: 84px;
  width: 100%;
  background-color: white;
  border-top: 1px solid ${colours.colour41};
  bottom: 0;
  padding: 0 15px;
  
  @media (min-width: ${breakpoints.desktop}) {
    display: ${({ type }) => (type === 'mobile' ? 'none' : 'flex')};
    width: auto;
    position: relative;
    height: auto;
    background-color: transparent;
    border-top: none;
  }
`;

export const SelectSpacer = styled.div`
  margin-right: ${toRem(20)};
`;
