import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import { LinkComponent } from '../../Link';
import { TextP } from '../../Text';
import { Figure } from '../../Figure';
import { imageList } from '../../../utils/imageList';
import environment from '../../../config/environment';

const Footer = () => (
  <Styled.FooterStyled>
    <Styled.FooterNavWrapper>
      <Styled.LinkWrapper>
        <LinkComponent url={`${environment.app.websiteURL}/volunteers`} theme="website">
          <TextP>For Volunteers</TextP>
        </LinkComponent>
      </Styled.LinkWrapper>

      <Styled.LinkWrapper>
        <LinkComponent url={`${environment.app.websiteURL}/researchers`} theme="website">
          <TextP>For Researchers</TextP>
        </LinkComponent>
      </Styled.LinkWrapper>

      <Styled.LinkWrapper>
        <LinkComponent url={`${environment.app.websiteURL}/studies`} theme="website">
          <TextP>Find Studies</TextP>
        </LinkComponent>
      </Styled.LinkWrapper>

      <Styled.LinkWrapper>
        <LinkComponent url={`${environment.app.websiteURL}/about`} theme="website">
          <TextP>About REACH BC</TextP>
        </LinkComponent>
      </Styled.LinkWrapper>

      <Styled.LinkWrapper>
        <LinkComponent url="/volunteer/create" theme="buttonSm">
          <div>
            <TextP>Sign Up</TextP>
          </div>
        </LinkComponent>
      </Styled.LinkWrapper>
      <Styled.LinkWrapper>

        <LinkComponent url="/login" theme="outlineSm">
          <div>
            <TextP>
              Log In
            </TextP>
          </div>
        </LinkComponent>
      </Styled.LinkWrapper>
    </Styled.FooterNavWrapper>

    <Styled.FooterFlexBox>
      <Styled.ItemWrapper theme="marginLeftSm">
        <LinkComponent url={`${environment.app.websiteURL}/privacystatement`} theme="websiteSm">
          <TextP>Privacy Statement and Terms of Use</TextP>
        </LinkComponent>
      </Styled.ItemWrapper>
      <Styled.ItemWrapper theme="marginLeftSm">
        <LinkComponent url="mailto:info@reachbc.ca" theme="websiteSm">
          <TextP>Contact Us</TextP>
        </LinkComponent>
      </Styled.ItemWrapper>
    </Styled.FooterFlexBox>

    <Styled.FooterDivWrapper>
      <LinkComponent url="#top" theme="footerIcon">
        <div>
          <Styled.ItemWrapper>
            <FontAwesomeIcon icon={faArrowUp} />
          </Styled.ItemWrapper>
          <TextP>Top</TextP>
        </div>
      </LinkComponent>
    </Styled.FooterDivWrapper>

    <Styled.IconsContainerStyled>
      <Styled.ItemWrapper theme="marginLeft">
        <Styled.LogoWrapper theme="bcAhsn">
          <Figure imageSrc={imageList.bcAhsnlogo} alt="Michael Smith Health BC Logo" />
        </Styled.LogoWrapper>
      </Styled.ItemWrapper>
    </Styled.IconsContainerStyled>

    <Styled.FooterCopyRightWrapper>
      <TextP>© Copyright 2022 REACH BC</TextP>
    </Styled.FooterCopyRightWrapper>

  </Styled.FooterStyled>
);

export default Footer;
