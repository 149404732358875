import styled from 'styled-components';
import { colours, fonts } from '../../../../styles/theme';
import toRem from '../../../../utils/toRem';

type PopUpErrorProps = {
  readonly width?: string;
}

export const FlexboxWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5rem;
  flex-direction: column;
  
  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

export const TextInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 0.875rem;

  @media (min-width: 600px) {
      margin-right: 0.875rem;
      margin-bottom: 0;
  }
`;

export const CheckBoxStyled = styled.div`
  padding-bottom: 0.875rem;
`;

export const CheckBoxErrorStyled = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 0.875rem;
  width:100%;
`;

export const PopUpErrorStyled = styled.div<PopUpErrorProps>`
  display: block;
  align-items: center;
  width: ${({ width }) => (width || '100%')};
`;

export const ErrorDivStyled = styled.div`
  border: 1px solid ${colours.colour7};
  padding: 0.875rem;
  color: ${colours.colour7};
  border-radius: 4px;
  ${fonts.font1.H18}
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0.625rem auto 0;
`;

export const IconWrapper = styled.span`
  margin-right: 0.625rem;
`;

export const FormUserMessageWrapper = styled.div`
  border: 1px solid ${colours.colour43};
  background-color: ${colours.colour42};
  border-radius: ${toRem(6)};
  padding: ${toRem(16)} ${toRem(24)};
  ${fonts.font1.H13};
  line-height: 1.33rem;
  margin: ${toRem(24)} 0 0;
`;
