import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type LabelStyledPropTypes = {
  readonly theme?: 'form'
}

type LengthCounterStyledProps = {
  readonly hasError: boolean
}

export const LabelStyled = styled.label<LabelStyledPropTypes>`
  ${fonts.font1.H17B};
  color: ${colours.colour6};
  font-weight: ${({ theme }) => (theme === 'form' ? 700 : 500)};
  line-height: 1.33rem;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextAreaStyled = styled.textarea`
  line-height: 0.9rem;
  padding: 0.875rem;
  border: 1px solid ${colours.colour5};
  border-radius: 4px;
  margin-top: 0.475rem;
  width: 100%;
  min-height: 11.5rem;
  resize: vertical;
  ${fonts.font1.H14}

  &:disabled {
    cursor: not-allowed;
    background: ${colours.colour39};
    color: ${colours.colour6};
    opacity: 0.9;
  }
`;

export const ParagrahStyled = styled.p`
  ${fonts.font1.H14}
  overflow-wrap: break-word;
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  `;

export const InputWrapper = styled.div`
  position: relative;
  `;

export const TextWrapper = styled.div`
  margin-top: 0.35rem;
  display: inline-block;
`;

export const DescriptionWrapper = styled.div`
  margin: 0.5rem 0;
  line-height: 1.25rem;
  ${fonts.font1.H14};
`;

export const DescriptionList = styled.ul`
  list-style: disc;
  list-style-position: inside;
  line-height: 1.25rem;
  margin: 0.25rem 0 1rem 0.5rem;
`;

export const LengthCounterStyled = styled.div<LengthCounterStyledProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ hasError }) => (hasError ? 'space-between' : 'flex-end')};
`;

export const CounterTextStyled = styled.p`
  margin: 0.5rem 0;
  ${fonts.font1.H12};

  span {
    ${fonts.font1.H12B};
  }
`;
