import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretRight, faCaretDown, faAngleDown, faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import { useAccordion } from './useHook';
import { TextP } from '../../Text';

type AccordionProps = {
  children: React.ReactNode
  label: string
  disabled?: boolean
  open?: boolean
  theme?: string
}
const AccordionWeb = ({
  label, open = false, disabled, children, theme,
}: AccordionProps) => {
  const { setAccordionOpen, accordionOpen } = useAccordion();

  useEffect(() => {
    setAccordionOpen(open);
  }, []);

  return (
    <>
      <Styled.ButtonStyled
        id={`${label}-Accordion`}
        onClick={() => setAccordionOpen(!accordionOpen)}
        disabled={disabled}
        theme={theme}
      >
        {theme === 'modelMenu' ? (
          <Styled.ButtonLabelStyled>
            <TextP>{label}</TextP>
            <Styled.IconWrapper theme={theme}>
              <FontAwesomeIcon icon={accordionOpen ? faAngleUp : faAngleDown} />
            </Styled.IconWrapper>
          </Styled.ButtonLabelStyled>
        ) : (
          <>
            <Styled.IconWrapper>
              <FontAwesomeIcon icon={accordionOpen ? faCaretDown : faCaretRight} />
            </Styled.IconWrapper>
            <TextP>{label}</TextP>
          </>
        )}
      </Styled.ButtonStyled>
      <Styled.AccordionChildernStyled isOpen={accordionOpen}>
        {children}
      </Styled.AccordionChildernStyled>
    </>
  );
};

export default AccordionWeb;
