import { useNavigate } from 'react-router-dom';
import { useForms } from '../../../../hooks';
import { ProjectDraftSchema, ProjectSchema } from '../../../../utils/validation';

export const useNewStudy = () => {
  const navigate = useNavigate();
  const hook = useForms();

  const handleCreateDraft = async () => {
    hook.setIsSubmiting(true);
    if (hook.isSubmiting) return;

    const formValues = { ...hook.formInfo };

    if (hook.formInfo.hospital_id && hook.formInfo.hospital_id.value) formValues.hospital_id = Number(hook.formInfo.hospital_id.value);
    if (formValues.registration_num === '') delete formValues.registration_num;

    formValues.collaborators = Object.values(hook.collaborators);

    formValues.additional_team_member = Object.keys(hook.teamMembers).map((key) => ({
      id: hook.teamMembers[key].id, receive_email: hook.teamMembers[key].receive_email,
    })).filter((researcher) => typeof researcher.id === 'number');

    formValues.collaborating_organizations = Object.values(hook.collaboratingOrganizations);
    formValues.health_categories = hook.selectedResearchAreas.map((value) => value.id);
    formValues.status = 'draft';

    if (!hook.formInfo.healthy_controls) formValues.target_controls = !formValues.target_controls ? 0 : formValues.target_controls;

    if (!formValues.recruitment_end) {
      delete formValues.recruitment_end;
    }
    delete formValues.pi_email;
    try {
      await hook.handleValidation(formValues, ProjectDraftSchema);
      const res = await hook.handleSubmit(formValues);
      if (res.id) {
        hook.setIsDraft(true);
        hook.handleChange(res.id, 'id');
        hook.handleChange('draft', 'status');
        hook.setFormErrors({});
        window.onbeforeunload = null;
        navigate('/success/study', { state: { id: res.id, type: 'draft', draftType: 'study' } });
      }
    } catch (error: any) {
      const err = await JSON.parse(error.message);
      if (hook.formErrors.pi) err.pi = hook.formErrors.pi;
      hook.setFormErrors(err);
    }
  };

  const handleFormSubmit = async () => {
    if (hook.isSubmiting) return;

    hook.setIsSubmiting(true);
    const formValues: any = { ...hook.formInfo };
    const funding = hook.formInfo.funding_agency;
    formValues.funding_agency = funding;
    if (hook.formInfo.contact1_position) {
      const contact1Position = hook.formInfo.contact1_position;
      formValues.contact1_position = contact1Position;
    }
    if (hook.formInfo.contact2_position) {
      const contact2Position = hook.formInfo.contact2_position;
      formValues.contact2_position = contact2Position;
    }
    if (hook.formInfo.hospital_id && hook.formInfo.hospital_id.value) formValues.hospital_id = Number(hook.formInfo.hospital_id.value);
    if (formValues.registration_num === '') delete formValues.registration_num;

    const studyType = hook.formInfo.study_type;
    formValues.study_type = studyType;
    formValues.collaborators = Object.values(hook.collaborators);
    formValues.additional_team_member = Object.keys(hook.teamMembers).map((key) => ({
      id: hook.teamMembers[key].id, receive_email: hook.teamMembers[key].receive_email,
    })).filter((researcher) => typeof researcher.id === 'number');

    formValues.collaborating_organizations = Object.values(hook.collaboratingOrganizations);
    formValues.health_categories = hook.selectedResearchAreas.map((value) => value.id);
    delete formValues.pi_email;

    if (hook.formInfo.healthy_controls) formValues.target_controls = !formValues.target_controls ? 0 : formValues.target_controls;

    Object.keys(formValues).forEach((value: string) => {
      if (typeof formValues[value] === 'string') {
        if (formValues[value].length === 0) return;
        const tirmValue = formValues[value]?.trim() || formValues[value];
        formValues[value] = tirmValue;
      }
    });

    try {
      await hook.handleValidation(formValues, ProjectSchema);
      formValues.status = 'pending_approval';
      const res = await hook.handleSubmit(formValues);
      if (res.id) navigate('/success/study', { state: { id: res.id, type: 'study' } });
    } catch (error: any) {
      const err = await JSON.parse(error.message);
      hook.setFormErrors({ ...err, ...hook.formErrors });
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleRightButtonAction = () => {
    switch (hook.modalType) {
      case 'discard':
        hook.setModalIsOpen(false);
        break;
      case 'discardShow':
        hook.setModalIsOpen(false);
        break;
      default:
        break;
    }
  };

  const handleLeftButtonAction = () => {
    switch (hook.modalType) {
      case 'delete':
        hook.setModalIsOpen(false);
        break;
      case 'discard':
        navigate('/dashboard');
        break;
      case 'discardShow':
        handleNavigate('/project/study/$.formInfo.id}');
        break;
      default:
        break;
    }
  };

  return {
    handleLeftButtonAction,
    handleCreateDraft,
    handleFormSubmit,
    handleNavigate,
    handleRightButtonAction,

    formHook: hook,
  };
};
