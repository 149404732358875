import React from 'react';
import environment from '../../config/environment';
import { colours, fonts } from '../../styles/theme';
import { LinkComponent } from '../Link';
import { TextH3, TextP } from '../Text';
import {
  AlternateWrapper,
  FlexWrapper,
  FooterDivStyled, FooterInnerDivStyled, FooterTextWrapper, MainFooterWrapper, TitleWrapper,
} from './styles';

type MainFooterProps = {
  alternate?: boolean
}

const MainFooter = ({ alternate }: MainFooterProps) => (
  <MainFooterWrapper>
    {!alternate ? (
      <>
        <FlexWrapper>
          <FooterDivStyled side="left">
            <FooterInnerDivStyled side="left">
              <TitleWrapper>
                <TextH3 font={fonts.font1.H24B} textAlgin="center">Researcher Resources</TextH3>
              </TitleWrapper>
              <TextP font={fonts.font1.H13} textAlgin="center">Find SOPs and templates{' '}
                <LinkComponent
                  url={`${environment.app.websiteURL}/researcher-resources`}
                  newWindow
                >here
                </LinkComponent>
              </TextP>
            </FooterInnerDivStyled>
          </FooterDivStyled>
          <FooterDivStyled side="right">
            <FooterInnerDivStyled side="right">
              <TitleWrapper>
                <TextH3 font={fonts.font1.H24B} textAlgin="center">Need Help?</TextH3>
              </TitleWrapper>
              <TextP font={fonts.font1.H13} textAlgin="center">Contact{' '}
                <LinkComponent
                  url="mailto: admin@reachbc.ca"
                >admin@reachbc.ca
                </LinkComponent>
              </TextP>
            </FooterInnerDivStyled>
          </FooterDivStyled>
        </FlexWrapper>
        <LinkComponent
          url={`${environment.app.websiteURL}/privacystatement`}
          theme="tertiary"
          newWindow
        >
          <TextP textAlgin="center">
            Privacy Statement and Terms of Use
          </TextP>
        </LinkComponent>
        <LinkComponent
          url={`${environment.app.websiteURL}/researcher-confidentiality-undertaking`}
          theme="tertiary"
          newWindow
        >
          <TextP textAlgin="center">
            Researcher Confidentiality Undertaking
          </TextP>
        </LinkComponent>
      </>
    ) : (
      <AlternateWrapper>
        <LinkComponent
          url={`${environment.app.websiteURL}/privacystatement`}
          theme="footerLink"
          newWindow
        >
          <TextP textAlgin="center">
            Terms and Conditions and Privacy Statement
          </TextP>
        </LinkComponent>
        <FooterTextWrapper>
          <TextP font={fonts.font1.H12} color={colours.colour8}>For help, please contact us at&nbsp;</TextP>
          <LinkComponent
            url="MAILTO:info@reachbc.ca"
            theme="footerLink"
          >
            <TextP textAlgin="center">
              info@reachbc.ca
            </TextP>
          </LinkComponent>
        </FooterTextWrapper>
      </AlternateWrapper>
    )}
  </MainFooterWrapper>
);

export default MainFooter;
