import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import Button from '../Button';
import { imageList } from '../../utils/imageList';
import { Figure } from '../Figure';
import { LinkComponent } from '../Link';

type IProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  theme?: string
  useIcon?: boolean
  children: ReactNode;
}

const SideDraw = ({
  isOpen, setIsOpen, theme, useIcon = false, children,
}: IProps) => (
  <Styled.SideDrawStyled isOpen={isOpen} theme={theme}>
    <Styled.DrawHeaderStyled icon={useIcon}>
      {useIcon && (
        <LinkComponent url="/dashboard" theme="logo">
          <Styled.LogoWrapper>
            <Figure imageSrc={imageList.logoFull} alt="logo" width="124px" />
          </Styled.LogoWrapper>
        </LinkComponent>
      )}
      <Button id="side-draw" onClick={() => setIsOpen(false)} theme="menuButton">
        <FontAwesomeIcon icon={faXmark} size="lg" />
      </Button>
    </Styled.DrawHeaderStyled>
    {children}
  </Styled.SideDrawStyled>
);

export default SideDraw;
