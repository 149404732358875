export const ModalConst: {[key: string]: { title: string, discription?: string[], buttonLeft: string, buttonRight: string}} = {
  delete: {
    title: 'Delete Draft',
    discription: ['Are you sure you want to delete this draft?'],
    buttonLeft: 'Cancel',
    buttonRight: 'Delete Draft',
  },
  discard: {
    title: 'Confirm discard changes?',
    discription: ['Your changes in this research study have not been saved.', 'Discard changes?'],
    buttonLeft: 'Discard changes',
    buttonRight: 'Cancel',
  },
  discardShow: {
    title: 'Confirm discard changes?',
    discription: ['Your changes in this research study have not been saved.', 'Discard changes?'],
    buttonLeft: 'Discard changes',
    buttonRight: 'Cancel',
  },
  editCriticalField: {
    title: 'Editing mandatory details',
    discription: [
      'You have edited one or more fields that need to be reviewed by the REACH BC Office. This may take up to 3 business days.',
      'Your research study will be hidden on REACH BC directory until the changes are approved.',
      'Are you sure you want to resubmit for review?',
    ],
    buttonLeft: 'Cancel',
    buttonRight: 'Resubmit for Review',
  },
  reopenResearch: {
    title: 'Reopen research',
    discription: [
      'You are about to reopen this research study and this need to be reviewed by the REACH BC Office. This may take up to 3 business days. ',
      'Your research study will be hidden on REACH BC directory until the changes are approved.',
      'Are you sure you want to resubmit for review?',
    ],
    buttonLeft: 'Cancel',
    buttonRight: 'Resubmit for Review',
  },
};
