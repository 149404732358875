import React from 'react';
import Button from '../Button';
import { TextP } from '../Text';
import { interestTypes } from './const';
import * as Styled from './styles';

type IProps = {
  projectName: string
  type: string
  action: () => void
  cancel: () => void
}

const ProjectInterestModal = ({
  projectName, type, action, cancel,
}: IProps) => (
  <Styled.ModalStyled>
    <Styled.BodyWrapper>
      {type === 'withdrawInterest' ? (
        <TextP>
          Withdraw Interest? You will no longer be able to see this opportunity on your dashboard and the research team will no longer be able to contact you.
        </TextP>
      ) : (
        <TextP>
          Are you sure you {interestTypes[type]} {projectName}?
        </TextP>
      )}
    </Styled.BodyWrapper>
    <Styled.FooterWrapper>

      <Styled.ButtonWrapper>
        <Button
          id="model-cancel"
          theme="outlineSecondary"
          onClick={cancel}
        >
          Cancel
        </Button>
      </Styled.ButtonWrapper>
      <Button
        id="model-yes"
        theme="interestedButton"
        onClick={action}
      >
        Yes
      </Button>
    </Styled.FooterWrapper>
  </Styled.ModalStyled>
);

export default ProjectInterestModal;
