import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { getDaysInMonth } from '../../utils/getDaysInMonth';
import * as Styled from './styles';
import Button from '../Button';
import { TextP } from '../Text';

type CalendarProps = {
  date: Date;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void
  onApply: (date: Date, endDate?: Date) => void;
  className?: string;
}

type CalendarSide = 'left' | 'right';

export const Calendar = ({
  date, isOpen = false, onApply, className, setIsOpen,
}: CalendarProps) => {
  const [monthValue, setMonthValue] = useState(Number(date.getMonth()));
  const [yearValue, setYearValue] = useState(Number(date.getFullYear()));
  const [selectedDate, setSelectedDate] = useState<Date>(date);

  const fullNameMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const weekDaysRender = () => {
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const week = weekDays.map((day, index) => (
      <Styled.WeekItemStyled key={`${day}-${index}`}>
        {day}
      </Styled.WeekItemStyled>
    ));

    return (
      <Styled.WeekListStyled>
        {week}
      </Styled.WeekListStyled>
    );
  };

  const nextMonth = (calendar: CalendarSide) => {
    if (calendar === 'left') {
      if (monthValue === 11) {
        setMonthValue(0);
        setYearValue(yearValue + 1);
      } else {
        setMonthValue(monthValue + 1);
      }
    }
  };

  const prevMonth = (calendar: CalendarSide) => {
    if (calendar === 'left') {
      if (monthValue === 0) {
        setMonthValue(11);
        setYearValue(yearValue - 1);
      } else {
        setMonthValue(monthValue - 1);
      }
    }
  };

  const onApplyHandler = (newDate: Date) => {
    onApply(newDate);
  };

  const selecteDateHandler = (selectedDay: number, calendarSide: CalendarSide) => {
    if (calendarSide === 'left') {
      const newDate = new Date(yearValue, monthValue, selectedDay);
      setSelectedDate(newDate);
      onApplyHandler(newDate);
    }
  };

  const daysRender = (selectedMonth: number, selectedYear: number, calendarSide: CalendarSide) => {
    const {
      daysInCurrentMonth, firstDateOfCurrentMonth, daysInPreviousMonth, lastDateOfCurrentMonth,
    } = getDaysInMonth(selectedMonth, selectedYear);
    const today = new Date().toLocaleDateString('en-CA');

    const days = [];

    for (let i = 0; i < firstDateOfCurrentMonth; i += 1) {
      const day = new Date(selectedYear, selectedMonth - 1, daysInPreviousMonth - i).toLocaleDateString('en-CA');

      days.push(
        <Styled.InvalidDayStyled key={`${i}-prev`} isToday={day === today}>
          {daysInPreviousMonth - i}
        </Styled.InvalidDayStyled>,
      );
    }

    days.reverse();

    for (let i = 1; i <= daysInCurrentMonth; i += 1) {
      const day = new Date(selectedYear, selectedMonth, i).toLocaleDateString('en-CA');
      const checkDay = selectedDate.toLocaleDateString('en-CA') === day;

      days.push(
        <Styled.DayItemStyled key={`${i}-curr`} isToday={day === today}>
          <input type="radio" name={`day-${calendarSide}-${selectedMonth}`} value={`${i}`} onChange={() => selecteDateHandler(i, calendarSide)} checked={checkDay} />
          <span>{i}</span>
        </Styled.DayItemStyled>,
      );
    }

    for (let i = 0; i < 6 - lastDateOfCurrentMonth; i += 1) {
      const day = new Date(selectedYear, selectedMonth, i + 1).toLocaleDateString('en-CA');

      days.push(
        <Styled.InvalidDayStyled key={`${i}-next`} isToday={day === today}>
          {i + 1}
        </Styled.InvalidDayStyled>,
      );
    }

    return (
      <Styled.DayListStyled>
        {days}
      </Styled.DayListStyled>
    );
  };

  function handleClickOutside(event: any) {
    if (event.target.id === 'calendarModal') {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    setSelectedDate(date);
    setMonthValue(Number(date.getMonth()));
    setYearValue(Number(date.getFullYear()));
  }, [date]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Styled.WrapperStyled isOpen={isOpen} className={className} id="calendarModal">
      <Styled.InnerStyled isOpen={isOpen}>
        <Styled.DateAreaStyled>
          <Styled.BodyStyled>
            <Styled.MonthWrapperStyled>
              <Button id="calendarButtonPrev" theme="plain" onClick={() => prevMonth('left')}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </Button>

              <TextP>{fullNameMonths[monthValue]}, {yearValue}</TextP>
              <Button id="calendarButtonnext" theme="plain" onClick={() => nextMonth('left')}>
                <FontAwesomeIcon icon={faAngleRight} />
              </Button>
            </Styled.MonthWrapperStyled>

            {weekDaysRender()}
            {daysRender(monthValue, yearValue, 'left')}
          </Styled.BodyStyled>
        </Styled.DateAreaStyled>
      </Styled.InnerStyled>
    </Styled.WrapperStyled>
  );
};
