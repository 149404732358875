import styled from 'styled-components';
import { colours } from '../../styles/theme';

type IProps = {
  readonly displayLock: boolean
}
export const AppWrapperStyled = styled.div<IProps>`
  position: ${({ displayLock }) => (displayLock ? 'fixed' : '')};
  border-top: 3px solid ${colours.colour1};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const BodyWrapper = styled.div`
  flex: 1
`;
