export const ELIGILITY_CRITERIA = {
  TITLE: 'Eligibility Criteria',
  DESCRIPTION: 'Who is eligible to participate?',
  AGE: {
    LABEL: 'Age Requirement',
    MIN_LABEL: 'Must be between',
    MAX_LABEL: 'and',
    END_LABEL: 'old',
  },
  HEALTHEY_CONTROLS: {
    LABEL: 'Are healthy controls eligible?',
    DESCRIPTION: 'A healthy control can be considered someone who does not have a lived experience of the condition or health category that is being studied.',
    CHECKBUT_LABEL: 'Yes, healthy controls are eligible for this study.',
  },
  SEXES: {
    LABEL: 'Sexes Eligible',
    DESCRIPTION: 'Select all that apply.',
  },
  GENDERS: {
    LABEL: 'Genders Eligible',
    DESCRIPTION: 'Leave as "All" unless you are specifically studying gender differences. Remember, gender is complex. It\'s possible that despite envisioning your study as being applicable only to women, for example, that some trans men and non-binary people might also have that particular health need or issue.',
    DESCRIPTION_TWO: 'Select all that apply.',
  },
  INCLUSION: {
    LABEL: 'Inclusion Criteria',
    DESCRIPTION: 'E.g. Be willing to refrain from vigorous physical activity and alcohol for 24 hours before test visit days and refrain from tobacco products for at least 1 hour before and during test visits. Additional criteria may apply in order for you to participate in this study. The study team will discuss this with you. (Max character limit is 3000).',
  },
  EXCLUSION: {
    LABEL: 'Exclusion Criteria',
    DESCRIPTION: 'E.g. Contraindications to the health condition studied or procedures used. (Max character limit is 3000).',
  },
  HEALTH_CATEGORIES: {
    LABEL: 'Health Research Categories',
    DESCRIPTION: 'Which of the following categories does your research study fall under? Choose at least one subcategory below. If your study does not fit in one of the subcategories listed, select the subcategory “Other” in the most appropriate category for your research.',
  },
  TARGETS: {
    TITLE: 'What is your target sample size?',
    LABEL_PATIENT: 'Patients/Non-Control/Experimental Group',
    LABEL_CONTROL: 'Controls',
  },
};
