import React from 'react';
import { useVolunteerDashboard } from '../../hooks/useVolunteerDashboard';
import Toast from '../Toast';
import {
  VolunteerDashboardWrapper, MobileWrapper,
} from './styles';
import { Tabs } from '../Tabs';
import { VOLUNTEER_DASHBOARD } from './const';
import { UserNotice } from '../UserNotice';
import { ProjectDashboard } from './SubComponents/ProjectDashboard';

type VolunteerDashboardProps = {
  user: IUser
  searchValue: string
}

const VolunteerDashboard = ({ user, searchValue }: VolunteerDashboardProps) => {
  const hook = useVolunteerDashboard(user, searchValue);

  return (
    <VolunteerDashboardWrapper>
      <MobileWrapper>
        {!user.profile_complete && (
          <UserNotice
            title={VOLUNTEER_DASHBOARD.COMPLETE_PROFILE.TITLE}
            description={[VOLUNTEER_DASHBOARD.COMPLETE_PROFILE.DESCRIPTION]}
            link={{
              label: VOLUNTEER_DASHBOARD.COMPLETE_PROFILE.LINK.LABEL,
              path: VOLUNTEER_DASHBOARD.COMPLETE_PROFILE.LINK.URL,
              theme: 'secondary',
            }}
            icon="circle-exclamation"
          />
        )}
        <>
          <Tabs
            tabs={VOLUNTEER_DASHBOARD.TABS}
            selectTabHandler={hook.setCurrentTab}
            selectedTab={hook.currentTab}
            projectCount={hook.projectTypeCounts}
          />

          <ProjectDashboard
            emptyState={VOLUNTEER_DASHBOARD.EMPTY_STATES[hook.currentTab.value]}
            projects={hook.projectsData}
            isLoading={hook.isLoading}
            currentTab={hook.currentTab}
            updateInterest={hook.handleButtonClick}
            pageNumber={hook.pageNumber}
            setPageNumber={hook.setPageNumber}
            totalPages={hook.totalPages}
            rowCount={hook.rowCount}
            handleRowCount={hook.handleRowCount}
            rowOption={hook.rowOption}
          />
        </>
      </MobileWrapper>
      {hook.toastMessage
        && (
          <Toast
            message={hook.toastMessage}
            isOpen={hook.toastIsOpen}
            setIsOpen={hook.setToastIsOpen}
            type={hook.toastMessage !== 'interestedCreate' ? 'interest' : ''}
            name={hook.toastProject.name}
            handleButton={() => hook.handleToastUndo(hook.toastProject.id)}
            bottomOffset={false}
          />
        )}
    </VolunteerDashboardWrapper>
  );
};

export default VolunteerDashboard;
