import React from 'react';
import { TextP, LinkComponent } from '../../../../components';
import Button from '../../../../components/Button';
import { ResearcherButtonWrapper, ResearcherLinkStyled } from '../../../../styles/DashboardStyles';
import { fonts } from '../../../../styles/theme';
import { RESEARCHER_CARD } from './const';
import * as Styled from '../../../../styles/VolunteerCreateStyles';

type AreResearcherCardPropTypes = {
  setIsOpen: (isOpen: boolean) => void
}

export const AreResearcherCard = ({ setIsOpen }: AreResearcherCardPropTypes) => (
  <Styled.BorderDivStyled>
    <Styled.ResearcherLoginWrapper>
      <Styled.TextDivStyled margin="0 1rem 0 0.05em">
        <TextP lineHeight="1.4em" font={fonts.font1.H24B}>
          {RESEARCHER_CARD.TITLE}
        </TextP>
      </Styled.TextDivStyled>
    </Styled.ResearcherLoginWrapper>
    <Styled.TextDivStyled margin="1rem 0 0 0">
      <TextP lineHeight="1.4em" font={fonts.font1.H14}>
        {RESEARCHER_CARD.DESCRIPTION}
      </TextP>
    </Styled.TextDivStyled>
    <Styled.FixedCard>
      <Styled.DismissButtonWrapper>
        <Button
          id="dismiss"
          theme="dismiss"
          onClick={() => setIsOpen(false)}
          buttonWidth="25%"
        >
          {RESEARCHER_CARD.DISMISS}
        </Button>
      </Styled.DismissButtonWrapper>
      <ResearcherButtonWrapper>
        <LinkComponent
          theme="createResearcherButton"
          url={RESEARCHER_CARD.LINK.URL}
        >
          <ResearcherLinkStyled>
            {RESEARCHER_CARD.LINK.LABEL}
          </ResearcherLinkStyled>
        </LinkComponent>
      </ResearcherButtonWrapper>
    </Styled.FixedCard>
  </Styled.BorderDivStyled>
);
