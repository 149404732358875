import { useState } from 'react';
import * as Yup from 'yup';
import { useAPI } from './useAPI';
import { ppoInitialValues } from '../utils/ppoFormInitialValues';
import { gendersEligible, regionalCentre } from '../utils/researcherConst';
import { useResearcherAPI } from './useResearcherAPI';
import { addressOnBlurSchemaShape, PPOSchema } from '../utils/validation';
import { useUser } from './useUser';

export const usePPOForms = () => {
  const { getUserInfo } = useUser();

  const {
    getHealthAuthorities,
    getHospitals,
    getHealthCategory,
    createProject,
    deleteDraftProject,
    updateProject,
  } = useAPI();
  const { getResearcherByEmail } = useResearcherAPI();

  const [formInfo, setFormInfo] = useState(ppoInitialValues);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [changesMade, setChangeMade] = useState(false);
  const [changes, setChanges] = useState<string[]>([]);

  // fetch values
  const [healthRegions, setHealthRegions] = useState<{ name: string, id: number }[]>([]);
  const [hospitals, setHospitals] = useState<{ value: string, label: string }[]>([]);
  const [healthCategory, setHealthCategory] = useState<{ category: string, items: { id: number, item: string, category: string }[] }[]>([]);

  // project Values
  const [collaborators, setCollaborators] = useState<{ [key: string]: string }>({});
  const [teamMembers, setTeamMembers] = useState<{ [key: string]: { id: string, email: string, receive_email: boolean } }>({});
  const [collaboratingOrganizations, setCollaboratingOrganizations] = useState<{ [key: string]: string }>({});

  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [modalType, setModalType] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [statusModal, setStatusModal] = useState(false);

  const [forceChange, setForceChange] = useState(false);

  const [selectedResearchAreas, setSelectedResearchArea] = useState<{ category: string, id: number, name: string }[]>([]);

  const addChanges = (field: string) => {
    if (!changes.includes(field)) setChanges((prev) => [...prev, field]);
  };

  const resetChanges = () => {
    setChanges([]);
  };

  const handleSingleValidation = async (value: any, field: string, type?: string) => {
    let errors: { [key: string]: string } = {};
    try {
      if (type === 'address') {
        await Yup.reach(addressOnBlurSchemaShape, field).validate(value);
      } else {
        await Yup.reach(PPOSchema, field).validate(typeof value === 'string' ? value.replace(/\r?\n|\r/g, ' ') : value);
      }

      errors = formErrors;

      if (field === 'reb_certificate' && !value) {
        delete errors.reb_number;
        delete errors.reb_expiry;
      }

      if (field === 'is_online' && value) {
        delete errors.address;
        delete errors.postal_code;
        delete errors.street_address;
        delete errors.city;
      }
      delete errors[field];
      setFormErrors({ ...errors });
    } catch (error: any) {
      errors[field] = error.message;
      setFormErrors({ ...formErrors, ...errors });
    }
  };

  const handleChange = (value: any, field: string) => {
    let val = value.value || value;
    if (field === 'hospital_id') {
      val = value;
    }

    if (field === 'contact2_position' && value.value === 'None') {
      const form = formInfo;
      delete form[field];
      setFormInfo({ ...form });
    } else if (value === '' && (field === 'contact1_phone' || field === 'contact2_phone')) {
      const form = formInfo;
      delete form[field];
      setFormInfo({ ...form });
    } else {
      setFormInfo((prev) => ({
        ...prev,
        [field]: val,
      }));
    }
    addChanges(field);
    if (field === 'funding_agency' || field === 'recruitment_end' || field === 'contact1_position' || field === 'desired_status') handleSingleValidation(val, field);
    if (!changesMade) {
      setChangeMade(true);
    }
  };

  const handleToggle = (value: boolean, field: string) => {
    addChanges(field);
    setFormInfo((prev) => ({
      ...prev,
      [field]: value,
    }));
    if (!changesMade) {
      setChangeMade(true);
    }
  };

  const handleCheck = (checked: boolean, field: string | number, key: string) => {
    const currentRegionalCenters = formInfo.regional_centers || [];
    const currentHealthAuthorityRegion = formInfo.health_authority_region;
    const currentSexEligible = formInfo.sex_eligible;
    const currentGendersEligible = formInfo.genders_eligible;
    const currentEngagement = formInfo.level_of_engagement;
    const currentFundingAgency = formInfo.funding_agency;

    addChanges(key);

    switch (key) {
      case 'regionalCenters':
        if (typeof field !== 'string') break;
        if (field === 'All') {
          if (!checked) {
            setFormInfo({
              ...formInfo,
              regional_centers: [],
            });
          } else {
            setFormInfo({
              ...formInfo,
              regional_centers: [...Object.keys(regionalCentre)],
            });
          }
        } else if (!checked) {
          const updateRegions = currentRegionalCenters.filter((region) => region !== field);
          setFormInfo({
            ...formInfo,
            regional_centers: updateRegions,
          });
        } else {
          setFormInfo({
            ...formInfo,
            regional_centers: [...currentRegionalCenters, field],
          });
        }
        break;
      case 'funding_agency': {
        let updateAgencies: string[];
        if (!checked) {
          updateAgencies = currentFundingAgency.filter((agency) => agency !== field);
        } else {
          if (typeof field !== 'string') break;
          updateAgencies = [...currentFundingAgency, field];
        }
        setFormInfo({
          ...formInfo,
          funding_agency: updateAgencies,
        });
        handleSingleValidation(updateAgencies, 'funding_agency');
        break;
      }
      case 'health_authority_region': {
        let updatedRegions: number[];
        if (field === 'All') {
          if (!checked) {
            updatedRegions = [];
          } else {
            updatedRegions = [...healthRegions.map((region) => region.id)];
          }
        } else if (!checked) {
          updatedRegions = currentHealthAuthorityRegion.filter((region) => region !== field);
        } else {
          if (typeof field !== 'number') break;
          updatedRegions = [...currentHealthAuthorityRegion, field];
        }
        setFormInfo({
          ...formInfo,
          health_authority_region: updatedRegions,
        });
        handleSingleValidation(updatedRegions, 'health_authority_region');
        break;
      }
      case 'sex_eligible': {
        if (typeof field !== 'string') break;
        let updatedSexes: string[];
        if (field === 'All') {
          if (!checked) {
            updatedSexes = [];
          } else {
            updatedSexes = ['Female', 'Male'];
          }
        } else if (!checked) {
          updatedSexes = currentSexEligible.filter((region) => region !== field);
        } else {
          updatedSexes = [...currentSexEligible, field];
        }
        setFormInfo({
          ...formInfo,
          sex_eligible: updatedSexes,
        });
        handleSingleValidation(updatedSexes, 'sex_eligible');
        break;
      }
      case 'genders_eligible': {
        if (typeof field !== 'string') break;
        let updatedGenders: string[];
        if (field === 'All') {
          if (!checked) {
            updatedGenders = [];
          } else {
            updatedGenders = [...gendersEligible];
          }
        } else if (!checked) {
          updatedGenders = currentGendersEligible.filter((region) => region !== field);
        } else {
          updatedGenders = [...currentGendersEligible, field];
        }
        setFormInfo({
          ...formInfo,
          genders_eligible: updatedGenders,
        });
        handleSingleValidation(updatedGenders, 'genders_eligible');
        break;
      }
      case 'level_of_engagement': {
        if (typeof field !== 'string') break;
        let updatedList: string[];
        if (!checked) {
          updatedList = currentEngagement.filter((engagement) => engagement !== field);
        } else {
          updatedList = [...currentEngagement, field];
        }
        setFormInfo({
          ...formInfo,
          level_of_engagement: updatedList,
        });
        handleSingleValidation(updatedList, 'level_of_engagement');
        break;
      }
      case 'receive_email': {
        delete formErrors.additional_team_member;

        const updatedTeamMembers: { [key: string]: { id: string, email: string, receive_email: boolean } } = JSON.parse(JSON.stringify(teamMembers));
        updatedTeamMembers[field].receive_email = checked;

        handleChange(updatedTeamMembers, 'additional_team_member');
        setTeamMembers(updatedTeamMembers);
        break;
      }
      default:
        break;
    }
    if (!changesMade) {
      setChangeMade(true);
    }
  };

  const handleRemoveCollaborators = (id: string) => {
    const list = { ...collaborators };
    delete list[id];
    setCollaborators(list);
    addChanges('collaborators');
  };

  const handleRemoveTeamMember = (id: string, name: string) => {
    if (Object.keys(teamMembers).length === 1) {
      const errors = { ...formErrors };
      errors[id] = 'A new Additional Team Member must be added before you can remove this team member';
      setFormErrors(errors);
      return;
    }
    const list = { ...teamMembers };
    delete list[id];
    const error = formErrors;
    delete error[name];
    setFormErrors({ ...error });
    setTeamMembers(list);
    addChanges('additional_team_member');
  };

  const handleRemoveCollaboratingOrganizations = (id: string) => {
    const list = { ...collaboratingOrganizations };
    delete list[id];
    setCollaboratingOrganizations(list);
    addChanges('collaborating_organizations');
  };

  const handleRemoveInput = (key: string, id: string, name?: string) => {
    switch (key) {
      case 'collaborator':
        handleRemoveCollaborators(id);
        setIsSubmiting(false);
        break;
      case 'teamMember':
        handleRemoveTeamMember(id, name || '');
        setIsSubmiting(false);
        break;
      case 'collaboratingOrganizations':
        handleRemoveCollaboratingOrganizations(id);
        setIsSubmiting(false);
        break;
      default:
        break;
    }
  };

  const handleAddInput = (key: string, max = Infinity) => {
    const id = `${key}-${Math.round(Math.random() * 10000)}`;
    switch (key) {
      case 'collaborator':
        if (Object.keys(collaborators).length > max) return;
        setCollaborators((prev) => ({
          ...prev,
          [id]: '',
        }));
        addChanges('collaborators');
        break;
      case 'teamMember':
        if (Object.keys(teamMembers).length > max) return;
        setTeamMembers((prev) => ({
          ...prev,
          [id]: { email: '', id: '', receive_email: true },
        }));
        addChanges('additional_team_member');
        break;
      case 'collaboratingOrganizations':
        if (Object.keys(collaboratingOrganizations).length > max) return;
        setCollaboratingOrganizations((prev) => ({
          ...prev,
          [id]: '',
        }));
        addChanges('collaborating_organizations');
        break;
      default:
        break;
    }
  };

  const handleChangeAddedInput = (value: string, id: string, key: string) => {
    switch (key) {
      case 'collaborator':
        setCollaborators((prev) => ({
          ...prev,
          [id]: value,
        }));
        addChanges('collaborators');
        break;
      case 'teamMember':
        setTeamMembers((prev) => ({
          ...prev,
          [id]: { email: value, id: '', receive_email: true },
        }));
        addChanges('additional_team_member');
        break;
      case 'collaboratingOrganizations':
        setCollaboratingOrganizations((prev) => ({
          ...prev,
          [id]: value,
        }));
        addChanges('collaborating_organizations');
        break;
      default:
        break;
    }
    if (!changesMade) {
      setChangeMade(true);
    }
  };

  const toggleResearchArea = (name: string, id: number, category: string) => {
    const areas = selectedResearchAreas;
    const tempIndex = areas.findIndex((item) => item.id === id);
    if (tempIndex !== -1) {
      areas.splice(tempIndex, 1);
    } else {
      areas.push({ name, id, category });
    }
    setForceChange(!forceChange);
    setSelectedResearchArea(areas);
    addChanges('health_categories');
    handleSingleValidation(areas, 'health_categories');
    if (!changesMade) {
      setChangeMade(true);
    }
  };

  const fetchValues = async (isNew = false) => {
    if (isNew) {
      const user = getUserInfo();
      setTeamMembers({ teamMembers: { id: user.researcherid, email: user.email, receive_email: user.receive_email ?? true } });
    }
    const hospitalsList = await getHospitals();
    const newArr = hospitalsList.map((hospital: { id: number, name: string }) => ({
      value: hospital.id.toString(),
      label: hospital.name,
    }));
    setHospitals([{ value: null, label: '' }, ...newArr]);

    const healthRegionsList = await getHealthAuthorities();
    setHealthRegions(healthRegionsList);

    const healthCategories = await getHealthCategory();
    setHealthCategory(healthCategories);
  };

  const handleSubmit = async (formValues: PatientPartnerResearchOpportunityValues | any) => {
    try {
      const data = new FormData();

      Object.keys(formValues).forEach((key) => {
        if (key === 'additional_team_member') {
          const value = JSON.stringify(formValues[key]);
          data.append(key, value);
        } else {
          data.append(key, formValues[key]);
        }
      });
      const res = await createProject(data);
      setIsSubmiting(false);

      return res;
    } catch (error: any) {
      setIsSubmiting(false);
      throw new Error(error.message);
    }
  };

  const handleUpdateSubmit = async (formValues: PatientPartnerResearchOpportunityValues | any) => {
    try {
      const data = new FormData();

      Object.keys(formValues).forEach((key) => {
        if (key === 'additional_team_member') {
          const value = JSON.stringify(formValues[key]);
          data.append(key, value);
        } else {
          data.append(key, formValues[key]);
        }
      });

      const res = await updateProject(data);
      return res;
    } catch (error: any) {
      setIsSubmiting(false);
      throw new Error(error.message);
    }
  };

  const checkTeamMember = async (user: string, email: string, validation = false) => {
    let errors: { [key: string]: string } = {};
    setIsSubmiting(true);

    const teamMemberError = 'An account with this email address does not exist. For this account to be saved, they must have an account associated with this email.';

    if (email === '') {
      errors[user] = teamMemberError;
      setFormErrors({ ...formErrors, ...errors });
      setIsSubmiting(false);
      throw new Error(JSON.stringify({ [user]: teamMemberError }));
    } else {
      const teamMember = await getResearcherByEmail(email.toLowerCase());
      if (!teamMember?.researcherid) {
        errors[user] = teamMemberError;
        setFormErrors({ ...formErrors, ...errors });
        setIsSubmiting(false);
        throw new Error(JSON.stringify({ [user]: teamMemberError }));
      } else {
        errors = formErrors;
        delete errors[user];
        if (Object.keys(errors).length > 0) {
          Object.keys(errors).forEach((err) => {
            if (errors[err] === 'A new Additional Team Member must be added before you can remove this team member') {
              delete errors[err];
            }
          });
        }
        delete errors['Team Member'];

        if (!validation) {
          handleChange(teamMember.researcherid, 'additional_team_member');
          teamMembers[user] = {
            id: teamMember.researcherid,
            email,
            receive_email: teamMembers[user].receive_email ?? true,
          };
          setTeamMembers(teamMembers);
        }
        setIsSubmiting(false);
      }
    }
  };

  const checkTeamMembers = async (members: any[]) => {
    const promises = members.map(async (member) => checkTeamMember(member[0], member[1].email, true));
    return Promise.all(promises);
  };

  const checkPI = async (email: string) => {
    let errors: { [key: string]: string } = {};
    setIsSubmiting(true);
    const form = formInfo;
    const piError = 'A Researcher Account with this email address does not exist. The PI must create a researcher account before this study can be submitted.';

    if (email === '') {
      delete form.pi_id;
      errors.pi_id = piError;
      setFormErrors({ ...formErrors, ...errors });
      setFormInfo({ ...form });
      setIsSubmiting(false);
      throw new Error(JSON.stringify({ pi_id: piError }));
    } else {
      const pi = await getResearcherByEmail(email);
      setIsSubmiting(false);
      if (!pi.researcherid) {
        delete form.pi_id;
        errors.pi_id = piError;
        setFormErrors({ ...formErrors, ...errors });
        setFormInfo({ ...form });
        throw new Error(JSON.stringify({ pi_id: piError }));
      } else {
        handleChange(pi.researcherid, 'pi_id');
        errors = formErrors;
        delete errors.pi_id;
        setFormErrors({ ...errors });
      }
    }
  };

  const handleValidation = async (formValues: PatientPartnerResearchOpportunityValues, validation: any) => {
    setIsSubmiting(true);
    try {
      await validation.validate({
        ...formValues,
        purpose: formValues.purpose.replace(/\r?\n|\r/g, ' '),
        description: formValues.description.replace(/\r?\n|\r/g, ' '),
        background: formValues.background.replace(/\r?\n|\r/g, ' '),
        commitment: formValues.commitment.replace(/\r?\n|\r/g, ' '),
        orientation_provided: formValues.orientation_provided.replace(/\r?\n|\r/g, ' '),
        additional_eligibility: formValues.additional_eligibility?.replace(/\r?\n|\r/g, ' ') || '',
      }, { abortEarly: false });
    } catch (error: any) {
      const errors: { [key: string]: string } = {};
      error.inner.forEach((element: any) => {
        errors[element.path] = element.message;
      });
      setIsSubmiting(false);
      throw new Error(JSON.stringify(errors));
    }
  };

  const deleteDraft = async (project: PatientPartnerResearchOpportunityValues) => {
    try {
      if (project.status !== 'draft' || !project.id) throw new Error('Only drafts can be deleted');
      const deletedProject = await deleteDraftProject(project?.id);
      return deletedProject;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return {
    formInfo,
    formErrors,
    healthRegions,
    hospitals,
    healthCategory,
    collaborators,
    teamMembers,
    collaboratingOrganizations,
    isSubmiting,
    selectedResearchAreas,
    toastIsOpen,
    toastMessage,
    modalIsOpen,
    modalType,
    isDraft,
    changesMade,
    setIsDraft,
    setToastIsOpen,
    setToastMessage,
    setModalIsOpen,
    setModalType,
    setFormInfo,
    fetchValues,
    setFormErrors,
    handleChange,
    handleToggle,
    handleValidation,
    handleSubmit,
    handleCheck,
    handleRemoveInput,
    toggleResearchArea,
    handleChangeAddedInput,
    handleAddInput,
    checkPI,
    checkTeamMember,
    setCollaboratingOrganizations,
    setCollaborators,
    setTeamMembers,
    setSelectedResearchArea,
    deleteDraft,
    handleUpdateSubmit,
    setChangeMade,
    resetChanges,
    changes,
    forceChange,
    setForceChange,
    handleSingleValidation,
    checkTeamMembers,
    setStatusModal,
    statusModal,
    setIsSubmiting,
  };
};
