export const userInitial: IUser = {
  first_name: '',
  last_name: '',
  id: '',
  type: 'volunteer',
  email: '',
};

export const resultInitial: IResult = {
  completed: false,
  summary: '',
  patient_partners_count: 0,
  patient_partners_engagement_strategy: '',
  study_limitations: '',
  project_id: 0,
  comments: '',
  status: 'pending_approval',
  patient_oriented_project: false,
  link_study: false,
};
