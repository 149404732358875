import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type TitleWrapperPropTypes = {
  readonly theme?: 'secondary'
}

export const SectionStyled = styled.section`
  margin-bottom: 0.875rem;
  background:  ${colours.colour23};
  color:  ${colours.colour6};
  padding: 1.5rem 1.5rem 2rem;
  ${fonts.font1.H14};
  `;

export const TitleWrapper = styled.div<TitleWrapperPropTypes>`
  border-bottom: ${({ theme }) => (theme === 'secondary' ? 'none' : `1px solid ${colours.colour17}`)};
  padding-bottom: ${({ theme }) => (theme === 'secondary' ? '0' : '0.875rem')};
  margin-bottom: ${({ theme }) => (theme === 'secondary' ? '2rem' : '1.5rem')};
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 820px) {
    flex-direction: row;
    padding-bottom: ${({ theme }) => (theme === 'secondary' ? '0' : '1.5rem')};
  }
`;

export const TitleStyled = styled.div`
  ${fonts.font1.H21B};
  line-height: 1.5rem;
`;

export const MobileWrapper = styled.span`
  @media (min-width: 820px) {
    display: none;
  }
`;

export const DesktopWrapper = styled.span`
  display: none;

  @media (min-width: 820px) {
    display: inherit;
  }
`;

export const SubtextStyled = styled.p`
  ${fonts.font1.H14};
  line-height: 1.33rem;
  margin-top: 1rem;
`;
