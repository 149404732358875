import { useState } from 'react';
import * as Yup from 'yup';
import { resultInitial } from '../utils/initialValues';
import { ppoResultOnBlurSchema, studyResultOnBlurSchema } from '../utils/validation';
import { useResearcherAPI } from './useResearcherAPI';
import { useUser } from './useUser';

export const useResultForm = () => {
  const { createResult, getResearchersStudy } = useResearcherAPI();
  const { getUserInfo } = useUser();

  const [projectType, setProjectType] = useState('');
  const [formInfo, setFormInfo] = useState(resultInitial);
  const [resourceFile, setResourceFile] = useState<File>();
  const [linkStudies, setLinkStudies] = useState([]);

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [changes, setChanges] = useState<string[]>([]);

  const handleSingleValidation = async (value: any, field: string) => {
    let errors: { [key: string]: string } = {};
    const validitionSchema = projectType === 'study' ? studyResultOnBlurSchema : ppoResultOnBlurSchema;
    try {
      await Yup.reach(validitionSchema, field).validate(value);
      errors = formErrors;
      delete errors[field];
      setFormErrors({ ...errors });
    } catch (error: any) {
      errors[field] = error.message;
      setFormErrors({ ...formErrors, ...errors });
    }
  };

  const addChanges = (field: string) => {
    if (!changes.includes(field)) setChanges((prev) => [...prev, field]);
  };

  const resetChanges = () => {
    setChanges([]);
  };

  const handleToggle = (value: boolean, field: string) => {
    addChanges(field);
    if (field === 'patient_oriented_project' && projectType === 'study' && value === false) {
      const errors = formErrors;
      delete errors.patient_partners_count;
      delete errors.patient_partners_engagement_strategy;
      setFormErrors(errors);
    }
    setFormInfo((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleChange = (value: string | number, field: string) => {
    addChanges(field);
    setFormInfo((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFile = (value: File, field: string) => {
    const errors = { ...formErrors };

    if (value.type !== 'application/pdf' && value.type !== 'pdf') {
      errors[field] = 'Invalid file type, please select a pdf file';
      setFormErrors(errors);
      return;
    }

    if (value && value.size >= 15 * 1024 * 1024) {
      errors[field] = 'Invalid file size, maximum file size 15MB.';
      return;
    }

    addChanges(field);
    setResourceFile(value);
    delete errors.resource_file;
    setFormErrors(errors);
  };

  const handleRemoveFile = () => setResourceFile(undefined);

  const getLinkStudies = async () => {
    const user = await getUserInfo();
    if (user.type !== 'researcher') return;
    const projects = await getResearchersStudy();
    setLinkStudies(projects);
  };

  const handleSubmit = async (formValues: IResult | any) => {
    const data = new FormData();

    Object.keys(formValues).forEach((key) => {
      data.append(key, formValues[key]);
    });

    if (resourceFile) {
      data.append('resourceFile', resourceFile);
    }
    const res = await createResult(data);
    return res;
  };

  const handleValidation = async (formValues: IResult, validation: any) => {
    setIsSubmiting(true);
    setFormErrors({});
    try {
      await validation.validate({
        ...formValues,
      }, { abortEarly: false });
    } catch (error: any) {
      const errors: { [key: string]: string } = {};
      error.inner.forEach((element: any) => {
        errors[element.path] = element.message;
      });
      setIsSubmiting(false);
      throw errors;
    }
  };

  return {
    formInfo,
    resourceFile,
    formErrors,
    isSubmiting,
    projectType,
    linkStudies,
    setProjectType,
    handleToggle,
    handleChange,
    handleFile,
    handleSubmit,
    handleValidation,
    getLinkStudies,
    setFormInfo,
    resetChanges,
    changes,
    handleSingleValidation,
    setFormErrors,
    handleRemoveFile,
  };
};
