import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';

type LabelStyledProps = {
  readonly theme?: string
};

type InputStyledProps = {
  readonly theme?: string
  readonly iconPositon?: 'left' | 'right'
}

type TextinputWrapperProps = {
  readonly width?: string
  readonly isPublic?: boolean
}

type LengthCounterStyledProps = {
  readonly hasError: boolean
}

type IconWrapperProps = {
  readonly iconPosition?: 'left' | 'right'
  readonly theme?: string
}

export const LabelStyled = styled.label<LabelStyledProps>`
  ${fonts.font1.H18B};
  font-weight: ${({ theme }) => (theme === 'form' ? '' : '500')};
  color: ${colours.colour6};
  line-height: 1.33rem;
  display: ${({ theme }) => {
    switch (theme) {
      case 'column':
        return 'block';
      case 'columnLeft':
        return 'block';
      case 'login':
        return 'block';
      default:
        return '';
    }
  }};

  margin: ${({ theme }) => {
    switch (theme) {
      case 'column':
        return '1.375rem auto 0.25rem';
      case 'form':
        return '0 0 0.25rem';
      default:
        return '1.375rem 0 0.25rem';
    }
  }};

  text-align: ${({ theme }) => (theme === 'column' ? 'center' : 'left')};
`;

export const InputStyled = styled.input<InputStyledProps>`
  ${fonts.font1.H16};
  line-height: 1.5rem;

  color: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour9;
      case 'searchSecondary':
        return colours.colour36;
      default:
        return colours.colour6;
    }
  }
};

  padding: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '0.3rem';
      case 'searchSecondary':
        return '0.75rem 0.75rem 0.75rem 2.5rem';
      default:
        return '0.75rem 1rem';
    }
  }};

  border:  ${({ theme }) => {
    switch (theme) {
      case 'round':
        return `3px solid ${colours.colour19}`;
      case 'search':
        return `1px solid ${colours.colour29}`;
      case 'searchSecondary':
        return `1px solid ${colours.colour41}`;
      case 'secondary':
        return `1px solid ${colours.colour9}`;
      default:
        return `1px solid ${colours.colour5}`;
    }
  }};

  border-radius: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '30px';
      case 'secondary':
        return '0px';
      default:
        return '4px';
    }
  }};

  margin-top: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '0';
      case 'column':
        return '0';
      case 'search':
        return '0';
      case 'labelless':
        return 0;
      case 'searchSecondary':
        return 0;
      default:
        return '0.475rem';
    }
  }};

  width: ${({ theme }) => {
    switch (theme) {
      case 'round':
        return '100%';
      default:
        return '100%';
    }
  }};

  background: ${({ theme }) => {
    switch (theme) {
      case 'searchSecondary':
        return colours.colour41;
      default:
        return '';
    }
  }};

  &:disabled {
    cursor: not-allowed;
    background: ${colours.colour41};
    color: ${colours.colour35};
    border: 1px solid ${colours.colour29};
  }
`;

export const ParagrahStyled = styled.p`
  ${fonts.font1.H14}
  overflow-wrap: break-word;
`;

export const TextinputWrapper = styled.div<TextinputWrapperProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => width || '100%'};
`;

export const LengthCounterStyled = styled.div<LengthCounterStyledProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ hasError }) => (hasError ? 'space-between' : 'flex-end')};
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  position: absolute;
  bottom: ${({ theme }) => (theme === 'inputIcon' ? '0.7rem' : '1rem')};
  ${fonts.font1.H16};

  right: ${({ iconPosition }) => (iconPosition === 'left' ? '' : '0.5rem')};
  left: ${({ iconPosition }) => (iconPosition === 'left' ? '1rem' : '')};
  color: ${({ theme }) => (theme === 'searchSecondary' ? colours.colour36 : '')};
`;

export const DescriptionWrapper = styled.div`
  margin-top: 0.25rem;
  flex: 1;
  ${fonts.font1.H14};
  line-height: 1.33rem;
`;

export const CounterTextStyled = styled.p`
  margin: 0.5rem 0;
  ${fonts.font1.H12};

  span {
    ${fonts.font1.H12B};
  }
`;
