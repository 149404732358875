import styled from 'styled-components';
import { colours, fonts } from '../../../styles/theme';

type IconWrapperProps = {
  readonly theme?: string
}

type IconStaindInProps = {
  readonly width: string
  readonly height: string
  readonly color: string
}

type LogoWrapperProps = {
  readonly theme?: string
}

export const IconStandIn = styled.div<IconStaindInProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ color }) => color};
`;

export const FooterStyled = styled.section`
  width: 100%;
  border-top: 1px solid ${colours.colour45};
  display: grid;
  grid-template-columns: auto auto;
  padding: 3.75rem 2.5rem 2.5rem;
`;

export const FooterNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-row: 1/3;
  grid-column: 1/2;
`;

export const FooterDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  grid-column: 2/3;
  grid-row: 1/2;
`;

export const LinkWrapper = styled.div`
  margin-bottom: 0.938rem;
  width: fit-content;
`;

export const ItemWrapper = styled.div<IconWrapperProps>`
  margin: ${({ theme }) => {
    switch (theme) {
      case 'marginLeft':
        return '0 0 2.188rem';
      case 'marginLeftSm':
        return '0 0 1rem 0';
      case 'none':
        return '0';
      default:
        return '0 auto';
    }
  }};

  @media (min-width: 362px) {
    margin: ${({ theme }) => {
    switch (theme) {
      case 'marginLeft':
        return '0 0 2.188rem 0';
      case 'marginLeftSm':
        return '0 1rem 1rem 0';
      case 'none':
        return '0';
      default:
        return '0 auto';
    }
  }};
  }
  @media (min-width: 700px) {
    margin: ${({ theme }) => {
    switch (theme) {
      case 'marginLeft':
        return '0 2.188rem 0 0';
      case 'marginLeftSm':
        return '0 1rem 1rem 0';
      case 'none':
        return '0';
      default:
        return '0 auto';
    }
  }};
  };
  @media (min-width: 900px) {
    margin: ${({ theme }) => {
    switch (theme) {
      case 'marginLeft':
        return '0 2.188rem 0 0';
      case 'marginLeftSm':
        return '0 1rem 0 0';
      case 'none':
        return '0';
      default:
        return '0 auto';
    }
  }};
  };
`;

export const LogoWrapper = styled.div<LogoWrapperProps>`
  position: relative;
  height: ${({ theme }) => {
    switch (theme) {
      case 'ubc':
        return '52px';
      case 'bcAhsn':
        return '57px';
      case 'vch':
        return '35px';
      default:
        return '52px';
    }
  }};

  width: ${({ theme }) => {
    switch (theme) {
      case 'ubc':
        return '38px';
      case 'bcAhsn':
        return '206px';
      case 'vch':
        return '142px';
      default:
        return '57px';
    }
  }};
`;

export const IconsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
  grid-row: 5/6;

  @media (min-width: 700px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    grid-column: 2/3;
    grid-row: 2/3;
  }
`;

export const FooterFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.875rem;
  grid-column: 1/3;
  grid-row: 3/4;

  @media (min-width: 362px) {
    grid-row: 3/4;
  }
  
  @media (min-width: 700px) {
    grid-row: 3/4;
    grid-column: 1/2;
  }
  
  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

export const FooterCopyRightWrapper = styled.div`
  ${fonts.font1.H13}
  color: ${colours.colour46};
  display: flex;
  margin-top: 1.875rem;
  grid-column: 1/3;
  grid-row: 6/7;
  
  @media (min-width: 700px) {
    justify-content: flex-end;
    grid-column: 2/3;
    grid-row: 3/4;
  }
`;
