import styled from 'styled-components';
import { colours, fonts } from './theme';

export const BodyStyled = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: ${colours.colour23};
  ${fonts.font1.H16};
      min-height: 480px;
      padding: 0 2rem;

  @media (min-width: 992px) {
      min-height: 360px;
    }
`;

export const MessageStyled = styled.div`
  ${fonts.font1.H24B};
  margin: 0.5rem 0 2rem;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 1rem;
  width: fit-content;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterTextWrapper = styled.span`
  margin-right: 0.5rem;
`;
