export const PPO_ESTIMATED_COMMITMENT = {
  TITLE: 'Estimated Commitment',
  COMMITMENT: {
    LABEL: 'Estimated Commitment',
    DESCRIPTION: ['Provide a quick summary of the estimated commitment.',
      'E.g. One face to face meeting, followed by 6 video conference meetings over 1 year. (Max character limit is 300).',
    ],
  },
  PROCEDURE: {
    LABEL: 'Procedures Required?',
    DESCRIPTION: 'E.g. Blood draw of 5 ml (tablespoon). (Max character limit is 450)',
    CHECKBOX_LABEL: 'Yes, this study requires procedures.',
  },
  COMPENSATION: {
    LABEL: 'Compensation/gifts offered?',
    DESCRIPTION: 'E.g. Gift card, honorarium, tablet/equipment, etc. This is different than reimbursement of expenses, see below.',
    CHECKBOX_LABEL: 'Yes, this study offers compensations/gifts.',
  },
  REIMBURSED: {
    LABEL: 'Other expenses reimbursed?',
    DESCRIPTION: 'Note: It is highly recommended that patient partner expenses be reimbursed. ',
    DESCRIPTION_TWO: 'E.g. Travel (e.g. parking fees, bus pass, or mileage) will be reimbursed.',
    CHECKBOX_LABEL: 'Yes, this study reimburses other expenses.',
  },
};
