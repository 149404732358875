import { useState } from 'react';

export const useAccordion = () => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return {
    accordionOpen,
    setAccordionOpen,
  };
};
