import environment from '../../config/environment';

export const VOLUNTEER_DASHBOARD: Record<string, any> = {
  TABS: [
    {
      label: 'Suggested For You',
      value: 'SUGGESTED',
      description: 'Here are the projects that best fit your indicated preferences from your profile. Click “View Full Details” to determine your eligibility for participation.',
    },
    {
      label: 'My Projects',
      value: 'PROJECT',
      description: 'These are the opportunities that you have expressed interest in. Click “Archive Project” to save them for future reference.',
    },
    {
      label: 'My Archived Projects',
      value: 'ARCHIVED',
      description: 'These are the opportunities you have archived. You can look back through previous opportunities to see if results have been shared.',
    },
  ],
  COMPLETE_PROFILE: {
    TITLE: 'Important',
    DESCRIPTION: 'Complete your profile now to get personalized research opportunities matched to you.',
    LINK: {
      LABEL: 'Go to My Profile',
      URL: '/myProfile',
    },
  },
  EMPTY_STATES: {
    SUGGESTED: {
      title: 'No Projects',
      description: 'Find research opportunities and participate in research.',
      url: `${environment.app.websiteURL}/studies`,
      path: 'Find Studies',
    },
    PROJECT: {
      title: 'No Projects',
      description: 'Find research opportunities and participate in research.',
      url: `${environment.app.websiteURL}/studies`,
      path: 'Find Studies',
    },
    ARCHIVED: {
      title: 'No Archived Projects',
      description: 'Go to “My Projects” tab to archive projects.',
      list: [
        'Archive inactive projects from current ones',
        "Archive projects to view study results you're interested in",
      ],
    },
  },
};

export const TABS = {
  SUGGESTED: 'SUGGESTED',
  PROJECT: 'PROJECT',
  ARCHIVED: 'ARCHIVED',
};

export const PROJECT_COUNTS = {
  SUGGESTED: 0,
  ARCHIVED: 0,
  PROJECT: 0,
};

export const PROJECT_LISTS = {
  suggested: [],
  project: [],
  archived: [],
};
