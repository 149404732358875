import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RESEARCHER_DASHBOARD_HERO } from './const';
import { LinkComponent } from '../../Link';
import QuestionSvg from '../../../assets/images/question_circle.svg';
import * as Styled from './styles';
import { UserNotice } from '../../UserNotice';

type ResearcherDashboardHeroPropTypes = {
  userName: string
  bypass?: boolean
}

export const ResearcherDashboardHero = ({ userName, bypass = false }: ResearcherDashboardHeroPropTypes) => (
  <Styled.HeroWrapper>
    {bypass && (
      <UserNotice
        title={RESEARCHER_DASHBOARD_HERO.BYPASS_MESSAGE.TITLE}
        description={RESEARCHER_DASHBOARD_HERO.BYPASS_MESSAGE.DESCRIPTION}
      />
    )}
    <Styled.TitleWrapper>
      <h3>{RESEARCHER_DASHBOARD_HERO.USER_MESSAGE} {userName}</h3>

      <LinkComponent
        url={RESEARCHER_DASHBOARD_HERO.LINK.URL}
        theme="plainSecondary"
      >
        <Styled.LinkContainWrapper>
          <Styled.LinkImageStyled src={QuestionSvg} alt="qm" />
          {RESEARCHER_DASHBOARD_HERO.LINK.LABEL}
        </Styled.LinkContainWrapper>
      </LinkComponent>
    </Styled.TitleWrapper>

    <Styled.BodyWrapper>
      {RESEARCHER_DASHBOARD_HERO.Cards.map((card) => (
        <Styled.CardWrapper key={card.TITLE}>
          <Styled.CardTitleStyled>{card.TITLE}</Styled.CardTitleStyled>
          <Styled.CardDescriptionWrapper>{card.DESCRIPTION}</Styled.CardDescriptionWrapper>
          <LinkComponent
            url={card.LINK.URL}
            theme="button"
            linkWidth="fit-content"
          >
            <Styled.LinkButtonWrapper>
              <FontAwesomeIcon icon="plus" />
              {card.LINK.LABEL}
            </Styled.LinkButtonWrapper>
          </LinkComponent>
        </Styled.CardWrapper>
      ))}
    </Styled.BodyWrapper>
  </Styled.HeroWrapper>
);
