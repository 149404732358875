import React from 'react';
import { TextInput } from '../../../../components';
import Button from '../../../../components/Button';
import { ButtonWrapper } from '../../../../styles/loginStyle';
import { InputWrapper } from '../../../../styles/ResearcherCreateStyles';
import { PasswordRequirementChecker } from '../../../../components/PasswordRequirementChecker';

type CreateFormProps = {
  handleSubmit: (event: React.FormEvent) => void
  email: string
  setEmail: (email: string) => void
  password: string
  setPassword: (password: string) => void
  confirmPassword: string
  setConfirmPassword: (confirmPassword: string) => void
  researcherInfoError: { [key: string]: string }
  checkEmail: () => void
  handleSingleValidation: (value: any, field: string, type?: string) => void
  hookPasswordRequirements: Record<string, boolean>
  hookHandlePasswordChecking: (value: string) => void
  hookDisplayValidationText: boolean
}

const CreateForm = ({
  handleSubmit, email, setEmail, password, researcherInfoError, setPassword, confirmPassword, setConfirmPassword, checkEmail, handleSingleValidation, hookPasswordRequirements, hookHandlePasswordChecking, hookDisplayValidationText,
}: CreateFormProps) => (
  <form onSubmit={handleSubmit}>
    <InputWrapper>
      <TextInput
        label="Email"
        description="Please use your employer/institutional e-mail address. Personal e-mail addresses are prohibited."
        textValue={email}
        setTextValue={setEmail}
        error={researcherInfoError.email || ''}
        type="email"
        onBlurAction={() => checkEmail()}
      />
    </InputWrapper>
    <InputWrapper>
      <TextInput
        label="Password"
        textValue={password}
        setTextValue={(value) => {
          setPassword(value);
          hookHandlePasswordChecking(value);
        }}
        error={researcherInfoError.password || ''}
        type="password"
      />
    </InputWrapper>
    {hookDisplayValidationText && (
      <InputWrapper>
        <PasswordRequirementChecker
          passwordRequirements={hookPasswordRequirements}
        />
      </InputWrapper>
    )}
    <InputWrapper>
      <TextInput
        label="Confirm Password"
        textValue={confirmPassword}
        setTextValue={setConfirmPassword}
        error={researcherInfoError.password_confirm || ''}
        type="password"
        onBlurAction={() => handleSingleValidation({ password_confirm: confirmPassword, password }, 'password_confirm', 'password')}
      />
    </InputWrapper>
    <ButtonWrapper>

      <Button
        id="create-button"
        onClick={() => { ''; }}
        theme="outline"
      >
        <p>Continue</p>
      </Button>
    </ButtonWrapper>
  </form>
);

export default CreateForm;
