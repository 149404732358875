import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '../../hooks';
import { TextInput } from '../../components';
import Button from '../../components/Button';
import { Figure } from '../../components/Figure';
import { LinkComponent } from '../../components/Link';
import { TextH1, TextP } from '../../components/Text';
import {
  ButtonWrapper, HeaderParaWrapper,
  HeaderStyled, MainStyled, PageWrapper,
} from '../../styles/loginStyle';
import { fonts } from '../../styles/theme';

import { imageList } from '../../utils/imageList';
import { ForgotPasswordSchema } from '../../utils/validation';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { forgotPassword } = useAPI();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await ForgotPasswordSchema.validate({ email }, { abortEarly: false });
      setEmailError('');
      const res = await forgotPassword(email);
      if (res.success) {
        navigate('/reset-password', { state: { email } });
      }
    } catch (error: any) {
      setEmailError(error.message);
    }
  };

  return (
    <PageWrapper>
      <HeaderStyled>
        <LinkComponent url="/login">
          <Figure imageSrc={imageList.logoFull} alt="logo" maxWidth="150px" marginBottom="1.375rem" />
        </LinkComponent>
        <TextH1 font={fonts.font1.H19}>Forgot your password?</TextH1>
        <HeaderParaWrapper>
          <TextP lineHeight="1.7rem" font={fonts.font1.H15}>Enter your email address below and we&apos;ll get you back on track.</TextP>
        </HeaderParaWrapper>
      </HeaderStyled>
      <MainStyled>
        <form onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            label="Email"
            type="email"
            textValue={email}
            setTextValue={setEmail}
            isRequired={false}
            theme="column"
            error={emailError}
          />
          <ButtonWrapper>
            <Button onClick={() => ''} id="loginButton">
              <p>Reset Password</p>
            </Button>
          </ButtonWrapper>
        </form>
        <ButtonWrapper>
          <TextP font={fonts.font1.H14} textAlgin="center">Or contact us at admin@reachbc.ca.</TextP>
        </ButtonWrapper>
      </MainStyled>
    </PageWrapper>
  );
};

export default ForgotPassword;
