import styled from 'styled-components';
import { colours } from '../../styles/theme';

type AccrodionChildernStyledProps = {
  readonly isOpen: boolean
}

export const AccrodionChildernStyled = styled.div<AccrodionChildernStyledProps>`
  transition: all 1s ease-out;
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? '2000px' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  width: 100%;
  background: ${colours.colour4};
`;

export const CountBubbleStyled = styled.div`
  background: ${colours.colour1};
  border-radius: 500px;
  color: ${colours.colour4};
  min-width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`;

export const ButtonStyled = styled.button`
  cursor: pointer;
  transition: all .3s ease;
  width: 100%;
  background: ${colours.colour4};
  text-align: left;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${colours.colour9};  
  color: ${colours.colour6};
  border-radius: 4px;
  
  &:disabled {
    cursor: not-allowed;
    color: ${colours.colour6};
    border: 1px solid ${colours.colour6};  
    background: ${colours.colour39};
  }
`;

export const WrapperStyled = styled.div`
  display: flex;
`;
