import React from 'react';
import { LinkComponent } from '../Link';
import * as Styled from './Styles';

type TextBlockWithLinkPropsTypes = {
  paragraphArray: any[]
}

const TextBlockWithLink = ({ paragraphArray }: TextBlockWithLinkPropsTypes) => (
  <Styled.TextBlockStyled>
    {paragraphArray.map((para: string | string[]) => (
      <div key={Math.random() * 123}>
        {Array.isArray(para) ? (
          <>
            {
              para.map((parawithLink: string) => (
                <React.Fragment key={`${parawithLink}=${Math.random()}`}>
                  {parawithLink.toLowerCase().includes('https') ? (
                    <LinkComponent
                      url={parawithLink[parawithLink.length - 1] === '.' ? parawithLink.slice(0, parawithLink.length - 1) : parawithLink}
                      newWindow
                      theme="websiteSpan"
                    >
                      <span>{parawithLink}</span>
                    </LinkComponent>
                  ) : (
                    <>
                      {parawithLink.length === 0 && (<br />)}
                      <span>{parawithLink}</span>
                    </>
                  )}
                </React.Fragment>
              ))
            }
          </>
        ) : (
          <>
            {para.length === 0 && (<br />)}
            <p>{para}</p>
          </>
        )}
      </div>
    ))}
  </Styled.TextBlockStyled>
);

export default TextBlockWithLink;
