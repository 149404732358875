import { useState } from 'react';
import { PPOCriticalFields } from '../utils/ProjectCriticalFields';
import { useAPI } from './useAPI';
import { usePPOForms } from './usePPOForms';

export const useEditPPOForms = () => {
  const {
    getProject,
    updateProject,
  } = useAPI();

  const {
    formInfo,
    formErrors,
    hospitals,
    healthRegions,
    healthCategory,
    collaboratingOrganizations,
    collaborators,
    teamMembers,
    selectedResearchAreas,
    isDraft,
    setIsDraft,
    toggleResearchArea,
    setFormErrors,
    setFormInfo,
    fetchValues,
    checkPI,
    checkTeamMember,
    handleAddInput,
    handleChangeAddedInput,
    handleCheck,
    handleToggle,
    handleChange,
    handleRemoveInput,
    setCollaboratingOrganizations,
    setCollaborators,
    setTeamMembers,
    setSelectedResearchArea,
    deleteDraft,
    modalType,
    changesMade,
    modalIsOpen,
    setModalIsOpen,
    setModalType,
    setToastIsOpen,
    setToastMessage,
    toastIsOpen,
    toastMessage,
    setChangeMade,
    changes,
    resetChanges,
    forceChange,
    setForceChange,
    handleSingleValidation,
    checkTeamMembers,
    setStatusModal,
    statusModal,
  } = usePPOForms();

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [criticalFieldUpdate, setCriticalFieldUpdate] = useState(false);

  const [stepBackStatus, setStepBackStatus] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const [currentDesiredStatus, setCurrentDesiredStatus] = useState();
  const [heroInfo, setHeroInfo] = useState({});

  const checkCriticalField = (field: string) => {
    const criticalField = PPOCriticalFields.includes(field);
    if (criticalField) {
      setCriticalFieldUpdate(true);
    }
  };

  const getProjectInfo = async (projectId: number) => {
    const project = await getProject(projectId, 'ppo');
    project.pi_email = project.pi.email;
    project.pi_id = project.pi?.pi_id;
    project.health_authority_region = project.healthAuthoritiesRegionIds;

    setCanEdit(project.canEdit);
    setCurrentDesiredStatus(project.desired_status);

    setSelectedResearchArea(project?.healthCategoriesIds || []);

    if (!project.recruitment_end) {
      delete project.recruitment_end;
    } else {
      const date = new Date(project.recruitment_end).setTime(new Date(project.recruitment_end).getTime() + (10 * 60 * 60 * 1000));
      project.recruitment_end = new Date(date);
    }

    if (project.hospital_id) {
      project.hospital_id = { label: project?.hospital.name, value: project.hospital.id };
    }

    delete project.healthAuthoritiesRegionIds;
    delete project.hospital;
    delete project.healthCategoriesIds;
    delete project.pi;
    delete project.created_by;

    setFormInfo(project);
    if (project.collaborating_organizations) {
      const organizations: { [key: string]: string } = {};
      project.collaborating_organizations.forEach((organization: string) => {
        organizations[organization] = organization;
      });
      setCollaboratingOrganizations(organizations);
    }

    if (project.collaborators) {
      const projectCollaborators: { [key: string]: string } = {};
      project.collaborators.forEach((collaborator: string) => {
        projectCollaborators[collaborator] = collaborator;
      });
      setCollaborators(projectCollaborators);
    }
    if (project.additional_team_member) {
      const members: { [key: string]: any } = {};
      project.additional_team_member.map((member: any) => {
        members[member.id] = member;
      });
      setTeamMembers(members);
    }

    setIsDraft(project.status === 'draft');
    setHeroInfo({
      title: project.title,
      topSubtitle: project.nick_name,
      patientOriented: project.patient_oriented_project,
      type: project.type,
    });
    setForceChange(!forceChange);
    setIsLoading(false);
  };

  const handleEditChange = (value: string | number | boolean | Date, field: string) => {
    if (!criticalFieldUpdate) checkCriticalField(field);
    handleChange(value, field);

    if (field === 'reb_certificate' && !value) {
      handleSingleValidation(value, field);
    }
  };

  const handleEditToggle = (value: boolean, field: string) => {
    if (!criticalFieldUpdate) checkCriticalField(field);
    handleToggle(value, field);
    if (field === 'is_online' && value) {
      handleSingleValidation(value, field);
    }
  };

  const handleEditCheck = (checked: boolean, field: string | number, key: string) => {
    if (!criticalFieldUpdate) checkCriticalField(key);
    handleCheck(checked, field, key);
  };

  const handleSubmit = async (formValues: StudyValuesTypes | any) => {
    try {
      const data = new FormData();

      Object.keys(formValues).forEach((key) => {
        if (key === 'additional_team_member') {
          const value = JSON.stringify(formValues[key]);
          data.append(key, value);
        } else {
          data.append(key, formValues[key]);
        }
      });
      const res = await updateProject(data);

      setFormInfo((prev) => ({
        ...prev,
        status: res.status,
      }));

      setChangeMade(false);
      setCanEdit(res.canEdit);

      if (res.stepBackStatus) {
        setStepBackStatus(res.stepBackStatus);
      }
      return res;
    } catch (error: any) {
      setIsSubmiting(false);
      throw new Error(error.message);
    }
  };

  const handleEditChangeAddedInput = (value: string, id: string, key: string) => {
    if (!criticalFieldUpdate) checkCriticalField(key);
    handleChangeAddedInput(value, id, key);
  };

  const handleValidation = async (formValues: StudyValuesTypes | any, validation: any) => {
    setIsSubmiting(true);
    try {
      await validation.validate({
        ...formValues,
        purpose: formValues.purpose.replace(/\r?\n|\r/g, ' '),
        description: formValues.description.replace(/\r?\n|\r/g, ' '),
        background: formValues.background.replace(/\r?\n|\r/g, ' '),
        commitment: formValues.commitment.replace(/\r?\n|\r/g, ' '),
        orientation_provided: formValues.orientation_provided.replace(/\r?\n|\r/g, ' '),
        additional_eligibility: formValues.additional_eligibility.replace(/\r?\n|\r/g, ' '),
      }, { abortEarly: false });
    } catch (error: any) {
      const errors: { [key: string]: string } = {};
      error.inner.forEach((element: any) => {
        errors[element.path] = element.message;
      });
      setIsSubmiting(false);
      throw errors;
    }
  };

  const handleCheckPI = async (email: string) => {
    setIsSubmiting(true);
    try {
      await checkPI(email);
      setIsSubmiting(false);
    } catch (error) {
      setIsSubmiting(false);
    }
  };

  const handleCheckTeamMember = async (user: string, email: string) => {
    setIsSubmiting(true);
    await checkTeamMember(user, email);
    setIsSubmiting(false);
  };

  const handleRemoveInputEdit = (key: string, id: string, name?: string) => {
    handleRemoveInput(key, id, name);
    setIsSubmiting(false);
  };

  return {
    formInfo,
    formErrors,
    healthRegions,
    hospitals,
    healthCategory,
    collaborators,
    teamMembers,
    collaboratingOrganizations,
    isSubmiting,
    selectedResearchAreas,
    criticalFieldUpdate,
    isLoading,
    isDraft,
    heroInfo,
    setIsDraft,
    getProjectInfo,
    fetchValues,
    setFormErrors,
    handleEditChange,
    handleEditToggle,
    handleEditCheck,
    handleRemoveInput: handleRemoveInputEdit,
    toggleResearchArea,
    handleEditChangeAddedInput,
    handleAddInput,
    checkPI: handleCheckPI,
    checkTeamMember: handleCheckTeamMember,
    handleValidation,
    handleSubmit,
    deleteDraft,
    modalType,
    changesMade,
    modalIsOpen,
    stepBackStatus,
    setModalIsOpen,
    setModalType,
    setToastIsOpen,
    setToastMessage,
    toastIsOpen,
    toastMessage,
    canEdit,
    changes,
    resetChanges,
    forceChange,
    handleSingleValidation,
    checkTeamMembers,
    setStatusModal,
    statusModal,
    setIsSubmiting,
    currentDesiredStatus,
  };
};
