import React from 'react';
import { fonts } from '../../styles/theme';
import { FileInput } from '../FileInput';
import FormSection from '../FormSelection';
import FormSubtitle from '../FormSubtitle';
import { NumberInput } from '../NumberInput';
import { TextP } from '../Text';
import { TextArea } from '../TextArea.tsx';
import { TextInput } from '../TextInput';
import * as Styled from './styles';
import { STUDY_RESULT } from './const';
import { Checkbox } from '../CheckBox';

type StudyResultFormProps = {
  result: IResult
  handleToggle: (value: boolean, field: string) => void
  handleChange: (value: string | number, field: string) => void
  handleFile: (value: File, field: string) => void
  fileName?: string
  currentFile?: File
  errors: { [key: string]: string }
  handleSingleValidation: (value: any, field: string, type?: string) => void
  handleRemoveFile: () => void
  handleGetSignedURl?: () => void
}
const StudyResultForm = ({
  result, fileName, handleToggle, handleChange, handleFile,
  errors, handleSingleValidation, currentFile, handleRemoveFile, handleGetSignedURl,
}: StudyResultFormProps) => (
  <div>
    <FormSection theme="secondary" noHeader>
      <FormSubtitle subtitle={STUDY_RESULT.STUDY_COMPLETED.TITLE} margin="small" isNotPublic />
      <Styled.SectiontWrapper>
        <Styled.FlexBoxStyled>
          <Checkbox
            label={STUDY_RESULT.STUDY_COMPLETED.CHECKBOX_LABEL}
            isChecked={result.completed}
            onChange={() => handleToggle(!result.completed, 'completed')}
            labelTheme="secondary"
            textTransform="none"
          />
        </Styled.FlexBoxStyled>
      </Styled.SectiontWrapper>

      <Styled.SectiontWrapper id="summary">
        <TextArea
          label={STUDY_RESULT.SUMMARY.LABEL}
          description={STUDY_RESULT.SUMMARY.DESCRIPTION}
          textValue={result.summary}
          setTextValue={(value) => handleChange(value, 'summary')}
          isRequired
          theme="form"
          error={errors.summary}
          onBlurAction={() => handleSingleValidation(result.summary, 'summary')}
          maxLength={900}
        />
      </Styled.SectiontWrapper>

      <Styled.SectiontWrapper>
        <FormSubtitle
          subtitle={STUDY_RESULT.PATIENT_ORIENTED.LABEL}
          link={{
            label: STUDY_RESULT.PATIENT_ORIENTED.LINK.LABEL,
            url: STUDY_RESULT.PATIENT_ORIENTED.LINK.URL,
          }}
          margin="small"
        />
        <TextP font={fonts.font1.H14} lineHeight="1.33rem">
          {STUDY_RESULT.PATIENT_ORIENTED.DESCRIPTION}
        </TextP>
        <Styled.FlexBoxStyled>
          <Checkbox
            label={STUDY_RESULT.PATIENT_ORIENTED.CHECKBOX_LABEL}
            isChecked={result.patient_oriented_project || false}
            onChange={() => handleToggle(!result.patient_oriented_project, 'patient_oriented_project')}
            textTransform="none"
          />
        </Styled.FlexBoxStyled>
      </Styled.SectiontWrapper>
      {result.patient_oriented_project && (
        <>
          <Styled.SectiontWrapper id="patient_partners_count">
            <NumberInput
              label={STUDY_RESULT.PATIENT_PARTNER_COUNT.LABEL}
              description={STUDY_RESULT.PATIENT_PARTNER_COUNT.DESCRIPTION}
              numberValue={result.patient_partners_count}
              setNumberValue={(value) => handleChange(value, 'patient_partners_count')}
              padding="md"
              isRequired
              error={errors.patient_partners_count}
              theme="form"
              onBlurAction={() => handleSingleValidation(result.patient_partners_count, 'patient_partners_count')}
            />
          </Styled.SectiontWrapper>

          <Styled.SectiontWrapper id="patient_partners_engagement_strategy">
            <TextArea
              label={STUDY_RESULT.PATIENT_PARTNER_ENGAGE.LABEL}
              description={STUDY_RESULT.PATIENT_PARTNER_ENGAGE.DESCRIPTION}
              description2={[STUDY_RESULT.PATIENT_PARTNER_ENGAGE.DESCRIPTION_TWO]}
              textValue={result.patient_partners_engagement_strategy}
              setTextValue={(value) => handleChange(value, 'patient_partners_engagement_strategy')}
              isRequired
              theme="form"
              error={errors.patient_partners_engagement_strategy}
              onBlurAction={() => handleSingleValidation(result.patient_partners_engagement_strategy, 'patient_partners_engagement_strategy')}
              maxLength={900}
            />
          </Styled.SectiontWrapper>
        </>
      )}
      <Styled.SectiontWrapper isLast>
        <TextArea
          label={STUDY_RESULT.LIMITATIONS.LABEL}
          description={STUDY_RESULT.LIMITATIONS.DESCRIPTION}
          description2={[STUDY_RESULT.LIMITATIONS.DESCRIPTION_TWO]}
          textValue={result.study_limitations}
          setTextValue={(value) => handleChange(value, 'study_limitations')}
          isRequired={false}
          theme="form"
          error={errors.study_limitations}
          onBlurAction={() => handleSingleValidation(result.study_limitations, 'study_limitations')}
          maxLength={450}
        />
      </Styled.SectiontWrapper>
    </FormSection>

    <FormSection
      title={STUDY_RESULT.RESOURCES.TITLE}
      subText={STUDY_RESULT.RESOURCES.SUBTEXT}
    >
      <Styled.SectiontWrapper>
        <TextInput
          label={STUDY_RESULT.RESOURCES.WEB_LINK.LABEL}
          placeholder={STUDY_RESULT.RESOURCES.WEB_LINK.PLACEHOLDER}
          description={STUDY_RESULT.RESOURCES.WEB_LINK.DESCRIPTION}
          textValue={result.resource_website_url || ''}
          setTextValue={(value) => handleChange(value, 'resource_website_url')}
          isRequired={false}
          theme="form"
          error={errors.resource_website_url}
          onBlurAction={() => handleSingleValidation(result.resource_website_url, 'resource_website_url')}
        />
      </Styled.SectiontWrapper>

      <Styled.SectiontWrapper>
        <TextInput
          label={STUDY_RESULT.RESOURCES.NAME_WEBSITE.LABEL}
          description={STUDY_RESULT.RESOURCES.NAME_WEBSITE.DESCRIPTION}
          descriptionSecondary={STUDY_RESULT.RESOURCES.NAME_WEBSITE.DESCRIPTION_TWO}
          textValue={result.resource_website_name || ''}
          setTextValue={(value) => handleChange(value, 'resource_website_name')}
          theme="form"
          isRequired={false}
          error={errors.resource_website_name}
          onBlurAction={() => handleSingleValidation(result.resource_website_name, 'resource_website_name')}
        />
      </Styled.SectiontWrapper>

      <Styled.SectiontWrapper id="resource_file">
        <FileInput
          label={STUDY_RESULT.RESOURCES.UPLOAD_FILE.LABEL}
          description={STUDY_RESULT.RESOURCES.UPLOAD_FILE.DESCRIPTION}
          handleChange={handleFile}
          currentFile={currentFile}
          fileName={fileName || result.resource_file}
          error={errors.resource_file}
          handleRemoveFile={handleRemoveFile}
          handleGetSignedURl={handleGetSignedURl}
          field="resource_file"
        />
      </Styled.SectiontWrapper>

      <Styled.SectiontWrapper>
        <TextInput
          label={STUDY_RESULT.RESOURCES.FILE_NAME.LABEL}
          description={STUDY_RESULT.RESOURCES.FILE_NAME.DESCRIPTION}
          textValue={result.resource_file_name || ''}
          setTextValue={(value) => handleChange(value, 'resource_file_name')}
          theme="form"
          isRequired={false}
          error={errors.resource_file_name}
          onBlurAction={() => handleSingleValidation(result.resource_file_name, 'resource_file_name')}
        />
      </Styled.SectiontWrapper>
    </FormSection>

    <FormSection noHeader>
      <Styled.SectiontWrapper id="comments" isLast>
        <TextArea
          label={STUDY_RESULT.COMMENT.LABEL}
          description2={[STUDY_RESULT.COMMENT.DESCRIPTION, STUDY_RESULT.COMMENT.DESCRIPTION_TWO]}
          textValue={result.comments}
          setTextValue={(value) => handleChange(value, 'comments')}
          error={errors.comments}
          theme="form"
          isNotPublic
          onBlurAction={() => handleSingleValidation(result.comments, 'comments')}
        />
      </Styled.SectiontWrapper>
    </FormSection>

    <FormSection noHeader>
      <TextArea
        label={STUDY_RESULT.ADMIN_COMMENTS.LABEL}
        textValue={result.admin_comments || ''}
        setTextValue={(value) => handleChange(value, 'admin_comments')}
        theme="form"
        isDisabled
        isRequired={false}
      />
    </FormSection>
  </div>
);

export default StudyResultForm;
