import styled from 'styled-components';
import { colours, fonts } from '../../../../styles/theme';
import toRem from '../../../../utils/toRem';

type SubTitleStyledPropTypes = {
  readonly theme?: 'secondary'
}

export const SideBarItemStyled = styled.div`
  display: flex;
  width: 100%;
  ${fonts.font1.H16};
  gap: ${toRem(16)};
  line-height: ${toRem(20)};
`;

export const IconWrapper = styled.div`
  color: ${colours.colour1};
  line-height: ${toRem(24)};
  width: ${toRem(16)};
`;

export const SubTitleStyled = styled.p<SubTitleStyledPropTypes>`
  line-height: ${toRem(24)};
  ${({ theme }) => (theme === 'secondary' ? fonts.font1.H16 : fonts.font1.H16B)};
`;

export const SideBarItemBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(10)};
`;
