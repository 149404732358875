import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';

type IProps = {
  readonly theme?: string
  readonly rightSpace?: boolean
  readonly buttonWidth?: string
  readonly buttonHeight?: string
}

export const ButtonStyled = styled.button<IProps>`
  width: ${({ buttonWidth }) => buttonWidth};
  height: ${({ buttonHeight }) => buttonHeight};
  transition: ${({ theme }) => (theme === 'volunteerHeader' ? 'none' : 'all .3s ease')};
  cursor: pointer;

  border-radius:  ${({ theme }) => {
    switch (theme) {
      case 'menuButton': {
        return '0';
      }
      case 'dismiss': {
        return '6px';
      }
      case 'popoverOutlineSecondary':
      case 'popoverOutlineSecondarySelected':
      case 'outlineFouth':
      case 'outline': {
        return '6px';
      }
      case 'outlineSecondary': {
        return '6px';
      }
      case 'outlineTertiary':
        return '6px';
      case 'delete': {
        return '6px';
      }
      case 'footerButton': {
        return '0';
      }
      case 'tableHeader': {
        return '0';
      }
      case 'volunteerHeader': {
        return '0';
      }
      case 'tab':
        return 0;
      case 'tabSecondary':
        return 0;
      default:
        return '4px';
    }
  }};

  ${({ theme }) => {
    switch (theme) {
      case 'popoverOutlineSecondary':
      case 'popoverOutlineSecondarySelected':
      case 'outline': {
        return fonts.font1.H14;
      }
      case 'outlineSecondary': {
        return fonts.font1.H14;
      }
      case 'outlineTertiary':
        return fonts.font1.H16B;
      case 'outlineFouth':
        return fonts.font1.H12B;
      case 'popoverOutline': {
        return fonts.font1.H14;
      }
      case 'plain': {
        return fonts.font1.H12B;
      }
      case 'plainTertiary':
      case 'plainPopover':
        return fonts.font1.H14B;
      case 'tag': {
        return fonts.font1.H12;
      }
      case 'menuButton': {
        return fonts.font1.H13;
      }
      case 'dismiss': {
        return fonts.font1.H14;
      }
      case 'volunteerHeader': {
        return fonts.font1.H14;
      }
      case 'modelMenuSecondary': {
        return fonts.font1.H16;
      }
      case 'underlineThird':
        return fonts.font1.H16B;
      case 'modelMenuPlain':
        return fonts.font1.H24;
      case 'underlinePlain':
        return fonts.font1.H16B;
      case 'plainThird':
        return fonts.font1.H15;
      case 'tab':
        return fonts.font1.H16B;
      case 'tabSecondary':
        return fonts.font1.H16B;
      case 'defaultSm':
        return fonts.font1.H14B;
      default:
        return fonts.font1.H14B;
    }
  }};

  background: ${({ theme }) => {
    switch (theme) {
      case 'secondary': {
        return colours.colour9;
      }
      case 'outlineFouth':
      case 'popoverOutlineSecondarySelected':
      case 'popoverOutlineSecondary':
      case 'outline': {
        return 'transparent';
      }
      case 'outlineSecondary': {
        return colours.colour4;
      }
      case 'outlineTertiary':
        return colours.colour4;
      case 'popoverOutline': {
        return colours.colour4;
      }
      case 'plainTertiary':
      case 'plain': {
        return 'transparent';
      }
      case 'plainPopover':
        return 'transparent';
      case 'tag': {
        return colours.colour21;
      }
      case 'inputIcon': {
        return 'transparent';
      }
      case 'menuButton': {
        return 'transparent';
      }
      case 'dismiss': {
        return 'transparent';
      }
      case 'plainSecondary': {
        return 'transparent';
      }
      case 'underlineThird':
      case 'underline': {
        return 'transparent';
      }
      case 'underlineSecondary': {
        return 'transparent';
      }
      case 'underlinePlain':
        return 'transparent';
      case 'delete': {
        return colours.colour4;
      }
      case 'footerButton': {
        return 'transparent';
      }
      case 'archiveButton': {
        return 'transparent';
      }
      case 'withdrawButton': {
        return 'white';
      }
      case 'interestedButton': {
        return colours.colour2;
      }
      case 'tableHeader': {
        return 'transparent';
      }
      case 'vibrant': {
        return colours.colour1;
      }
      case 'volunteerHeader': {
        return 'transparent';
      }
      case 'modelMenuSecondary': {
        return 'transparent';
      }
      case 'modelMenuPlain':
        return 'transparent';
      case 'plainThird':
        return 'transparent';
      case 'tab':
        return 'transparent';
      case 'tabSecondary':
        return 'transparent';
      default:
        return colours.colour8;
    }
  }};

  color:  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour10;
      case 'outlineFouth':
      case 'popoverOutlineSecondary':
      case 'popoverOutlineSecondarySelected':
      case 'outline': {
        return colours.colour5;
      }
      case 'outlineSecondary': {
        return colours.colour5;
      }
      case 'outlineTertiary':
        return colours.colour5;
      case 'popoverOutline': {
        return colours.colour5;
      }
      case 'plain': {
        return colours.colour5;
      }
      case 'plainPopover':
        return colours.colour8;
      case 'plainTertiary':
        return colours.colour8;
      case 'tag': {
        return colours.colour6;
      }
      case 'inputIcon': {
        return colours.colour1;
      }
      case 'menuButton': {
        return colours.colour6;
      }
      case 'dismiss': {
        return colours.colour6;
      }
      case 'plainSecondary': {
        return colours.colour24;
      }
      case 'delete': {
        return colours.colour7;
      }
      case 'underlineThird':
      case 'underline': {
        return colours.colour6;
      }
      case 'underlineSecondary': {
        return colours.colour6;
      }
      case 'footerButton': {
        return colours.colour8;
      }
      case 'archiveButton': {
        return colours.colour8;
      }
      case 'withdrawButton': {
        return colours.colour8;
      }
      case 'interestedButton': {
        return colours.colour8;
      }
      case 'tableHeader': {
        return colours.colour40;
      }
      case 'volunteerHeader': {
        return colours.colour8;
      }
      case 'modelMenuSecondary': {
        return colours.colour5;
      }
      case 'modelMenuPlain':
        return colours.colour6;
      case 'underlinePlain':
        return colours.colour1;
      case 'plainThird':
        return colours.colour1;
      case 'tab':
        return colours.colour1;
      case 'tabSecondary':
        return colours.colour35;
      default:
        return colours.colour4;
    }
  }};

  border: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return `1px solid ${colours.colour9}`;
      case 'outlineFouth':
      case 'outline': {
        return `1px solid ${colours.colour8}`;
      }
      case 'popoverOutlineSecondarySelected':
        return `1px solid ${colours.colour2}`;
      case 'popoverOutlineSecondary':
        return `1px solid ${colours.colour29}`;
      case 'popoverOutline': {
        return 'none';
      }
      case 'plainTertiary':
      case 'plainPopover':
      case 'plain': {
        return '1px solid transparent';
      }
      case 'tag': {
        return `1px solid ${colours.colour21}`;
      }
      case 'inputIcon': {
        return '1px solid transparent';
      }
      case 'menuButton': {
        return '1px solid transparent';
      }
      case 'dismiss': {
        return '1px solid transparent';
      }
      case 'plainSecondary': {
        return '1px solid transparent';
      }
      case 'underlineThird':
      case 'underline': {
        return '1px solid transparent';
      }
      case 'underlineSecondary': {
        return '1px solid transparent';
      }
      case 'delete': {
        return `1px solid ${colours.colour7}`;
      }
      case 'footerButton': {
        return 'none';
      }
      case 'archiveButton': {
        return 'none';
      }
      case 'withdrawButton': {
        return `1px solid ${colours.colour29}`;
      }
      case 'interestedButton': {
        return 'none';
      }
      case 'tableHeader': {
        return 'none';
      }
      case 'vibrant': {
        return 'none';
      }
      case 'volunteerHeader': {
        return 'none';
      }
      case 'modelMenuSecondary': {
        return 'none';
      }
      case 'modelMenuPlain':
        return 'none';
      case 'underlinePlain':
        return 'none';
      case 'plainThird':
        return 'none';
      case 'tab':
        return 'none';
      case 'tabSecondary':
        return 'none';
      default:
        return `1px solid ${colours.colour8}`;
    }
  }};

  border-bottom: ${({ theme }) => {
    switch (theme) {
      case 'tab':
        return `2px solid ${colours.colour1}`;
      case 'tabSecondary':
        return `1px solid ${colours.colour35}`;
      default:
        return '';
    }
  }};

  text-decoration: ${({ theme }) => {
    switch (theme) {
      case 'underline':
        return 'underline';
      case 'underlineSecondary':
        return 'underline';
      case 'underlinePlain':
        return 'underline';
      default:
        return '';
    }
  }};

  text-decoration-color: ${({ theme }) => {
    switch (theme) {
      case 'underline':
        return colours.colour2;
      case 'underlineSecondary':
        return colours.colour2;
      default:
        break;
    }
  }};

  text-underline-offset: ${({ theme }) => {
    switch (theme) {
      case 'underline':
        return '0.3rem';
      case 'underlineSecondary':
        return '0.3rem';
      case 'defaultSm':
        return toRem(10);
      default:
        return '';
    }
  }};

  text-decoration-thickness: ${({ theme }) => {
    switch (theme) {
      case 'underline':
        return '2px';
      case 'underlineSecondary':
        return '2px';
      default:
        return '';
    }
  }};

  padding: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return '0.625rem 0.875rem';
      case 'plainTertiary':
      case 'plain': {
        return '0';
      }
      case 'plainPopover':
        return `${toRem(16)} 0 0 0`;
      case 'tag': {
        return '0.375rem 0.563rem';
      }
      case 'popoverOutlineSecondarySelected':
      case 'popoverOutlineSecondary':
        return `${toRem(8)} ${toRem(12)}`;
      case 'outlineFouth':
        return `${toRem(8)} ${toRem(12)}`;
      case 'outline': {
        return '0.625rem 0.875rem';
      }
      case 'outlineSecondary': {
        return '0.5rem 0.875rem';
      }
      case 'outlineTertiary': {
        return '0.75rem 1.5rem';
      }
      case 'popoverOutline': {
        return '0.875rem';
      }
      case 'inputIcon': {
        return '0.25rem';
      }
      case 'menuButton': {
        return '0.5rem';
      }
      case 'plainSecondary': {
        return '0.5rem 0';
      }
      case 'underlineThird':
        return 0;
      case 'underline': {
        return '0 0 0.625rem';
      }
      case 'underlineSecondary': {
        return '0.25rem 0px';
      }
      case 'footerButton': {
        return ' 0';
      }
      case 'archiveButton': {
        return '0';
      }
      case 'tableHeader': {
        return '0';
      }
      case 'underlinePlain':
        return '0';
      case 'plainThird':
        return '0 0.5rem';
      case 'modelMenuPlain':
        return '0.5rem 0 0.5rem 0.5rem';
      case 'primary':
        return '0.75rem 1.5rem';
      case 'tab':
        return `0 ${toRem(14)} ${toRem(14)}`;
      case 'tabSecondary':
        return `0 ${toRem(14)} ${toRem(14)}`;
      case 'defaultSm':
        return `${toRem(8)} ${toRem(12)}`;
      default:
        return '.5rem 1.5rem';
    }
  }};

  box-shadow: ${({ theme }) => {
    switch (theme) {
      case 'outline': {
        return 'inset 0 0 0 1px rgb(34 36 38 / 15%)';
      }
      case 'popoverOutline': {
        return 'inset 0 0 0 1px rgb(34 36 38 / 15%)';
      }
      default:
        return 'none';
    }
  }};
  
  display:  ${({ theme }) => {
    switch (theme) {
      case 'plain': {
        return 'flex';
      }
      case 'menuButton': {
        return 'flex';
      }
      case 'plainSecondary': {
        return 'flex';
      }
      case 'tableHeader': {
        return 'flex';
      }
      default:
        return '';
    }
  }};

  justify-content: ${({ theme }) => {
    switch (theme) {
      case 'plain': {
        return 'space-between';
      }
      case 'menuButton': {
        return 'flex-start';
      }
      case 'tableHeader': {
        return 'flex-start';
      }
      case 'popoverOutlineSecondarySelected':
      case 'popoverOutlineSecondary':
        return 'space-between';
      default:
        return '';
    }
  }};

p {
  border-bottom: ${({ theme }) => {
    switch (theme) {
      case 'underlineThird':
        return `2px solid ${colours.colour2}`;
      default:
        return 'none';
    }
  }};

  padding: ${({ theme }) => {
    switch (theme) {
      case 'underlineThird':
        return `0 0 ${toRem(8)}`;
      default:
        return '';
    }
  }};
  };

  &:hover {
    background: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour12;
      case 'popoverOutlineSecondarySelected':
      case 'popoverOutlineSecondary':
      case 'outlineFouth':
      case 'outline': {
        return 'transparent';
      }
      case 'outlineSecondary': {
        return colours.colour4;
      }
      case 'outlineTertiary':
        return colours.colour4;
      case 'popoverOutline': {
        return colours.colour4;
      }
      case 'plainTertiary':
      case 'plain': {
        return 'transparent';
      }
      case 'plainPopover':
        return 'transparent';
      case 'tag': {
        return colours.colour21;
      }
      case 'inputIcon': {
        return 'transparent';
      }
      case 'delete': {
        return colours.colour37;
      }
      case 'menuButton': {
        return colours.colour19;
      }
      case 'underlineThird':
      case 'underline': {
        return 'transparent';
      }
      case 'underlineSecondary': {
        return 'transparent';
      }
      case 'footerButton': {
        return 'transparent';
      }
      case 'archiveButton': {
        return 'transparent';
      }
      case 'withdrawButton': {
        return colours.colour2;
      }
      case 'interestedButton': {
        return colours.colour5;
      }
      case 'tableHeader': {
        return 'transparent';
      }
      case 'volunteerHeader': {
        return colours.colour2;
      }
      case 'modelMenuPlain':
        return 'transparent';
      case 'plainThird':
        return 'transparent';
      case 'underlinePlain':
        return 'transparent';
      case 'tab':
        return 'transparent';
      case 'tabSecondary':
        return 'transparent';
      default:
        return colours.colour11;
    }
  }};

  color: ${({ theme }) => {
    switch (theme) {
      case 'interestedButton': {
        return colours.colour4;
      }
      case 'plainThird':
        return colours.colour5;
      default:
        break;
    }
  }};

  box-shadow: ${({ theme }) => {
    switch (theme) {
      case 'outline': {
        return 'inset 0 0 0 1px rgb(34 36 38 / 35%), inset 0 0 0 0 rgb(34 36 38 / 15%)';
      }
      case 'popoverOutline': {
        return 'inset 0 0 0 1px rgb(34 36 38 / 35%), inset 0 0 0 0 rgb(34 36 38 / 15%)';
      }
      default:
        return 'none';
    }
  }};

  border-bottom: ${({ theme }) => {
    switch (theme) {
      case 'footerButton': {
        return `2px solid ${colours.colour2}`;
      }
      case 'volunteerHeader': {
        return `3px solid ${colours.colour2}`;
      }
      default:
        return '';
    }
  }};

  p {
    background: ${({ theme }) => {
    switch (theme) {
      case 'underlineThird':
      case 'underline':
        return colours.colour2;
      default:
        return '';
    }
  }};

  text-decoration: ${({ theme }) => {
    switch (theme) {
      case 'inputIcon':
        return 'underline';
      default:
        return '';
    }
  }};

  border-bottom: ${({ theme }) => {
    switch (theme) {
      case 'underline':
        return `1px solid ${colours.colour2}`;
      case 'underlineThird':
        return `2px solid ${colours.colour2}`;
      default:
        return 'none';
    }
  }}
  }

  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour13;
      case 'popoverOutlineSecondarySelected':
      case 'popoverOutlineSecondary':
      case 'outlineFouth':
      case 'outline': {
        return 'transparent';
      }
      case 'outlineSecondary': {
        return colours.colour39;
      }
      case 'outlineTertiary':
        return colours.colour39;
      case 'tag': {
        return colours.colour21;
      }
      default:
        return colours.colour8;
    }
  }};

  color:  ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return colours.colour12;
      case 'popoverOutline': {
        return colours.colour12;
      }
      case 'popoverOutlineSecondarySelected':
      case 'popoverOutlineSecondary':
      case 'outlineFouth':
      case 'outline': {
        return colours.colour12;
      }
      case 'outlineSecondary': {
        return colours.colour6;
      }
      case 'outlineTertiary':
        return colours.colour6;
      default:
        return colours.colour12;
    }
  }};
  }
}

  @media (min-width: 820px) {
  padding: ${({ theme }) => {
    switch (theme) {
      case 'secondary':
        return '0.875rem';
      case 'plainTertiary':
      case 'plain': {
        return '0';
      }
      case 'plainPopover':
        return `${toRem(16)} 0 0 0`;
      case 'tag': {
        return '0.375rem 0.563rem';
      }
      case 'popoverOutlineSecondarySelected':
      case 'popoverOutlineSecondary':
        return `${toRem(8)} ${toRem(12)}`;
      case 'outlineFouth':
        return `${toRem(8)} ${toRem(12)}`;
      case 'outline': {
        return '0.875rem';
      }
      case 'outlineSecondary': {
        return '0.5rem 0.875rem';
      }
      case 'outlineTertiary':
        return '0.75rem 1.5rem';
      case 'popoverOutline': {
        return '0.875rem';
      }
      case 'inputIcon': {
        return '0.25rem';
      }
      case 'menuButton': {
        return '0.5rem';
      }
      case 'plainSecondary': {
        return '0.5rem 0';
      }
      case 'underlineThird':
      case 'underline': {
        return '1rem 0px';
      }
      case 'underlineSecondary': {
        return '0.25rem 0px';
      }
      case 'footerButton': {
        return ' 0';
      }
      case 'archiveButton': {
        return '0';
      }
      case 'tableHeader': {
        return '0';
      }
      case 'underlinePlain':
        return '0 0 0 0.25rem';
      case 'plainThird':
        return '0 0.5rem';
      case 'modelMenuPlain':
        return '0.5rem 0 0.5rem 0.5rem';
      case 'primary':
        return '0.75rem 1.5rem';
      case 'defaultSm':
        return `${toRem(10)} ${toRem(20)}`;
      default:
        return '.5rem 1.5rem';
    }
  }};
  }
`;
