import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fonts } from '../../../../styles/theme';
import FormRemoveableInput from '../../../FormRemoveableInput';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { TextP } from '../../../Text';
import { TextInput } from '../../../TextInput';
import { Checkbox } from '../../../CheckBox';
import { PRINCIPAL_INVERTIGATOR } from './const';
import Button from '../../../Button';
import * as Styled from '../styles';

type PrincipalInvestigatorSectionProps = {
  piValue: string
  patientOrientedProject: boolean
  coInvestigator: string
  ppAffiliatedOrganization: string
  collaborators: { [key: string]: string }
  teamMembers: {
    [key: string]: {
      id: string,
      email: string,
      receive_email: boolean,
    }
  }
  errors: { [key: string]: string }
  isDraft?: boolean
  canEdit: boolean
  handleChange: (value: string, field: string) => void
  handleToggle: (value: boolean, field: string) => void
  handleCheck: (value: boolean, field: string, key: string) => void
  handleAddInput: (key: string, maxNumber?: number) => void
  handleChangeAddedInput: (value: string, id: string, key: string) => void
  handleRemoveInput: (key: string, id: string) => void
  checkPI: (email: string) => void
  checkTeamMember: (user: string, email: string) => void
  handleDelete: () => void
}

const MAX_ENTRIES = 10;

const PrincipalInvestigatorSection = ({
  handleChange, handleToggle, handleCheck, piValue, patientOrientedProject, coInvestigator, handleRemoveInput, checkPI,
  ppAffiliatedOrganization, handleAddInput, collaborators, handleChangeAddedInput, teamMembers,
  errors, isDraft, handleDelete, canEdit, checkTeamMember,
}: PrincipalInvestigatorSectionProps) => (
  <FormSection
    title={PRINCIPAL_INVERTIGATOR.TITLE}
    isDraft={isDraft}
    handleDelete={handleDelete}
  >
    <Styled.SectiontWrapper id="pi_id">
      <TextInput
        id="pi"
        label={PRINCIPAL_INVERTIGATOR.PI.LABEL}
        description={PRINCIPAL_INVERTIGATOR.PI.LABEL_DESCRIPTION}
        setTextValue={(value) => handleChange(value, 'pi_email')}
        onBlurAction={() => checkPI(piValue)}
        placeholder={PRINCIPAL_INVERTIGATOR.PI.PLACEHOLDER}
        textValue={piValue}
        theme="form"
        isRequired
        error={errors.pi || errors.pi_id}
        disabled={!canEdit}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="additional_team_member">
      <FormSubtitle subtitle={PRINCIPAL_INVERTIGATOR.ADDITIONAL_TEAM.LABEL} />
      <Styled.InfoTextWrapper>
        <TextP>{PRINCIPAL_INVERTIGATOR.ADDITIONAL_TEAM.LABEL_DESCRIPTION}</TextP>
      </Styled.InfoTextWrapper>
      {Object.keys(teamMembers).map((teamMember: any) => (
        <React.Fragment key={teamMember}>
          <FormRemoveableInput
            id={teamMember}
            field="teamMember"
            textValue={teamMembers[teamMember].email}
            handleChange={handleChangeAddedInput}
            handleRemoveInput={handleRemoveInput}
            onBlurAction={() => checkTeamMember(teamMember, teamMembers[teamMember].email)}
            placeholder={PRINCIPAL_INVERTIGATOR.ADDITIONAL_TEAM.PLACEHOLDER}
            disabled={!canEdit}
            error={errors[teamMember]}
            name={teamMember}
            increaseMargin
          />
          <Checkbox
            id={teamMember}
            label={PRINCIPAL_INVERTIGATOR.ADDITIONAL_TEAM.CHECKBOX_LABEL}
            isChecked={teamMembers[teamMember].receive_email}
            onChange={(check) => handleCheck(check, teamMember, 'receive_email')}
            textTransform="none"
          />
        </React.Fragment>
      ))}
      <Styled.ButtonWrapper>
        <Button
          id="add-team-member"
          onClick={() => {
            handleAddInput('teamMember');
          }}
          disabled={!canEdit}
        >
          <Styled.ButtonStyled>
            <FontAwesomeIcon icon="plus" />
            <Styled.ButtonTextWrapper>
              {PRINCIPAL_INVERTIGATOR.ADDITIONAL_TEAM.BUTTONS.ADD}
            </Styled.ButtonTextWrapper>
          </Styled.ButtonStyled>
        </Button>
      </Styled.ButtonWrapper>
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper>
      <FormSubtitle
        subtitle={PRINCIPAL_INVERTIGATOR.PATIENT_ORIENTED.TITLE}
        margin="small"
        link={{
          url: PRINCIPAL_INVERTIGATOR.PATIENT_ORIENTED.LINK_URL,
          label: PRINCIPAL_INVERTIGATOR.PATIENT_ORIENTED.LINK,
        }}
      />
      <Styled.TextWrapper>
        <TextP font={fonts.font1.H14} lineHeight="1.33rem">
          {PRINCIPAL_INVERTIGATOR.PATIENT_ORIENTED.DESCRIPTION}
        </TextP>
      </Styled.TextWrapper>

      <Styled.FlexWrapper id="patient_oriented_project">
        <Checkbox
          isChecked={patientOrientedProject}
          labelTheme="secondary"
          label={PRINCIPAL_INVERTIGATOR.PATIENT_ORIENTED.CHECKBOX_LABEL}
          onChange={() => handleToggle(!patientOrientedProject, 'patient_oriented_project')}
          disabled={!canEdit}
          textTransform="none"
        />
      </Styled.FlexWrapper>
      {patientOrientedProject && (
        <Styled.InputWrapperStyled>
          <Styled.InputWrapperStyled id="pp_co_pi">
            <TextInput
              label={PRINCIPAL_INVERTIGATOR.PP_INVESTIGATOR_NAME.LABEL}
              description={PRINCIPAL_INVERTIGATOR.PP_INVESTIGATOR_NAME.LABEL_DESCRIPTION}
              textValue={coInvestigator}
              setTextValue={(value) => handleChange(value, 'pp_co_pi')}
              error={errors.pp_co_pi}
              theme="form"
              isRequired={false}
            />
          </Styled.InputWrapperStyled>
          <Styled.InputWrapperStyled id="pp_affiliated_organization">
            <TextInput
              label={PRINCIPAL_INVERTIGATOR.PP_AFFILIATED_ORGANIZATION.LABEL}
              description={PRINCIPAL_INVERTIGATOR.PP_AFFILIATED_ORGANIZATION.LABEL_DESCRIPTION}
              isRequired={false}
              textValue={ppAffiliatedOrganization}
              setTextValue={(value) => handleChange(value, 'pp_affiliated_organization')}
              theme="form"
              error={errors.pp_affiliated_organization}
            />
          </Styled.InputWrapperStyled>

          <Styled.InputWrapperStyled>
            <FormSubtitle subtitle={PRINCIPAL_INVERTIGATOR.PARIENT_INITIATED_RESEARCH.TITLE} />
            <TextP lineHeight="1.33rem">
              {PRINCIPAL_INVERTIGATOR.PARIENT_INITIATED_RESEARCH.DESCRIPTION}
            </TextP>
          </Styled.InputWrapperStyled>
        </Styled.InputWrapperStyled>
      )}
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="collaborators" isLast>
      <FormSubtitle subtitle={PRINCIPAL_INVERTIGATOR.COLLABORATORS.TITLE} margin="small" />
      <TextP lineHeight="1.33rem">
        {PRINCIPAL_INVERTIGATOR.COLLABORATORS.DESCRIPTION}
      </TextP>
      {Object.keys(collaborators).map((collaborator: any) => (
        <React.Fragment key={collaborator}>
          <FormRemoveableInput
            id={collaborator}
            field="collaborator"
            textValue={collaborators[collaborator]}
            handleChange={handleChangeAddedInput}
            handleRemoveInput={handleRemoveInput}
            disabled={!canEdit}
          />
        </React.Fragment>
      ))}

      <Styled.ButtonWrapper>
        <Button
          id="add-collaborator"
          onClick={() => {
            handleAddInput('collaborator', MAX_ENTRIES);
          }}
          disabled={!canEdit}
        >
          <Styled.ButtonStyled>
            <FontAwesomeIcon icon="plus" />
            <Styled.ButtonTextWrapper>
              {PRINCIPAL_INVERTIGATOR.COLLABORATORS.BUTTON}
            </Styled.ButtonTextWrapper>
          </Styled.ButtonStyled>
        </Button>
      </Styled.ButtonWrapper>
    </Styled.SectiontWrapper>
  </FormSection>
);

export default PrincipalInvestigatorSection;
