import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';

type CounterStyledPropTypes = {
  readonly theme?: 'primary' | 'secondary'
}

export const ContainerWrapper = styled.div`
  margin-bottom: ${toRem(48)};
  width: 100%;
`;

export const ContainerScrollWrapper = styled.div`
  overflow-y: auto;
  width: 100%;

 /* hide scroll bar for side menu */
  -ms-overflow-style: none; 
  scrollbar-width: none;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  width: fit-content;
  white-space: nowrap;

  @media (min-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;

export const TabExtraContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colours.colour35};
`;

export const CounterStyled = styled.div<CounterStyledPropTypes>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  ${fonts.font1.H12B};
  background:  ${({ theme }) => (theme === 'primary' ? colours.colour1 : colours.colour41)};
  color: ${({ theme }) => (theme === 'primary' ? colours.colour4 : colours.colour6)};
  margin-left: ${toRem(8)};
  border-radius: 6px;
`;

export const ButtonContainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  justify-content: center;
`;

export const DescriptionWrapper = styled.div`
  margin-top: ${toRem(16)};
  ${fonts.font1.H16};
  color: ${colours.colour36};
  line-height: ${toRem(24)};
  letter-spacing: 0.2px;
`;
