import React from 'react';
import AppWrapper from '../../components/AppWrapper';

import ResearcherDashboard from './ResearcherDashboard';
import VolunteerDashboard from '../../components/VolunteerDashboard';
import { useDashboard } from '../../hooks/useDashboard';
import { ResearcherDashboardHero, VolunteerDashboardHero } from '../../components/DashboardHero';

const Dashboard = () => {
  const hook = useDashboard();

  return (
    <AppWrapper footer alternateFooter theme="secondary">
      {hook.user.type === 'volunteer' ? (
        <VolunteerDashboardHero
          userName={hook.user.first_name}
          searchValue={hook.searchValue}
          searchValueHandler={hook.setSearchValueHandler}
        />
      ) : (
        <ResearcherDashboardHero
          userName={hook.user.first_name}
          bypass={hook.user.bypass}
        />
      )}
      {
        hook.user.type === 'researcher' ? (
          <ResearcherDashboard
            tableData={hook.tableData}
            hasSiteCode={!!hook.user.site_code}
            currentTab={hook.researcherTab}
            setCurrentTab={hook.setResearcherTab}
            siteVolunteerData={hook.siteVolunteerData}
            searchValue={hook.searchValue}
            searchHandler={hook.setSearchValueHandler}
            isLoading={hook.isLoading}
          />
        ) : (
          <VolunteerDashboard
            user={hook.user}
            searchValue={hook.searchValue}
          />
        )
      }
    </AppWrapper>
  );
};

export default Dashboard;
