import styled from 'styled-components';

export const ProjectBodyStyled = styled.section`
  max-width: 1200px;
  margin: auto;
`;

export const VolunteerButtonsWrapperStyled = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
