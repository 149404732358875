import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import environment from '../config/environment';
import { LoginSchema } from '../utils/validation';
import { useAPI } from './useAPI';
import { useCookies } from './useCookies';

export const useLogin = () => {
  const navigate = useNavigate();
  const { createCookie } = useCookies();
  const { login, resendVerificationEmail } = useAPI();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [status, setStatus] = useState('');
  const [isLoading, setIsloading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const [redirect, setRedirect] = useState<{ id: string, type: string } | undefined>();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setPasswordError('');
      setUsernameError('');
      await LoginSchema.validate({ password, username }, { abortEarly: false });

      const token = await login({ email: username.toLocaleLowerCase(), password });
      if (token.error) {
        setErrorMessage(token.error.message);
        return;
      }

      if (token.challengeName) {
        createCookie('authChallenge', JSON.stringify(token.jwtToken), token.userType);

        if (token.challengeName === 'SMS_MFA') {
          navigate('/login/two-factor', { state: { redirect } });
        } else {
          navigate('/change-password');
        }
      } else {
        await createCookie(environment.app.cookieName, JSON.stringify(token.jwtToken), token.userType);
        createCookie(environment.app.cognitoName, JSON.stringify(token.cognitoToken), token.userType);
        if (redirect && redirect.id === 'profile') {
          navigate(redirect.type === 'email' ? '/myProfile/email-notifications' : '/myProfile');
        } else if (redirect) {
          navigate(`/project/${redirect.type}/${redirect.id}`);
        } else {
          navigate('/dashboard');
        }
      }
    } catch (error: any) {
      if (error.inner) {
        error.inner.forEach((err: any) => {
          switch (err.path) {
            case 'password':
              setPasswordError(err.errors[0]);
              break;
            case 'username':
              setUsernameError(err.errors[0]);
              break;
            default:
              break;
          }
        });
      } else {
        setErrorMessage(error);
      }
    }
  };

  const handleResendVerification = async () => {
    try {
      const code = await resendVerificationEmail(username);
      if (code.resend) {
        setStatus('email-verification');
        setErrorMessage('');
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const handleParams = (params: URLSearchParams) => {
    const projectId = params.get('project') as string;
    const projectType = params.get('type') as string;
    const type = params.get('myProfile') as string;

    if (projectId && projectType) {
      setRedirect({ id: projectId, type: projectType });
    }

    if (type) {
      setRedirect({ id: 'profile', type });
    }
  };

  const handleExistingUser = (user: any, params: any) => {
    if (user) {
      if (params.includes('project')) {
        const paramsSplit = params.split('&');
        const projectId = paramsSplit[0].split('=')[1];
        const projectType = paramsSplit[1].split('=')[1];
        navigate(`/project/${projectType}/${projectId}`);
      } else if (params.includes('myProfile')) {
        navigate('/myProfile');
      } else {
        navigate('/dashboard');
      }
    } else {
      setIsloading(false);
    }
  };

  return {
    status,
    setStatus,
    isLoading,
    setIsloading,
    isOpen,
    setIsOpen,

    handleSubmit,
    errorMessage,
    password,
    setPassword,
    username,
    setUsername,
    usernameError,
    passwordError,
    setRedirect,
    handleResendVerification,
    handleParams,
    handleExistingUser,
  };
};
