import React from 'react';
import { LinkComponent } from '../../../../components/Link';
import { TextP } from '../../../../components/Text';
import {
  ButtonStyled, SideMenuStyled, SubButtonContainer, SubButtonStyled,
} from './styles';

type SideMenuProps = {
  onClick: (value: string) => void
  menu: string
  subMenu: string
}
const SideMenu = ({
  onClick, menu, subMenu,
}: SideMenuProps) => (
  <SideMenuStyled>
    <ButtonStyled
      onClick={() => onClick('My Profile')}
      theme={menu === 'My Profile' ? 'active' : 'inactive'}
    >
      <TextP>
        My Profile
      </TextP>
    </ButtonStyled>
    <ButtonStyled
      onClick={() => onClick('Research Preferences')}
      theme={menu === 'Research Preferences' && subMenu === '' ? 'active' : 'inactive'}
      noBorderBottom={menu === 'Research Preferences'}
    >
      <TextP>
        Research Preferences
      </TextP>
    </ButtonStyled>
    {
      menu === 'Research Preferences' && (
        <SubButtonContainer>
          <LinkComponent url="#PARENTS">
            <SubButtonStyled
              theme={subMenu === 'PARENTS' ? 'active' : 'inactive'}
            >
              <TextP>Parents & Guardians</TextP>
            </SubButtonStyled>
          </LinkComponent>
          <LinkComponent url="#TRAVEL">
            <SubButtonStyled
              theme={subMenu === 'TRAVEL' ? 'active' : 'inactive'}
            >
              <TextP>Travel Preferences</TextP>
            </SubButtonStyled>
          </LinkComponent>
          <LinkComponent url="#PARTICIPATION">
            <SubButtonStyled
              theme={subMenu === 'PARTICIPATION' ? 'active' : 'inactive'}
            >
              <TextP>Participation Preferences</TextP>
            </SubButtonStyled>
          </LinkComponent>
          <LinkComponent url="#AREAS">
            <SubButtonStyled
              theme={subMenu === 'AREAS' ? 'active' : 'inactive'}
            >
              <TextP>Health Research Interests</TextP>
            </SubButtonStyled>
          </LinkComponent>
        </SubButtonContainer>
      )
    }
    <ButtonStyled
      onClick={() => onClick('Email Notifications')}
      theme={menu === 'Email Notifications' ? 'active' : 'inactive'}
    >
      <TextP>
        Email Notifications
      </TextP>
    </ButtonStyled>
    <ButtonStyled
      onClick={() => onClick('Password Reset')}
      theme={menu === 'Password Reset' ? 'active' : 'inactive'}
    >
      <TextP>
        Password Reset
      </TextP>
    </ButtonStyled>
  </SideMenuStyled>
);

export default SideMenu;
