import React from 'react';
import { fonts } from '../../../../styles/theme';
import { Checkbox } from '../../../CheckBox';
import { DropdownSelect } from '../../../DropdownSelect';
import { ErrorMessage } from '../../../ErrorMessage';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import HealthResearchAreas from '../../../HealthResearchAreas';
import { TextP } from '../../../Text';
import { TextArea } from '../../../TextArea.tsx';
import { TextInput } from '../../../TextInput';
import * as Styled from '../styles';
import { NumberInput } from '../../../NumberInput';
import { PPO_ELIGILITY_CRITERIA } from './const';

type OpportunityEligibilityCriteriaProps = {
  maxAge: string
  minAge: string
  yearsMonths: string
  sexEligibleOptions: string[]
  sexEligible: string[]
  gendersEligibleOptions: string[]
  gendersEligible: string[]
  additionalCriteria: string
  categoriesMap: { category: string, items: { id: number, item: string, category: string }[] }[]
  selectedResearchAreas: { category: string, id: number, name: string }[]
  canEdit: boolean
  patients: number | undefined,
  controls: number | undefined,
  errors: { [key: string]: string }
  handleChange: (value: string | number, field: string) => void
  handleCheck: (value: boolean, field: string, key: string) => void
  handleChangeResearchAreas: (name: string, id: number, category: string) => void
  forceChange: boolean;
  handleSingleValidation: (value: any, field: string) => void
}

const OpportunityEligibilityCriteria = ({
  maxAge, minAge, yearsMonths, handleChange, handleCheck, sexEligible, sexEligibleOptions, gendersEligible, gendersEligibleOptions, additionalCriteria, canEdit,
  categoriesMap, selectedResearchAreas, handleChangeResearchAreas, patients, controls, errors, forceChange, handleSingleValidation,
}: OpportunityEligibilityCriteriaProps) => (
  <FormSection title={PPO_ELIGILITY_CRITERIA.TITLE} subText={PPO_ELIGILITY_CRITERIA.DESCRIPTION}>

    <Styled.SectiontWrapper>
      <FormSubtitle subtitle={PPO_ELIGILITY_CRITERIA.AGE.LABEL} isRequired />
      <Styled.AgeInputWrapper id="min_age">
        <TextP font={fonts.font1.H14}>{PPO_ELIGILITY_CRITERIA.AGE.MIN_LABEL}</TextP>
        <Styled.DivStyled margin="0 0.875rem" id="min_age">
          <TextInput
            textValue={minAge}
            setTextValue={(value) => handleChange(value, 'min_age')}
            error={errors.min_age}
            disabled={!canEdit}
            onBlurAction={() => handleSingleValidation(minAge, 'min_age')}
          />
        </Styled.DivStyled>
        <TextP font={fonts.font1.H14}>{PPO_ELIGILITY_CRITERIA.AGE.MAX_LABEL}</TextP>
        <Styled.DivStyled margin="0 0.875rem" id="max_age">
          <TextInput
            textValue={maxAge}
            setTextValue={(value) => handleChange(value, 'max_age')}
            disabled={!canEdit}
            error={errors.max_age}
            onBlurAction={() => handleSingleValidation(maxAge, 'max_age')}
          />
        </Styled.DivStyled>
        <Styled.DivStyled maxWidth="30%" margin="0 0.875rem" id="years_months">
          <DropdownSelect
            id="ageSelect"
            onChange={(value) => handleChange(value, 'years_months')}
            selected={{ value: yearsMonths, label: yearsMonths }}
            options={[
              { value: 'years', label: 'years' },
              { value: 'months', label: 'months' },
            ]}
            required={false}
            isDisabled={!canEdit}
            error={errors.years_months}
          />
        </Styled.DivStyled>
        <TextP>{PPO_ELIGILITY_CRITERIA.AGE.END_LABEL}</TextP>
      </Styled.AgeInputWrapper>
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="sex_eligible">
      <FormSubtitle subtitle={PPO_ELIGILITY_CRITERIA.SEXES.LABEL} isRequired />
      <TextP font={fonts.font1.H14}>{PPO_ELIGILITY_CRITERIA.SEXES.DESCRIPTION}</TextP>
      <Styled.CheckboxWrapper>
        <Checkbox
          id="sex-all"
          label="All"
          isChecked={sexEligible.length === sexEligibleOptions.length}
          onChange={(check) => handleCheck(check, 'All', 'sex_eligible')}
          disabled={!canEdit}
        />
      </Styled.CheckboxWrapper>
      {sexEligibleOptions.map((option) => (
        <Styled.CheckboxWrapper key={`sex-${option}`}>
          <Checkbox
            label={option}
            isChecked={sexEligible.includes(option)}
            onChange={(check) => handleCheck(check, option, 'sex_eligible')}
            disabled={!canEdit}
          />
        </Styled.CheckboxWrapper>
      ))}
      {errors.sex_eligible && <ErrorMessage message={errors.sex_eligible} />}
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="genders_eligible">
      <FormSubtitle subtitle={PPO_ELIGILITY_CRITERIA.GENDERS.LABEL} isRequired />
      <Styled.TextWrapper>
        <TextP font={fonts.font1.H14} lineHeight="1.33rem">
          {PPO_ELIGILITY_CRITERIA.GENDERS.DESCRIPTION}
        </TextP>
      </Styled.TextWrapper>
      <TextP font={fonts.font1.H14} lineHeight="1.33rem">
        {PPO_ELIGILITY_CRITERIA.GENDERS.DESCRIPTION_TWO}
      </TextP>

      <Styled.CheckboxWrapper id="gender-all">
        <Checkbox
          label="All"
          isChecked={gendersEligible.length === gendersEligibleOptions.length}
          onChange={(check) => handleCheck(check, 'All', 'genders_eligible')}
          disabled={!canEdit}
        />
      </Styled.CheckboxWrapper>
      {gendersEligibleOptions.map((option) => (
        <Styled.CheckboxWrapper key={`gender-${option}`}>
          <Checkbox
            label={option}
            isChecked={gendersEligible.includes(option)}
            onChange={(check) => handleCheck(check, option, 'genders_eligible')}
            disabled={!canEdit}
          />
        </Styled.CheckboxWrapper>
      ))}
      {errors.genders_eligible && <ErrorMessage message={errors.genders_eligible} />}

    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="additional_eligibility">
      <TextArea
        label={PPO_ELIGILITY_CRITERIA.ADDITIONAL_ELIGIBILITY.LABEL}
        description={PPO_ELIGILITY_CRITERIA.ADDITIONAL_ELIGIBILITY.DESCRIPTION}
        textValue={additionalCriteria}
        setTextValue={(value) => handleChange(value, 'additional_eligibility')}
        error={errors.additional_eligibility}
        isDisabled={!canEdit}
        onBlurAction={() => handleSingleValidation(additionalCriteria, 'additional_eligibility')}
        maxLength={700}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="health_categories" isLast>
      <FormSubtitle
        subtitle={PPO_ELIGILITY_CRITERIA.HEALTH_CATEGORIES.LABEL}
        margin="small"
        isRequired
      />
      <TextP font={fonts.font1.H14} lineHeight="1.33rem">
        {PPO_ELIGILITY_CRITERIA.HEALTH_CATEGORIES.DESCRIPTION}
      </TextP>
      <HealthResearchAreas
        categoriesMap={categoriesMap}
        selectedResearchAreas={selectedResearchAreas}
        handleChange={handleChangeResearchAreas}
        isReseacher
        error={errors.health_categories}
        disabled={!canEdit}
        forceChange={forceChange}
      />
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper id="target_patients">
      <FormSubtitle subtitle={PPO_ELIGILITY_CRITERIA.TARGETS.TITLE} margin="small" />
      <NumberInput
        label={PPO_ELIGILITY_CRITERIA.TARGETS.LABEL_PATIENT}
        numberValue={patients}
        setNumberValue={(value) => handleChange(value, 'target_patients')}
        minValue={0}
        isRequired
        padding="md"
        error={errors.target_patients}
        isNotPublic
        disabled={!canEdit}
      />
    </Styled.SectiontWrapper>
    <Styled.SectiontWrapper id="target_controls">
      <NumberInput
        label={PPO_ELIGILITY_CRITERIA.TARGETS.LABEL_CONTROL}
        numberValue={controls}
        setNumberValue={(value) => handleChange(value, 'target_controls')}
        isRequired={false}
        padding="md"
        minValue={0}
        error={errors.target_controls}
        disabled={!canEdit}
      />
    </Styled.SectiontWrapper>
  </FormSection>
);

export default OpportunityEligibilityCriteria;
