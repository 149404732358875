import styled from 'styled-components';
import { colours } from '../../styles/theme';

type FooterDivStyledProps = {
  readonly side: string
}
export const MainFooterWrapper = styled.footer`
  border-top: 1px solid ${colours.colour19};
  width: 100%;
  padding: 2rem 0.875rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
`;

export const FlexWrapper = styled.div`
  display: flex;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
`;

export const FooterDivStyled = styled.div<FooterDivStyledProps>`
  padding: 0.875rem;
  display: flex;
  width: 100%;
  margin-bottom: 1.7rem;
  border-right: 1px solid ${({ side }) => (side === 'left' ? colours.colour19 : 'transparent')};
  justify-content:   ${({ side }) => (side === 'left' ? 'flex-start' : 'flex-end')};
`;

export const FooterInnerDivStyled = styled.div<FooterDivStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 0.875rem;
`;

export const AlternateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
