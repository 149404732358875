import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '../ErrorMessage';
import * as Styled from './styles';
import { RequiredForm } from '../RequiredForm';
import { FILE_INPUT } from './const';
import { useHook } from './useHook';
import Button from '../Button';
import { formateFileSizeMD } from '../../utils/formateFileSizeMD';

type FileInputProps = {
  label: string;
  placeholder?: string;
  handleChange: (value: File, field: string) => void
  handleRemoveFile: (field: string) => void
  handleGetSignedURl?: () => void
  field: string
  description?: string;
  currentFile?: File
  fileName?: string
  error?: string
  disabled?: boolean
  isRequired?: boolean
  onBlurAction?: () => void
}

export const FileInput = ({
  label, placeholder, description, error, handleChange, field, disabled, isRequired = false,
  onBlurAction, handleRemoveFile, currentFile, fileName, handleGetSignedURl,
}: FileInputProps) => {
  const hook = useHook();

  const handleChangeFile = ({ target: { files } }: any) => {
    handleChange(files[0], field);
  };

  const setFileStateHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleChange(file, field);
    hook.setIsDraggingOver(false);
  };

  return (
    <Styled.TextinputWrapper>
      <Styled.ContainerStyled>
        <Styled.TextWrapper>
          {label}
        </Styled.TextWrapper>
        {isRequired && <RequiredForm />}
        <Styled.DescriptionWrapper>
          {description}
        </Styled.DescriptionWrapper>

        {currentFile && !fileName && (
          <Styled.CurrentFileWrapper>
            <Styled.FileWrapper>
              <Styled.ButtonWrapper>
                <Button
                  id="view-file"
                  onClick={() => hook.handleViewFile(currentFile)}
                  theme="underlinePlain"
                  buttonWidth="100%"
                >
                  <Styled.ButtonTextWrapper>
                    {currentFile?.name}
                  </Styled.ButtonTextWrapper>
                </Button>
                {' '}{currentFile ? `(${formateFileSizeMD(currentFile.size)}MB)` : ''}
              </Styled.ButtonWrapper>
              {currentFile && (
                <Button
                  id="remove-file"
                  onClick={() => handleRemoveFile(field)}
                  theme="plain"
                >
                  <FontAwesomeIcon icon="xmark" size="lg" />
                </Button>
              )}
            </Styled.FileWrapper>
          </Styled.CurrentFileWrapper>
        )}

        {fileName && (
          <Styled.CurrentFileWrapper>
            <Styled.FileWrapper theme={currentFile ? '' : 'secondary'}>
              <Styled.ButtonWrapper theme={currentFile ? '' : 'secondary'}>
                <Button
                  id="view-file"
                  onClick={() => {
                    if (currentFile) hook.handleViewFile(currentFile);
                    if (fileName && !currentFile && handleGetSignedURl) handleGetSignedURl();
                  }}
                  theme="underlinePlain"
                  buttonWidth="100%"
                >
                  <Styled.ButtonTextWrapper>
                    {currentFile?.name || fileName}
                  </Styled.ButtonTextWrapper>
                </Button>
                {' '}{currentFile ? `(${formateFileSizeMD(currentFile.size)}MB)` : ''}
              </Styled.ButtonWrapper>
              {currentFile && (
                <Button
                  id="remove-file"
                  onClick={() => handleRemoveFile(field)}
                  theme="plain"
                >
                  <FontAwesomeIcon icon="xmark" size="lg" />
                </Button>
              )}
            </Styled.FileWrapper>

            {!currentFile && (
              <>
                <Styled.FileButtonStyled
                  htmlFor={`id-${label}`}
                  theme="secondary"
                >
                  {FILE_INPUT.REPLACE}
                </Styled.FileButtonStyled>

                <Styled.InputStyled
                  type="file"
                  accept="application/pdf"
                  id={`id-${label}`}
                  placeholder={placeholder}
                  onChange={handleChangeFile}
                  autoComplete="off"
                  hidden
                  disabled={disabled}
                  onBlur={() => {
                    if (onBlurAction) {
                      onBlurAction();
                    }
                  }}
                />
              </>
            )}
          </Styled.CurrentFileWrapper>
        )}

        {(!currentFile && !fileName) && (
          <Styled.FileInputStyled
            fileHover={hook.isDraggingOver}
            onDragOver={hook.dragOverHandler}
            onDragLeave={hook.dragLeaveHandler}
            onDrop={setFileStateHandler}
            disabled={disabled}
          >
            <Styled.FileButtonStyled
              htmlFor={`id-${label}`}
            >
              {FILE_INPUT.BUTTON}
            </Styled.FileButtonStyled>
            <p>{FILE_INPUT.DROP_LABEL}</p>
            <Styled.InputStyled
              type="file"
              accept="application/pdf"
              id={`id-${label}`}
              placeholder={placeholder}
              onChange={handleChangeFile}
              autoComplete="off"
              hidden
              disabled={disabled}
              onBlur={() => {
                if (onBlurAction) {
                  onBlurAction();
                }
              }}
            />
          </Styled.FileInputStyled>
        )}

      </Styled.ContainerStyled>
      {error && <ErrorMessage message={error} />}
    </Styled.TextinputWrapper>
  );
};
