export const STUDY_DETAILS_FORM = {
  TITLE: 'Study Details',
  STUDY_TITLE: {
    LABEL: 'Study Title',
    LABEL_DESCRIPTION: 'The full title of the research study (i.e. Protocol title). (Max character limit is 500).',
  },
  LAY_TITLE: {
    TITLE: 'Lay Study Title',
    DESCRIPTION: 'Enter a short 5-15 word lay title for your study. (Max character limit is 90).',
    DESCRIPTION_SECONDARY: "E.g. Biomarkers study for early diagnosis of Alzheimer's disease.",
  },
  NICK_NAME: {
    LABEL: 'Study Headline',
    DESCRIPTION: 'Give your study a catchy lay-friendly headline that will attract Volunteers.',
    DESCRIPTION_SECONDARY: "E.g. Can we predict Alzheimer's disease?.  This will also be used as your quick reference to access the study on your dashboard. (Max character limit is 90)",
  },
  STUDY_TYPE: {
    LABEL: 'Type of Research Study',
    DESCRIPTION: 'What type of health research study is this?',
    PLACEHOLDER: 'Choose a type of health research study',
  },
};
