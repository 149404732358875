import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from '../styles';
import { fonts } from '../../../../styles/theme';
import { Checkbox } from '../../../CheckBox';
import { ErrorMessage } from '../../../ErrorMessage';
import FormRemoveableInput from '../../../FormRemoveableInput';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { TextP } from '../../../Text';
import { OPPORTUNITY_AFFILIATION } from './const';
import Button from '../../../Button';

type OpportunityAffiliationsProps = {
  handleAddInput: (key: string, maxNumber?: number) => void
  handleChangeAddedInput: (value: string, id: string, key: string) => void
  handleRemoveInput: (key: string, id: string) => void
  handleToggle: (value: boolean, field: string) => void
  handleCheck: (value: boolean, field: string, key: string) => void

  canEdit: boolean
  collaboratingOrganizations: { [key: string]: string }
  bcSupportUnitToggle: boolean
  regionalCentersList: { [key: string]: string }
  selectedRegionalCenters: string[]
  errors: { [key: string]: string }
}

const MAX_ENTRIES = 5;

const OpportunityAffiliations = ({
  collaboratingOrganizations, bcSupportUnitToggle, regionalCentersList, selectedRegionalCenters, canEdit,
  handleAddInput, handleChangeAddedInput, handleRemoveInput, handleToggle, handleCheck,
  errors,
}: OpportunityAffiliationsProps) => (
  <FormSection title={OPPORTUNITY_AFFILIATION.TITLE}>
    <Styled.SectiontWrapper id="collaborating_organizations">
      <FormSubtitle subtitle={OPPORTUNITY_AFFILIATION.COLLABORATING_ORGANIZATION.LABEL} />
      <TextP font={fonts.font1.H14} lineHeight="1.33rem">
        {OPPORTUNITY_AFFILIATION.COLLABORATING_ORGANIZATION.DESCRIPTION}
      </TextP>

      {Object.keys(collaboratingOrganizations).map((organization: any) => (
        <React.Fragment key={organization}>
          <FormRemoveableInput
            id={organization}
            field="collaboratingOrganizations"
            textValue={collaboratingOrganizations[organization]}
            handleChange={handleChangeAddedInput}
            handleRemoveInput={handleRemoveInput}
            disabled={!canEdit}
          />
        </React.Fragment>
      ))}

      <Styled.ButtonWrapper>
        <Button
          id="add-collaborating-Organizations"
          onClick={() => {
            handleAddInput('collaboratingOrganizations', MAX_ENTRIES);
          }}
          disabled={!canEdit}
        >
          <Styled.ButtonStyled>
            <FontAwesomeIcon icon="plus" />
            <Styled.ButtonTextWrapper>
              {OPPORTUNITY_AFFILIATION.COLLABORATING_ORGANIZATION.BUTTON}
            </Styled.ButtonTextWrapper>
          </Styled.ButtonStyled>
        </Button>
      </Styled.ButtonWrapper>
    </Styled.SectiontWrapper>

    <Styled.SectiontWrapper isLast>
      <FormSubtitle
        subtitle={OPPORTUNITY_AFFILIATION.SUPPORT_UNIT.TITLE}
        link={{
          label: OPPORTUNITY_AFFILIATION.SUPPORT_UNIT.LINK,
          url: OPPORTUNITY_AFFILIATION.SUPPORT_UNIT.LINK_URL,
        }}
        margin="small"
      />
      <TextP lineHeight="1.33rem">
        {OPPORTUNITY_AFFILIATION.SUPPORT_UNIT.DESCRIPTION}
      </TextP>
      <Styled.ButtonWrapper>
        <Checkbox
          label={OPPORTUNITY_AFFILIATION.SUPPORT_UNIT.CHECKBOX_LABEL}
          isChecked={bcSupportUnitToggle}
          onChange={() => handleToggle(!bcSupportUnitToggle, 'bc_support_unit')}
          disabled={!canEdit}
          textTransform="none"
        />
      </Styled.ButtonWrapper>

      {bcSupportUnitToggle && (
        <Styled.ButtonWrapper>
          <TextP lineHeight="1.33rem">
            {OPPORTUNITY_AFFILIATION.REGIONAL_CENTRE.LABEL}
          </TextP>
          <Styled.CheckboxWrapper>
            <Checkbox
              label="All"
              isChecked={selectedRegionalCenters.length === Object.keys(regionalCentersList).length}
              onChange={(check) => handleCheck(check, 'All', 'regionalCenters')}
              disabled={!canEdit}
            />
          </Styled.CheckboxWrapper>
          {Object.keys(regionalCentersList).map((region) => (
            <Styled.CheckboxWrapper key={region}>
              <Checkbox
                label={regionalCentersList[region]}
                isChecked={selectedRegionalCenters.includes(region)}
                onChange={(check) => handleCheck(check, region, 'regionalCenters')}
                disabled={!canEdit}
              />
            </Styled.CheckboxWrapper>
          ))}
          {errors.regional_centers && <ErrorMessage message={errors.regional_centers} />}
        </Styled.ButtonWrapper>
      )}
    </Styled.SectiontWrapper>
  </FormSection>
);

export default OpportunityAffiliations;
