import React from 'react';
import { fonts } from '../../styles/theme';
import { FileInput } from '../FileInput';
import FormSection from '../FormSelection';
import FormSubtitle from '../FormSubtitle';
import { NumberInput } from '../NumberInput';
import { SelectInput } from '../SelectInput';
import { TextP } from '../Text';
import { TextArea } from '../TextArea.tsx';
import { TextInput } from '../TextInput';
import * as Styled from './styles';
import { PPO_RESULT } from './const';
import { Checkbox } from '../CheckBox';

type PPOResultFormProps = {
  result: IResult
  handleToggle: (value: boolean, field: string) => void
  handleChange: (value: string | number, field: string) => void
  handleFile: (value: File, field: string) => void
  fileName?: string
  currentFile?: File
  errors: { [key: string]: string }
  linkStudyOptions: { value: string, label: string }[]
  handleSingleValidation: (value: any, field: string, type?: string) => void
  handleRemoveFile: () => void
  handleGetSignedURl?: () => void
}

const PPOResultForm = ({
  result, fileName, handleToggle, handleChange, handleFile, handleSingleValidation,
  errors, linkStudyOptions, handleRemoveFile, currentFile, handleGetSignedURl,
}: PPOResultFormProps) => (
  <div>
    <FormSection theme="secondary" noHeader>
      <FormSubtitle subtitle={PPO_RESULT.STUDY_COMPLETED.LABEL} margin="small" isNotPublic />
      <Styled.SectiontWrapper>
        <Styled.FlexBoxStyled>
          <Checkbox
            label={PPO_RESULT.STUDY_COMPLETED.CHECKBOX_LABEL}
            isChecked={result.completed}
            labelTheme="secondary"
            onChange={() => handleToggle(!result.completed, 'completed')}
            textTransform="none"
          />
        </Styled.FlexBoxStyled>
      </Styled.SectiontWrapper>

      <Styled.SectiontWrapper>
        <FormSubtitle subtitle={PPO_RESULT.LINK_STUDY.LABEL} margin="small" />
        <TextP font={fonts.font1.H14} lineHeight="1.33rem">
          {PPO_RESULT.LINK_STUDY.DESCRIPTION}
        </TextP>
        <Styled.FlexBoxStyled>
          <Checkbox
            label={PPO_RESULT.LINK_STUDY.CHECKBOX_LABEL}
            isChecked={!!result.link_study || false}
            onChange={() => handleToggle(!result.link_study, 'link_study')}
            labelTheme="secondary"
            textTransform="none"
          />
        </Styled.FlexBoxStyled>
      </Styled.SectiontWrapper>

      {result.link_study && (
        <Styled.SectiontWrapper isLast>
          <SelectInput
            id="linked_study"
            label=""
            description={PPO_RESULT.LINK_STUDY.SELECT_DESCRIPTION}
            descriptionSecondary={PPO_RESULT.LINK_STUDY.SELECT_DESCRIPTION_TWO}
            onChange={(value) => handleChange(value, 'linked_study_id')}
            placeholder=" "
            value={result.linked_study_id}
            options={linkStudyOptions}
            isRequired={false}
            error={errors.linked_study_id}
          />
          <Styled.TextWrapper>
            <TextP lineHeight="1.33rem">
              {PPO_RESULT.LINK_STUDY.SELECT_POSTSCRIPT}
            </TextP>
          </Styled.TextWrapper>
        </Styled.SectiontWrapper>
      )}

      {!result.link_study && (
        <>
          <Styled.SectiontWrapper id="summary">
            <TextArea
              label={PPO_RESULT.SUMMARY.LABEL}
              description={PPO_RESULT.SUMMARY.DESCRIPTION}
              textValue={result.summary}
              setTextValue={(value) => handleChange(value, 'summary')}
              isRequired
              theme="form"
              error={errors.summary}
              onBlurAction={() => handleSingleValidation(result.summary, 'summary')}
              maxLength={900}
            />
          </Styled.SectiontWrapper>

          <Styled.SectiontWrapper id="patient_partners_count">
            <NumberInput
              label={PPO_RESULT.PATIENT_PARTNER_COUNT.LABEL}
              description={PPO_RESULT.PATIENT_PARTNER_COUNT.DESCRIPTION}
              numberValue={result.patient_partners_count}
              setNumberValue={(value) => handleChange(value, 'patient_partners_count')}
              padding="md"
              isRequired
              error={errors.patient_partners_count}
              onBlurAction={() => handleSingleValidation(result.patient_partners_count, 'patient_partners_count')}
            />
          </Styled.SectiontWrapper>
          <Styled.SectiontWrapper id="patient_partners_engagement_strategy">
            <TextArea
              label={PPO_RESULT.PATIENT_PARTNER_ENGAGED.LABEL}
              description2={[
                PPO_RESULT.PATIENT_PARTNER_ENGAGED.DESCRIPTION,
                PPO_RESULT.PATIENT_PARTNER_ENGAGED.DESCRIPTION_TWO,
              ]}
              theme="form"
              textValue={result.patient_partners_engagement_strategy}
              setTextValue={(value) => handleChange(value, 'patient_partners_engagement_strategy')}
              isRequired
              error={errors.patient_partners_engagement_strategy}
              onBlurAction={() => handleSingleValidation(result.patient_partners_engagement_strategy, 'patient_partners_engagement_strategy')}
              maxLength={900}
            />
          </Styled.SectiontWrapper>
        </>
      )}
    </FormSection>

    {
      !result.link_study && (
        <>
          <FormSection
            title={PPO_RESULT.RESOURCES.TITLE}
            subText={PPO_RESULT.RESOURCES.SUBTEXT}
          >
            <Styled.SectiontWrapper>
              <TextInput
                label={PPO_RESULT.RESOURCES.WEB_LINK.LABEL}
                description={PPO_RESULT.RESOURCES.WEB_LINK.DESCRIPTION}
                placeholder={PPO_RESULT.RESOURCES.WEB_LINK.PLACEHOLDER}
                textValue={result.resource_website_url || ''}
                setTextValue={(value) => handleChange(value, 'resource_website_url')}
                isRequired={false}
                theme="form"
                error={errors.resource_website_url}
                onBlurAction={() => handleSingleValidation(result.resource_website_url, 'resource_website_url')}
              />
            </Styled.SectiontWrapper>

            <Styled.SectiontWrapper>
              <TextInput
                label={PPO_RESULT.RESOURCES.NAME_WEBSITE.LABEL}
                description={PPO_RESULT.RESOURCES.NAME_WEBSITE.DESCRIPTION}
                descriptionSecondary={PPO_RESULT.RESOURCES.NAME_WEBSITE.DESCRIPTION_TWO}
                placeholder={PPO_RESULT.RESOURCES.NAME_WEBSITE.PALCEHOLDER}
                theme="form"
                textValue={result.resource_website_name || ''}
                setTextValue={(value) => handleChange(value, 'resource_website_name')}
                isRequired={false}
                error={errors.resource_website_name}
                onBlurAction={() => handleSingleValidation(result.resource_website_name, 'resource_website_name')}
              />
            </Styled.SectiontWrapper>

            <Styled.SectiontWrapper id="resource_file">
              <FileInput
                label={PPO_RESULT.RESOURCES.UPLOAD_FILE.LABEL}
                description={PPO_RESULT.RESOURCES.UPLOAD_FILE.DESCRIPTION}
                fileName={fileName || result.resource_file}
                currentFile={currentFile}
                handleChange={handleFile}
                handleRemoveFile={handleRemoveFile}
                handleGetSignedURl={handleGetSignedURl}
                field="resource_file"
                error={errors.resource_file}
              />
            </Styled.SectiontWrapper>

            <Styled.SectiontWrapper isLast>
              <TextInput
                label={PPO_RESULT.RESOURCES.FILE_NAME.LABEL}
                description={PPO_RESULT.RESOURCES.FILE_NAME.DESCRIPTION}
                textValue={result.resource_file_name || ''}
                theme="form"
                setTextValue={(value) => handleChange(value, 'resource_file_name')}
                isRequired={false}
                error={errors.resource_file_name}
                onBlurAction={() => handleSingleValidation(result.resource_file_name, 'resource_file_name')}
              />
            </Styled.SectiontWrapper>
          </FormSection>

          <FormSection noHeader>
            <Styled.SectiontWrapper id="comments" isLast>
              <TextArea
                label={PPO_RESULT.COMMENT.LABEL}
                description2={[
                  PPO_RESULT.COMMENT.DESCRIPTION,
                  PPO_RESULT.COMMENT.DESCRIPTION_TWO,
                ]}
                theme="form"
                textValue={result.comments}
                setTextValue={(value) => handleChange(value, 'comments')}
                error={errors.comments}
                onBlurAction={() => handleSingleValidation(result.comments, 'comments')}
                isNotPublic
              />
            </Styled.SectiontWrapper>
          </FormSection>

          <FormSection noHeader>
            <TextArea
              label={PPO_RESULT.ADMIN_COMMENTS.LABEL}
              textValue={result.admin_comments || ''}
              setTextValue={(value) => handleChange(value, 'admin_comments')}
              isDisabled
              theme="form"
              isRequired={false}
            />
          </FormSection>
        </>
      )
    }
  </div>
);

export default PPOResultForm;
