export const studyFormInitialValues: StudyValuesTypes = {
  status: '',
  desired_status: '',
  title: '',
  lay_title: '',
  nick_name: '',
  funding_agency: [],
  onsite_presence_needed: false,
  street_address: '',
  city: '',
  postal_code: '',
  province: 'BC',
  health_authority_region: [],
  purpose: '',
  description: '',
  commitment: '',
  procedures_required: false,
  compensation: false,
  expenses: false,
  min_age: '',
  max_age: '',
  healthy_controls: false,
  sex_eligible: [
    'Female',
    'Male',
  ],
  genders_eligible: [
    'Woman',
    'Man',
    'Non-binary',
    'Trans woman',
    'Trans man',
    'Two-spirit',
  ],
  contact1_name: '',
  contact1_position: '',
  contact1_email: '',
  pi_email: '',
  health_categories: [],
  comments: '',
  pp_co_pi: '',
  pp_affiliated_organization: '',
  collaborating_organizations: [],
  study_type: '',
  bc_support_unit: false,
  patient_oriented_project: false,
  years_months: 'years',
  notifications_to: 'All Volunteers',
  type: 'study',
  is_online: false,
  reb_certificate: false,
  is_clinical_trial: false,
  has_direct_link: false,
};

export const projectInitialValues: IProject = {
  id: 0,
  pi: '',
  type: 'study',
  lay_title: '',
  nick_name: '',
  title: '',
  status: '',
  purpose: '',
  commitment: '',
  description: '',
  health_categories: [],
  procedures_required: false,
  min_age: 0,
  max_age: 0,
  sex_eligible: [],
  genders_eligible: [],
  healthy_controls: false,
  expenses: false,
  compensation: false,
  recruitment_end: '',
  funding_agency: [],
  contact1_email: '',
  contact1_name: '',
  contact1_position: '',
  health_authority_region: [],
  street_address: '',
  city: '',
  postal_code: '',
  province: '',
  is_online: false,
  access: false,
  showProject: true,
  has_direct_link: false,
};
