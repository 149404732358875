import styled from 'styled-components';
import { colours, fonts } from '../../styles/theme';
import toRem from '../../utils/toRem';

type TagStyledProps = {
  readonly theme: string
  readonly isRound: boolean
  readonly fontSize?: string
  readonly padding?: string
  readonly marginLeft?: boolean
}

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TagStyled = styled.div<TagStyledProps>`
  line-height: ${toRem(16)};
  ${({ fontSize }) => (fontSize || fonts.font1.H12)};
  text-transform: ${({ isRound }) => (isRound ? '' : 'capitalize')};

  border-radius: ${({ isRound }) => (isRound ? '500px' : '4px')};
  width: ${({ isRound }) => (isRound ? '100%' : 'fit-content')};
  padding: ${({ isRound, padding }) => {
    if (padding) return padding;
    if (isRound) return '0.5rem 1rem';
    return '0.25rem 0.5rem';
  }};
  display: ${({ isRound }) => (isRound ? 'flex' : 'inline-block')};
  align-items: center;

  margin-left: ${({ marginLeft }) => (marginLeft ? '8px' : 0)};

  background: ${({ theme }) => {
    switch (theme) {
      case 'draft':
        return colours.colour28;
      case 'on_hold':
        return colours.colour30;
      case 'approved':
        return colours.colour21;
      case 'reb_review':
        return colours.colour1;
      case 'closed_recruitment':
        return colours.colour32;
      case 'completed_project':
        return colours.colour35;
      case 'currently_recruiting':
        return colours.colour3;
      case 'results':
        return 'transparent';
      default:
        return colours.colour27;
    }
  }};
  border: 1px solid  ${({ theme }) => {
    switch (theme) {
      case 'draft':
        return colours.colour29;
      case 'on_hold':
        return colours.colour30;
      case 'approved':
        return colours.colour26;
      case 'reb_review':
        return colours.colour31;
      case 'closed_recruitment':
        return colours.colour33;
      case 'completed_project':
        return colours.colour34;
      case 'currently_recruiting':
        return colours.colour26;
      case 'results':
        return colours.colour6;
      default:
        return colours.colour2;
    }
  }};
  color:  ${({ theme }) => {
    switch (theme) {
      case 'on_hold':
        return colours.colour4;
      case 'reb_review':
        return colours.colour4;
      case 'completed_project':
        return colours.colour28;
      case 'currently_recruiting':
        return colours.colour8;
      case 'closed_recruitment':
        return colours.colour8;
      default:
        return colours.colour6;
    }
  }};;
`;

export const IconWrapper = styled.span`
  margin-right: 0.875rem;
`;
