import styled from 'styled-components';
import { colours } from '../../styles/theme';

type AppWrapperStylePropTypes = {
  readonly theme?: 'secondary'
}

export const BodyWrapper = styled.main`
  max-width: 1200px;
  margin: 0 auto;
`;

export const AppWrapperStyle = styled.div<AppWrapperStylePropTypes>`
  position: relative;
  margin: 0 auto;
  letter-spacing: 0.2px;
  background: ${({ theme }) => (theme === 'secondary' ? colours.colour49 : '')};
  min-height: 100vh;
`;
