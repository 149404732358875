import React from 'react';
import {
  fundingAgencyOptions, TypeOfResearchStudy, regionalCentre, sexEligible, gendersEligible, researcherPosition,
} from '../../utils/researcherConst';
import AffiliationsForm from './components/AffiliationsForm';
import PrincipalInvestigatorSection from './components/PrincipalInvestigator';
import StudyDetails from './components/StudyDetails';
import StudyDatesFundingInformation from './components/StudyDatesFundingInformation';
import StudyLocation from './components/StudyLocation';
import StudyPurpose from './components/StudyPurpose';
import EstimatedCommitment from './components/EstimatedCommitment';
import EligibilityCriteria from './components/EligibilityCriteria';
import ContactForm from './components/ContactForm';
import Comments from './components/Comments';
import AdminComments from './components/AdminComments';
import ResearchEthicsBoard from './components/ResearchEthicsBoard';

type StudyFormProps = {
  formInfo: StudyValuesTypes
  formErrors: { [key: string]: string }
  collaborators: { [key: string]: string }
  teamMembers: {
    [key: string]: {
      id: string,
      email: string,
      receive_email: boolean,
    }
  }
  collaboratingOrganizations: { [key: string]: string }
  healthAuthorityRegionOptions: { id: number, name: string }[]
  hospitalList: { label: string, value: string }[]
  healthCategories: { category: string, items: { id: number, item: string, category: string }[] }[]
  selectedResearchAreas: { category: string, id: number, name: string }[]
  adminComments?: string
  RebFileCurrent?: File
  additionalDocCurrent?: File
  rebFileName?: string
  additionalDocName?: string
  isDraft?: boolean
  canEdit: boolean
  handleChange: (value: string | number | boolean | Date, field: string) => void
  handleToggle: (value: boolean, field: string) => void
  handleAddInput: (key: string, maxNumber?: number) => void
  handleChangeAddedInput: (value: string, id: string, key: string) => void
  handleRemoveInput: (key: string, id: string) => void
  checkPI: (email: string) => void
  checkTeamMember: (user: string, email: string) => void
  handleCheck: (value: boolean, field: string | number, key: string) => void
  handleChangeResearchAreas: (name: string, id: number, category: string) => void
  handleFile: (value: File, field: string) => void
  handleDelete: () => void
  forceChange: boolean
  handleSingleValidation: (value: any, field: string) => void
  handleRemoveFile: (field: string) => void
}

const StudyForm = ({
  formInfo,
  formErrors,
  collaborators,
  teamMembers,
  collaboratingOrganizations,
  healthAuthorityRegionOptions,
  hospitalList,
  healthCategories,
  selectedResearchAreas,
  adminComments,
  RebFileCurrent,
  additionalDocCurrent,
  isDraft,
  canEdit,
  additionalDocName,
  rebFileName,
  handleChange,
  handleAddInput,
  handleChangeAddedInput,
  handleRemoveInput,
  handleToggle,
  checkPI,
  checkTeamMember,
  handleCheck,
  handleChangeResearchAreas,
  handleFile,
  handleDelete,
  forceChange,
  handleSingleValidation,
  handleRemoveFile,
}: StudyFormProps) => (
  <>
    <PrincipalInvestigatorSection
      handleChange={handleChange}
      handleToggle={handleToggle}
      handleCheck={handleCheck}
      piValue={formInfo.pi_email || ''}
      patientOrientedProject={formInfo.patient_oriented_project}
      coInvestigator={formInfo.pp_co_pi || ''}
      ppAffiliatedOrganization={formInfo.pp_affiliated_organization || ''}
      handleAddInput={handleAddInput}
      collaborators={collaborators}
      teamMembers={teamMembers}
      handleChangeAddedInput={handleChangeAddedInput}
      handleRemoveInput={handleRemoveInput}
      checkPI={checkPI}
      checkTeamMember={checkTeamMember}
      errors={formErrors}
      isDraft={isDraft}
      handleDelete={handleDelete}
      canEdit={canEdit}
    />
    <AffiliationsForm
      handleChangeAddedInput={handleChangeAddedInput}
      handleRemoveInput={handleRemoveInput}
      handleAddInput={handleAddInput}
      handleToggle={handleToggle}
      handleCheck={handleCheck}
      collaboratingOrganizations={collaboratingOrganizations}
      bcSupportUnit={formInfo.bc_support_unit}
      regionalCentersList={regionalCentre}
      selectedRegionalCenters={formInfo.regional_centers || []}
      errors={formErrors}
      canEdit={canEdit}
    />
    <StudyDetails
      handleChange={handleChange}
      title={formInfo.title}
      layTitle={formInfo.lay_title}
      nickName={formInfo.nick_name}
      studyType={{ value: formInfo.study_type, label: formInfo.study_type }}
      TypeOfResearchStudy={TypeOfResearchStudy}
      errors={formErrors}
      canEdit={canEdit}
      handleSingleValidation={handleSingleValidation}
    />
    <StudyDatesFundingInformation
      handleCheck={handleCheck}
      handleChange={handleChange}
      handleToggle={handleToggle}
      isClinicalTrial={formInfo.is_clinical_trial}
      registrationNum={formInfo.registration_num || ''}
      selectedFundingAgency={formInfo.funding_agency}
      fundingAgencyOptions={fundingAgencyOptions}
      recruitmentEndDate={formInfo.recruitment_end}
      errors={formErrors}
      canEdit={canEdit}
      handleSingleValidation={handleSingleValidation}
    />
    <StudyLocation
      handleCheck={handleCheck}
      handleChange={handleChange}
      handleToggle={handleToggle}
      selectedhealthAuthorityRegion={formInfo.health_authority_region}
      healthAuthorityRegionOptions={healthAuthorityRegionOptions}
      hospital={formInfo.hospital_id}
      hospitalList={hospitalList || []}
      building={formInfo.building || ''}
      city={formInfo.city}
      streetAddress={formInfo.street_address}
      postalCode={formInfo.postal_code}
      department={formInfo.department || ''}
      isOnline={formInfo.is_online}
      hasDirectLink={formInfo.has_direct_link}
      directLinkUrl={formInfo.direct_link_url}
      errors={formErrors}
      canEdit={canEdit}
      handleSingleValidation={handleSingleValidation}
    />
    <StudyPurpose
      handleChange={handleChange}
      purpose={formInfo.purpose}
      description={formInfo.description}
      errors={formErrors}
      canEdit={canEdit}
      handleSingleValidation={handleSingleValidation}
    />

    <EstimatedCommitment
      handleChange={handleChange}
      handleToggle={handleToggle}
      proceduresRequired={formInfo.procedures_required}
      proceduresDescription={formInfo.procedures_description || ''}
      commitment={formInfo.commitment}
      compensation={formInfo.compensation}
      expenses={formInfo.expenses}
      canEdit={canEdit}
      errors={formErrors}
      handleSingleValidation={handleSingleValidation}
    />

    <EligibilityCriteria
      handleChange={handleChange}
      handleToggle={handleToggle}
      handleCheck={handleCheck}
      handleChangeResearchAreas={handleChangeResearchAreas}
      minAge={formInfo.min_age}
      maxAge={formInfo.max_age}
      yearsMonths={formInfo.years_months}
      healthyControls={formInfo.healthy_controls}
      sexEligibleOptions={sexEligible}
      sexEligible={formInfo.sex_eligible}
      gendersEligibleOptions={gendersEligible}
      gendersEligible={formInfo.genders_eligible}
      inclusionCriteria={formInfo.inclusion_criteria || ''}
      exclusionCriteria={formInfo.exclusion_criteria || ''}
      categoriesMap={healthCategories}
      selectedResearchAreas={selectedResearchAreas}
      patients={formInfo.target_patients}
      controls={formInfo.target_controls}
      canEdit={canEdit}
      errors={formErrors}
      forceChange={forceChange}
      handleSingleValidation={handleSingleValidation}
    />

    <ContactForm
      handleChange={handleChange}
      contactName={formInfo.contact1_name}
      contactPosition={formInfo.contact1_position}
      contactPositionOptions={researcherPosition}
      contactEmail={formInfo.contact1_email}
      contactPhone={formInfo.contact1_phone}
      contactExtension={formInfo.contact1_phone_extension || ''}
      contact2Email={formInfo.contact2_email || ''}
      contact2Phone={formInfo.contact2_phone || ''}
      contact2Extension={formInfo.contact2_phone_extension || ''}
      contact2Name={formInfo.contact2_name || ''}
      contact2Position={formInfo.contact2_position || ''}
      canEdit={canEdit}
      errors={formErrors}
      handleSingleValidation={handleSingleValidation}
    />

    <ResearchEthicsBoard
      handleChange={handleChange}
      handleFile={handleFile}
      handleRemoveFile={handleRemoveFile}
      rebNumber={formInfo.reb_number || ''}
      RebFileCurrent={RebFileCurrent}
      rebExpiryDate={formInfo.reb_expiry || ''}
      additionalDocCurrent={additionalDocCurrent}
      additionalDocName={additionalDocName}
      rebFileName={rebFileName}
      rebCertificate={formInfo.reb_certificate || false}
      canEdit={canEdit}
      errors={formErrors}
      handleSingleValidation={handleSingleValidation}
    />

    <Comments
      handleChange={handleChange}
      comments={formInfo.comments}
      canEdit={canEdit}
      errors={formErrors}
    />

    <AdminComments
      adminComments={adminComments || ''}
    />
  </>
);
export default StudyForm;
