import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faSpinner,
  faEye,
  faEyeSlash,
  faXmark,
  faAngleDown,
  faBars,
  faPlus,
  faPaperPlane,
  faBirthdayCake,
  faVenusMars,
  faUser,
  faCheck,
  faCircleCheck,
  faCar,
  faCalendar,
  faDollar,
  faBuilding,
  faHandshake,
  faAddressCard,
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faUserDoctor,
  faGraduationCap,
  faUsers,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faCaretUp,
  faCaretDown,
  faHourglass,
  faInfoCircle,
  faArrowRightLong,
  faTrashCan,
  faRotateLeft,
  faExclamationCircle,
  faCheckCircle,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faMagnifyingGlass,
  faEarthAmericas,
  faBookmark,
} from '@fortawesome/free-solid-svg-icons';

import {
  faMoneyBill1, faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faSpinner,
  faEye,
  faEyeSlash,
  faXmark,
  faAngleDown,
  faBars,
  faPlus,
  faPaperPlane,
  faBirthdayCake,
  faVenusMars,
  faUser,
  faCheck,
  faCircleCheck,
  faMoneyBill1,
  faCar,
  faCalendar,
  faDollar,
  faBuilding,
  faHandshake,
  faAddressCard,
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faUserDoctor,
  faGraduationCap,
  faUsers,
  faPenToSquare,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faCaretUp,
  faCaretDown,
  faHourglass,
  faInfoCircle,
  faArrowRightLong,
  faTrashCan,
  faRotateLeft,
  faExclamationCircle,
  faCheckCircle,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faMagnifyingGlass,
  faEarthAmericas,
  faBookmark,
);
