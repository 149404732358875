import React, { ReactNode, useEffect, useState } from 'react';
import environment from '../../config/environment';
import { useCheckLogin } from '../../hooks/useCheckLogin';
import { useCookies } from '../../hooks/useCookies';
import { useUser } from '../../hooks/useUser';
import MainFooter from '../MainFooter';
import MainHeader from '../MainHeader';
import VolunteerHeader from '../VolunteerHeader';
import { AppWrapperStyle, BodyWrapper } from './styles';

type AppWrapperProps = {
  children: ReactNode,
  footer?: boolean
  alternateFooter?: boolean
  theme?: 'secondary'
}
const AppWrapper = ({
  children, footer, alternateFooter = false, theme,
}: AppWrapperProps) => {
  const { getCookie } = useCookies();
  const { getUserInfo, user } = useUser();
  const { hookRenewCookie } = useCheckLogin();
  const [isVolunteer, setIsVolunteer] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const userInfo = getUserInfo();
    setIsVolunteer(userInfo?.type !== 'researcher');
    if (userInfo && getCookie(environment.app.cookieName) !== 'undefined') {
      document.addEventListener('keypress', hookRenewCookie);
      document.addEventListener('mousemove', hookRenewCookie);
      document.addEventListener('touchstart', hookRenewCookie);

      return () => {
        document.removeEventListener('mousemove', hookRenewCookie);
        document.removeEventListener('keypress', hookRenewCookie);
        document.removeEventListener('touchstart', hookRenewCookie);
      };
    }
  }, []);

  return (
    <AppWrapperStyle theme={theme}>
      {isVolunteer
        ? (
          <VolunteerHeader
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            userName={`${user?.first_name} ${user?.last_name}`}
            userInitial={
              user && user.first_name && user.last_name
                ? `${user?.first_name[0]}${user?.last_name[0]}`
                : ''
            }
          />
        )
        : <MainHeader />}
      <BodyWrapper>
        {children}
      </BodyWrapper>
      {footer && <MainFooter alternate={alternateFooter} />}
    </AppWrapperStyle>
  );
};

export default AppWrapper;
