import React from 'react';
import { LinkComponent, TextInput } from '../../../../components';
import { ButtonStyled } from '../../../../components/Button/styles';
import { Checkbox } from '../../../../components/CheckBox';
import { ErrorMessage } from '../../../../components/ErrorMessage';
import FormSubtitle from '../../../../components/FormSubtitle';
import { NumberInput } from '../../../../components/NumberInput';
import { SelectInput } from '../../../../components/SelectInput';
import { TextH1, TextP } from '../../../../components/Text';
import { fonts } from '../../../../styles/theme';
import { profileEthnicityOptions, profileGenderOptions, profileSexOptions } from '../../../../utils/profileConst';
import {
  ButtonWrapper, DefaultWrapper, SectionStyled, CheckBoxErrorStyled, MobilePhoneWrapper,
} from '../../../../styles/MyProfileStyles';
import * as Styled from './styles';
import { VOLUNTEER_PROFILE_BASIC } from './const';

type MyProfileBasicProps = {
  handleChange: (value: string | number, field: string) => void
  handleCheck: (check: boolean, keyName: string, keyType: string) => void
  handleValidation: (formValues: IVolunteerUser, page: string) => void
  handleSingleValidation: (value: any, field: string, type: string) => void
  user: IVolunteerUser
  errors: any
}

const MyProfileBasic = ({
  user, handleChange, handleCheck, handleValidation, errors, handleSingleValidation,
}: MyProfileBasicProps) => (
  <DefaultWrapper>
    <SectionStyled>
      <TextH1 font={fonts.font1.H26} textAlgin="left">
        {VOLUNTEER_PROFILE_BASIC.TITLE}
      </TextH1>
    </SectionStyled>
    <Styled.FlexboxWrapper>
      <Styled.TextInputWrapper>
        <TextInput
          label={VOLUNTEER_PROFILE_BASIC.FIRST_NAME.LABEL}
          textValue={user.first_name}
          setTextValue={(value) => handleChange(value, 'first_name')}
          isRequired
          error={errors.first_name}
          onBlurAction={() => handleSingleValidation(user.first_name, 'first_name', 'profile')}
        />
      </Styled.TextInputWrapper>
      <TextInput
        label={VOLUNTEER_PROFILE_BASIC.LAST_NAME.LABEL}
        textValue={user.last_name}
        setTextValue={(value) => handleChange(value, 'last_name')}
        isRequired
        error={errors.last_name}
        onBlurAction={() => handleSingleValidation(user.last_name, 'last_name', 'profile')}
      />
    </Styled.FlexboxWrapper>
    <Styled.FlexboxWrapper>
      <Styled.TextInputWrapper>
        <NumberInput
          label={VOLUNTEER_PROFILE_BASIC.PHONE.LABEL}
          setNumberValue={(value) => handleChange(value, 'phone_number')}
          numberValue={Number(user.phone_number)}
          description={VOLUNTEER_PROFILE_BASIC.PHONE.DESCRIPTION}
          padding="md"
          unbold
          onBlurAction={() => handleSingleValidation(user.phone_number, 'phone_number', 'profile')}
          error={errors.phone_number}
        />
      </Styled.TextInputWrapper>
      <TextInput
        label={VOLUNTEER_PROFILE_BASIC.BIRTH.LABEL}
        setTextValue={(value) => handleChange(value, 'birth_date')}
        textValue={user.birth_date}
        onBlurAction={() => handleSingleValidation(user.birth_date, 'birth_date', 'profile')}
        error={errors.birth_date}
      />
    </Styled.FlexboxWrapper>

    <MobilePhoneWrapper>
      <TextInput
        label={VOLUNTEER_PROFILE_BASIC.EMAIL.LABEL}
        textValue={user.email}
        setTextValue={() => ''}
        isRequired={false}
        disabled
      />
      <Styled.FormUserMessageWrapper>
        <TextP>{VOLUNTEER_PROFILE_BASIC.EMAIL.EDIT_EMAIL.LABEL}
          <LinkComponent url={`MAILTO:${VOLUNTEER_PROFILE_BASIC.EMAIL.EDIT_EMAIL.LINK.URL}`}>
            {VOLUNTEER_PROFILE_BASIC.EMAIL.EDIT_EMAIL.LINK.LABEL}
          </LinkComponent>
        </TextP>
      </Styled.FormUserMessageWrapper>
    </MobilePhoneWrapper>

    <SelectInput
      label={VOLUNTEER_PROFILE_BASIC.SEX.LABEL}
      description={VOLUNTEER_PROFILE_BASIC.SEX.DISCRIPTION}
      value={user.sex ? user.sex : ''}
      onChange={(value) => handleChange(value, 'sex')}
      id="id"
      options={profileSexOptions}
      error={errors.sex}
    />
    <SectionStyled>
      <FormSubtitle subtitle={VOLUNTEER_PROFILE_BASIC.GENDER.LABEL} isRequired unbold />
      <TextP font={fonts.font1.H14}>{VOLUNTEER_PROFILE_BASIC.GENDER.DISCRIPTION}</TextP>
    </SectionStyled>
    {profileGenderOptions.map((gender) => (
      <Styled.CheckBoxStyled key={`gender-${gender.label}`}>
        <Checkbox
          label={gender.label}
          isChecked={user.gender ? user.gender.filter((selected) => selected === gender.value).length > 0 : false}
          onChange={(checked) => { handleCheck(checked, gender.value, 'gender'); }}
        />
      </Styled.CheckBoxStyled>
    ))}
    <CheckBoxErrorStyled>
      {errors.gender && <ErrorMessage message={errors.gender} />}
    </CheckBoxErrorStyled>
    <SectionStyled>
      <FormSubtitle subtitle={VOLUNTEER_PROFILE_BASIC.ETHNICITY.LABEL} isRequired unbold />
      <SectionStyled>
        <TextP font={fonts.font1.H14}>
          {VOLUNTEER_PROFILE_BASIC.ETHNICITY.DISCRIPTION}
        </TextP>
      </SectionStyled>
      <TextP font={fonts.font1.H14} lineHeight="1.33rem">
        {VOLUNTEER_PROFILE_BASIC.ETHNICITY.DISCRIPTION_TWO}
      </TextP>
    </SectionStyled>
    {profileEthnicityOptions.map((ethnicity) => (
      <Styled.CheckBoxStyled key={`ethnicity-${ethnicity.label}`}>
        <Checkbox
          label={ethnicity.label}
          isChecked={user.ethnicity ? user.ethnicity.includes(ethnicity.value) : false}
          onChange={(checked) => {
            handleCheck(checked, ethnicity.value, 'ethnicity');
          }}
          textTransform=""
        />
      </Styled.CheckBoxStyled>
    ))}
    <CheckBoxErrorStyled>
      {errors.ethnicity && <ErrorMessage message={errors.ethnicity} />}
    </CheckBoxErrorStyled>
    {user.ethnicity && user.ethnicity.includes('Other') && (
      <Styled.TextInputWrapper>
        <TextInput
          description={VOLUNTEER_PROFILE_BASIC.ETHNICITY.OTHER_LABEL}
          textValue={user.ethnicity_other || ''}
          setTextValue={(value) => handleChange(value, 'ethnicity_other')}
          error={errors.ethnicity_other}
          onBlurAction={() => handleSingleValidation(user.ethnicity_other, 'ethnicity_other', 'profile')}
        />
      </Styled.TextInputWrapper>
    )}
    <ButtonWrapper>
      <ButtonStyled
        onClick={() => handleValidation(user, 'MyProfile')}
        theme="myProfileButton"
      >
        {VOLUNTEER_PROFILE_BASIC.BUTTONS.SAVE}
      </ButtonStyled>
    </ButtonWrapper>
  </DefaultWrapper>
);

export default MyProfileBasic;
