export const RESEARCHER_DASHBOARD = {
  TABS: [
    {
      label: 'Opportunities',
      value: 'OPPORTUNITIES',
    },
    {
      label: 'Site Volunteers',
      value: 'SITE_VOLUNTEERS',
    },
  ],
  SEARCH_BAR: {
    PLACEHOLDER: 'Search for Project Name',
  },

  EMPTY_STATE: {
    title: 'No Projects',
    description: "You currently don't have any projects.",
  },
};

export const TABS = {
  OPPORTUNITIES: 'OPPORTUNITIES',
  SITE_VOLUNTEERS: 'SITE_VOLUNTEERS',
};
