import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useAPI } from '../../hooks';
import { usePasswordRequirement } from '../../hooks/usePasswordRequirement';
import { domainWhitelist } from '../../utils/domainWhiteList';
import { PasswordOnBlurSchema, CreateVolunteerSchema, VolunteerEmailSchema } from '../../utils/validation';

type volunteerInfoProps = {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  password: string
  passwordConfirm: string
  birthDateMonth?: string
  birthDateDay?: string
  birthDateYear?: string
  siteCode?: string
  healthCategories?: {
    name: string
    id: number
    category: string
  }[]
  birthDate?: string
  learnAboutReachBC?: string
  learnAboutReachBCOther?: string
}

export const useCreateVolunteerHook = () => {
  const captchaRef = useRef();

  const captchaHandler = (value: any) => {
    captchaRef.current = value;
  };
  const navigate = useNavigate();

  const { getHealthCategory, signUp, checkUserExist } = useAPI();
  const hookPassword = usePasswordRequirement();
  const [receiveNews, setReciveNews] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [researcherEmailType, setResearcherEmailType] = useState(false);

  const [forceChange, setForceChange] = useState(false);

  const [volunteerInfo, setvolunteerInfo] = useState<volunteerInfoProps>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    passwordConfirm: '',
    birthDateMonth: '',
    birthDateDay: '',
    birthDateYear: '',
    siteCode: '',
    healthCategories: [],
  });

  const [volunteerInfoError, setvolunteerInfoError] = useState<{ [key: string]: string }>({});
  const [selectedResearchAreas, setSelectedResearchArea] = useState<{ category: string, id: number, name: string }[]>([]);
  const [healthCategory, setHealthCategory] = useState<{ category: string, items: { id: number, item: string, category: string }[] }[]>([]);
  const [isCardOpen, setIsCardOpen] = useState(true);

  const handleSingleValidation = async (value: any, field: string, type?: string) => {
    let errors: { [key: string]: string } = {};
    try {
      if (type === 'password') {
        await PasswordOnBlurSchema.validate({
          ...value,
        }, { abortEarly: false });
      } else {
        await Yup.reach(CreateVolunteerSchema, field).validate(value);
      }
      errors = volunteerInfoError;
      if (type === 'password') {
        delete errors.password;
        delete errors.password_confirm;
      } else {
        delete errors[field];
      }
      setvolunteerInfoError({ ...volunteerInfoError });
    } catch (error: any) {
      if (type === 'password') {
        error.inner.forEach((err: any) => {
          errors[err.path] = err.message;
        });
        setvolunteerInfoError({ ...volunteerInfoError, ...errors });
      } else {
        errors[field] = error.message;
        setvolunteerInfoError({ ...volunteerInfoError, ...errors });
      }
    }
  };

  const checkEmail = async () => {
    setvolunteerInfoError({});
    try {
      const whiteEmail = volunteerInfo.email.match(`^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\\.)?[a-zA-Z]+\\.)?(${domainWhitelist.join('|')})$`);
      if (whiteEmail) {
        setResearcherEmailType(true);
      } else {
        setResearcherEmailType(false);
      }

      await VolunteerEmailSchema.validate({
        email: volunteerInfo.email,
      }, { abortEarly: false });
      await checkUserExist(volunteerInfo.email.toLowerCase());
    } catch (error: any) {
      if (error.inner) {
        setvolunteerInfoError({
          ...volunteerInfoError, email: error.inner[0].errors[0],
        });
      } else {
        setvolunteerInfoError({ ...volunteerInfoError, email: error });
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setvolunteerInfoError({});
    try {
      volunteerInfo.healthCategories = Object.values(selectedResearchAreas);
      await CreateVolunteerSchema.validate({
        ...volunteerInfo,
        emailExists: false,
        passwordSimilar: false,
      }, { abortEarly: false });

      if (!captchaRef.current) {
        setvolunteerInfoError({ recaptcha: 'Please check reCAPTCHA checkbox to complete a verification' });
        return;
      }

      const {
        birthDateMonth,
        birthDateYear,
        birthDateDay,
        email,
      } = volunteerInfo;

      const updatedValues = { ...volunteerInfo };
      updatedValues.birthDate = `${birthDateYear}-${birthDateMonth}-${birthDateDay}`;
      if (updatedValues.birthDateYear) {
        delete updatedValues.birthDateYear;
      }
      if (updatedValues.birthDateMonth) {
        delete updatedValues.birthDateMonth;
      }
      if (updatedValues.birthDateDay) {
        delete updatedValues.birthDateDay;
      }

      updatedValues.email = updatedValues.email.toLowerCase();
      const user = {
        user_type: 'volunteer',
        birthDate: `${birthDateYear}-${birthDateMonth}-${birthDateDay}`,
        receive_news: receiveNews,
        ...updatedValues,
      };

      const actionResult = await signUp(user);
      if (actionResult.status === 'success') {
        navigate('/login?status=email-verification', { state: { email } });
      }
      if (actionResult.status === 'failed') {
        throw new Error(actionResult.data.message);
      }
    } catch (error: any) {
      const errors: { [key: string]: string } = {};
      if (error.inner) {
        error.inner.forEach((element: any) => {
          errors[element.path] = element.message;
        });
        setvolunteerInfoError(errors);
      } else if (error.includes('email')) {
        setvolunteerInfoError({ email: error });
      } else {
        setvolunteerInfoError({ fetch: error });
      }
    }
  };

  const getHealthCategoryInfo = async () => {
    const category = await getHealthCategory();
    setHealthCategory(category);
  };

  const handleChange = (value: string, field: string) => {
    if (field !== 'email' && field !== 'password') {
      setIsCardOpen(false);
    }

    setvolunteerInfo((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const toggleResearchArea = (name: string, id: number, category: string) => {
    const areas = selectedResearchAreas;
    const tempIndex = areas.findIndex((item: {id: number}) => item.id === id);
    if (tempIndex !== -1) {
      areas.splice(tempIndex, 1);
    } else {
      areas.push({ name, id, category });
    }
    handleSingleValidation(areas, 'healthCategories');
    setForceChange(!forceChange);
    setSelectedResearchArea(areas);
  };

  return {
    hookPassword,

    isOpen,
    researcherEmailType,
    captchaRef,
    healthCategory,
    isCardOpen,
    volunteerInfoError,
    volunteerInfo,
    selectedResearchAreas,
    forceChange,
    receiveNews,

    captchaHandler,
    toggleResearchArea,
    handleChange,
    getHealthCategoryInfo,
    handleSubmit,
    setReciveNews,
    setIsCardOpen,
    setIsOpen,
    checkEmail,
    handleSingleValidation,
  };
};
