import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useResultForm } from '../../../../hooks/useResultForm';
import AppWrapper from '../../../../components/AppWrapper';
import ProjectForm from '../../../../components/ProjectForm';
import ResultForm from '../../../../components/ResultsForm';
import { studyResultSchema, ppoResultSchema } from '../../../../utils/validation';
import { studyResultForm } from '../../../../utils/researcherConst';

const ResultNew = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    formInfo,
    resourceFile,
    formErrors,
    projectType,
    linkStudies,
    handleToggle,
    handleChange,
    handleFile,
    handleValidation,
    handleSubmit,
    setProjectType,
    getLinkStudies,
    handleSingleValidation,
    setFormErrors,
    handleRemoveFile,
  } = useResultForm();

  const handleSubmitResults = async () => {
    const formValues: any = { ...formInfo };
    formValues.type = projectType;

    if (params.id) {
      formValues.project_id = parseInt(params.id, 10);
    }

    if (projectType === 'ppo') {
      delete formValues.patient_oriented_project;
    }

    const website: boolean = !!formValues.resource_website_url || false;
    if (!!formValues.resource_website_url && !formValues.resource_website_name) {
      formValues.resource_website_name = formValues.resource_website_url;
    }
    const needFileName: boolean = !!resourceFile || false;
    const checkFile = !!formValues.resource_file_name;

    try {
      const validitionSchema = projectType === 'study' ? studyResultSchema : ppoResultSchema;
      await handleValidation({
        ...formValues, website, needFileName, resource_file: resourceFile, checkFile,
      }, validitionSchema);

      delete formValues.link_study;

      const res = await handleSubmit(formValues);
      if (res.id) {
        navigate('/success/result', { state: { id: formValues.project_id, type: 'result', resultType: projectType } });
      }
    } catch (error: any) {
      setFormErrors(error);
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    if (location.pathname) {
      if (location.pathname.includes('study')) {
        setProjectType('study');
      } else {
        setProjectType('ppo');
        getLinkStudies();
      }
    }
  }, [location]);

  return (
    <AppWrapper>
      <ProjectForm
        generalInfo={studyResultForm}
        isSubmiting={false}
        isResults
        errors={formErrors}
        handleSubmit={handleSubmitResults}
        handleNavigate={handleNavigate}
      >
        <ResultForm
          handleToggle={handleToggle}
          handleChange={handleChange}
          handleFile={handleFile}
          result={formInfo}
          currentFile={resourceFile}
          handleRemoveFile={handleRemoveFile}
          errors={formErrors}
          projectType={projectType}
          linkStudyOptions={linkStudies}
          handleSingleValidation={handleSingleValidation}
        />
      </ProjectForm>
    </AppWrapper>
  );
};

export default ResultNew;
