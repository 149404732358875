import React from 'react';
import { ImageStyled, FigureStyled } from './styles';

type FigureProps = {
  imageSrc: string
  alt: string
  caption?: string
  maxWidth?: string
  maxHeight?: string
  marginBottom?: string
  width?: string
  theme?: string
};

export const Figure = ({
  imageSrc, alt, caption, maxWidth, marginBottom, maxHeight, width, theme,
}: FigureProps) => (
  <FigureStyled
    maxWidth={maxWidth}
    marginBottom={marginBottom}
    maxHeight={maxHeight}
    width={width}
    theme={theme}
  >
    <ImageStyled src={imageSrc} alt={alt} />
    {caption
      && <figcaption>{caption}</figcaption>}
  </FigureStyled>
);
