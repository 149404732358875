import React from 'react';
import { FileInput } from '../../../FileInput';
import FormSection from '../../../FormSelection';
import FormSubtitle from '../../../FormSubtitle';
import { RadioInput } from '../../../RadioInput';
import { TextP } from '../../../Text';
import { TextInput } from '../../../TextInput';
import * as Styled from '../styles';
import { RESEARCH_ETHICS } from './const';

type ResearchEthicsBoardProps = {
  handleChange: (value: string | boolean, field: string) => void
  handleSingleValidation: (value: any, field: string, type?: string) => void
  handleFile: (value: File, field: string) => void
  handleRemoveFile: (field: string) => void
  rebNumber: string
  RebFileCurrent?: File
  rebFileName?: string
  rebExpiryDate: string
  additionalDocCurrent?: File
  additionalDocName?: string
  rebCertificate: boolean
  canEdit: boolean
  errors: { [key: string]: string }
}

const ResearchEthicsBoard = ({
  rebNumber, handleChange, handleFile, RebFileCurrent, rebExpiryDate, additionalDocCurrent, errors, rebCertificate, canEdit, additionalDocName, rebFileName,
  handleRemoveFile, handleSingleValidation,
}: ResearchEthicsBoardProps) => (
  <FormSection
    title={RESEARCH_ETHICS.TITLE}
    subText={RESEARCH_ETHICS.DESCRIPTION}
  >
    <Styled.SectiontWrapper id="reb_certificate">
      <TextP lineHeight="1.33rem">
        {RESEARCH_ETHICS.SUB_DESCRIPTION}
      </TextP>
      <FormSubtitle
        subtitle={RESEARCH_ETHICS.REB_APPROVED.LABEL}
        link={{
          label: RESEARCH_ETHICS.REB_APPROVED.LINK.LABEL,
          url: RESEARCH_ETHICS.REB_APPROVED.LINK.URL,
        }}
        isRequired
      />
      <div>
        <Styled.DivStyled margin="0 0 0.875rem">
          <RadioInput
            id="reb_yes"
            name="reb_list"
            value="reb_yes"
            checked={rebCertificate}
            onChange={() => handleChange(true, 'reb_certificate')}
            disabled={!canEdit}
          />
          <Styled.LabelStyled htmlFor="reb_yes">
            {RESEARCH_ETHICS.REB_APPROVED.RADIO.YES}
          </Styled.LabelStyled>
        </Styled.DivStyled>
        <Styled.FlexWrapper>
          <RadioInput
            id="reb_no"
            name="reb_list"
            value="reb_no"
            checked={!rebCertificate}
            onChange={() => handleChange(false, 'reb_certificate')}
            disabled={!canEdit}
          />
          <Styled.LabelStyled htmlFor="reb_no">
            {RESEARCH_ETHICS.REB_APPROVED.RADIO.NO}
          </Styled.LabelStyled>
        </Styled.FlexWrapper>
      </div>
    </Styled.SectiontWrapper>
    {
      rebCertificate && (
        <>
          <Styled.SectiontWrapper id="reb_number">
            <TextInput
              label={RESEARCH_ETHICS.REB_NUMBER.LABEL}
              textValue={rebNumber}
              description={RESEARCH_ETHICS.REB_NUMBER.DESCRIPTION}
              setTextValue={(value) => handleChange(value, 'reb_number')}
              disabled={!canEdit}
              theme="form"
              error={errors.reb_number}
              onBlurAction={() => handleSingleValidation(rebNumber, 'reb_number', 'reb')}
            />
          </Styled.SectiontWrapper>
          <Styled.SectiontWrapper id="reb_file">
            <FileInput
              label={RESEARCH_ETHICS.REB_FILE.LABEL}
              description={RESEARCH_ETHICS.REB_FILE.DESCRIPTION}
              handleChange={handleFile}
              handleRemoveFile={handleRemoveFile}
              currentFile={RebFileCurrent}
              fileName={rebFileName}
              disabled={!canEdit}
              field="reb_file"
              isRequired
              error={errors.reb_file}
              onBlurAction={() => handleSingleValidation(RebFileCurrent, 'reb_file', 'reb')}
            />
          </Styled.SectiontWrapper>

          <Styled.SectiontWrapper id="reb_expiry">
            <TextInput
              label={RESEARCH_ETHICS.REB_EXPIRY_DATE.LABEL}
              description={RESEARCH_ETHICS.REB_EXPIRY_DATE.DESCRIPTION}
              placeholder={RESEARCH_ETHICS.REB_EXPIRY_DATE.PLACEHOLDER}
              textValue={rebExpiryDate}
              setTextValue={(value) => handleChange(value, 'reb_expiry')}
              disabled={!canEdit}
              theme="form"
              error={errors.reb_expiry}
              onBlurAction={() => handleSingleValidation(rebExpiryDate, 'reb_expiry', 'reb')}
            />
          </Styled.SectiontWrapper>

          <Styled.SectiontWrapper id="additinal_doc">
            <FileInput
              label={RESEARCH_ETHICS.ADDITIONAL_DOC.LABEL}
              description={RESEARCH_ETHICS.ADDITIONAL_DOC.DESCRICPTION}
              handleChange={handleFile}
              handleRemoveFile={handleRemoveFile}
              currentFile={additionalDocCurrent}
              fileName={additionalDocName}
              disabled={!canEdit}
              field="additional_doc"
              error={errors.additional_doc}
            />
          </Styled.SectiontWrapper>
        </>
      )
    }
  </FormSection>
);

export default ResearchEthicsBoard;
