import React from 'react';
import { passwordRequirementsConst } from '../../utils/passwordRequirementsConst';
import { colours } from '../../styles/theme';
import { PasswordCheckerWrapper, StyledFontAwesomeIcon, StyledTextP } from './style';

type PasswordRequiredmentCheckerPropTypes = {
  passwordRequirements: Record<string, boolean>
  testId?: string
}

export const PasswordRequirementChecker = ({ passwordRequirements }: Readonly<PasswordRequiredmentCheckerPropTypes>) => (
  <div>
    {passwordRequirementsConst.map((requirement) => (
      <PasswordCheckerWrapper>
        <StyledFontAwesomeIcon
          icon={passwordRequirements[requirement.type] ? 'check' : 'xmark'}
          color={passwordRequirements[requirement.type] ? colours.colour5 : colours.colour47}
          size="sm"
        />
        <StyledTextP>{requirement.text}</StyledTextP>
      </PasswordCheckerWrapper>
    ))}
  </div>
);
