import styled from 'styled-components';

type FigureStyledProps = {
  readonly maxWidth?: string
  readonly maxHeight?: string
  readonly marginBottom?: string
  readonly width?: string
  readonly theme?: string
}

export const FigureStyled = styled.figure<FigureStyledProps>`
  position: relative;
  display: flex;
  justify-content: center;
  max-width: ${({ maxWidth }) => (maxWidth)};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  max-height: ${({ maxHeight }) => (maxHeight)};
  width: ${({ width }) => (width)};
  height: ${({ theme }) => (theme === 'banner' ? '100%' : '')};
  
  @media (min-width: 520px) {
    height: auto;
  }
`;

export const ImageStyled = styled.img`
  max-width: 100%;
`;
