import styled from 'styled-components';
import { TextP } from '../../../Text';
import toRem from '../../../../utils/toRem';

export const TableFooterStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1rem 0;
  gap: ${toRem(16)};
  `;

export const NumberInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0rem 1rem;
  word-wrap: normal;
`;

export const NumberInputTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PageSetterWrapper = styled.div`
  display: flex;
  align-items: center;
  ${TextP} {
    margin-left: 0.5rem;
  }
`;

export const RowCountWrapper = styled.div`
  display: flex;
  align-items: center;
  ${TextP} {
    margin-right: 0.5rem;
  }
`;
