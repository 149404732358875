export const profileSexOptions = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Inter-sex',
    label: 'Inter-sex',
  },
  {
    value: 'Decline to State',
    label: 'Decline to State',
  },
];

export const profileLocationOptions = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Vancouver Island / Coast',
    lable: 'Vancouver Island / Coast',
  },
  {
    value: 'Lower Mainland',
    label: 'Lower Mainland',
  },
  {
    value: 'Thompson - Okanagan',
    label: 'Thompson - Okanagan',
  },
  {
    value: 'Kootenay',
    label: 'Kootenay',
  },
  {
    value: 'Cariboo',
    label: 'Cariboo',
  },
  {
    value: 'North Coast and Nechako',
    label: 'North Coast and Nechako',
  },
  {
    value: 'Northeast',
    label: 'Northeast',
  },
];

export const profileWillingnessToTravelOptions = [
  {
    value: 'any',
    label: 'Travel to any region',
  },
  {
    value: 'health_authority',
    label: 'Travel to my selected region(s)',
  },
  {
    value: 'remote_only',
    label: 'No travel. Online/phone/mail opportunities only',
  },
];

export const profileGenderOptions = [
  {
    value: 'Woman',
    label: 'Woman',
  },
  {
    value: 'Man',
    label: 'Man',
  },
  {
    value: 'Non-binary',
    label: 'Non-binary',
  },
  {
    value: 'Trans woman',
    label: 'Trans woman',
  },
  {
    value: 'Trans man',
    label: 'Trans man',
  },
  {
    value: 'Two-spirit',
    label: 'Two-spirit',
  },
  {
    value: 'Other',
    label: 'Other',
  },
  {
    value: 'Decline to State',
    label: 'Decline to State',
  },
];

export const profileEthnicityOptions = [
  {
    value: 'Arab',
    label: 'Arab',
  },
  {
    value: 'Black',
    label: 'Black',
  },
  {
    value: 'Chinese',
    label: 'Chinese',
  },
  {
    value: 'Filipino',
    label: 'Filipino',
  },
  {
    value: 'Indigenous (First Nations, M\xe9tis, Inuit within North America)',
    label: 'Indigenous (First Nations, M\xe9tis, Inuit within North America)',
  },
  {
    value: 'Indigenous (outside North America)',
    label: 'Indigenous (outside North America)',
  },
  {
    value: 'Japanese',
    label: 'Japanese',
  },
  {
    value: 'Korean',
    label: 'Korean',
  },
  {
    value: 'Latin American',
    label: 'Latin American',
  },
  {
    value: 'South Asian (e.g., Indian, Pakistani, Sri Lankan)',
    label: 'South Asian (e.g., Indian, Pakistani, Sri Lankan)',
  },
  {
    value: 'Southeast Asian (e.g., Vietnamese, Cambodian, Laotian, Thai)',
    label: 'Southeast Asian (e.g., Vietnamese, Cambodian, Laotian, Thai)',
  },
  {
    value: 'West Asian (e.g., Iranian, Afghan)',
    label: 'West Asian (e.g., Iranian, Afghan)',
  },
  {
    value: 'White',
    label: 'White',
  },
  {
    value: 'Other',
    label: 'Other group - specify in the text box below',
  },
  {
    value: 'Prefer not to answer',
    label: 'Prefer not to answer',
  },
];

export const InitialVolunteerUser: IVolunteerUser = {
  first_name: '',
  last_name: '',
  phone_number: NaN,
  birth_date: '',
  sex: '',
  gender: [],
  ethnicity: [],
  email: '',
  receive_matching_email: false,
  receive_news: false,
  for_child: false,
  project_type_preference: [],
  travel_distance: 'remote_only',
  travel_locations: [],
  affiliated_health_authority_region_id: 1,
  health_categories: [],
  ethnicity_is_other: false,
};

export const InitialResearcherUser: IResearcherUser = {
  first_name: '',
  last_name: '',
  phone_number: '',
  mobile_phone_number: '',
  email: '',
  position: '',
  ha_affiliation_id: 0,
  academic_affiliation_id: '',
  receive_news: false,
  researcher_term_accept: false,
};

export const InitialHealthCategory: IHealthCategory = {
  category: '',
  dscpt: '',
  id: 0,
  item: '',
  long_dscpt: '',
};

export const MyProfileSideMenu = [
  {
    value: 'My Profile',
    label: 'My Profile',
  },
  {
    value: 'Research Preferences',
    label: 'Research Preferences',
  },
  {
    value: 'Email Notifications',
    label: 'Email Notifications',
  },
  {
    value: 'Password Reset',
    label: 'Password Reset',
  },
];

export const urlToMenu: {[key: string]: string} = {
  myProfile: 'My Profile',
  'researcher-preferences': 'Research Preferences',
  'email-notifications': 'Email Notifications',
  'password-reset': 'Password Reset',
};

export const menuToUrl: {[key: string]: string} = {
  'My Profile': 'myProfile',
  'Research Preferences': 'researcher-preferences',
  'Email Notifications': 'email-notifications',
  'Password Reset': 'password-reset',
};
