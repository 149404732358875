import styled from 'styled-components';
import { colours } from '../../../styles/theme';

type EndWrappersProps = {
  readonly isLeftEnd?: boolean
}

type MobileLinkWrapperProps = {
  readonly theme?: string
}

export const HeaderStyled = styled.section`
  display: flex;
  width: 100%;
  flex: 1 1 1;
  padding: 0.938rem;
  justify-content: center;
  border-top: 4px solid ${colours.colour4};
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  position: relative;
  z-index: 10;

  @media (min-width: 1025px) {
    padding: 1.875rem 0.938rem;
    justify-content: space-between;
  };
`;

export const NavWrapper = styled.nav`
  display: none;

  @media (min-width: 1051px) {
    display: flex;
    align-items: flex-end;
  };
`;

export const DrawBodyWrapper = styled.div`
  padding: 1.875rem;
`;

export const MobileNavWrapper = styled.nav`
  margin-bottom: 1.875rem;
`;

export const MobileDivWrapper = styled.div`
  margin-bottom: 1.875rem;
`;

export const EndWrappers = styled.div<EndWrappersProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: ${({ isLeftEnd }) => (isLeftEnd ? 'flex-start' : 'flex-end')};

  @media (min-width: 1051px) {
    justify-content: center;
    width: 400px;
    max-width: 400px;
  };
`;

export const ItemWrapper = styled.div`
  margin-right: 1rem;
`;

export const MobileItemWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const ImageWrapper = styled.div`
  width: 123px;
  height: 28px;

  @media (min-width: 1025px) {
    width: 206px;
    height: 46px;  
  };
`;

export const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 1025px) {
    display: flex;
  };
`;

export const MobileWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1025px) {
    display: none;
  };
`;

export const MobileLinkWrapper = styled.div<MobileLinkWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: ${({ theme }) => (theme === 'accordion' ? `1px solid ${colours.colour28}` : `3px solid ${colours.colour2}`)};
  padding: ${({ theme }) => (theme === 'accordion' ? '1rem' : '0.5rem')};
  margin-bottom: ${({ theme }) => (theme === 'accordion' ? '0' : '0.625rem')} ;
`;

export const MobileMenuOptions = styled.div<MobileLinkWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  border-bottom: ${({ theme }) => (theme === 'accordion' ? `1px solid ${colours.colour28}` : `1px solid ${colours.colour9}`)};
  padding: ${({ theme }) => (theme === 'accordion' ? '1rem' : '0.5rem')};
`;

export const ButtonTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LinkStyled = styled.div`
  width: fit-content;
`;
