import styled from 'styled-components';

type IProps = {
  readonly color?: string
  readonly lineHeight?: string
  readonly fontWeight?: number
  readonly font?: string
  readonly textAlgin?: string
  readonly marginTop?: string
  readonly marginBottom?: string
}

export const TextH1 = styled.h1<IProps>`
  color: ${({ color }) => color};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ textAlgin }) => textAlgin || 'center'};
  ${({ font }) => `${font}`};
  margin-top: ${({ marginTop }) => marginTop};
`;

export const TextH2 = styled.h2<IProps>`
    ${({ font }) => `${font}`};
  color: ${({ color }) => color};
  line-height: ${({ lineHeight }) => lineHeight};
`;

export const TextH3 = styled.h3<IProps>`
  ${({ font }) => `${font}`};
  color: ${({ color }) => color};
  line-height: ${({ lineHeight }) => lineHeight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const TextH4 = styled.h4<IProps>`
  ${({ font }) => `${font}`};
  color: ${({ color }) => color};
  line-height: ${({ lineHeight }) => lineHeight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const TextP = styled.p<IProps>`
  ${({ font }) => `${font}`};
  color: ${({ color }) => color};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlgin }) => textAlgin || 'left'};
`;
