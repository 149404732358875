import { useState } from 'react';
import { useAPI } from '../../hooks';

export const useHook = () => {
  const { getSignedURL } = useAPI();

  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);

  const dragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const dragLeaveHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };

  const handleViewFile = (currentFile: File) => {
    if (!currentFile) return;
    const fileURL = URL.createObjectURL(currentFile);
    window.open(fileURL, '_blank');
  };

  const handleGetSignedURl = async (field: string, id?: number) => {
    if (!id) return;

    const { signedUrlLink } = await getSignedURL(id, field);
    if (signedUrlLink) {
      window.open(signedUrlLink, '_blank');
    }
  };

  return {
    isDraggingOver,
    handleViewFile,

    dragOverHandler,
    dragLeaveHandler,
    setIsDraggingOver,

    handleGetSignedURl,
  };
};
