import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { LinkComponent } from '../../../../components/Link';
import { TextH3, TextP } from '../../../../components/Text';
import {
  BoldTextStyled,
  ButtonSectionStyled,
  DetailBarStyled, IconWrapper, LinkButtonWrapper, LinkContentWrapper, LinkWrapper, SectionStyled, TextWrapper, PageWrapper,
} from './styles';
import { fonts } from '../../../../styles/theme';
import StatusTag from '../../../../components/StatusTag';
import { resultStatus } from '../../../../utils/researcherConst';
import TextBlockWithLink from '../../../../components/TextBlockWithLinks';

type ResultTabProps = {
  projectId: number
  projectType: string
  resultId?: number
  result: IResult | undefined
  userType: string
  getResult: (projectId: number, projectType: string) => void
  access: boolean
}

const ResultTab = ({
  projectId, resultId, result, projectType, userType, getResult, access,
}: ResultTabProps) => {
  useEffect(() => {
    if (!result && resultId) {
      getResult(projectId, projectType);
    }
  }, []);

  return (
    <PageWrapper>
      {!resultId && userType === 'researcher' && access ? (
        <LinkWrapper>
          <LinkComponent
            url={`${projectId}/results-form`}
            theme="buttonSecondary"
          >
            <LinkContentWrapper>
              <IconWrapper>
                <FontAwesomeIcon icon="plus" />
              </IconWrapper>
              <TextP>Add Results</TextP>
            </LinkContentWrapper>
          </LinkComponent>
        </LinkWrapper>
      ) : (
        <>
          {result && userType === 'researcher' && (
            <ButtonSectionStyled>
              <StatusTag
                theme={result.status}
                label={resultStatus[result.status]}
              />
              {access && (
                <TextWrapper type="button">
                  <LinkComponent
                    url={
                      projectType === 'study'
                        ? `/project/study/${projectId}/result/edit`
                        : `/project/ppo/${projectId}/result/edit`
                    }
                    theme="buttonSecondary"
                    linkWidth="fix-content"
                  >
                    <LinkButtonWrapper>
                      <IconWrapper>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </IconWrapper>
                      <TextP>Edit Results</TextP>
                    </LinkButtonWrapper>
                  </LinkComponent>
                </TextWrapper>
              )}
            </ButtonSectionStyled>
          )}
          <SectionStyled>
            <DetailBarStyled />
            <TextWrapper type="header">
              <TextH3 font={fonts.font1.H28B}>
                {projectType === 'study' ? 'Study' : 'PPO'} Results
              </TextH3>
            </TextWrapper>
            {result?.linked_study_id
              && (
                <TextWrapper type="text">
                  <LinkComponent url={`/project/study/${result.linked_study_id}`}>
                    <TextP font={fonts.font1.H14}>View Linked Research Study</TextP>
                  </LinkComponent>
                </TextWrapper>
              )}
            <TextWrapper type="text">
              {result?.summaryWithLink ? (
                <TextBlockWithLink paragraphArray={result.summaryWithLink} />
              ) : (
                <>
                  here
                  {result?.summary?.split('\r\n').map((para: string) => (
                    <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                      {para.length === 0 && (<br />)}
                      <TextP font={fonts.font1.H14} lineHeight="1.5rem">
                        {para}
                      </TextP>
                    </React.Fragment>
                  ))}
                </>
              )}
            </TextWrapper>
          </SectionStyled>

          {(result?.patient_oriented_project || (projectType === 'ppo' && !result?.linked_study_id)) && (
            <SectionStyled>
              <DetailBarStyled />
              <TextWrapper type="header">
                <TextH3 font={fonts.font1.H28B}>
                  Patient Partner Engagement Strategies
                </TextH3>
              </TextWrapper>
              {(result?.patient_oriented_project || (projectType === 'ppo' && !result?.linked_study_id)) && (
                <TextWrapper type="text">
                  <TextP font={fonts.font1.H14}>
                    <BoldTextStyled>Number of Patient Partners:{' '}</BoldTextStyled> {result?.patient_partners_count}
                  </TextP>
                </TextWrapper>
              )}
              <TextWrapper type="text">
                {result?.patient_partners_engagement_strategy?.split('\r\n').map((para: string) => (
                  <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                    {para.length === 0 && (<br />)}
                    <TextP font={fonts.font1.H14} lineHeight="1.5rem">{para}</TextP>
                  </React.Fragment>
                ))}
              </TextWrapper>
            </SectionStyled>
          )}
          {result?.study_limitations && (
            <SectionStyled>
              <DetailBarStyled />
              <TextWrapper type="header">
                <TextH3 font={fonts.font1.H28B}>
                  Study Limitations
                </TextH3>
              </TextWrapper>
              <TextWrapper type="text">
                {result.study_limitations.split('\r\n').map((para: string) => (
                  <React.Fragment key={para.length !== 0 ? para.slice(0, 10) : Math.random() * 123}>
                    {para.length === 0 && (<br />)}
                    <TextP font={fonts.font1.H14} lineHeight="1.5rem">{para}</TextP>
                  </React.Fragment>
                ))}
              </TextWrapper>
            </SectionStyled>
          )}

          {(result?.resource_website_url || result?.resource_file_name) && (
            <SectionStyled>
              <DetailBarStyled />
              <TextWrapper type="header">
                <TextH3 font={fonts.font1.H28B}>
                  Additional Information/Resources
                </TextH3>
              </TextWrapper>
              {result.resource_website_url && (
                <TextWrapper type="link">
                  <LinkComponent url={result.resource_website_url} newWindow>
                    <TextP font={fonts.font1.H14}>{result.resource_website_name}</TextP>
                  </LinkComponent>
                </TextWrapper>
              )}
              {result.resource_file_name && result.fileLink && (
                <TextWrapper type="link">
                  <LinkComponent url={result.fileLink} newWindow>
                    <TextP font={fonts.font1.H14}>
                      {result.resource_file_name || result.resource_file}
                    </TextP>
                  </LinkComponent>
                </TextWrapper>
              )}
            </SectionStyled>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default ResultTab;
