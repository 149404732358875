import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import environment from '../../config/environment';
import { imageList } from '../../utils/imageList';
import Button from '../Button';
import { Figure } from '../Figure';
import { LinkComponent } from '../Link';
import * as Styled from './styles';
import SideDraw from '../SideDraw';
import { TextP } from '../Text';

type IProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const LoginHeader = ({ isOpen, setIsOpen }: IProps) => (
  <Styled.MainHeaderStyled>
    <Styled.MainHeaderInnerStyled>
      <LinkComponent url={`${environment.app.websiteURL}/`}>
        <Styled.LogoWrapper>
          <Figure imageSrc={imageList.logoFull} alt="logo" />
        </Styled.LogoWrapper>
      </LinkComponent>

      <Styled.DesktopWrapper>
        <Styled.ButtonContainerWrapper>
          <Styled.LoginHeaderButtonWrapper>
            <LinkComponent url="/volunteer/create" theme="button">
              <Styled.ButtonLoginWrapper theme="center">
                <p>Sign Up</p>
              </Styled.ButtonLoginWrapper>
            </LinkComponent>
          </Styled.LoginHeaderButtonWrapper>
          <LinkComponent url="/login" theme="outline">
            <Styled.ButtonLoginWrapper theme="center">
              <p>Log In</p>
            </Styled.ButtonLoginWrapper>
          </LinkComponent>
        </Styled.ButtonContainerWrapper>
      </Styled.DesktopWrapper>

      <Styled.MobileWrapper>
        <Button id="menu" onClick={() => setIsOpen(!isOpen)} theme="menuButton">
          <Styled.ButtonTextWrapper>
            <FontAwesomeIcon icon={faBars} size="2x" />
          </Styled.ButtonTextWrapper>
        </Button>

        <SideDraw isOpen={isOpen} setIsOpen={setIsOpen}>
          <Styled.DrawBodyWrapper>

            <Styled.MobileDivWrapper>
              <Styled.MobileItemWrapper>
                <LinkComponent
                  url="/volunteer/create"
                  theme="button"
                >
                  <Styled.LinkStyled>
                    <TextP>Sign Up</TextP>
                  </Styled.LinkStyled>
                </LinkComponent>
              </Styled.MobileItemWrapper>

              <LinkComponent url="/login" theme="outline">
                <Styled.LinkStyled>
                  <TextP>
                    Log In
                  </TextP>
                </Styled.LinkStyled>
              </LinkComponent>
            </Styled.MobileDivWrapper>

            <Styled.MobileItemWrapper>
              <LinkComponent url={`${environment.app.websiteURL}/privacystatement`} theme="underlineSecondary">
                <TextP>Privacy Statement and Terms of Use</TextP>
              </LinkComponent>
            </Styled.MobileItemWrapper>
            <Styled.MobileItemWrapper>
              <LinkComponent url="mailto:info@reachbc.ca" theme="underlineSecondary">
                <TextP>Contact Us</TextP>
              </LinkComponent>
            </Styled.MobileItemWrapper>
          </Styled.DrawBodyWrapper>
        </SideDraw>
      </Styled.MobileWrapper>

    </Styled.MainHeaderInnerStyled>
  </Styled.MainHeaderStyled>
);

export default LoginHeader;
